import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import mq from '../../utils/mq';
import ReactSwiper from '../react-swiper';
import TeamItem from '../team-item';

const StyledTeamItem = styled(TeamItem)`
  width: 330px;
  ${mq.mobile(css`
    width: 300px;
  `)};
`;

function Carousel({ items, swiperOptions, ...rest }) {
  return (
    <ReactSwiper options={swiperOptions} {...rest}>
      {items.map((item, index) => {
        return (
          <ReactSwiper.Item key={index}>
            <StyledTeamItem {...item} />
          </ReactSwiper.Item>
        );
      })}
    </ReactSwiper>
  );
}

export default Carousel;
