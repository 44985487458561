import React from 'react';

import TeamCarousel from '../../team-carousel';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import { parseTeamMembers } from '../../../hooks/parse-team-members';

function StoryblokTeamCarouselAdapter({
  teamMembers,
  link,
  linkText,
  ...rest
}) {
  const teamMembersParsed = parseTeamMembers(
    teamMembers && teamMembers.content && teamMembers.content.team
  );
  const parsedLink = parseStoryblokLink(link);

  return (
    <TeamCarousel
      {...rest}
      teamMembers={teamMembersParsed}
      link={
        parsedLink &&
        linkText && {
          to: parsedLink,
          children: linkText,
        }
      }
    />
  );
}

export default StoryblokTeamCarouselAdapter;
