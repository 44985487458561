import React from 'react';
import SectionAccordion from '../../section-accordion';
import RichtextParser from '../../../utils/richtext-parser';

function StoryblokFaqBlockAdapter({ title, subtext, paragraph, questions }) {
  let parsedQuestions = [];

  questions.forEach((question) => {
    let questionObj = {
      question: question.question,
      answer: RichtextParser(question.answer),
    };
    parsedQuestions.push(questionObj);
  });

  return (
    <SectionAccordion
      title={title}
      subtext={RichtextParser(subtext)}
      bodyText={paragraph}
      data={parsedQuestions}
    />
  );
}

export default StoryblokFaqBlockAdapter;
