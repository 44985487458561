import React from 'react';
import styled, { css } from 'styled-components';

import Title from './title';
import { Box, Flex, Paragraph } from './UIKit/system';
import iconPackage from '../images/icon-package-purple.png';
import LazyImage from './lazy-image';
import LinkWithArrow from './link-with-arrow';
import { supportsGrid } from '../utils/styles';
import Container from './container';
import { STANDARD_PADDING } from '../constants';
import mq from '../utils/mq';

const StyledSectionContact = styled(Box)`
  ${mq.mobile(css`
    background-color: #fff;
  `)};
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    width: 33.33%;
  }
  ${mq.mobile(css`
    > * {
      width: 100%;
      background-color: #f8f8f8;
      margin-top: 8px;
      margin-bottom: 8px;
      justify-content: flex-start;
      padding: 22px 16px 26px;
    }
  `)};
`;

const ItemInner = styled(Flex)`
  ${supportsGrid(css`
    display: grid;
    justify-items: center;
    grid-template-rows: auto 1fr auto;
    align-items: flex-start;
  `)}
  ${mq.mobile(
    supportsGrid(css`
      justify-items: self-start;
    `)
  )};
`;

const StyledParagraph = styled(Paragraph)`
  line-height: 1.75;

  p {
    margin-bottom: 0;
  }
`;

function SectionContact({ items }) {
  return (
    <StyledSectionContact>
      <Container>
        <Box
          maxWidth={1092}
          py={69}
          bg={['transparent', '#f8f8f8']}
          mx={'auto'}
        >
          <Title as={'h2'} mb={61} textAlign={'center'} fontSizeSmall>
            Contact Details
          </Title>
          <Columns>
            {items.map((item, index) => {
              return (
                <Flex
                  key={index}
                  justifyContent={['flex-start', 'center', 'center']}
                >
                  <ItemInner
                    flexDirection={'column'}
                    alignItems={['flex-start', 'center', 'center']}
                    textAlign={['left', 'center', 'center']}
                    maxWidth={['unset', 320, 320]}
                  >
                    <Flex
                      flexDirection={'column'}
                      alignItems={['flex-start', 'center', 'center']}
                    >
                      <LazyImage
                        mb={[15, 24, 24]}
                        width={'100%'}
                        maxWidth={36}
                        fluid={item.icon}
                      />
                      <Title mb={[7, 15, 15]} fontSizeExtraSmall>
                        {item.title}
                      </Title>
                    </Flex>
                    <div>
                      {item.text && (
                        <StyledParagraph
                          fontSizeSmall
                          grey
                          html={item.text}
                          px={[0, 10, 0]}
                        />
                      )}
                      {item.bottomText && (
                        <StyledParagraph
                          fontSizeSmall
                          bold
                          html={item.bottomText}
                        />
                      )}
                    </div>
                    {item.link && (
                      <LinkWithArrow mt={[11, 28, 28]} to={item.link}>
                        {item.linkName}
                      </LinkWithArrow>
                    )}
                  </ItemInner>
                </Flex>
              );
            })}
          </Columns>
        </Box>
      </Container>
    </StyledSectionContact>
  );
}

export default SectionContact;
