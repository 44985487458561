import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import ArticleTileItem from './article-tile-item';
import { supportsGrid } from '../utils/styles';
import Title from './title';
import { Paragraph, Text } from './UIKit/system';
import mq from '../utils/mq';
import SubscriptionBlock from './subscription-block';
import CustomStackGrid from './custom-stack-grid';

const StyledArticleTileList = styled.div``;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    ${(props) =>
      props.threeColumns &&
      css`
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
      `};

    ${(props) =>
      props.fourColumns &&
      css`
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
      `};
  `)}
  ${mq.tablet(css`
    overflow-x: auto;
    justify-content: flex-start;
    scroll-snap-type: x mandatory;
    padding-left: 20px;
    height: 420px;

    > * :last-of-type {
      padding-right: 20px;
    }
  `)};
`;

const TitleContainer = styled.div`
  margin-top: 72px;
  margin-bottom: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const StyledTitle = styled(Title)`
  font-weight: bold;
  font-size: 42px;
  line-height: 46px;
  text-align: center;
  color: #161616;
  ${mq.mobile(css`
    font-size: 28px;
    line-height: 36px;
  `)};
`;
const StyledParagraph = styled(Paragraph)`
  margin-top: 18px;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #666666;
  ${mq.mobile(css`
    font-size: 16px;
    line-height: 28px;
  `)};
`;

function ArticleTileList({
  items,
  threeColumns,
  fourColumns,
  title,
  paragraph,
  related,
  noSubscriptionBlock,
  ...rest
}) {
  const SUBSCRIPTION_BLOCK_POSITION = 3;
  const itemsModified = useMemo(() => {
    if (noSubscriptionBlock) {
      return items;
    }
    return [
      ...items.slice(0, SUBSCRIPTION_BLOCK_POSITION),
      null,
      ...items.slice(SUBSCRIPTION_BLOCK_POSITION),
    ];
  }, [items]);

  return (
    <StyledArticleTileList {...rest}>
      {title && (
        <TitleContainer>
          <StyledTitle>{title}</StyledTitle>
          {paragraph && <StyledParagraph>{paragraph}</StyledParagraph>}
        </TitleContainer>
      )}
      {!related ? (
        <CustomStackGrid pt={0} pb={0}>
          {itemsModified &&
            itemsModified.map((item, index) => {
              if (
                !noSubscriptionBlock &&
                index === SUBSCRIPTION_BLOCK_POSITION
              ) {
                return <SubscriptionBlock />;
              }

              return (
                <ArticleTileItem
                  key={index}
                  small={threeColumns || fourColumns}
                  {...item}
                />
              );
            })}
        </CustomStackGrid>
      ) : (
        <Items threeColumns={threeColumns} fourColumns={fourColumns}>
          {items &&
            items.map((item, index) => {
              return (
                <ArticleTileItem
                  key={index}
                  small={threeColumns || fourColumns}
                  {...item}
                />
              );
            })}
        </Items>
      )}
    </StyledArticleTileList>
  );
}

export default ArticleTileList;
