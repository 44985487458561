import React from 'react';
import styled from 'styled-components';

import NarrowContent from './narrow-content';
import { Paragraph } from './UIKit/system';
import Container from './container';
import { STANDARD_PADDING } from '../constants';

const StyledFormattedTextBlock = styled.section`
  &,
  p {
    letter-spacing: 0;
  }

  &:first-of-type {
    padding-top: 66px;
  }
`;

function FormattedTextBlock({ children }) {
  return (
    <StyledFormattedTextBlock mb={STANDARD_PADDING}>
      <Container>
        <NarrowContent>
          <Paragraph lineHeight={1.78} grey centered>
            {children}
          </Paragraph>
        </NarrowContent>
      </Container>
    </StyledFormattedTextBlock>
  );
}

export default FormattedTextBlock;
