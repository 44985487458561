import React from 'react';

import { Text } from './UIKit/system';
import styled, { css } from 'styled-components';
import Separator from './separator-vertical';
import mq from '../utils/mq';

const StyledTitleWithSeparator = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.split &&
    mq.mobile(css`
      flex-wrap: wrap;
    `)}
`;

const TopLayer = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.split &&
    mq.mobile(css`
      width: 100%;
      margin-bottom: 2.5px;
    `)};
`;

const BottomLayer = styled.div`
  display: flex;
`;

function TitleWithSeparator({ title, subtitle, large, split }) {
  const fontSize = [16, 18, 20];

  return (
    <StyledTitleWithSeparator split={split}>
      <TopLayer split={split}>
        <Text
          fontSize={fontSize}
          fontSizeExtraLarge={large}
          color={'#161616'}
          bold
        >
          {title}
        </Text>
        {subtitle && title && <Separator mx={7} />}{' '}
      </TopLayer>
      <BottomLayer>
        <Text color={'#959595'} fontSize={fontSize} fontSizeExtraLarge={large}>
          {subtitle}
        </Text>
      </BottomLayer>
    </StyledTitleWithSeparator>
  );
}

export default TitleWithSeparator;
