import React from 'react';
import { Field } from 'formik';

import InputCards from '../controls/input-cards';
import StepBase from './step-base';

function StepInputCardsFactory({
  fieldName,
  options,
  values,
  yesNoType,
  fontSizeLarge,
  onNext,
  ...rest
}) {
  return (
    <StepBase {...rest}>
      <Field
        name={fieldName}
        yesNoType={yesNoType}
        options={options}
        component={InputCards}
        values={values}
        fontSizeLarge={fontSizeLarge}
        onSelected={onNext}
      />
    </StepBase>
  );
}

export default StepInputCardsFactory;
