import React from 'react';

import RichtextParser from '../../../utils/richtext-parser';
import Intro4 from '../../intro4';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed';

function StoryblokIntroIconicLinksAdapter({
  links,
  bottomText,
  backgroundImage,
  ...rest
}) {
  return (
    <Intro4
      {...rest}
      links={links.map((v) => ({
        ...v,
        icon: getFluidGatsbyImage(v.icon),
        link: parseStoryblokLink(v.link),
      }))}
      bottomText={RichtextParser(bottomText)}
      backgroundImage={getFluidGatsbyImage(backgroundImage, {
        maxWidth: 2560,
      })}
    />
  );
}

export default StoryblokIntroIconicLinksAdapter;
