import React from 'react';

import { Box, Text } from '../UIKit/system';
import Title from '../title';
import StyledLink from '../styled-link';
import { createMailTo } from '../../utils/utils';
import { EMAIL_CONTACT } from '../../constants';

function Header({ title, subtitle }) {
  return (
    <Box mb={[40, 60, 80]} textAlign={'center'}>
      <Text mb={7} fontSizeExtraSmall>
        {subtitle}
      </Text>
      <Title mb={18}>{title}</Title>
      <Text>
        Hate forms?{' '}
        <StyledLink to={createMailTo(EMAIL_CONTACT)} colorPrimary>
          Send an email instead.
        </StyledLink>
      </Text>
    </Box>
  );
}

export default Header;
