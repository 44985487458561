import React, { useMemo } from 'react';

import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';
import SectionComparisonGrid from '../../section-comparison-grid';

function StoryblokSystemsComparisonGridAdapter({
  title,
  subtitle,
  naturalSystemsLogo,
  mechanicalSystemsLogo,
  comparisonItems,
}) {
  const ICON_MAX_WIDTH = 160;

  const paramsArr = useMemo(() => {
    return comparisonItems.map((item) => {
      return {
        title: item.title,
        subtitle: item.subtitle,
      };
    });
  }, [comparisonItems]);

  const transformedData = useMemo(() => {
    const result = [
      {
        title: 'Pressurisation Systems',
        icon: getFluidGatsbyImage(naturalSystemsLogo, {
          maxWidth: ICON_MAX_WIDTH,
        }),
        data: [],
      },
      {
        title: 'Mechanical Systems',
        icon: getFluidGatsbyImage(mechanicalSystemsLogo, {
          maxWidth: ICON_MAX_WIDTH,
        }),
        data: [],
      },
    ];

    comparisonItems.forEach((item) => {
      result[0].data.push({
        title: item.title,
        text: item.naturalSubtitle,
        availability: item.naturalState,
      });
      result[1].data.push({
        title: item.title,
        text: item.mechanicalSubtitle,
        availability: item.mechanicalState,
      });
    });

    return result;
  }, [comparisonItems]);

  return (
    <SectionComparisonGrid
      title={title}
      text={subtitle}
      params={paramsArr}
      data={transformedData}
    />
  );
}

export default StoryblokSystemsComparisonGridAdapter;
