import React from 'react';
import styled, { css } from 'styled-components';
import mq from '../utils/mq';
import Title from './title';
import { Text } from '../components/UIKit/system';
import Button from './button';
import BackgroundImg from '../images/cta-banner-background.png';
import { Box, Flex } from './UIKit/system';
import Container from './container';
import { STANDARD_PADDING, OFFSET_SIDE_SM } from '../constants';

const BannerWithCtaOuterContainer = styled(Box)`
  display: flex;
  justify-content: center;
  ${mq.mobile(css`
    width: 100%;
    padding: 0px;
  `)};
`;

const StyledContainer = styled(Container)`
  ${mq.mobile(css`
    width: 100%;
    border-left-width: 0px;
    border-right-width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  `)};
`;

const BannerWithCtaContainer = styled(Flex)`
  text-align: center;
  background-size: cover;
  background-position: center;
  background-color: #f8f8f8;
  justify-content: center;
  ${mq.mobile(css`
    align-items: flex-start;
    padding-left: ${OFFSET_SIDE_SM}px;
  `)};
`;

const StyledTitle = styled(Title)`
  font-weight: bold;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.02em;
  max-width: 648px;
  margin-bottom: 17px;
  color: ${Title.color};
  ${mq.mobile(css`
    padding-right: ${OFFSET_SIDE_SM}px;
    font-size: 32px;
    line-height: 38px;
    text-align: left;
  `)};
`;

const Paragraph = styled(Text)`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Text.color};
  max-width: 769px;
  margin-bottom: 31px;
  ${mq.tablet(css`
    width: 90%;
  `)};
  ${mq.mobile(css`
    padding-right: ${OFFSET_SIDE_SM}px;
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
  `)};
`;

function BannerWithCta ( { title, text, button, backgroundImage, color, whiteTransparent = true, hasContainer = true } ) {
  const colorProps = {}
  if ( color ) Reflect.set( colorProps, 'color', color )

  const Banner = () => (
    <BannerWithCtaContainer
      flexDirection={'column'}
      alignItems={'center'}
      style={{ backgroundImage: `url(${backgroundImage ? backgroundImage.filename : BackgroundImg})` }}
      pt={75}
      pb={52}
    >
      <StyledTitle {...colorProps} html={title} />
      <Paragraph color={color || '#666666'} html={text} />
      <Button {...button} whiteTransparent={whiteTransparent} />
    </BannerWithCtaContainer>
  )

  if (!hasContainer) return <Banner />

  return (
    <BannerWithCtaOuterContainer py={STANDARD_PADDING}>
      <StyledContainer>
        <Banner />
      </StyledContainer>
    </BannerWithCtaOuterContainer>
  );
}

export default BannerWithCta;
