import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import Container from './container';
import Title from './title';
import { Box } from './UIKit/system';
import ReactSwiper from './react-swiper';
import mq from '../utils/mq';
import ProductPreview from './product-preview';
import ArrowRight from '../images/svg/arrow-right.svg';
import {
  getThemeColor,
  hoverDefault,
  hoverScaleDefault,
} from '../utils/styles';

const StyledRelatedProducts = styled(Box)`
  overflow-x: hidden;
`;

const StyledProductPreview = styled(ProductPreview)`
  height: 420px;
  margin-right: 70px !important;
  margin-bottom: 19px;
  width: calc(50% - 19px);

  ${mq.desktopSmall(css`
    width: 100%;
    max-width: 298px;
    height: 218px;
    padding-right: 70px !important;
  `)};
`;

const StyledNavigationButton = styled.div`
  ${hoverScaleDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  cursor: pointer;
  margin-left: 47px;
  margin-right: 47px;

  &:hover {
    transform: translateY(-50%) scale(1.025);
  }

  &:active {
    transform: translateY(-50%) scale(1.05);
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.prev &&
    css`
      left: 0;

      svg {
        transform: scaleX(-1);
      }
    `};

  ${(props) =>
    props.next &&
    css`
      right: 0;
    `}
`;

const SliderContainer = styled(motion.div)`
  position: relative;
`;

const NavigationButtonContainer = styled(motion.div)``;

const StyledArrowRight = styled(ArrowRight)`
  stroke: ${getThemeColor('black')};
`;

const NavigationButton = React.forwardRef((props, ref) => {
  return (
    <StyledNavigationButton {...props} ref={ref}>
      <StyledArrowRight />
    </StyledNavigationButton>
  );
});

function RelatedProducts({ title, products }) {
  const [swiperOptions, setSwiperOptions] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [slidePos, setSlidePos] = useState(1);
  const prevElRef = useRef(null);
  const nextElRef = useRef(null);

  useEffect(() => {
    setSwiperOptions(createSwiperOptions());
  }, []);

  function handleNext() {
    if (slidePos !== products.length) {
      setSlidePos(slidePos + 1);
    } else {
      setSlidePos(1);
    }
  }

  function handlePrev() {
    setSlidePos(slidePos - 1);
  }

  function createSwiperOptions() {
    return {
      slidesPerView: 'auto',
      spaceBetween: 20,
      autoHeight: true,
      navigation: {
        prevEl: prevElRef.current,
        nextEl: nextElRef.current,
      },
      breakpoints: {
        1100: {
          slidesPerView: 'auto',
          autoHeight: true,
        },
        768: {
          spaceBetween: 10,
        },
      },
    };
  }
  return (
    <StyledRelatedProducts pt={80} pb={68}>
      <Container style={{ overflow: 'hidden' }}>
        <Title mb={64} textAlign={'center'} fontSizeSmall>
          {title}
        </Title>
        <SliderContainer
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
        >
          {products && (
            <ReactSwiper options={swiperOptions}>
              {products.map((item, index) => {
                return (
                  <ReactSwiper.Item key={index}>
                    <StyledProductPreview {...item} altStyling />
                  </ReactSwiper.Item>
                );
              })}
            </ReactSwiper>
          )}
          <NavigationButtonContainer animate={{ opacity: isHovered ? 1 : 0 }}>
            <NavigationButton
              style={{ display: slidePos === 1 ? 'none' : 'flex' }}
              prev
              ref={prevElRef}
              onClick={handlePrev}
            />
          </NavigationButtonContainer>
          <NavigationButtonContainer animate={{ opacity: isHovered ? 1 : 0 }}>
            <NavigationButton next ref={nextElRef} onClick={handleNext} />
          </NavigationButtonContainer>
        </SliderContainer>
      </Container>
    </StyledRelatedProducts>
  );
}

export default RelatedProducts;
