import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { createHubSpotForm } from '../utils/utils';
import Spinner from './spinner';
import { hubSpotFormStyles } from './hubspot-form.styles';
import { hubSpotFormStylesAlt } from './hubspot-form.styles-alt';

const StyledHubSpotForm = styled.div`
  ${(props) => (props.altStyling ? hubSpotFormStylesAlt : hubSpotFormStyles)};
  letter-spacing: 0;

  h1 {
    color: red;
  }
`;

function HubSpotForm({
  portalId,
  formId,
  altStyling,
  darkBackground,
  fieldsFullWidth,
  onFormSubmitted,
  onFormReady
}) {
  const created = useRef(false);
  const embeddedCodeNode = useRef(null);
  const [loading, setLoading] = useState(true);
  const targetId = `hubspot-form-${formId}`;

  useEffect(() => {
    return () => {
      embeddedCodeNode.current.innerHTML = '';
    };
  }, []);

  useEffect(() => {
    if (created.current) {
      return;
    }
    createHubSpotForm({
      portalId,
      formId,
      targetId,
      onFormReady: handleFormReady,
      onFormSubmitted,
    });
    created.current = true;
  }, [portalId, formId]);

  function handleFormReady() {
    setLoading(false);
    removeEmptyLabels();
    addLogicForSelectInputs();
    onFormReady && onFormReady(embeddedCodeNode.current);
  }

  function removeEmptyLabels() {
    const labelNodes = [...embeddedCodeNode.current.querySelectorAll('label')];
    labelNodes.forEach((node) => {
      if (!node.innerText || node.innerText === '*') {
        node.style.display = 'none';
      }
    });
  }

  function addLogicForSelectInputs() {
    const selectInputs = [
      ...embeddedCodeNode.current.querySelectorAll('select'),
    ];
    selectInputs.forEach(changeSelectInput);
  }

  function changeSelectInput(selectNode) {
    const parentNode = selectNode.parentElement;
    const selected = document.createElement('div');
    selected.classList.add('selected');
    selected.innerHTML = 'Please select';
    selectNode.addEventListener('change', (e) => {
      selected.innerHTML = e.currentTarget.value;
    });
    parentNode.appendChild(selected);
  }

  return (
    <StyledHubSpotForm
      altStyling={altStyling}
      fieldsFullWidth={fieldsFullWidth}
      darkBackground={darkBackground}
    >
      {loading && <Spinner />}
      <span id={targetId} ref={embeddedCodeNode} />
    </StyledHubSpotForm>
  );
}

export default HubSpotForm;
