import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Sticky } from 'react-sticky';

import Container from './container';
import { FILTER_ALL, HEADER_HEIGHT } from '../constants';
import DropdownFilter from './dropdown-filter';
import { createDropdownFilterOptions } from '../utils/utils';
import mq from '../utils/mq';

const OuterContainer = styled.div`
  width: 100%;
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 5;
`;

const FilterBarContainer = styled.div`
  width: 100%;
  height: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 5;
`;

const TitleContainer = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #161616;
`;

const FilterOptionsContainer = styled.div`
  height: 100%;
  display: flex;
  z-index: 5;
`;

const BuildingTypeFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 33px;

  &:before {
    content: '';
    height: 30%;
    width: 1px;
    background-color: #d4d4d4;
    margin-right: 25px;
  }

  ${mq.tablet(css`
    margin-left: 20px;

    &:before {
      margin-right: 15px;
    }
  `)};
`;

const BuildingTypeOption = styled.div`
  position: relative;
  margin: 0 14.5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #959595;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  ${mq.tablet(css`
    margin: 0 10px;
  `)};

  ${(props) =>
    props.active &&
    css`
      color: #161616;
    `}
`;

const DropdownFiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default function CaseStudiesBrowserFilter({
  locations,
  propertyTypes,
  handleFilterUpdate,
  propertyType,
  projectType,
  location,
}) {
  const propertyTypeOptions = createDropdownFilterOptions(propertyTypes.edges);
  const locationOptions = createDropdownFilterOptions(locations.edges);

  function handlePropertyTypeChange(type) {
    handleFilterUpdate('propertyType', type);
    // resetScroll();
  }

  function handleLocationSelect(location) {
    handleFilterUpdate('location', location);
    // resetScroll();
  }

  function handleBuildingTypeSelect(type) {
    handleFilterUpdate('projectType', type);
    // resetScroll();
  }

  // function resetuScroll() {
  //   scroll.scrollTo(940, {
  //     duration: 600,
  //   });
  // }

  return (
    <Sticky topOffset={848} disableHardwareAcceleration>
      {({ style, isSticky }) => {
        return (
          <OuterContainer
            style={{ ...style, top: isSticky ? HEADER_HEIGHT : 'auto' }}
          >
            <Container>
              <FilterBarContainer>
                <TitleContainer>Case Studies</TitleContainer>
                <FilterOptionsContainer>
                  <DropdownFiltersContainer>
                    <DropdownFilter
                      title={'Property Type'}
                      currentOption={propertyType}
                      options={propertyTypeOptions}
                      onChange={handlePropertyTypeChange}
                    />
                    <DropdownFilter
                      title={'Location'}
                      currentOption={location}
                      options={locationOptions}
                      onChange={handleLocationSelect}
                    />
                  </DropdownFiltersContainer>
                  <BuildingTypeFilter>
                    <BuildingTypeOption
                      onClick={() => handleBuildingTypeSelect('natural')}
                      active={projectType === 'natural'}
                    >
                      Natural
                    </BuildingTypeOption>
                    <BuildingTypeOption
                      onClick={() => handleBuildingTypeSelect('mechanical')}
                      active={projectType === 'mechanical'}
                    >
                      Mechanical
                    </BuildingTypeOption>
                    <BuildingTypeOption
                      onClick={() => handleBuildingTypeSelect('')}
                      active={!projectType}
                    >
                      All
                    </BuildingTypeOption>
                  </BuildingTypeFilter>
                </FilterOptionsContainer>
              </FilterBarContainer>
            </Container>
          </OuterContainer>
        );
      }}
    </Sticky>
  );
}
