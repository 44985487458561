export interface AdvancedFormulaInput {
  dimensions: Dimensions
  storeys: number
  shaftHeight: number
  shaftConstruction: string
  damperExtractRate: number
  safetyGrilles: boolean
  safetyGrillesQuantity?: number
  safetyGrillesFreeArea?: number
  shaftContractions: boolean
  shaftContractionType?: string
  shaftContractionDimensions?: ShaftContractionOrExpansionDimensions
  shaftExpansions: boolean
  shaftExpansionType?: string
  shaftExpansionDimensions?: ShaftContractionOrExpansionDimensions
  doors: number
  liftDoors: number
  smokeControlDamperSize: DamperSize
  smokeControlDamperQuantity: number
  environmentalDamper: boolean
  environmentalDamperSize?: DamperSize
  environmentalDamperQuantity?: number
  verticalPod: boolean
  verticalVeinsQuantity?: number
  features: string[]
}
export interface Dimensions {
  shaftWidth: number
  shaftDepth: number
}
export interface ShaftContractionOrExpansionDimensions {
  smaller: number
  larger: number
}
export interface DamperSize {
  type: string
  overallWidth: number
  overallHeight: number
  size: string
  geometricArea: number
  freeArea: number
  velocities: DamperSizeVelocitiesEntity[]
}
export interface DamperSizeVelocitiesEntity {
  velocity: number
  flowRate: number
  pressureDrop: number
  noiseLevel: number
}

export const constructionFactors = new Map([
  [
    'ductwork',
    {
      elta: 1,
      safetyFactor: 0.2,
      totalCorrectionFactor: 1.2
    }
  ],
  [
    'drylining',
    {
      elta: 1.5,
      safetyFactor: 0.2,
      totalCorrectionFactor: 1.8
    }
  ],
  [
    'concrete',
    {
      elta: 2.2,
      safetyFactor: 0.2,
      totalCorrectionFactor: 2.64
    }
  ]
])

export const veinsLossCoefficients = new Map([
  [0, 0.4],
  [1, 0.2],
  [2, 0.15],
  [3, 0.14],
  [4, 0.13],
  [5, 0.125],
  [6, 0.12]
])

export const shaftContractionCoefficients = [
  {
    type: 'gradual',
    area: 0.25,
    loss: 0.05
  },
  {
    type: 'gradual',
    area: 0.5,
    loss: 0.05
  },
  {
    type: 'gradual',
    area: 0.75,
    loss: 0
  },
  {
    type: 'vertical',
    area: 0.25,
    loss: 0.5
  },
  {
    type: 'vertical',
    area: 0.5,
    loss: 0.35
  },
  {
    type: 'vertical',
    area: 0.75,
    loss: 0.1
  }
].sort((a, b) => a.area - b.area)

export const shaftExpansionCoefficients = [
  {
    type: 'gradual',
    area: 0.25,
    loss: 0.45
  },
  {
    type: 'gradual',
    area: 0.5,
    loss: 0.2
  },
  {
    type: 'gradual',
    area: 0.75,
    loss: 0.05
  },
  {
    type: 'vertical',
    area: 0.25,
    loss: 0.6
  },
  {
    type: 'vertical',
    area: 0.5,
    loss: 0.25
  },
  {
    type: 'vertical',
    area: 0.75,
    loss: 0.1
  }
].sort((a, b) => a.area - b.area)

export const grilleFactors = new Map([
  [
    0.25,
    {
      contraction: 0.5,
      expansion: 0.6,
      totalLossCoefficient: 1.1
    }
  ],
  [
    0.3,
    {
      contraction: 0.5,
      expansion: 0.6,
      totalLossCoefficient: 1.1
    }
  ],
  [
    0.35,
    {
      contraction: 0.5,
      expansion: 0.6,
      totalLossCoefficient: 1.1
    }
  ],
  [
    0.4,
    {
      contraction: 0.5,
      expansion: 0.6,
      totalLossCoefficient: 1.1
    }
  ],
  [
    0.45,
    {
      contraction: 0.5,
      expansion: 0.6,
      totalLossCoefficient: 1.1
    }
  ],
  [
    0.5,
    {
      contraction: 0.35,
      expansion: 0.25,
      totalLossCoefficient: 0.6
    }
  ],
  [
    0.55,
    {
      contraction: 0.35,
      expansion: 0.25,
      totalLossCoefficient: 0.6
    }
  ],
  [
    0.6,
    {
      contraction: 0.35,
      expansion: 0.25,
      totalLossCoefficient: 0.6
    }
  ],
  [
    0.65,
    {
      contraction: 0.35,
      expansion: 0.25,
      totalLossCoefficient: 0.6
    }
  ],
  [
    0.7,
    {
      contraction: 0.35,
      expansion: 0.25,
      totalLossCoefficient: 0.6
    }
  ],
  [
    0.75,
    {
      contraction: 0.1,
      expansion: 0.1,
      totalLossCoefficient: 0.2
    }
  ]
])

/** Format a number to a string, with a set number of fraction digits */
export function formatNumber(number: number, fractionalDigits = 4): string {
  return Intl.NumberFormat('en-GB', {
    maximumFractionDigits: fractionalDigits,
    minimumFractionDigits: fractionalDigits
  }).format(number)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCalculatorAdvancedValues(input: AdvancedFormulaInput) {
  const errors: string[] = []

  const formulaValues = {
    shaftDampersQuantity: input.smokeControlDamperQuantity,
    smokeShaftHeight: input.shaftHeight * 1000 // in mm
  }

  // Shaft leakage
  const shaftPerimeter = ((input.dimensions.shaftWidth + input.dimensions.shaftDepth) * 2) / 1000
  const shaftHeight = input.shaftHeight
  const shaftLeakageValue = 0.00105
  const shaftCorrectionFactor = 1.5
  const shaftTotalLeakage = shaftPerimeter * shaftHeight * shaftLeakageValue * shaftCorrectionFactor
  const shaftTotalLeakageFormatted = `${formatNumber(shaftTotalLeakage)} m3/s`

  // Smoke Damper Leakage
  const smokeDamperGeometricArea = input.smokeControlDamperSize.geometricArea
  const smokeDamperTotalNumber = input.smokeControlDamperQuantity
  const smokeDamperLeakageRate = 0.0036
  const smokeDamperCorrectionFactor = 1.5
  const smokeDamperLeakage = smokeDamperGeometricArea * smokeDamperTotalNumber * smokeDamperLeakageRate * smokeDamperCorrectionFactor
  // const smokeDamperLeakageFormatted = `${formatNumber(smokeDamperLeakage)}m3/s`

  // environmental Damper Leakage
  const environmentalDamperGeometricArea = input.environmentalDamperSize?.geometricArea ?? 0
  const environmentalDamperTotalNumber = input.environmentalDamperQuantity ?? 0
  const environmentalDamperLeakageRate = 0.0036
  const environmentalDamperCorrectionFactor = 1.5
  const environmentalDamperLeakage = environmentalDamperGeometricArea * environmentalDamperTotalNumber * environmentalDamperLeakageRate * environmentalDamperCorrectionFactor
  // const environmentalDamperLeakageFormatted = `${formatNumber(environmentalDamperLeakage)}m3/s`

  // Required Extract Rate
  const extractRateAtDamper = input.damperExtractRate
  const leakage = shaftTotalLeakage + smokeDamperLeakage + environmentalDamperLeakage
  // const leakageFormatted = `${formatNumber(leakage)}m3/s`
  const extractRateRequired = extractRateAtDamper + leakage
  const extractRateRequiredFormatted = formatNumber(extractRateRequired, 1)

  const shaftArea = (input.dimensions.shaftWidth / 1000) * (input.dimensions.shaftDepth / 1000)
  const velocity = extractRateRequired / shaftArea

  // Shaft Pressure Drop
  const pressureLossSteelTube = 0.816
  const shaftConstruction = constructionFactors.get(input.shaftConstruction)
  if (!shaftConstruction) throw new Error('Missing construction material factors.')

  const shaftStaticPressureDrop = pressureLossSteelTube * input.shaftHeight * shaftConstruction.totalCorrectionFactor

  // Damper Pressure Drop
  const velocityThroughSmokeDamper = input.damperExtractRate / smokeDamperGeometricArea

  const sortedVelocities = input.smokeControlDamperSize.velocities.sort((a, b) => a.velocity - b.velocity)
  const matchingVelocity = sortedVelocities.find((item) => velocityThroughSmokeDamper <= item.velocity)

  if (!matchingVelocity) {
    const message = `We'd recommend increasing the shaft size to accommodate the required extract rate`
    errors.push(message)
  }
  const damperStaticPressureDrop = matchingVelocity?.pressureDrop ?? 4

  // Transition Piece
  const numberOfVeins = input.verticalVeinsQuantity ?? 0
  const velocityPressure = (1.225 * velocity ** 2) / 2

  const veinsLossCoefficient = veinsLossCoefficients.get(numberOfVeins)
  const totalVeinsPressureLoss = (veinsLossCoefficient ?? 0.4) * velocityPressure

  const grilleFactor = input.safetyGrillesFreeArea && grilleFactors.get(input.safetyGrillesFreeArea)
  if (input.safetyGrillesFreeArea && !grilleFactor) throw new Error(`Missing grille factor for percentage ${input.safetyGrillesFreeArea}`)

  // Grille Pressure Drop
  const numberOfGrilles = input.safetyGrillesQuantity ?? 0
  const totalGrilleLossCoefficient = numberOfGrilles * (grilleFactor ? grilleFactor.totalLossCoefficient : 1.1)
  const totalGrillePressureLoss = totalGrilleLossCoefficient * velocityPressure

  // Shaft Contraction Pressure Drop
  const shaftContractionArea = (input.shaftContractionDimensions?.smaller ?? 0.25) / (input.shaftContractionDimensions?.larger ?? 1)
  let shaftContractionLossCoefficient = shaftContractionCoefficients.find(co => co.type === input.shaftContractionType && co.area < shaftContractionArea)
  if (!shaftContractionLossCoefficient) shaftContractionLossCoefficient = shaftContractionCoefficients[0]
  const shaftContractionPressureLoss = shaftContractionLossCoefficient.loss * velocityPressure

  // Shaft Expansion Pressure Drop
  const shaftExpansionArea = (input.shaftExpansionDimensions?.smaller ?? 0.25) / (input.shaftExpansionDimensions?.larger ?? 1)
  let shaftExpansionLossCoefficient = shaftExpansionCoefficients.find(co => co.type === input.shaftExpansionType && co.area < shaftExpansionArea)
  if (!shaftExpansionLossCoefficient) shaftExpansionLossCoefficient = shaftExpansionCoefficients[0]
  const shaftExpansionPressureLoss = shaftExpansionLossCoefficient.loss * velocityPressure

  const totalSystemPressureDrop = shaftStaticPressureDrop + damperStaticPressureDrop + totalVeinsPressureLoss + totalGrillePressureLoss + shaftContractionPressureLoss + shaftExpansionPressureLoss

  return {
    shaftPerimeter,
    shaftHeight,
    shaftLeakageValue,
    shaftCorrectionFactor,
    shaftTotalLeakageFormatted,
    smokeDamperGeometricArea,
    smokeDamperTotalNumber,
    smokeDamperLeakageRate,
    smokeDamperCorrectionFactor,
    smokeDamperLeakage,
    environmentalDamperGeometricArea,
    environmentalDamperTotalNumber,
    environmentalDamperLeakageRate,
    environmentalDamperCorrectionFactor,
    environmentalDamperLeakage,
    extractRateAtDamper,
    leakage,
    extractRateRequired,
    shaftArea,
    velocity,
    pressureLossSteelTube,
    shaftConstruction,
    shaftStaticPressureDrop,
    velocityThroughSmokeDamper,
    damperStaticPressureDrop,
    velocityPressure,
    veinsLossCoefficient,
    totalVeinsPressureLoss,
    totalGrilleLossCoefficient,
    totalGrillePressureLoss,
    extractRateRequiredFormatted,
    formulaValues,
    errors,
    shaftContractionPressureLoss,
    shaftExpansionPressureLoss,
    totalSystemPressureDrop
  } as const
}
