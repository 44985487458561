import React from 'react';
import styled from 'styled-components';

import Container from '../container';
import { Box } from '../UIKit/system';
import Item from '../accordion/item';
import Accordion from '../accordion';

const StyledTabsContent = styled.div`
  padding-top: 55px;
`;

function TabsContent({ data, activeIndex }) {
  return (
    <StyledTabsContent>
      <Container>
        <Box maxWidth={760} mx={'auto'}>
          <Accordion data={data.items} metaId={activeIndex} />
        </Box>
      </Container>
    </StyledTabsContent>
  );
}

export default TabsContent;
