import React from 'react';
import styled, { css } from 'styled-components';

import { Flex } from './UIKit/system';
import Link from './link';
import { getThemeColor } from '../utils/styles';

const StyledPagination = styled(Flex)``;

const Separator = styled.div`
  height: 14px;
  width: 2px;
  background-color: ${getThemeColor('primary')};
  margin: 0 10px;
  transform: rotate(25deg);
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  color: #959595;
  font-weight: 700;

  &:after {
    content: '';
  }

  &:last-of-type {
    color: ${getThemeColor('black')};
  }

  ${(props) =>
    props.white &&
    css`
      &:last-of-type {
        color: #fff;
      }
    `};
`;

const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: 14px;

  ${(props) =>
    !props.to &&
    css`
      pointer-events: none;
    `};
`;

function Breadcrumbs({ items, white, ...rest }) {
  return (
    <StyledPagination alignItems={'center'} {...rest}>
      {items.map((item, index) => {
        {
          return (
            <Item key={index} white={white}>
              <StyledLink to={item.link} hover>{item.name}</StyledLink>
              {index < items.length - 1 && <Separator />}
            </Item>
          );
        }
      })}
    </StyledPagination>
  );
}

export default Breadcrumbs;
