import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Paragraph } from './UIKit/system';
import backgroundImage from '../images/subscription-background.jpg';
import Title from './title';
import { getThemeColor } from '../utils/styles';
import HubSpotForm from './hubspot-form';
import mq from '../utils/mq';

const StyledSubscriptionBlock = styled(Box)`
  background-image: url(${backgroundImage});
  height: 480px;
  background-size: cover;
  color: #fff;

  input[type='submit'] {
    width: 100%;
    min-width: 0;
    height: 48px;
  }

  .submitted-message {
    margin-top: 100px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  ${mq.tablet(css`
    height: 420px;
  `)};
`;

const HighlightedText = styled.span`
  color: ${getThemeColor('primary')};
`;

function SubscriptionBlock() {
  return (
    <StyledSubscriptionBlock py={30} px={27} bg={'black'}>
      <Title mb={[5, 60, 5]} textAlign={'center'} fontSizeSmall>
        Subscribe
      </Title>
      <Paragraph
        display={['block', 'none', 'block']}
        mb={40}
        textAlign={'center'}
        fontSizeSmall
      >
        Stay up to date with the latest{' '}
        <HighlightedText>smoke and heat ventilation</HighlightedText> tips from
        Sertus.
      </Paragraph>
      <HubSpotForm
        portalId={'5924647'}
        formId={'fba10400-1b90-4323-863a-9135ae0e2532'}
        fieldsFullWidth
        darkBackground
      />
    </StyledSubscriptionBlock>
  );
}

export default SubscriptionBlock;
