import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Img from 'gatsby-image';

import IconText from './section-icon-text';
import Title from './title';
import Container from './container';
import GridCol from './grid-col';
import { STANDARD_PADDING, OFFSET_SIDE_SM } from '../constants';
import { Box, Flex } from './UIKit/system';
import mq from '../utils/mq';
import Responsive, { withResponsiveContext } from './responsive';

const LeftThreeIconsRightImageContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftThreeIconsRightImageContainer = styled.div`
  max-width: 1850px;
  width: 100%;
`;

const StyledContainer = styled(Container)`
  ${mq.mobile(css`
    flex-wrap: wrap;
    flex-direction: column-reverse;
    width: calc(100% + 40px);
    margin-left: -65px;
  `)};
`;

const ImageContainer = styled(motion.div)`
  width: 100vw;
  max-width: 1850px;
  overflow: hidden;
  justify-content: flex-end;

  ${mq.tablet(css`
    width: 50%;
    max-width: 50%;
  `)};
  ${mq.mobile(css`
    width: 100%;
    max-width: 100%;
  `)};
`;

const InnerImageContainer = styled(motion.div)`
  position: absolute !important;
  top: 0;
  right: 0;
  width: 651px;
  height: 100%;
  overflow: hidden;
  @media (max-width: 1320px) {
    width: 500px;
  }
  ${mq.tablet(css`
    position: relative !important;
    width: 80%;
    object-fit: contain;
    object-position-y: top;
  `)};
  ${mq.mobile(css`
    height: 100%;
    width: 100%;
    position: relative !important;
    max-height: 500px;
    object-position: right;
  `)};
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`;

const StyledTitle = styled(Title)`
  ${mq.tablet(css`
    font-size: 32px;
    line-height: 36px;
  `)};
`;

const StyledGridCol = styled(GridCol)`
  ${mq.desktopSmall(css`
    width: 50%;
  `)};

  ${mq.mobile(css`
    width: calc(100% - 90px);
    margin-left: 90px;
    padding-right: calc(${OFFSET_SIDE_SM}px + 20px);
  `)};
`;

const IconContainer = styled(motion.div)`
  ${mq.desktopSmall(css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  `)};
`;

const ImageWipe = styled(motion.div)`
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  top: 0;

  ${mq.mobile(css`
    position: relative;
    height: auto;
  `)};
`;

const IconItemContainer = styled(motion.div)`
  margin-bottom: 42px;
`;

const ImageOverlayAnim = {
  hidden: { x: 0 },
  visible: {
    x: '50vw',
    transition: {
      delay: 0.2,
      duration: 0.6,
      type: 'tween',
    },
  },
};

const ImageOverlayAnimMobile = {
  hidden: { x: 0 },
  visible: {
    x: '110vw',
    transition: {
      delay: 0.2,
      duration: 0.6,
      type: 'tween',
    },
  },
};

const ImageAnim = {
  hidden: { scale: 1.1 },
  visible: {
    scale: 1,
    transition: {
      delay: 0.3,
      duration: 0.8,
      type: 'tween',
    },
  },
};

const container = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.8,
      type: 'tween',
    },
  },
};

const animatedItem = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0 },
};

const ImageZoomAnim = styled(motion.div)``;

const LeftIconsRightImage = ({ title, items, image }) => {
  const [isInView, setIsInView] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.45,
  });

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  return (
    <LeftThreeIconsRightImageContainerOuter
      as={'section'}
      pt={STANDARD_PADDING}
      pb={STANDARD_PADDING}
      pr={0}
      ref={ref}
    >
      <LeftThreeIconsRightImageContainer>
        <Box position={'relative'}>
          <StyledContainer as={Flex} alignItems={'center'}>
            <StyledGridCol>
              <Flex
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                minHeight={[0, 0, 749]}
              >
                <Title
                  as={'h2'}
                  mb={[50, 70, 70, 70]}
                  mt={[47, 0, 0, 0]}
                  maxWidth={486}
                  fontSizeMedium
                  html={title}
                />
                <IconContainer
                  variants={container}
                  initial="hidden"
                  animate={isInView ? 'show' : 'hidden'}
                >
                  {items.map((item, index) => {
                    return (
                      <IconItemContainer variants={animatedItem} key={index}>
                        <IconText key={index} {...item} />
                      </IconItemContainer>
                    );
                  })}
                </IconContainer>
              </Flex>
            </StyledGridCol>
            <ImageContainer>
              <Responsive.Desktop>
                <ImageWipe
                  initial="hidden"
                  animate={isInView ? 'visible' : 'hidden'}
                  variants={ImageOverlayAnim}
                />
              </Responsive.Desktop>
              <Responsive.MobileOrTablet>
                <ImageWipe
                  initial="hidden"
                  animate={isInView ? 'visible' : 'hidden'}
                  variants={ImageOverlayAnimMobile}
                />
              </Responsive.MobileOrTablet>
              <InnerImageContainer>
                <ImageZoomAnim
                  initial="hidden"
                  animate={isInView ? 'visible' : 'hidden'}
                  variants={ImageAnim}
                >
                  <Image fluid={image} alt="Temp Image" />
                </ImageZoomAnim>
              </InnerImageContainer>
            </ImageContainer>
          </StyledContainer>
        </Box>
      </LeftThreeIconsRightImageContainer>
    </LeftThreeIconsRightImageContainerOuter>
  );
};

export default withResponsiveContext(LeftIconsRightImage);
