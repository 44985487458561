import React from 'react';
import styled, { css } from 'styled-components';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import GridCol from './grid-col';
import Title from './title';
import LinkWithArrow from './link-with-arrow';
import mq from '../utils/mq';

const StyledItem = styled.div`
  border-bottom: 2px #f3f3f3 solid;
  ${(props) =>
    props.lastItem &&
    css`
      border-bottom: 0px;
    `};
  padding-bottom: 20px;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledSectionVacanciesInnerContainer = styled(Flex)`
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const TitleContainer = styled(GridCol)`
  ${mq.mobile(css`
    width: 100%;
    margin-bottom: 26px;
  `)};
`;

function Item({ title, link, lastItem }) {
  return (
    <StyledItem lastItem={lastItem}>
      <LinkWithArrow to={link} fontSizeLarge reverse spread smallIcon>
        {title}
      </LinkWithArrow>
    </StyledItem>
  );
}

const StyledSectionVacancies = styled(Box)``;

function SectionVacancies({ title, paragraph, items }) {
  configureAnchors({ offset: -100, scrollDuration: 200 });
  return (
    <ScrollableAnchor id={'careers'}>
      <StyledSectionVacancies pt={[102]} pb={[133]}>
        <Container>
          <StyledSectionVacanciesInnerContainer>
            <TitleContainer>
              <Title fontSize={[28, 28, 38, 42]}>{title}</Title>
            </TitleContainer>
            <Box>
              <Paragraph mb={64} color={'#666666'} html={paragraph} />

              {items &&
                items.map((item, index) => {
                  if (index === items.length - 1) {
                    return <Item key={index} {...item} lastItem />;
                  } else {
                    return <Item key={index} {...item} />;
                  }
                })}
            </Box>
          </StyledSectionVacanciesInnerContainer>
        </Container>
      </StyledSectionVacancies>
    </ScrollableAnchor>
  );
}

export default SectionVacancies;
