import React from 'react';
import styled, { css } from 'styled-components';

import mq from '../utils/mq';
import Title from './title';
import { Text } from './UIKit/system';
import Container from './container';
import CourseSignupForm from './course-signup-form';

const CourseSignupContainer = styled.div`
  width: 100%;
  padding: 88px 0px;
  background-color: #f8f8f8;
`;

const ContainerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${mq.tablet(css`
    flex-wrap: wrap;
  `)};
`;

const TextContent = styled.div`
  width: 40%;
  max-width: 550px;
  margin-top: 30px;
  margin-right: 40px;
  ${mq.tablet(css`
    width: 100%;
    margin-bottom: 40px;
  `)};
`;

const StyledTitle = styled(Title)`
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #161616;
`;

const Subtitle = styled(Text)`
  font-size: 18px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #666666;
  margin-top: 38px;
  margin-bottom: 15px;
`;

const Subtext = styled(Text)`
  font-weight: bold;
  color: #161616;
  span {
    color: #753fbf;
  }
`;

const CourseSignupFormContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 40px;
  ${mq.tablet(css`
    width: 100%;
    margin: 0;
  `)};
`;

function CourseSignup({ title, subtitle, portalId, formId }) {
  return (
    <CourseSignupContainer>
      <Container>
        <ContainerInner>
          <TextContent>
            <StyledTitle>{title}</StyledTitle>
            {subtitle && <Subtitle html={subtitle} />}
            <Subtext>
              Ideally, please try to book <span>2 weeks in advance.</span>
            </Subtext>
          </TextContent>
          <CourseSignupFormContainer>
            <CourseSignupForm portalId={portalId} formId={formId} />
          </CourseSignupFormContainer>
        </ContainerInner>
      </Container>
    </CourseSignupContainer>
  );
}

export default CourseSignup;
