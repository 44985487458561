import React from 'react';
import { Formik } from 'formik';

function FormikWrapper({ initialValues, onSubmit, children }) {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formikState) => children(formikState)}
    </Formik>
  );
}

export default FormikWrapper;
