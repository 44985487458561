import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

// Styles
const StyledFormType = styled.div``
const StyledFormHeader = styled.div`
  .step {
    font-family: Muli;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #753fbf;
  }

  .title {
    font-family: Muli;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: center;
    color: #161616;
  }

  .subtitle {
    font-family: Muli;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.01em;
    text-align: center;
    margin-top: 0.8rem;
  }
`

const StyledFormInput = styled.div`
  margin: auto;
`

interface Props {
  currentStep: number
  title: string
  subtitle?: string
}

const Form: FunctionComponent<Props> = ({ children, currentStep, title, subtitle }) => {
  return (
    <StyledFormType>
      <StyledFormHeader>
        <p className="step">Step {currentStep}</p>
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
      </StyledFormHeader>
      <StyledFormInput>{children}</StyledFormInput>
    </StyledFormType>
  )
}

export default Form
