import React, { useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';
import PageNavBar from '../../page-nav-bar';
import PageNavBarMobile from '../../page-nav-bar-mobile.jsx';
import Responsive from '../../responsive';
import VideoGrid from '../../resource-centre/video-grid';
import { createDropdownFilterOptions } from '../../../utils/utils';
import DropdownFilter from '../../dropdown-filter';
import { Flex } from '../../UIKit/system';

function StoryblokVideoResourceGridAdapter({ videos }) {
  const { items } = useStaticQuery(graphql`
    query {
      items: allStoryblokEntry(
        filter: { field_component: { eq: "videoResourceCategories" } }
      ) {
        edges {
          node {
            name
            slug
            content
          }
        }
      }
    }
  `);

  const [activeCategory, setActiveCategory] = useState('');
  let videosParsed = [];

  const categories = createDropdownFilterOptions(items.edges);

  const categoriesMobile = useMemo(() => {
    return items.edges.map((v) => {
      return {
        title: v.node.name,
      };
    });
  }, [items.edges]);

  videos.forEach((video, i) => {
    let fluidThumbnail = getFluidGatsbyImage(video.videoThumbnail, {
      maxWidth: 700,
    });

    let categoryTitle = '';

    if (video.videoCategory.content) {
      categoryTitle = video.videoCategory.content.title;
    }

    let videoId;

    if (video.videoId === undefined) {
      let now = new Date();
      videoId = Math.round(now.getTime() / 1000) + i;
    } else {
      videoId = video.videoId;
    }

    let videoObj = {
      id: i,
      name: video.title,
      categories: categoryTitle,
      length: video.length,
      image: fluidThumbnail,
      videoLink: video.videoLink,
      videoId: videoId,
    };
    videosParsed.push(videoObj);
  });

  function renderFilters() {
    return (
      <Flex alignItems={'center'}>
        <DropdownFilter
          title={'Category'}
          currentOption={activeCategory}
          options={categories}
          alignDropdownRight
          onChange={setActiveCategory}
        />
      </Flex>
    );
  }

  return (
    <div>
      <div>
        <Responsive.DesktopOrTablet>
          <PageNavBar
            largeMode
            title={'Video Resources'}
            filters={renderFilters()}
            showDesktopFiltersOnTablet
          />
        </Responsive.DesktopOrTablet>
      </div>
      <div>
        <Responsive.Mobile>
          <PageNavBarMobile
            title={'Video Resources'}
            filters={categoriesMobile}
            activeFilter={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        </Responsive.Mobile>
      </div>
      <VideoGrid data={videosParsed} activeCategory={activeCategory} />
    </div>
  );
}

export default StoryblokVideoResourceGridAdapter;
