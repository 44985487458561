import { useStaticQuery, graphql } from "gatsby"
import React, { FunctionComponent, useMemo } from "react"
import styled from "styled-components"

// Styles
export const StyledTable = styled.table`
  width: 100%;
  overflow: auto;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0 16px;

  @media (max-width: 500px) {
    overflow: scroll;
    width: fit-content;
  }

  .active {
    border: 3px solid #8A55FB;
    border-radius: 11px;
  }

  .row {
    td {
      padding: 15px 5px;
    }
    th {
      padding: 15px 25px;
    }
  }

  .head {
    .row {
      .title {
        font-family: Muli;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 93.5%;
        color: #753fbf;
      }

      .pod {
        text-align: center;

        .image {
          height: 150px;
          width: auto;
          max-width: 160px;
        }

        .title {
          font-family: Muli;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          color: #000000;
          padding: 5%;
        }
      }
    }
  }

  .body {
    .row {
      height: 90px;
      background: #f3f3f3;

      .feature {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        .feature-image {
          width: 65px;
          text-align: left;

          img {
            max-width: 100%;
          }
        }

        .feature-title {
          font-family: Muli;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 32px;
          color: #000000;
          text-align: left;
          width: -webkit-fill-available;
        }
      }
    }

    .checkmark {
      text-align: center;
    }
  }
`

// Components
const CheckMark: FunctionComponent<{ checked: boolean }> = ({ checked }) => {
  if (checked) return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.17712 15.2378L13.9206 20.0849L22.8007 10.9975M15.9926 1C18.9596 0.998538 21.8605 1.87703 24.3282 3.52436C26.7959 5.17169 28.7196 7.51386 29.8561 10.2546C30.9925 12.9954 31.2906 16.0116 30.7127 18.9218C30.1347 21.832 28.7067 24.5055 26.6092 26.604C24.5117 28.7025 21.839 30.1319 18.9291 30.7112C16.0192 31.2906 13.0028 30.994 10.2615 29.8589C7.52014 28.7238 5.17702 26.8012 3.52847 24.3343C1.87993 21.8674 1 18.967 1 16C0.99903 14.0305 1.38611 12.0801 2.13913 10.2603C2.89214 8.44047 3.99633 6.78683 5.38861 5.39386C6.7809 4.00088 8.43402 2.89586 10.2535 2.14195C12.073 1.38804 14.0231 1 15.9926 1V1Z" stroke="#8A55FB" strokeWidth="1.87"/>
    </svg>
  )

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.74485 6.70873C9.20902 4.24457 12.5512 2.86021 16.036 2.86021C18.6348 2.86021 21.1752 3.63086 23.3361 5.07467C25.4969 6.51847 27.181 8.57061 28.1755 10.9716C29.1701 13.3725 29.4303 16.0145 28.9233 18.5633C28.4163 21.1122 27.1648 23.4534 25.3272 25.2911C23.4896 27.1287 21.1483 28.3801 18.5995 28.8871C16.0506 29.3941 13.4087 29.1339 11.0077 28.1394C8.60672 27.1449 6.55457 25.4607 5.11076 23.2999C3.66695 21.1391 2.89634 18.5987 2.89634 15.9999C2.89634 12.5151 4.28068 9.1729 6.74485 6.70873ZM16.036 0.960205C12.0473 0.960205 8.22184 2.54474 5.40135 5.36523C2.58085 8.18572 0.996338 12.0111 0.996338 15.9999C0.996338 18.9745 1.87838 21.8822 3.53097 24.3555C5.18355 26.8288 7.53245 28.7565 10.2806 29.8948C13.0287 31.0331 16.0527 31.3309 18.9701 30.7506C21.8875 30.1703 24.5674 28.7379 26.6707 26.6346C28.7741 24.5312 30.2064 21.8514 30.7868 18.934C31.3671 16.0166 31.0692 12.9926 29.9309 10.2445C28.7926 7.49632 26.8649 5.14745 24.3916 3.49487C21.9184 1.8423 19.0106 0.960205 16.036 0.960205ZM20.7349 15.0499H11.3441V16.9499H20.7349V15.0499Z" fill="#B2B2B2"/>
    </svg>
  )
}

export interface FeatureTableProduct {
  id: string;
  featureTable: string[];
  image: { filename: string, alt: string };
  name: string
}
interface Props {
  current: string | null;
  products: FeatureTableProduct[]
}

const FeatureTable: FunctionComponent<Props> = ({ products = [], current }) => {
  const data = useStaticQuery(graphql`{
    features: allStoryblokEntry(filter: {field_component: {eq: "featureTableRow"}}) {
      nodes {
        id
        uuid
        name
        field_component
        content
      }
    }
  }`)

  const features = useMemo(() =>
    data.features.nodes
      .map(node => ({ ...JSON.parse(node.content), uuid: node.uuid }))
      .sort((a, b) => {
        const aTotal = products.filter(product => product.featureTable.includes(a.uuid))
        const bTotal = products.filter(product => product.featureTable.includes(b.uuid))
        return bTotal.length - aTotal.length
    }),
    [data]
  )

  return (
    <StyledTable>
      <colgroup>
        <col />
        {products.map((product, index) => <col key={index} className={current === product.id ? 'active' : undefined} />)}
      </colgroup>
      <thead className='head'>
        <tr className="row">
          <td className='title' scope="row">
            <p>Pod&trade; Type</p>
          </td>
          {products.map((product, index) => (
            <td key={index} className='pod' scope="row">
              <img className="image" src={product.image.filename} alt={product.image.alt || product.name} />
              <p className="title">{product.name}</p>
            </td>
          ))}
        </tr>
      </thead>
      <tbody className="body">
        {features.map((feature, index) => (
          <tr key={index} className="row">
            <th scope="row">
              <div className="feature">
                <div className="feature-image"><img src={feature.icon.filename} alt={feature.icon.alt} /></div>
                <p className="feature-title">{feature.title}</p>
              </div>
            </th>
            {products.map((product, index) => (
              <td key={index} className="checkmark">
                <CheckMark checked={product.featureTable?.includes(feature.uuid)} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}


export default FeatureTable
