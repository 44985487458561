import React from 'react';
import styled from 'styled-components';

import { inputLabel } from '../../utils/styles';

const StyledInputLabel = styled.label`
  ${inputLabel};
`;

function InputLabel(props) {
  return <StyledInputLabel {...props} />;
}

export default InputLabel;
