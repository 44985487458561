import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import ChevronDown from '../images/svg/chevron-down.svg';
import { getThemeColor, hoverDefault } from '../utils/styles';
import { useOutsideClick } from '../hooks/use-outside-click';
import CloseCross from '../images/svg/close-cross.svg';
import mq from '../utils/mq';

const StyledDropdownFilter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
  
  ${mq.tablet(css`
    margin: 0 10px;
  `)};
`;

const DropdownTitle = styled.div`
  ${hoverDefault};
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;
  color: #161616;
  height: 100%;
  cursor: pointer;
  white-space: nowrap;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
`;

const DropdownOptionsContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 200%;
  background-color: #fff;
  z-index: 100;
  min-width: 220px;
  visibility: hidden;
  opacity: 0;

  ${(props) =>
    props.shown &&
    css`
      transition: opacity 0.35s ease-out;
      visibility: visible;
      opacity: 1;
    `};

  ${(props) =>
    props.alignRight &&
    css`
      right: 0;
      left: auto;
    `};
`;

const DropdownOption = styled.div`
  padding: 0 15px;
  min-width: 120px;
  height: 46px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #161616;
  transition: 400ms;
  cursor: pointer;
  border-bottom: 1px solid #ddd;

  :last-of-type {
    border-bottom: none;
  }

  :hover {
    background-color: #f8f8f8;
    transition: 400ms;
  }
  ${(props) =>
    props.active &&
    css`
      background-color: #f8f8f8;
    `}
`;

const ChevronDownContainer = styled.div`
  position: relative;
  top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
`;

const StyledChevronDown = styled(ChevronDown)`
  fill: ${getThemeColor('purple')};
`;

const SelectedCard = styled.div`
  padding: 6px 11px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  cursor: pointer;
  white-space: nowrap;
`;

const CardTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;
  color: #161616;
  margin-right: 7px;
  line-height: 0;
  text-transform: capitalize;
`;

const CloseCrossContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
`;

function DropdownFilter({
  title,
  currentOption,
  options,
  alignDropdownRight,
  onChange,
}) {
  const [opened, setOpened] = useState(false);
  const containerNode = useRef(null);

  useOutsideClick(() => setOpened(false), {
    node: containerNode.current,
    enabled: opened,
  });

  function toggle() {
    setOpened((state) => !state);
  }

  function handleOptionSelect(e, value) {
    e.stopPropagation();
    setOpened(false);
    onChange(value);
  }

  function handleResetClick(e) {
    e.stopPropagation();
    reset();
  }

  function reset() {
    onChange('');
  }

  return (
    <StyledDropdownFilter>
      {currentOption ? (
        <SelectedCard onClick={toggle}>
          <CardTitle>{currentOption}</CardTitle>
          <CloseCrossContainer onClick={handleResetClick}>
            <CloseCross stroke={'#959595'} height={'10px'} width={'10px'} />
          </CloseCrossContainer>
        </SelectedCard>
      ) : (
        <DropdownTitle onClick={toggle}>
          {title}
          <ChevronDownContainer>
            <StyledChevronDown />
          </ChevronDownContainer>
        </DropdownTitle>
      )}
      <DropdownOptionsContainer
        shown={opened}
        ref={containerNode}
        alignRight={alignDropdownRight}
      >
        {options.map((option, index) => {
          return (
            <DropdownOption
              key={index}
              active={currentOption === option.value}
              onClick={(e) => handleOptionSelect(e, option.value)}
            >
              {option.name}
            </DropdownOption>
          );
        })}
      </DropdownOptionsContainer>
    </StyledDropdownFilter>
  );
}

export default DropdownFilter;
