import React from 'react';
import SectionTitleAndButton from '../../section-title-and-button';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

function StoryblokTitleThinbannerWithCtaAdapter({
  subtitle,
  title,
  ctaLink,
  ctaText,
  displayDivider,
}) {
  return (
    <SectionTitleAndButton
      title={title}
      subtitle={subtitle}
      displayDivider={displayDivider}
      button={{
        to: parseStoryblokLink(ctaLink),
        children: ctaText,
        whiteTransparent: true,
      }}
    />
  );
}

export default StoryblokTitleThinbannerWithCtaAdapter;
