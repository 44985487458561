import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'

// Components
import FormStep from '../components/FormStep'
import Layout from '../components/Layout'
import Specification from './specification'
import { inputComponents } from '../components/inputs'

// Types
import { Info } from '../root-page'
import { View, FormStep as FormStepPartial } from '../types'

interface Props {
  info: Info
  steps: FormStepPartial[]
  view: View
  setView: (view: View) => void
}

const SimpleForm: FunctionComponent<Props> = ({ setView, info, steps, view }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [input, setInput] = useState<Record<string, unknown>>({})
  const [stepsToSkip, setStepsToSkip] = useState<Record<number, number>>({})

  const progress = ((currentStep + 1) / (steps.length + 1)) * 100
  const data = steps[currentStep] as FormStepPartial

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [currentStep])

  const handleRestart = useCallback(() => setCurrentStep(0), [])
  const handleBack = useCallback(() => {
    if (currentStep === 0) return setView(View.TYPE)
    const previousStep = currentStep - 1
    const hasSkipStep = Reflect.has(stepsToSkip, previousStep)
    if (hasSkipStep) return setCurrentStep(Reflect.get(stepsToSkip, previousStep))
    setCurrentStep(currentStep - 1)
  }, [currentStep, stepsToSkip])

  const handleSetOption = (key: string) => (value: unknown) => {
    setInput((input) => ({ ...input, [key]: value }))

    if (data.actions?.afterSelect) {
      data.actions.afterSelect(value, { steps, setCurrentStep })
    } else {
      setCurrentStep((step) => step + 1)
    }

    if (data.actions?.skip) {
      const stepsToJump = data.actions.skip?.(value)
      if (typeof stepsToJump === 'number') {
        // TODO: Handle in case they update
        if (stepsToJump === -1) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const updatedStepsToSkip = Object.entries(stepsToSkip).filter(([_, current]) => current !== currentStep)
          setStepsToSkip(Object.fromEntries(updatedStepsToSkip))
          const nextStep = currentStep + 1
          setCurrentStep(nextStep)
        } else {
          const nextStep = currentStep + stepsToJump + 1
          const stepIndexes = Array.from({ length: nextStep - currentStep - 1 }, (_, i) => i + currentStep + 1)
          stepIndexes.forEach((stepIndex) => {
            setStepsToSkip((skipped) => ({ ...skipped, [stepIndex]: currentStep }))

            const step = steps[stepIndex]
            if (Reflect.has(input, step.key)) {
              setInput((input) => {
                Reflect.deleteProperty(input, step.key)
                return input
              })
            }
          })

          setCurrentStep(nextStep)
        }
      }
    } else {
      const nextStep = currentStep + 1
      setCurrentStep(nextStep)
    }
  }

  const CurrentStep: FunctionComponent = () => {
    const currentInput = input[data.key]
    const Component = inputComponents.get(data.type)
    const step = currentStep + 2 // We already have a step preceding this simple form, and we are starting from index 0

    if (!Component) return <p>No component for {data.type} yet!</p>

    useEffect(() => {
      if (data.actions?.default && !currentInput) {
        const defaultValue = data.actions?.default?.(input)
        setInput((input) => ({ ...input, [data.key]: defaultValue }))
      }
    }, [])

    if (data.actions?.preselected) {
      const preselected = data.actions.preselected?.(input)
      data.preselected = preselected
    }

    return (
      <FormStep currentStep={step} title={data.title}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Component {...data} callback={handleSetOption(data.key)} input={currentInput} allInput={input} />
      </FormStep>
    )
  }

  if (currentStep >= steps.length) {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    return <Specification input={input} info={info} back={handleBack} restart={handleRestart} type={view} />
  }

  return (
    <Layout back={handleBack} progress={progress} tooltip={data.tip}>
      <CurrentStep />
    </Layout>
  )
}

export default SimpleForm
