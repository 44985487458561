import React from 'react';
import styled, { css } from 'styled-components';

import { supportsGrid } from '../../utils/styles';
import InputCardsItem from './input-cards-item';
import mq from '../../utils/mq';
import { YES_NO_TYPE } from '../../constants';

const StyledInputCards = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  user-select: none;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: repeat(auto-fit, 242px);
    grid-gap: 20px;
  `)};

  ${mq.tablet(css`
    grid-template-columns: repeat(auto-fit, 220px);
  `)};
`;

const YES_NO_OPTIONS = [
  { title: 'Yes', value: true },
  { title: 'No', value: false },
];

function InputCards({
  yesNoType,
  options: optionsProp,
  field,
  form,
  fontSizeLarge,
  onSelected,
}) {
  function handleClick(value) {
    form.setFieldValue(field.name, value);
    onSelected && onSelected();
  }

  const options = yesNoType ? YES_NO_OPTIONS : optionsProp;

  return (
    <StyledInputCards>
      {options.map((option, index) => {
        return (
          <InputCardsItem
            key={index}
            title={option.title}
            subtitle={option.subtitle}
            fontSizeLarge={fontSizeLarge || yesNoType}
            onClick={() =>
              handleClick(
                typeof option.value == 'undefined' ? option.title : option.value
              )
            }
          />
        );
      })}
    </StyledInputCards>
  );
}

export default InputCards;
