import React from 'react';
import {
  getFluidGatsbyImage,
  getFixedGatsbyImage,
} from '../../../utils/gatsby-storyblok-image-fixed';
import RichtextParser from '../../../utils/richtext-parser';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

import LeftIconsRightImage2 from '../../left-image-right-content2';

function StoryblokLeftIconsRightImageAdapter({
  paragraph,
  button,
  image,
  ...rest
}) {
  let fixedImg = getFluidGatsbyImage(image, {
    maxWidth: 760,
  });
  let parsedButton = {
    children: button[0].children,
    to: parseStoryblokLink(button[0].link),
    component: button[0].component,
  };
  return (
    <LeftIconsRightImage2
      paragraph={RichtextParser(paragraph)}
      image={fixedImg}
      button={parsedButton}
      {...rest}
    />
  );
}

export default StoryblokLeftIconsRightImageAdapter;
