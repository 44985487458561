import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import LazyImage from '../lazy-image';
import ReactSwiper from '../react-swiper';
import ArrowRight from '../../images/svg/arrow-right.svg';
import mq from '../../utils/mq';
import {
  getThemeColor,
  hoverDefault,
  hoverScaleDefault,
} from '../../utils/styles';

const StyledProductCarousel = styled(motion.div)`
  display: flex;
  position: relative;
  flex: 1;
  height: 535px;
  background-color: #f8f8f8;
  overflow: hidden;
  ${mq.tablet(css`
    flex: unset;
    height: 420px;
  `)};
  ${mq.mobile(css`
    height: 280px;
  `)};
`;

const Image = styled(LazyImage)`
  max-width: 95%;
  height: 100%;
  width: 100%;
`;

const StyledReactSwiperItem = styled(ReactSwiper.Item)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const Pagination = styled.div`
  position: absolute;
  left: 0;
  bottom: 43px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;

  .swiper-pagination-bullet {
    ${hoverDefault};
    position: relative;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #d4d4d4;
    margin: 0 5px;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
    }

    &:focus {
      outline: none;
    }

    &-active {
      background-color: #666;
      pointer-events: none;
    }
  }
`;

const StyledNavigationButton = styled.div`
  ${hoverScaleDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  cursor: pointer;
  margin-left: 47px;
  margin-right: 47px;

  &:hover {
    transform: translateY(-50%) scale(1.025);
  }

  &:active {
    transform: translateY(-50%) scale(1.05);
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.prev &&
    css`
      left: 0;

      svg {
        transform: scaleX(-1);
      }
    `};

  ${(props) =>
    props.next &&
    css`
      right: 0;
    `}
`;

const NavigationButtonContainer = styled(motion.div)``;

const StyledArrowRight = styled(ArrowRight)`
  stroke: ${getThemeColor('black')};
`;

const NavigationButton = React.forwardRef((props, ref) => {
  return (
    <StyledNavigationButton {...props} ref={ref}>
      <StyledArrowRight />
    </StyledNavigationButton>
  );
});

function ProductCarousel({ items }) {
  const [swiperOptions, setSwiperOptions] = useState(null);
  const prevElRef = useRef(null);
  const nextElRef = useRef(null);
  const paginationRef = useRef(null);
  const [slidePos, setSlidePos] = useState(1);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setSwiperOptions(createSwiperOptions());
  }, []);

  function createSwiperOptions() {
    return {
      slidesPerView: 'auto',
      speed: 1000,
      loop: false,
      pagination: {
        el: paginationRef.current,
        clickable: true,
      },
      navigation: {
        prevEl: prevElRef.current,
        nextEl: nextElRef.current,
      },
    };
  }

  function handleNext() {
    if (slidePos !== items.length) {
      setSlidePos(slidePos + 1);
    } else {
      setSlidePos(1);
    }
  }

  function handlePrev() {
    setSlidePos(slidePos - 1);
  }

  return (
    <StyledProductCarousel
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      {swiperOptions && (
        <>
          <ReactSwiper options={swiperOptions}>
            {items.map((item, index) => {
              return (
                <StyledReactSwiperItem key={index}>
                  <Image fluid={item} />
                </StyledReactSwiperItem>
              );
            })}
          </ReactSwiper>
        </>
      )}
      <Pagination ref={paginationRef} />
      <NavigationButtonContainer animate={{ opacity: isHovered ? 1 : 0 }}>
        <NavigationButton prev ref={prevElRef} onClick={handlePrev} />
      </NavigationButtonContainer>
      <NavigationButtonContainer animate={{ opacity: isHovered ? 1 : 0 }}>
        <NavigationButton next ref={nextElRef} onClick={handleNext} />
      </NavigationButtonContainer>
    </StyledProductCarousel>
  );
}

export default ProductCarousel;
