import React from 'react';
import styled, { css } from 'styled-components';

import { supportsGrid } from '../utils/styles';
import PreviewTile from './preview-tile';
import mq from '../utils/mq';

const TileGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -26px;

  > * {
    width: calc(33.33% - 26px);
    flex-shrink: 0;
    margin-right: 26px;
    margin-bottom: 26px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 26px;
    margin-bottom: 0;

    > * {
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
    }

    ${mq.tablet(css`
      grid-gap: 18px;
    `)};

    ${mq.mobile(css`
      grid-template-columns: none;
      grid-gap: 14px;
    `)}
  `)};

  ${(props) =>
    props.featured &&
    css`
      grid-auto-rows: 326px;
      
      ${mq.tablet(css`
        grid-auto-rows: 220px;
      `)};
    `};
`;

function Items({ items, featured }) {
  return items.map((item, index) => {
    return (
      <PreviewTile
        key={index}
        {...item}
        large={featured && index === 0}
        gatsbyImg
      />
    );
  });
}

TileGrid.Items = Items;

export default TileGrid;
