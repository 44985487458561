import React from 'react';
import styled, {css} from 'styled-components';

import Link from './link';
import { getThemeColor, styledLink } from '../utils/styles';

const StyledComponentLink = styled(Link)`
  ${styledLink};
  text-decoration: none;
  
  ${props => props.colorPrimary && css`
    color: ${getThemeColor('primary')};
  `};
`;

function StyledLink(props) {
  return <StyledComponentLink {...props} />;
}

export default StyledLink;
