import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import throttle from 'lodash/throttle';
import mq from '../../utils/mq';
import ArrowRight from '../../images/svg/arrow-right.svg';
import {
  getThemeColor,
  hoverDefault,
  hoverScaleDefault,
} from '../../utils/styles';

import Carousel from './carousel';

const QuotesCarouselContainer = styled(motion.div)`
  background-color: #f8f8f8;
  padding: 113px 163px;
  margin: 80px 0px;
  position: relative;
  ${mq.mobile(css`
    padding: 20px 20px;
    margin: 20px 0px;
  `)};
`;

const NavigationButtonContainer = styled(motion.div)``;

const StyledNavigationButton = styled.div`
  ${hoverScaleDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  cursor: pointer;
  margin-left: 47px;
  margin-right: 47px;

  &:hover {
    transform: translateY(-50%) scale(1.025);
  }

  &:active {
    transform: translateY(-50%) scale(1.05);
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.prev &&
    css`
      left: 0;

      svg {
        transform: scaleX(-1);
      }
    `};

  ${(props) =>
    props.next &&
    css`
      right: 0;
    `}
`;

const StyledArrowRight = styled(ArrowRight)`
  stroke: ${getThemeColor('black')};
`;

const NavigationButton = React.forwardRef((props, ref) => {
  return (
    <StyledNavigationButton {...props} ref={ref}>
      <StyledArrowRight />
    </StyledNavigationButton>
  );
});

function QuotesCarousel({ quotes, ...rest }) {
  const [isHovered, setIsHovered] = useState(false);
  const [slidePos, setSlidePos] = useState(1);
  const prevElRef = useRef(null);
  const nextElRef = useRef(null);

  const [swiperOptions, setSwiperOptions] = useState(null);

  useEffect(() => {
    setSwiperOptions(createSwiperOptions());
  }, []);

  function handleUpdate(e) {
    setSlidePos(e);
  }

  function createSwiperOptions() {
    return {
      spaceBetween: 18,
      slidesPerView: 'auto',
      speed: 1000,

      navigation: {
        prevEl: prevElRef.current,
        nextEl: nextElRef.current,
      },
    };
  }

  return (
    <QuotesCarouselContainer
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      {swiperOptions && (
        <Carousel
          items={quotes}
          swiperOptions={swiperOptions}
        />
      )}
      <NavigationButtonContainer
        style={{ display: slidePos === 0 ? 'none' : 'block' }}
        animate={{ opacity: isHovered ? 1 : 0 }}
      >
        <NavigationButton prev ref={prevElRef} />
      </NavigationButtonContainer>
      <NavigationButtonContainer
        style={{ display: slidePos === quotes.length ? 'none' : 'block' }}
        animate={{ opacity: isHovered ? 1 : 0 }}
      >
        <NavigationButton next ref={nextElRef} />
      </NavigationButtonContainer>
    </QuotesCarouselContainer>
  );
}

export default QuotesCarousel;
