import React from 'react';
import styled from 'styled-components';

import LazyImage from './lazy-image';
import { Box, Flex, Text } from './UIKit/system';
import getFixedGatsbyImage from '../utils/gatsby-storyblok-image-fixed/getFixedGatsbyImage';

const StyledAuthorWithAvatar = styled(Flex)``;

const Photo = styled(LazyImage)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 50%;
`;

const Name = styled.span`
  font-weight: 700;
`;

function AuthorWithPhoto({
  photo,
  firstName,
  lastName,
  date,
  minutesToRead,
  ...rest
}) {
  return (
    <StyledAuthorWithAvatar alignItems={'center'} {...rest}>
      <Photo
        mr={12}
        fluid={getFixedGatsbyImage(photo, {
          maxWidth: 48,
        })}
      />
      <Box>
        <Text fontSizeSmall>
          By{' '}
          <Name>
            {firstName} {lastName}
          </Name>
        </Text>
        <div>
          {date && (
            <Text as={'span'} mr={11} fontSizeSmall grey>
              {date}
            </Text>
          )}
          {minutesToRead && (
            <Text as={'span'} color={'primary'} fontSizeSmall>
              {minutesToRead} minute to read
            </Text>
          )}
        </div>
      </Box>
    </StyledAuthorWithAvatar>
  );
}

export default AuthorWithPhoto;
