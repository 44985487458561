import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useScrollPercentage } from 'react-scroll-percentage';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Title from './title';
import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import { darkBackground } from '../utils/styles';
import TextWithBar from './text-with-bar';
import LazyImage from './lazy-image';
import LinkWithArrow from './link-with-arrow';
import ButtonPlay from './button-play';
import mq from '../utils/mq';
import { vw } from '../utils/size-functions';
import { MOBILE_MENU_HEIGHT } from '../constants';
import Button from '../components/button';
import DownloadPopup from '../components/resource-centre/download-popup';
import VideoPlayerOverlay from '../components/video-player-overlay';

const StyledIntro = styled.div`
  position: relative;
  color: #fff;
  height: calc(100vh - 137px);
  max-height: 800px;
  background-size: cover;
  overflow: hidden;
  ${(props) =>
    props.darkBackground &&
    css`
      ${darkBackground};
    `};

  ${(props) =>
    props.small &&
    css`
      height: 392px;
    `};

  ${mq.tablet(css`
    height: auto;
    max-height: none;
  `)};

  ${mq.mobile(css`
    height: calc(100vh - ${MOBILE_MENU_HEIGHT}px);
    max-height: 735px;
    padding-bottom: 90px;
    ${(props) =>
      props.small &&
      css`
        height: 60vh;
        min-height: 392px;
        padding-bottom: 0px;
      `};
  `)};
`;

const BackgroundImageContainer = styled(LazyImage)`
  width: 100%;
  height: 100%;
`;

const Image = styled(LazyImage)`
  max-width: 536px;
  width: ${vw(580)};
  ${(props) =>
    props.largerImage &&
    css`
      max-width: 785px;
    `};
  ${mq.tablet(css`
    display: none;
  `)};
`;

const MobileImageItem = styled(LazyImage)`
  display: none;
  ${mq.tablet(css`
    display: block;
    margin-top: 34px;
    max-height: 300px;
    object-fit: contain;
    height: 100%;
    width: 100%;
  `)};
`;

const StyledContainer = styled(Container)`
  ${mq.tablet(css`
    flex-wrap: wrap;
    flex-direction: row;
    ${(props) =>
      props.small &&
      css`
        align-items: center;
      `};
  `)};
`;

const StyledFlex = styled(Flex)`
  ${mq.tablet(css`
    margin-top: 50px;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: unset;
    padding: 0px;
    align-items: center;
  `)};
  ${mq.mobile(css`
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;
  `)};
`;

const StyledBox = styled(Box)`
  ${mq.tablet(css`
    margin-top: 44px;
    margin-bottom: 22px;
  `)};
`;

const CtaContainer = styled.div`
  ${mq.mobile(css`
    margin-top: 19px;
  `)};
`;

const PlayButtonContainer = styled(Box)`
  display: flex;
  cursor: pointer;
`;

const PlayButtonText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-right: 11px;
`;

const ImageContainer = styled(motion.div)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled(motion.div)`
  width: 100%;
`;

const DownloadButtonContainer = styled.div`
  margin-top: 32px;
`;

const LeftImageContainer = styled.div`
  position: relative;
  width: 50%;
`;

const InnerImageContainer = styled.div`
  width: 100%;
  height: 100%;
  will-change: transform;
  transition: transform 0.15s linear;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
`;

const imageFaderAnim = {
  hidden: { opacity: 0, scale: 1.1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      type: 'tween',
      easing: 'easeIn',
    },
  },
};

function Intro({
  title,
  subtitle,
  text,
  backgroundImage,
  image,
  largerImage,
  link,
  playButton,
  darkBackground,
  small,
  mobileImage,
  mobileImageTopMode,
  ctaText,
  ctaLink,
  download,
  popupTitle,
  video,
  videoLink,
  filePath,
  overlay,
}) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [videoOpen, setvideoOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1.1);
  const [doZoom, setDoZoom] = useState(false);

  const [ref, percentage] = useScrollPercentage({
    threshold: 0,
  });

  const [isInView, setIsInView] = useState(false);

  const [inView] = useInView();

  useEffect(() => {
    let doc = document.documentElement;
    setZoomLevel(window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (zoomLevel < 350) {
      setDoZoom(true);
    } else {
      setDoZoom(false);
    }
  }, [percentage]);

  function handlePopupClose() {
    setPopupOpen(false);
    setvideoOpen(false);
  }

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  return (
    <StyledIntro darkBackground={darkBackground} small={small} ref={ref}>
      {videoOpen && (
        <VideoPlayerOverlay
          videoLink={videoLink}
          handlePopupClose={handlePopupClose}
        />
      )}
      {popupOpen && (
        <div>
          <DownloadPopup
            filePath={filePath}
            title={popupTitle}
            handlePopupClose={handlePopupClose}
          />
        </div>
      )}

      {backgroundImage && (
        <ImageContainer
          initial="hidden"
          animate={isInView ? 'visible' : 'hidden'}
          variants={imageFaderAnim}
        >
          <InnerImageContainer
            style={
              doZoom
                ? {
                    transform: `translate3d(0%, -${
                      zoomLevel / 1000
                    }%, 0) scale(${(1000 - zoomLevel / 5) / 1000 + 0.1})`,
                  }
                : {}
            }
          >
            <BackgroundImageContainer fluid={backgroundImage} />
          </InnerImageContainer>
        </ImageContainer>
      )}

      {overlay && <Overlay />}

      <StyledContainer
        as={Flex}
        position={'relative'}
        alignItems={['flex-end', 'center']}
        justifyContent={'space-between'}
        pb={[20, 20, 0]}
        height={'100%'}
        small={small}
      >
        {mobileImageTopMode !== undefined ? (
          <MobileImageItem
            fluid={mobileImage !== undefined ? mobileImage : image}
          />
        ) : null}
        <TextContainer
          initial={{
            y: 40,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.5,
              duration: 0.35,
            },
          }}
        >
          <StyledFlex
            flexDirection={'column'}
            alignItems={'flex-start'}
            flexShrink={0}
            pr={30}
            maxWidth={522}
            width={'100%'}
            pt={[50, 0, 0]}
          >
            <TextWithBar mb={15}>{subtitle}</TextWithBar>
            <Title
              as={'h1'}
              maxWidth={570}
              mb={[14, null, 26]}
              lineHeight={1.1}
              html={title}
            />
            {text && (
              <Paragraph
                maxWidth={330}
                semibold
                color={!backgroundImage && darkBackground ? '#f6f6f6' : '#fff'}
                fontSize={[14, 18]}
                html={text}
              />
            )}
            {(link || playButton) && (
              <StyledBox mt={[86]}>
                {link && <LinkWithArrow white {...link} />}
                {playButton && (
                  <ButtonPlay
                    semiTransparent={darkBackground}
                    {...playButton}
                  />
                )}
              </StyledBox>
            )}
            {ctaText && (
              <CtaContainer>
                {download ? (
                  <DownloadButtonContainer>
                    <Button onClick={() => setPopupOpen(true)} whitePurple>
                      {ctaText}
                    </Button>
                  </DownloadButtonContainer>
                ) : video ? (
                  <PlayButtonContainer
                    mt={[24, 44, 86]}
                    mb={[0, 100, 0]}
                    onClick={() => setvideoOpen(true)}
                  >
                    <PlayButtonText>Watch Video</PlayButtonText>
                    <ButtonPlay
                      semiTransparent={darkBackground}
                      {...playButton}
                    />
                  </PlayButtonContainer>
                ) : (
                  <Button to={ctaLink} white>
                    {ctaText}
                  </Button>
                )}
              </CtaContainer>
            )}
          </StyledFlex>
        </TextContainer>
        {mobileImageTopMode === undefined ? (
          <MobileImageItem
            fluid={mobileImage !== undefined ? mobileImage : image}
          />
        ) : null}
        <LeftImageContainer>
          <Image fluid={image} largerImage={largerImage} />
        </LeftImageContainer>
      </StyledContainer>
    </StyledIntro>
  );
}

export default Intro;
