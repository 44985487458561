import React from 'react';
import RichtextParser from '../../../utils/richtext-parser';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

import GridTileListDualWidth from '../../grid-tile-list-dual-width';
import Container from '../../container';

function StoryblokGridTileListAdapter({ tiles }) {
  let tilesArr = [];

  tiles.forEach((tile) => {
    let fluidTileImage = getFluidGatsbyImage(tile.image, {
      maxWidth: 700,
    });

    let link;

    if (tile.ctaMode === 'file') {
      link = {
        to: tile.ctaFile.filename,
        children: tile.ctaText,
      };
    } else {
      link = {
        to: parseStoryblokLink(tile.ctaLink),
        children: tile.ctaText,
      };
    }

    let tileObj = {
      title: tile.title,
      text: RichtextParser(tile.paragraph),
      link: link,
      image: fluidTileImage,
    };
    tilesArr.push(tileObj);
  });

  return (
    <Container>
      <GridTileListDualWidth items={tilesArr} />
    </Container>
  );
}

export default StoryblokGridTileListAdapter;
