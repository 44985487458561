import React from 'react';
import SectionFeatures from '../../section-features.jsx';
import RichTextParser from '../../../utils/richtext-parser';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';

function StoryblokCoreFeaturesAdapter({ values, subtext, subtitle, ...rest }) {
  let valuesArr = [];
  values.map((v) => {
    let fixedImg = getFluidGatsbyImage(v.icon, {
      maxWidth: 36,
    });
    let val = {
      title: v.title,
      subtitle: v.subtitle,
      icon: fixedImg,
    };
    valuesArr.push(val);
  });
  let subtextParsed = RichTextParser(subtext);
  return (
    <SectionFeatures
      values={valuesArr}
      text={subtitle}
      subtext={subtextParsed}
      {...rest}
    />
  );
}
export default StoryblokCoreFeaturesAdapter;
