import React, { FormEvent, FormEventHandler, FunctionComponent, useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'

// Styles
import { StyledFormButton } from '../../styles'

const StyledInputContainer = styled.div`
  margin: 2rem auto;

  .form {
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }

    .inputs {
      height: fit-content;
      margin: auto 0;
      max-width: 500px;
      position: relative;
      flex-grow: 1;

      @media (max-width: 768px) {
        overflow: auto;
        max-width: 85vw;
      }

      .text {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #666666;
        max-width: inherit;

        position: sticky;
        top: 0;
        left: 0;
      }

      .subtitle {
        font-family: Muli;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #161616;
        margin: 1.5rem auto;
        max-width: inherit;

        position: sticky;
        top: 0;
        left: 0;
        right: 0;
      }
    }

    .image {
      flex-basis: 40%;

      img {
        width: 100%;
      }
    }

    .video {
      flex-basis: 40%;
    }
  }
`
const StyledInputRows = styled.div`
  margin-top: 18px;

  .title {
    margin-bottom: -9px;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #161616;
  }
`
const StyledInput = styled.input`
  background-color: #fff;
  border: solid 1px #fff;
  border-radius: 1px;
  height: 52px;
  width: 100%;
  margin-top: 18px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #666666;

  &::placeholder {
    color: rgba(102, 102, 102, 0.33);
  }
`

const StyledInputColumns = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  margin: 0 auto 1.5rem;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`

const StyledInputColumn = styled.div`
  padding-top: 20px;
  width: 242px;
  min-height: 242px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: Muli;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #161616;
  }

  .image {
    max-height: 65px;
  }

  input {
    border-top: 2px solid #753fbf;
  }
`

const StyledWarnings = styled.div`
  margin: 1rem auto;
  padding: 1rem;
  background-color: white;

  div:not(:first-child) {
    margin-top: 1.5rem;

    p:not(:first-child) {
      margin-top: 1rem;
    }
  }
`

interface Input {
  key: string
  title?: string
  type: 'number' | 'string'
  placeholder: string
  required: boolean
  step?: number
  image?: FunctionComponent
}
interface Props {
  step: number
  title: string
  subtitle?: string
  text?: string
  type: 'input'
  layout: 'row' | 'column'
  key: string
  button: string
  image?: string
  video?: string
  thumbnail?: string
  callback: (value: Record<string, number> | string | number) => void
  inputs: Input[]
  input: Record<string, string | number>
  validations?: ((input: Record<string, string | number>) => string | undefined)[]
}

const Input: FunctionComponent<Props> = ({ title, subtitle, text, button, image, callback, inputs, input, validations, video, thumbnail, layout = 'row' }) => {
  const initialValue = typeof input === 'object' ? input : { [inputs[0].key]: input }

  const [inputValues, setInputValues] = useState(initialValue || {})
  const [errors, setErrors] = useState<string[]>([])

  const handleInput = (input: Input): FormEventHandler<HTMLInputElement> =>
    useCallback(
      (event) => {
        const value = (event.target as HTMLInputElement).value
        setInputValues({
          ...inputValues,
          [input.key]: input.type === 'number' ? Number(value) : value
        })
      },
      [inputValues]
    )

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      // @ts-expect-error Need to override types here.
      if (inputs.length > 1) return callback(inputValues)
      const [value]: (string | number)[] = Object.values(inputValues)
      return callback(value)
    },
    [inputValues]
  )

  useEffect(() => {
    if (!validations || !validations.length) return
    const errors = validations.flatMap((fn) => {
      const error = fn(inputValues)
      return error ? [error] : []
    })
    setErrors(errors)
  }, [inputValues])

  return (
    <StyledInputContainer>
      <form onSubmit={handleSubmit} className="form">
        <div className="content">
          <div className="inputs">
            {text && <p className="text">{text}</p>}
            {subtitle && <p className="subtitle">{subtitle}</p>}
            {layout === 'column' && (
              <StyledInputColumns>
                {inputs.map((input, i) => (
                  <StyledInputColumn key={i} className="column">
                    <p className="title">{input.title}</p>
                    {input.image && <div className="image">{input.image({})}</div>}
                    <StyledInput {...input} value={inputValues[input.key]?.toString() ?? ''} onChange={handleInput(input)} />
                  </StyledInputColumn>
                ))}
              </StyledInputColumns>
            )}
            {layout === 'row' &&
              inputs.map((input, i) => (
                <StyledInputRows key={i}>
                  {input.title && <p className="title">{input.title}</p>}
                  <StyledInput {...input} value={inputValues[input.key]?.toString() ?? ''} onChange={handleInput(input)} />
                </StyledInputRows>
              ))}
          </div>
          {image && (
            <div className="image">
              <img src={image} alt={title} />
            </div>
          )}
          {video && (
            <div className="video">
              <video src={video} width="100%" height="auto" controls poster={thumbnail}></video>
            </div>
          )}
        </div>
        {!!errors.length && (
          <StyledWarnings style={{ maxWidth: image || video ? 'unset' : '500px' }}>
            {errors.map((error, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: error }}></div>
            ))}
          </StyledWarnings>
        )}
        <StyledFormButton type="submit">{button}</StyledFormButton>
      </form>
    </StyledInputContainer>
  )
}

export default Input
