import React from 'react';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';

import LogoTileList from '../../logo-tile-list';

function StoryblokTitleWithSubtextAdapter({ tiles }) {
  let parsedData = [];

  tiles.forEach((tile) => {
    let fluidLogo = getFluidGatsbyImage(tile.logo, {
      maxWidth: 193,
    });

    let parsedTile = {
      link: {
        to: parseStoryblokLink(tile.ctaLink),
        children: tile.ctaText,
      },
      image: fluidLogo,
      ...tile,
    };
    parsedData.push(parsedTile);
  });

  return <LogoTileList items={parsedData} />;
}

export default StoryblokTitleWithSubtextAdapter;
