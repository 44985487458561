import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { Sticky } from 'react-sticky';

import Container from './container';
import { Flex } from './UIKit/system';
import TitleWithSeparator from './title-with-separator';
import Responsive, { withResponsiveContext } from './responsive';
import mq from '../utils/mq';
import { getThemeColor } from '../utils/styles';
import ChevronDown from '../images/svg/chevron-down.svg';
import Button from '../components/button';
import { useOutsideClick } from '../hooks/use-outside-click';
import PageNavMenuItem from './page-nav-menu-item';
import { menuItemIsUnderlined } from '../utils/utils';

const SubnavbarContainer = styled.div`
  height: 72px;
  margin-top: 0;
  ${(props) =>
    props.isSticky &&
    css`
      margin-top: ${(props) => props.marginTop};
    `}
  background-color: #fff;
  z-index: 5;
  left: 0;
  ${mq.tablet(css`
    top: 50px;
  `)};
`;

const MenuItemsContainer = styled.div`
  display: flex;
  height: 100%;
`;

const MobileMenuContainer = styled.div``;

const ActiveIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MobileMenuActiveItem = styled.div`
  cursor: pointer;
`;

const MobileMenuDropdownContainerOuter = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #0003;
`;

const MobileMenuDropdownContainer = styled.div`
  width: 169px;
  background-color: #fff;
  z-index: 6;
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 100px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
`;

const MobileLink = styled(Link)`
  width: 100%;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  text-decoration: none;
  color: #161616;
  ${(props) =>
    props.active &&
    css`
      background-color: #f8f8f8;
    `};
`;

const ChevronDownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
`;

const StyledChevronDown = styled(ChevronDown)`
  fill: ${getThemeColor('purple')};
`;

const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 28px;
`;

function PageSubnavBar({
  pages,
  title,
  subtitle,
  activePage,
  enableCTA,
  ctaLink,
  ctaText,
  isMobileOrTablet,
}) {
  const [itemHoverIndex, setItemHoverIndex] = useState(undefined);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const modalNode = useRef(null);

  useOutsideClick(() => setMobileMenuIsOpen(false), {
    node: modalNode.current,
    enabled: mobileMenuIsOpen,
  });

  function handleMobileMenuToggle() {
    if (mobileMenuIsOpen) {
      setMobileMenuIsOpen(false);
    } else {
      setMobileMenuIsOpen(true);
    }
  }

  return (
    <Sticky topOffset={48}>
      {({ style, isSticky }) => {
        const { transform, ...restStyles } = style; // eslint-disable-line
        return (
          <SubnavbarContainer
            style={restStyles}
            isSticky={isSticky}
            marginTop={isMobileOrTablet ? '50px' : '89px'}
          >
            <Container
              as={Flex}
              height={'100%'}
              alignItems={'center'}
              justifyContent={'space-between'}
              large
            >
              <TitleWithSeparator title={title} subtitle={subtitle} />
              <Responsive.Desktop>
                <MenuItemsContainer>
                  <React.Fragment>
                    {pages.map((page, index) => (
                      <PageNavMenuItem
                        key={index}
                        to={page.link}
                        active={page.active}
                        underline={menuItemIsUnderlined(
                          page.active,
                          index,
                          itemHoverIndex
                        )}
                        onMouseOver={() => setItemHoverIndex(index)}
                        onMouseLeave={() => {
                          if (index === itemHoverIndex) {
                            setItemHoverIndex(undefined);
                          }
                        }}
                      >
                        {page.title}
                      </PageNavMenuItem>
                    ))}
                    {enableCTA && (
                      <ButtonContainer>
                        <Button px={33} height={48} to={ctaLink}>{ctaText}</Button>
                      </ButtonContainer>
                    )}
                  </React.Fragment>
                </MenuItemsContainer>
              </Responsive.Desktop>
              <Responsive.MobileOrTablet>
                <MobileMenuContainer>
                  <ActiveIconContainer onClick={() => handleMobileMenuToggle()}>
                    <MobileMenuActiveItem>{activePage}</MobileMenuActiveItem>
                    <ChevronDownContainer>
                      <StyledChevronDown />
                    </ChevronDownContainer>
                  </ActiveIconContainer>
                  {mobileMenuIsOpen ? (
                    <MobileMenuDropdownContainerOuter>
                      <MobileMenuDropdownContainer ref={modalNode}>
                        {pages.map((page, i) => (
                          <MobileLink
                            key={i}
                            active={page.active ? 'true' : ''}
                            to={page.link}
                          >
                            {page.title}
                          </MobileLink>
                        ))}
                      </MobileMenuDropdownContainer>
                    </MobileMenuDropdownContainerOuter>
                  ) : null}
                </MobileMenuContainer>
              </Responsive.MobileOrTablet>
            </Container>
          </SubnavbarContainer>
        );
      }}
    </Sticky>
  );
}

export default withResponsiveContext(PageSubnavBar);
