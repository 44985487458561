import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from '../UIKit/system';
import Title from '../title';
import mq from '../../utils/mq';
import RichTextField from '../rich-text-field';

const StyledItem = styled.div`
  margin-bottom: 48px;

  &:last-of-type {
    margin-bottom: 0;
  }
  ${mq.mobile(css`
    margin-bottom: 27px;
  `)};
`;

function Item({ title, text }) {
  return (
    <StyledItem>
      <Flex flexWrap={['wrap', 'unset', 'unset', 'unset']}>
        <Box
          flexShrink={0}
          width={['100%', '20%', '30.5%']}
          mb={[25, 0, 0]}
          pr={30}
        >
          <Title fontSizeSmall>{title}</Title>
        </Box>
        {typeof text == 'string' ? (
          <Paragraph fontSize={['14px', '16px', '16px']} html={text} grey />
        ) : (
          <Paragraph fontSize={['14px', '16px', '16px']} grey>
            <RichTextField data={text} />
          </Paragraph>
        )}
      </Flex>
    </StyledItem>
  );
}

function TextContent({ items }) {
  return items.map((item, index) => {
    return <Item key={index} {...item} />;
  });
}

export default TextContent;
