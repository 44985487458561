import CarouselContent from "./content"
import React, { FunctionComponent, useMemo } from "react"
import ReactSwiper from "../react-swiper"
import styled, { css } from "styled-components"
import { CarouselProps } from "./types"
import { getFluidGatsbyImage } from "../../utils/gatsby-storyblok-image-fixed"

const StyledCarouselContainer = styled.div`
  max-height: 800px;
  min-height: 400px;
  aspect-ratio: 2 / 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const StyledCarouselContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 1172px;
  display: flex;
`

const StyledImg = styled.img<{ enableFilter: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  ${props => props.enableFilter ? css`filter: brightness(0.65);` : ''}
`

type Props = CarouselProps

const Carousel: FunctionComponent<Props> = ({ images, autoplay, duration, disableImageOverlay, ...rest }) => {
  console.log({ disableImageOverlay })
  const transformedImages = useMemo(() => images.flatMap(image => {
    if (!image?.filename) return []
    const transformed = getFluidGatsbyImage(image, { maxWidth: 1800 })
    return transformed.src ? [transformed] : []
  }), [images])

  const swiperOptions = {
    autoplay: autoplay ? {
      delay: duration,
      pauseOnMouseEnter: true
    } : false,
    loop: true,
    slidesPerView: 1
  }

  return (
    <StyledCarouselContainer>
      <ReactSwiper style={{ height: '100%' }} options={swiperOptions} className="" wrapperClassName="" onChange={undefined} onProgressChange={undefined}>
        {transformedImages.map((image, index) => (
          <ReactSwiper.Item key={index}>
            <StyledImg src={image.src} enableFilter={!disableImageOverlay} />
          </ReactSwiper.Item>
        ))}
      </ReactSwiper>
      <StyledCarouselContent>
        <CarouselContent content={rest}  />
      </StyledCarouselContent>
    </StyledCarouselContainer>
  )
}


export default Carousel
