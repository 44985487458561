import React from "react"
import FeatureTable from "../feature-table"
import styled from 'styled-components'

const StyledFeatureTableContainer = styled.div`
  overflow: auto;
`

const ComparisonTable = (props) => {
  return (
    <StyledFeatureTableContainer>
      <FeatureTable {...props} />
    </StyledFeatureTableContainer>
  )
}

export default ComparisonTable
