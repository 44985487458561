import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import LazyImage from './lazy-image';
import { Box, Flex, Text } from './UIKit/system';
import Title from './title';
import LinkWithArrow from './link-with-arrow';
import { supportsGrid } from '../utils/styles';
import mq from '../utils/mq';
import ellipsis from '../utils/ellipsed';
import Link from './link';

const StyledArticleTileItem = styled.div`
  width: calc(50% - 40px);
  margin-right: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  ${supportsGrid(css`
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  `)};

  ${mq.tablet(css`
    height: 420px;
  `)};
`;

const StyledTitle = styled(Title)`
  overflow: hidden;
`;

function ArticleTileItem({ to, title, category, gridImage: image, small }) {
  const titleRef = useRef(null);

  useEffect(() => {
    if (!titleRef.current) {
      return;
    }
    ellipsis(titleRef.current, 3);
  }, [title]);

  return (
    <StyledArticleTileItem small={small}>
      <Link to={to}>
        <LazyImage height={small ? 208 : 380} fluid={image} />
      </Link>
      <Flex
        flex={1}
        minHeight={small ? [0, 0, 272] : 300}
        flexDirection={'column'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        backgroundColor={'lightGrey'}
        pt={small ? [14, 14, 35] : 40}
        pb={small ? [14, 14, 30] : 40}
        pl={small ? [24, 24, 30] : [15, 30, 60, 60]}
        pr={small ? [24, 24, 30] : [15, 30, 60, 60]}
      >
        <Box mb={20}>
          {category && (
            <Text mb={[6, 6, 20]} fontSizeSmall grey>
              {category.name}
            </Text>
          )}
          <Box maxHeight={80}>
            <StyledTitle
              lineHeight={1.5}
              fontSizeSmall={!small}
              fontSizeExtraSmall={small}
              ref={titleRef}
            >
              {title}
            </StyledTitle>
          </Box>
        </Box>
        <LinkWithArrow to={to}>Read More</LinkWithArrow>
      </Flex>
    </StyledArticleTileItem>
  );
}

export default ArticleTileItem;
