import React from 'react';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';

import LogoBlock from '../../logo-block';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

function StoryblokIntroAdapter({ logos, ...rest }) {
  let logoArr = [];
  if (logos.length > 0) {
    logos.map((item) => {
      let fluidLogo = getFluidGatsbyImage(item.logo, {
        maxWidth: 160,
      });
      let logoItem = {
        logo: fluidLogo,
        link: parseStoryblokLink(item.link),
      };
      logoArr.push(logoItem);
    });
  }
  return <LogoBlock logos={logoArr} {...rest} />;
}

export default StoryblokIntroAdapter;
