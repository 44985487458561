import React, { ReactElement, useCallback, useState } from 'react'

// Storyblok Components
import StoryblokComponent from '../storyblok/storyblok-component'

// Components
import EntryFormView from './views/entry-form'
import FormTypeView from './views/form-type'
import FormView from './views/form'

// Data
import simpleData from './data/simple'
import advancedData from './data/advanced'

// Types
import { StoryBlokContent, StoryBlokForm, View } from './types'

interface Props {
  _uid: string
  content: StoryBlokContent[]
  form: StoryBlokForm[]
}

export interface Info {
  projectName: string
  email: string
  firstname: string
  lastname: string
}

const initialData: Info = {
  projectName: '',
  email: '',
  firstname: '',
  lastname: ''
}

function PodCalculatorTool({ content, ...data }: Props): ReactElement {
  const [form] = data.form

  const [info, setInfo] = useState<Info>(initialData)
  const [view, setView] = useState<View>(View.ENTRY)

  const backToView = useCallback((view: View) => setView(view), [])

  const handleEntryForm = useCallback((infoInput: Info) => {
    setInfo(infoInput)
    setView(View.TYPE)
  }, [])
  const handleTypeForm = useCallback((value: View) => {
    setView(value)
  }, [])

  if (view === View.ENTRY) {
    return (
      <>
        <EntryFormView {...form} callback={handleEntryForm} info={info} />
        {content.map((block) => (
          <StoryblokComponent key={block._uid} {...block} />
        ))}
      </>
    )
  }

  if (view === View.TYPE) {
    return <FormTypeView info={info} callback={handleTypeForm} setView={backToView} />
  }

  if (view === View.SIMPLE_FORM) {
    return <FormView info={info} steps={simpleData} setView={backToView} view={view} />
  }

  if (view === View.ADVANCED_FORM) {
    return <FormView info={info} steps={advancedData} setView={backToView} view={view} />
  }

  return <p>No view for this type yet!</p>
}

export default PodCalculatorTool
