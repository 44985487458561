import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Container from './container';
import { Flex, Text } from './UIKit/system';
import { getThemeColor, hoverDefault } from '../utils/styles';
import Link from './link';
import Button from './button';
import Separator from './separator-vertical';
import TitleWithSeparator from './title-with-separator';
import mq from '../utils/mq';
import ChevronDown from '../images/svg/chevron-down.svg';
import { useOutsideClick } from '../hooks/use-outside-click';
import PageNavMenuItem from './page-nav-menu-item';
import { menuItemIsUnderlined } from '../utils/utils';
import withLocation from './with-location';

const StyledPageNavBar = styled.div`
  height: 72px;
  position: sticky;
  max-width: 100vw;
  background-color: #fff;
  z-index: 5;
  left: 0;
`;

const StyledContainer = styled(Container)``;

const Links = styled.div`
  position: relative;
  height: 100%;
  right: -28px;
`;

const FilterLink = styled.div`
  ${hoverDefault};
  position: relative;
  font-weight: 700;
  color: #959595;
  margin-right: 32px;
  user-select: none;
  cursor: pointer;
  text-transform: capitalize;
  letter-spacing: 0px;
  white-space: nowrap;
  &:last-of-type {
    margin-right: 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${getThemeColor('black')(props)};
    `};

  ${(props) =>
    props.altStyling &&
    css`
      font-size: 14px;
      margin-right: 42px;
    `};

  ${(props) =>
    props.fontSizeSmall &&
    css`
      font-size: 14px;
    `};
`;

const StyledFilters = styled.div`
  display: flex;
  align-items: center;
`;

const FiltersTitle = styled(Text)`
  text-transform: uppercase;
  color: #959595;
`;

const DesktopMenuItemsContainer = styled(Flex)`
  max-width: 80%;

  ${mq.tablet(css`
    display: none;
  `)};

  ${(props) =>
    props.showOnTablet &&
    css`
      ${mq.tablet(css`
        display: flex;
      `)};
    `};
`;

const MobileMenuOuterContainer = styled.div`
  display: none;
  ${mq.tablet(css`
    display: block;
  `)};
`;

const MobileMenuContainer = styled.div``;

const ActiveIconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
`;

const MobileMenuActiveItem = styled.div``;

const MobileMenuDropdownContainerOuter = styled.div`
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #0003;
`;

const MobileMenuDropdownContainer = styled.div`
  width: 169px;
  background-color: #fff;
  z-index: 6;
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 100px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
`;

const MobileLink = styled(Link)`
  width: 100%;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  ${(props) =>
    props.active &&
    css`
      background-color: #f8f8f8;
    `};
`;

const ChevronDownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
`;

const StyledChevronDown = styled(ChevronDown)`
  fill: ${getThemeColor('purple')};
`;

function Filters({ withTitle, children }) {
  return (
    <StyledFilters>
      {withTitle && (
        <FiltersTitle mr={30} fontSizeExtraSmall>
          Showing:
        </FiltersTitle>
      )}
      <Flex alignItems={'center'}>{children}</Flex>
    </StyledFilters>
  );
}

function PageNavBar({
  title,
  subtitle,
  links,
  filters,
  mobileFilters,
  button,
  location,
  largeMode,
  showDesktopFiltersOnTablet,
}) {
  const [itemHoverIndex, setItemHoverIndex] = useState(undefined);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const ref = useRef(null);

  useOutsideClick(() => setMobileMenuIsOpen(false), {
    node: ref.current,
    enabled: mobileMenuIsOpen,
  });

  useEffect(() => {
    let locationHash = location.hash;
    if (links) {
      if (locationHash === '') {
        if (links.length > 1) {
          setActivePage(links[0].children);
        } else if (links !== null) {
          setActivePage(links.children);
        } else {
          setActivePage(null);
        }
      } else {
        let activePageTmp = links.filter((item) => {
          return item.to === locationHash;
        });
        setActivePage(activePageTmp);
      }
    }
  }, [location]);

  function renderLinks() {
    if (!links) {
      return null;
    }

    return (
      <Links as={Flex} alignItems={'center'}>
        {links.map((item, index) => {
          return (
            <PageNavMenuItem
              key={index}
              {...item}
              active={item.active}
              underlined={menuItemIsUnderlined(
                item.active,
                index,
                itemHoverIndex
              )}
              onMouseOver={() => setItemHoverIndex(index)}
              onMouseLeave={() => {
                if (index === itemHoverIndex) {
                  setItemHoverIndex(undefined);
                }
              }}
            />
          );
        })}
      </Links>
    );
  }

  function handleMobileMenuToggle() {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  }

  function renderMobileMenu() {
    if (!links) {
      return null;
    }

    return (
      <MobileMenuContainer>
        <ActiveIconContainer>
          <MobileMenuActiveItem onClick={() => handleMobileMenuToggle()}>
            {activePage}
          </MobileMenuActiveItem>
          <ChevronDownContainer>
            <StyledChevronDown />
          </ChevronDownContainer>
        </ActiveIconContainer>
        {mobileMenuIsOpen ? (
          <MobileMenuDropdownContainerOuter>
            <MobileMenuDropdownContainer ref={ref}>
              {links.map((link, index) => (
                <MobileLink
                  key={index}
                  active={link.to === activePage.to ? 'true' : ''}
                  {...link}
                >
                  {link.children}
                </MobileLink>
              ))}
            </MobileMenuDropdownContainer>
          </MobileMenuDropdownContainerOuter>
        ) : null}
      </MobileMenuContainer>
    );
  }

  return (
    <StyledPageNavBar>
      <StyledContainer
        as={Flex}
        height={'100%'}
        alignItems={'center'}
        justifyContent={'space-between'}
        large={largeMode}
      >
        <TitleWithSeparator title={title} subtitle={subtitle} />
        <DesktopMenuItemsContainer
          height={'100%'}
          alignItems={'center'}
          showOnTablet={showDesktopFiltersOnTablet}
        >
          {filters || renderLinks()}
          {button && <Button ml={56} height={48} {...button} />}
        </DesktopMenuItemsContainer>
        {(mobileFilters || links) && (
          <MobileMenuOuterContainer>
            {mobileFilters || renderMobileMenu()}
          </MobileMenuOuterContainer>
        )}
      </StyledContainer>
    </StyledPageNavBar>
  );
}

PageNavBar = withLocation(PageNavBar);

PageNavBar.Separator = Separator;
PageNavBar.Filters = Filters;
PageNavBar.FilterLink = FilterLink;

export default PageNavBar;
