import React from 'react';
import styled, { keyframes } from 'styled-components';

import { getThemeColor } from '../utils/styles';
import { Flex } from './UIKit/system';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledSpinner = styled.div`
  border: 6px solid ${getThemeColor('primary')};
  border-top: 6px solid transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
`;

function Spinner(props) {
  return (
    <Flex justifyContent={'center'} {...props}>
      <StyledSpinner />
    </Flex>
  );
}

export default Spinner;
