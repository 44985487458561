import React from 'react';
import styled, { css } from 'styled-components';

import Container from './container';
import { Box, Flex, Paragraph, Text } from './UIKit/system';
import Title from './title';
import GridCol from './grid-col';
import { supportsGrid } from '../utils/styles';
import LazyImage from './lazy-image';
import mq from '../utils/mq';

const StyledAboutProject = styled(Box)`
  padding-bottom: 140px;
  ${mq.mobile(css`
    padding-bottom: 38px;
  `)};
`;

const Specification = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;

  > * {
    width: calc(50% - 16px);
    margin-right: 16px;
    margin-bottom: 30px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  ${supportsGrid(css`
    display: grid;
    margin-bottom: 0;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 30px;

    > * {
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
    }
  `)}
`;

const SpecificationItem = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #f3f3f3;

  &:last-of-type,
  &:nth-last-of-type(2) {
    border-bottom: none;
  }
`;

const AboutContainer = styled(GridCol)`
  ${mq.mobile(css`
    flex-wrap: wrap;
    width: 100%;
    padding-right: 0;
  `)};
`;

const InnerContainer = styled(Flex)`
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const ImageContainer = styled.div`
  background-color: #f8f8f8;
  width: 346px;
  height: 243px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  ${mq.mobile(css`
    width: 100%;
    margin-top: 43px;
    margin-bottom: 30px;
  `)};
`;

const StyledLazyImage = styled(LazyImage)`
  width: 60%;
  margin-bottom: 0px;
`;

const WhoAreContainer = styled(Box)`
  ${mq.mobile(css`
    flex-direction: column-reverse;
    display: flex;
    margin-top: 67px;
  `)};
`;

function AboutProject({
  text,
  specification,
  image,
  imageTitle,
  imageDescription,
}) {
  return (
    <StyledAboutProject pb={70}>
      <Container>
        <InnerContainer justifyContent={'space-between'}>
          <AboutContainer>
            <Box mb={64}>
              <Title as={'h2'} mb={16} fontSizeMedium>
                About this project.
              </Title>
              <Paragraph html={text} grey maxWidth={440} />
            </Box>
            {specification && (
              <Specification>
                {specification.map((item) => {
                  return (
                    <SpecificationItem>
                      <Text bold html={item.name} />
                      <Text grey html={item.value} />
                    </SpecificationItem>
                  );
                })}
              </Specification>
            )}
          </AboutContainer>
          <WhoAreContainer maxWidth={['unset', null, 346]}>
            {image && (
              <ImageContainer>
                <StyledLazyImage mb={50} fluid={image} />
              </ImageContainer>
            )}
            <Box maxWidth={'95%'}>
              <Text mb={10} html={imageTitle} bold />
              <Paragraph
                fontSize={[13, 16, 16]}
                grey
                lineHeight={2}
                html={imageDescription}
              />
            </Box>
          </WhoAreContainer>
        </InnerContainer>
      </Container>
    </StyledAboutProject>
  );
}

export default AboutProject;
