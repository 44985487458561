import React from 'react';
import SectionStages from '../../section-stages';
import RichtextParser from '../../../utils/richtext-parser';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed';

function StoryblokHowItWorksStagesAdapter({
  stages,
  disclaimer,
  title,
  video,
}) {
  let parsedStages = [];
  let totalLength = 0;

  stages.forEach((stage) => {
    let fluidImage = getFluidGatsbyImage(stage.image, {
      maxWidth: 1080,
    });
    let stageObj = {
      title: stage.title,
      text: stage.description,
      image: fluidImage,
      length: parseInt(stage.animationLength),
      ...stage,
    };
    totalLength = totalLength + parseInt(stage.animationLength);
    parsedStages.push(stageObj);
  });

  return (
    <SectionStages
      title={title}
      stages={parsedStages}
      text={RichtextParser(disclaimer)}
      video={video && video.filename}
      totalLength={parseInt(totalLength)}
    />
  );
}

export default StoryblokHowItWorksStagesAdapter;
