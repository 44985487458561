import React from 'react';
import styled from 'styled-components';

import Title from './title';
import { Box, Paragraph } from './UIKit/system';

const IconTextContainer = styled.div`
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

function IconText({ title, text, icon }) {
  return (
    <IconTextContainer>
      <Box mb={12} maxWidth={36}>
        <img src={icon && icon.filename} alt={'Icon'} aria-hidden={'true'} />
      </Box>
      <Title
        as={'h3'}
        maxWidth={500}
        mb={[9]}
        fontSizeExtraSmall
        html={title}
      />
      <Paragraph grey html={text} />
    </IconTextContainer>
  );
}

export default IconText;
