import React, { FunctionComponent } from "react"
import styled from 'styled-components'

// Styles
const StyledVideoHero = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: max(80vh, 500px);
  overflow: hidden;

  @media (max-width: 500px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
  }

  .title {
    z-index: 5;

    font-family: Muli;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.02em;
    margin: 85px 100px;

    @media (max-width: 500px) {
      margin: 6%;
    }
  }
`

// Types
interface Props {
  title: string;
  title_color?: { color?: string };
  video?: {
    id: number;
    alt: string;
    name: string;
    focus?: null;
    title: string;
    filename: string;
    copyright: string;
    fieldtype: string;
  }
}

const VideoHero: FunctionComponent<Props> = ({ title, video, title_color = { color: 'black' } }) => {
  return (
    <StyledVideoHero>
      {video && <video className="video" src={video.filename} autoPlay muted loop></video>}
      <p className="title" style={{ color: title_color.color ?? 'black' }}>{title}</p>
    </StyledVideoHero>
  )
}

export default VideoHero
