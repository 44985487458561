import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import Title from './title';
import Container from './container';
import { darkBackground, getThemeColor } from '../utils/styles';
import { Box, Flex, Paragraph, Text } from './UIKit/system';
import TextWithBar from './text-with-bar';
import mq from '../utils/mq';
import TimelineItem from './timelineItem.jsx';

const ANIMATION_LENGTH = 5000;

const ContentContainer = styled(Flex)`
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const TitleContainer = styled(Box)`
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const TimlineOuterContainer = styled(Box)`
  ${mq.mobile(css`
    flex: unset !important;
    margin-top: 47px;
  `)};
`;

const StyledSectionAboutCompany = styled(Box)`
  ${darkBackground};
`;

function SectionAboutCompany({ title, subtitle, paragraph, items }) {
  const [activeStage, setActiveStage] = useState(0);
  const [animateItems, setAnimateItems] = useState(true);
  const [updatedAnim, setUpdatedAnim] = useState(1);

  const [blockRef, inView] = useInView({
    threshold: 0.25,
  });

  return (
    <StyledSectionAboutCompany pt={[70, 70, 140]} pb={[120]}>
      <Container>
        <ContentContainer>
          <TitleContainer pr={60} maxWidth={552} flex={1}>
            <Box maxWidth={400}>
              <Title fontSizeMedium mb={30}>
                {title}
              </Title>
              <TextWithBar>{subtitle}</TextWithBar>
            </Box>
          </TitleContainer>
          <TimlineOuterContainer flex={1}>
            <Paragraph mb={60} lightGrey html={paragraph} />
            <Box ref={blockRef}>
              {items.map((item, index) => {
                return (
                  <TimelineItem
                    key={index}
                    title={item.year}
                    text={item.title}
                    animate={true}
                    activeItem={activeStage === index ? true : false}
                    filled={activeStage > index ? true : false}
                    totalLength={ANIMATION_LENGTH * items.length}
                    delay={ANIMATION_LENGTH * index}
                  />
                );
              })}
            </Box>
          </TimlineOuterContainer>
        </ContentContainer>
      </Container>
    </StyledSectionAboutCompany>
  );
}

export default SectionAboutCompany;
