import React from 'react';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import SectionLinks from '../../section-links';

function StoryblokSystemsLegislationAdapter({ title, subtext, items }) {
  let linksArr = [];
  items.forEach((item) => {
    let itemObj;
    if (item.type === 'link') {
      itemObj = {
        to: parseStoryblokLink(item.link),
        children: item.text,
      };
    } else {
      itemObj = {
        to: item.file.filename,
        children: item.text,
      };
    }
    linksArr.push(itemObj);
  });
  return <SectionLinks title={title} text={subtext} links={linksArr} />;
}

export default StoryblokSystemsLegislationAdapter;
