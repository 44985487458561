import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import GridCol from './grid-col';
import Title from './title';
import { STANDARD_PADDING } from '../constants';
import mq from '../utils/mq';

const StyledItem = styled.div`
  margin-bottom: 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:last-of-type {
    margin-bottom: 0;
  }
  ::before {
    content: '';
    min-height: 80px;
    height: 100%;
    width: 2px;
    background-color: #d4d4d4;
    margin-right: 27px;
  }
  ${mq.mobile(css`
    ::before {
      min-height: 68px;
      margin-right: 13px;
    }
  `)};
`;

const TextContainer = styled.div``;

function Item({ title, subtitle }) {
  return (
    <StyledItem>
      <TextContainer>
        <Title mb={7} fontSizeExtraSmall html={title} />
        <Paragraph fontSize={15} grey html={subtitle} />
      </TextContainer>
    </StyledItem>
  );
}

const StyledSectionTimeline = styled(Box)``;

const StyledGridCol = styled(GridCol)`
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

function SectionTimeline({
  title,
  contentTitle,
  durationText,
  contentText,
  items,
}) {
  return (
    <StyledSectionTimeline pt={STANDARD_PADDING} pb={STANDARD_PADDING}>
      <Container as={Flex} flexWrap={['wrap', 'unset', 'unset']}>
        <StyledGridCol>
          <Title as={'h2'} html={title} />
        </StyledGridCol>
        <Box pt={20}>
          <Box mb={48}>
            <Box>
              <Title
                as={'span'}
                lineHeight={1.1}
                fontSizeExtraSmall2
                html={contentTitle}
              />{' '}
              {durationText && (
                <Title
                  as={'span'}
                  lineHeight={1.1}
                  fontSizeExtraSmall2
                  color={'primary'}
                  html={durationText}
                />
              )}
            </Box>
            {contentText && <Paragraph mt={13} grey html={contentText} />}
          </Box>
          <Box>
            {items.map((item, index) => {
              return <Item key={index} {...item} />;
            })}
          </Box>
        </Box>
      </Container>
    </StyledSectionTimeline>
  );
}

export default SectionTimeline;
