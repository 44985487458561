import React, { FC } from "react"
import Accordion from "../accordion"

interface Props {
  items: any[]
}

const FAQs: FC<Props> = ({ items = [] }) => {
  return (
    <Accordion data={items} metaId={undefined} />
  )
}

export default FAQs
