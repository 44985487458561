import React from 'react';
import styled, { css } from 'styled-components';

import mq from '../utils/mq';
import LinkWithArrow from './link-with-arrow';
import Title from './title';
import { Flex, Text } from './UIKit/system';
import { supportsGrid } from '../utils/styles';

const IconCardContainerOuter = styled.div`
  width: 338px;
  height: 412px;
  margin-left: 10px;
  margin-right: 10px;

  ${mq.desktopSmall(css`
    margin-top: 8px;
    margin-bottom: 8px;
    width: 278px;
    height: 382px;
  `)};

  ${mq.mobile(css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `)};
`;

const IconCardContainer = styled.div`
  max-width: 338px;
  height: 412px;
  background: #ffffff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.025), 0 6px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: box-shadow 0.65s ease-out;

  ${supportsGrid(css`
    display: grid;
    grid-template-rows: minmax(100px, 1.7fr) 1fr 1fr;
    justify-items: center;
    align-items: center;
  `)}

  ${mq.desktopSmall(css`
    width: 278px;
    height: 382px;
  `)};
`;

const StyledTitle = styled(Title)`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #161616;
  letter-spacing: 0;
  margin-bottom: 14px;
`;

const Paragraph = styled(Text)`
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #666666;
  max-width: 278px;
  ${mq.desktopSmall(css`
    width: 90%;
    font-size: 15px;
  `)};
`;

const IconContainer = styled.div`
  position: relative;
  padding-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const IconImg = styled.img`
  display: inline;
  max-height: 85%;
  max-width: 72%;
`;

function IconCard({ title, paragraph, icon, ctaText, ctaLink }) {
  return (
    <IconCardContainerOuter>
      <IconCardContainer>
        <IconContainer>{icon && <IconImg src={icon.filename} />}</IconContainer>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <StyledTitle html={title} />
          <Paragraph grey html={paragraph} />
        </Flex>
        <LinkWithArrow to={ctaLink}>{ctaText}</LinkWithArrow>
      </IconCardContainer>
    </IconCardContainerOuter>
  );
}
export default IconCard;
