import React from 'react';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';

import ServicesHeader from '../../services-header';

function StoryblokServicesHeaderAdapter({ image, ...rest }) {
  let fluidImage = getFluidGatsbyImage(image, {
    maxWidth: 1920,
  });
  return <ServicesHeader image={fluidImage} {...rest} />;
}

export default StoryblokServicesHeaderAdapter;
