import React, { useState } from 'react';
import styled from 'styled-components';

import Question from './question';
import Answer from './answer';
import RichtextParser from '../../utils/richtext-parser';

const StyledItem = styled.div`
  border-bottom: 1px solid #f3f3f3;

  &:last-of-type {
    border-bottom: none;
  }
`;

function Item({ question, answer }) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <StyledItem>
      <Question
        collapsed={collapsed}
        onClick={() => setCollapsed((state) => !state)}
      >
        {question}
      </Question>
      <Answer collapsed={collapsed}>
        {typeof answer == 'string' ? answer : RichtextParser(answer)}
      </Answer>
    </StyledItem>
  );
}

export default Item;
