import React from 'react';
import styled, { css } from 'styled-components';

import Container from './container';
import { Box, Flex, Paragraph, Text } from './UIKit/system';
import GridCol from './grid-col';
import Title from './title';
import { darkBackground } from '../utils/styles';
import Carousel from './carousel';
import mq from '../utils/mq';

const StyledSection = styled.div`
  margin-bottom: 60px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.div`
  position: relative;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 12px;
  ${mq.tablet(css`
    display: flex;
    flex-direction: column;
  `)};
`;

const SectionTitleNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -24px;
  top: 0;
  transform: translate(-100%, -25%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #474747;
  text-align: center;
  ${mq.tablet(css`
    position: initial;
    left: 0;
    transform: translate(0);
    margin-bottom: 18px;
  `)};
`;

const ContentContainer = styled(Flex)`
  ${mq.tablet(css`
    flex-wrap: wrap;
  `)};
`;

const TitleContainer = styled(GridCol)`
  padding-right: 30px;
  ${mq.tablet(css`
    width: 100%;
  `)};
`;

const StyledParagraph = styled(Paragraph)`
  ul {
    margin-left: 17px;
  }
`;

function Section({ index, title, text }) {
  return (
    <StyledSection>
      <SectionTitle>
        <SectionTitleNumber>
          <Text color={'white'} fontSizeMedium>
            {index + 1}
          </Text>
        </SectionTitleNumber>
        <Text fontSize={18} html={title} />
      </SectionTitle>
      <StyledParagraph html={text} fontSize={[16, 18, 18]} lightGrey />
    </StyledSection>
  );
}

const StyledProductDetails = styled(Box)`
  ${darkBackground};
  color: #fff;
`;

function DetailsSteps({ title, sections, smallPaddingTop }) {
  return (
    <StyledProductDetails
      pt={smallPaddingTop ? [70, 80, 100] : [70, 100, 124]}
      pb={[70, 80, 100]}
    >
      <Container>
        <ContentContainer>
          <TitleContainer>
            <Box pr={[0, null, 70]}>
              <Title
                fontSizeMedium
                lineHeight={[null, null, '48px']}
                html={title}
              />
            </Box>
          </TitleContainer>
          <Box pt={16}>
            {sections.map((item, index) => {
              return <Section key={index} index={index} {...item} />;
            })}
          </Box>
        </ContentContainer>
      </Container>
    </StyledProductDetails>
  );
}

export default DetailsSteps;
