import React from 'react';
import styled from 'styled-components';

import LinkWithArrow from './link-with-arrow';
import { Flex } from './UIKit/system';
import PreviewTile from './preview-tile';

const StyledCaseStudyWithCta = styled.div``;

function CaseStudyWithCta({ title, link, image, dark, zoomOut, ...rest }) {
  return (
    <StyledCaseStudyWithCta
      as={Flex}
      flexDirection={'column'}
      alignItems={'flex-start'}
      {...rest}
    >
      <PreviewTile
        width={'100%'}
        mb={[23, 30]}
        to={link}
        image={image}
        title={title}
        fullWidthResponsive
        zoomOut={zoomOut}
      />
      <LinkWithArrow to={link} white={dark}>
        View Case Study
      </LinkWithArrow>
    </StyledCaseStudyWithCta>
  );
}

export default CaseStudyWithCta;
