import React from 'react';
import Spacer from '../../spacer';

function StoryblokBlankSpace({
  desktopHeight,
  tabletHeight,
  mobileHeight,
  ...rest
}) {
  return (
    <Spacer
      height={desktopHeight + 'px'}
      tabletHeight={
        tabletHeight === '' ? tabletHeight + 'px' : desktopHeight + 'px'
      }
      mobileHeight={
        mobileHeight === ''
          ? tabletHeight + 'px' === ''
            ? tabletHeight + 'px'
            : desktopHeight + 'px'
          : mobileHeight + 'px'
      }
    />
  );
}

export default StoryblokBlankSpace;
