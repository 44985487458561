import styled from 'styled-components'

export const StyledSpecificationLayout = styled.div`
  /* CSS Vars */
  --padding: 3rem;
`
export const StyledLayoutNav = styled.div`
  padding: var(--padding) var(--padding) 0;
  background: #161616;

  @media (max-width: 425px) {
    padding: calc(var(--padding) / 2);
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    background: #fff;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
`
export const StyledSpecificationHeader = styled.div`
  background: #161616;
  color: #fafafa;
  text-align: center;
  padding: 0 80px 80px;

  @media (max-width: 500px) {
    padding: 0 20px 20px;
  }

  .subtitle {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
  }
  .title {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 48px;
  }
`
export const StyledSpecificationProduct = styled.div`
  background: #ffffff;
  color: #666666;
  display: flex;
  flex-direction: row;
  margin: 50px auto 0;
  padding: 50px;
  gap: 50px;
  text-align: left;
  width: min(1200px, 90%);

  @media (max-width: 500px) {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    margin-bottom: 20px;
  }

  .image {
    position: relative;
    width: 40%;
    max-height: 250px;

    @media (max-width: 500px) {
      width: 100%;
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    width: 60%;
    padding: 1rem 0;

    @media (max-width: 500px) {
      width: 100%;
    }

    .title {
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 93.5%;
      color: #161616;
      margin-bottom: 24px;
    }

    .content {
      font-family: Muli;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #666666;
    }

    ul {
      margin-top: 1rem;
      padding-left: 2rem;

      li {
        margin-top: 0;
      }
    }

    .error {
      margin-top: 30px;
      font-family: Muli;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.02em;
      color: red;
    }

    .buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      @media (max-width: 500px) {
        flex-direction: column;
      }

      .button {
        width: 50%;
        padding: 22px 25px;
        border: 1.5px solid #753fbf;
        background: #753fbf;
        color: #fff;
        font-family: Muli;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        cursor: pointer;

        @media (max-width: 500px) {
          width: 100%;
        }

        &.outlined {
          border: 1.5px solid #753fbf;
          background: #fff;
          color: #474747;
        }
      }
    }
  }
`
export const StyledSpecificationContainer = styled.div`
  background: transparent;
  color: #666;
  min-height: 40vh;
  padding: 0 80px;
  position: relative;
  text-align: center;

  @media (max-width: 500px) {
    padding: 20px 0;
  }
`
export const StyledSpecificationOverview = styled.div`
  background-color: #fff;
  margin: -50px auto 0;
  min-height: 20vh;
  position: relative;
  width: min(1200px, 80%);
  padding: 50px;

  @media (max-width: 500px) {
    margin: 0 auto;
    padding: 0;
  }

  .title {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #161616;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .subtitle {
    text-align: left;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 93.5%;
    color: #161616;
    margin: 24px 0;
  }

  .description {
    margin-top: 48px;

    .content {
      text-align: left;
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #666666;
    }
  }

  .gallery {
    overflow: hidden;

    @media (max-width: 500px) {
      margin-top: 40px;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    margin: 48px 0;

    @media (max-width: 500px) {
      flex-direction: column;
      gap: 20px;
    }

    .button {
      align-items: center;
      background: #ffffff;
      border-radius: 2px;
      border: 1.5px solid #753fbf;
      box-sizing: border-box;
      color: #161616;
      display: flex;
      font-family: Muli;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      height: 64px;
      line-height: 18px;
      padding: 14px 18px;
      text-align: center;
      width: 50%;
      text-decoration: none;

      @media (max-width: 500px) {
        width: 100%;
      }

      svg {
        height: 100%;
      }

      span {
        flex-grow: 1;
        text-align: center;
      }
    }
  }
`
export const StyledSpecificationCTA = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
  width: min(1200px, 80%);
  padding: 50px;
  background: #f8f8f8;
  gap: 2rem;

  @media (max-width: 500px) {
    flex-direction: column;
    padding: 20px;
  }

  .text {
    width: 50%;
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #161616;
    text-align: left;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .button {
    align-items: center;
    align-items: center;
    background: #753fbf;
    border-radius: 1px;
    color: #ffffff;
    display: flex;
    font-family: Muli;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    height: 64px;
    justify-content: center;
    letter-spacing: -0.01em;
    line-height: 140%;
    text-align: center;
    text-decoration: none;
    width: 257px;

    @media (max-width: 500px) {
      width: 100%;
    }
  }
`
export const StyledTableContent = styled.div`
  margin: 50px auto;
  width: min(1200px, 80%);
  padding: 50px;
  text-align: left;

  @media (max-width: 500px) {
    display: none;
  }

  .subtitle {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 93.5%;
    color: #161616;
  }

  .content {
    margin-top: 20px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 32px;
    color: #666666;
  }
`

export const StyledTable = styled.table`
  margin: auto;
  width: min(1200px, 80%);
  border-spacing: 0 16px;

  @media (max-width: 500px) {
    display: none;
  }

  .row {
    td {
      padding: 15px;
    }
  }

  .head {
    .row {
      .title {
        font-family: Muli;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 93.5%;
        color: #753fbf;
      }

      .pod {
        .image {
          height: 150px;
          width: 160px;
        }

        .title {
          font-family: Muli;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          color: #000000;
          padding: 5%;
        }
      }
    }
  }

  .body {
    .row {
      height: 90px;
      background: #f3f3f3;

      .feature {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        p {
          font-family: Muli;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 32px;
          color: #000000;
        }
      }
    }
  }
`
