import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import Container from './container';
import { Flex } from './UIKit/system';
import { getThemeColor } from '../utils/styles';
import TitleWithSeparator from './title-with-separator';
import ChevronDown from '../images/svg/chevron-down.svg';

const PageNavBarMobileContainer = styled.div`
  height: 72px;
  position: sticky;
  top: 88px;
  max-width: 100vw;
  background-color: #fff;
  z-index: 5;
  left: 0;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuContainer = styled.div`
  position: relative;
  display: flex;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
`;

const ActiveFilterText = styled.div`
  text-transform: capitalize;
  user-select: none;
`;

const MenuObject = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #eee;
  z-index: 6;
  margin-top: 60px;
`;

const ChevronDownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
`;

const StyledChevronDown = styled(ChevronDown)`
  fill: ${getThemeColor('purple')};
`;

const FilterObject = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  width: 167px;
  background: #fff;
  padding: 9px 15px;
  user-select: none;
  ${(props) =>
    props.active &&
    css`
      background-color: #f8f8f8;
    `};
`;

const BackgroundContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #0003;
`;

function PageNavBarMobile({
  title,
  subtitle,
  filters,
  activeFilter,
  setActiveCategory,
}) {
  const activeFilterObj = useMemo(() => {
    return filters.find((v) => v.title.toLowerCase() === activeFilter);
  }, [filters, activeFilter]);

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  return (
    <PageNavBarMobileContainer>
      <Container
        as={Flex}
        height={'100%'}
        alignItems={'center'}
        justifyContent={'space-between'}
        large
      >
        <InnerContainer>
          <TitleWithSeparator title={title} subtitle={subtitle} />
          <MenuContainer onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}>
            <ActiveFilterText>
              {activeFilterObj && activeFilterObj.title}
            </ActiveFilterText>
            <ChevronDownContainer>
              <StyledChevronDown />
            </ChevronDownContainer>
            {mobileMenuIsOpen && (
              <>
                <MenuObject>
                  {filters.map((filter, i) => (
                    <FilterObject
                      key={i}
                      active={filter.title.toLowerCase() === activeFilter}
                      onClick={() =>
                        setActiveCategory(filter.title.toLowerCase())
                      }
                    >
                      {filter.title}
                    </FilterObject>
                  ))}
                </MenuObject>
                <BackgroundContainer />
              </>
            )}
          </MenuContainer>
        </InnerContainer>
      </Container>
    </PageNavBarMobileContainer>
  );
}

export default PageNavBarMobile;
