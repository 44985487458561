import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from './UIKit/system';
import Container from './container';
import LogoTile from './logo-tile';
import { supportsGrid } from '../utils/styles';
import { STANDARD_PADDING } from '../constants';
import mq from '../utils/mq';

const StyledLogoTileList = styled(Box)``;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${supportsGrid(css`
    display: grid;
    grid-gap: 19px;
    grid-template-columns: repeat(4, 1fr);
  `)}
  ${mq.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)};
  ${mq.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)};
`;

function LogoTileList({ items }) {
  return (
    <StyledLogoTileList pb={STANDARD_PADDING}>
      <Container>
        <Items>
          {items.map((item, index) => (
            <LogoTile key={index} {...item} />
          ))}
        </Items>
      </Container>
    </StyledLogoTileList>
  );
}

export default LogoTileList;
