import React from 'react';
import RichtextParser from '../../../utils/richtext-parser';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import SectionTitleAndButton from '../../section-title-and-button';

function StoryblokCtaWithTextblockAdapter({
  text,
  ctaText,
  ctaLink,
  ctaType,
  ctaFile,
  popupTitle,
}) {
  if (ctaType === 'download') {
    return (
      <SectionTitleAndButton
        text={text}
        subtitle={''}
        download
        filePath={ctaFile && ctaFile.filename}
        popupTitle={RichtextParser(popupTitle)}
        button={{
          children: ctaText,
        }}
        grey
      />
    );
  } else if (ctaType === 'link') {
    return (
      <SectionTitleAndButton
        text={text}
        subtitle={''}
        button={{
          children: ctaText,
          to: parseStoryblokLink(ctaLink),
        }}
        grey
      />
    );
  } else {
    return <div></div>;
  }
}

export default StoryblokCtaWithTextblockAdapter;
