import React from 'react';
import parseStoryblokLink from '../../utils/parse-storyblok-link';
import RichtextParser from '../../utils/richtext-parser';
import { getFluidGatsbyImage } from '../../utils/gatsby-storyblok-image-fixed/';

import Intro6 from '../../components/intro6';
import LazyImage from '../../components/lazy-image';
import { Box } from '../UIKit/system';
import LeftImageRightContent from '../../components/left-image-right-content';
import SectionTimeline from '../../components/section-timeline';
import SectionQuote from '../../components/section-quote';
import CourseSignup from '../course-signup';
import QuoteCarousel from '../quotes-carousel/index';

function StoryblokCoursePage(body) {
  let fluidFullWidthImg;
  let aboutImage;

  if (body.introFullWidthImage !== undefined) {
    fluidFullWidthImg = getFluidGatsbyImage(body.introFullWidthImage, {
      maxWidth: 1920,
    });
  }

  if (body.aboutImage !== undefined) {
    aboutImage = getFluidGatsbyImage(body.aboutImage, {
      maxWidth: 800,
    });
  }

  function isEven(value) {
    if (value % 2 == 0) return true;
    else return false;
  }

  return (
    <>
      <Intro6
        pagination={[
          { link: '/courses/', name: 'Sertus Training' },
          { name: 'Course details' },
        ]}
        title={body.courseTitle}
        introduction={body.courseIntroSubtitle}
        button={{
          to: '#form',
          children: body.introCtaText,
        }}
      />
      {body.introFullWidthImage && <LazyImage fluid={fluidFullWidthImg} />}
      <Box>
        <LeftImageRightContent
          title={body.aboutTitle}
          paragraph={RichtextParser(body.aboutText)}
          image={aboutImage}
        />
        <SectionTimeline
          title={'Course programme.'}
          contentTitle={'Course length:'}
          durationText={body.courseLength}
          contentText={body.courseLengthSubtitle}
          items={body.courseProgramme}
        />
        {body.quotes.length !== 0 ? (
          body.quotes.length === 1 ? (
            <SectionQuote
              text={body.quotes[0].quote}
              author={body.quotes[0].quotee}
              company={body.quotes[0].quoteeSubtitle}
              largeQuoteMode={body.quotes[0].largeQuoteMode}
            />
          ) : (
            <QuoteCarousel quotes={body.quotes} />
          )
        ) : null}

        <div id="form" />
        {body.signupFormTitle &&
          body.signupFormPortalId &&
          body.signupFormId && (
            <CourseSignup
              title={body.signupFormTitle}
              subtitle={RichtextParser(body.signupFormSubtext)}
              portalId={body.signupFormPortalId}
              formId={body.signupFormId}
            />
          )}
      </Box>
    </>
  );
}

export default StoryblokCoursePage;
