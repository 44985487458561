import React from 'react';
import PropTypes from 'prop-types';

class VideoTag extends React.PureComponent {
  static propTypes = {
    videoRef: PropTypes.object,
    onLoadedData: PropTypes.func,
    src: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { onLoadedData, onCanPlay } = this.props;
    const videoNode = this.containerNode.querySelector('video');

    if (videoNode.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA) {
      onLoadedData && videoNode.addEventListener('loadeddata', onLoadedData);
      onCanPlay && videoNode.addEventListener('canplay', onCanPlay);
    } else {
      onLoadedData && onLoadedData();
      onCanPlay && onCanPlay();
    }
    if (this.props.videoRef) {
      this.props.videoRef.current = videoNode;
    }
  }

  render() {
    const {
      className,
      src,
      withSound,
      noLoop,
      onEnded,
      onCanPlay,
      videoRef,
    } = this.props;

    return (
      <div className={className} ref={(node) => (this.containerNode = node)}>
        <video
          src={src}
          playsInline
          muted={!withSound}
          loop={!noLoop}
          preload
          onEnded={onEnded}
          onCanPlay={onCanPlay}
          ref={videoRef}
        />
      </div>
    );
  }
}

export default VideoTag;
