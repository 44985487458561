import React from 'react';
import styled, { css } from 'styled-components';
import Title from '../title';
import { Paragraph } from '../UIKit/system';
import IconTick from '../../images/svg/tick-in-circle.svg';
import IconMinus from '../../images/svg/minus-in-circle.svg';
import mq from '../../utils/mq';

const MobileComparisonGridContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 380px) {
    justify-content: space-between;
  }
`;

const ComparisonColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 250px;
  background-color: #f7f7f8;
  border-width: 0px;
  border-top-width: 2px;
  border-style: solid;
  border-color: #959595;
  margin-right: 22px;

  :first-of-type {
    border-color: #753fbf;
    margin-left: 22px;
  }

  @media (max-width: 380px) {
    width: 175px;
    margin-right: 8px;
    :first-of-type {
      margin-left: 8px;
    }
  }
`;

const ItemContainer = styled.div`
  text-align: center;
  max-width: 85%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;
  height: 200px;
  &:after {
    content: '';
    height: 1px;
    width: 90%;
    display: block;
    margin-top: 21px;
    background-color: #dddddd;
  }
  :last-of-type {
    &:after {
      display: none;
    }
  }
`;

const ItemAvailability = styled.div``;

const StyledTitle = styled(Title)`
  margin-top: 30px;
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  ${mq.mobileSmall(css`
    font-size: 14px;
  `)};
`;

const ItemTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 11px;
`;

const ItemText = styled(Paragraph)`
  font-size: 13px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #666666;
  margin-bottom: 16px;
`;

function ComparisonGridMobile(params, data) {
  return (
    <MobileComparisonGridContainer>
      {params.data.map((item, index) => (
        <ComparisonColumn>
          <StyledTitle>{item.title}</StyledTitle>
          {item.data.map((data, i) => (
            <ItemContainer>
              <ItemTitle>{data.title}</ItemTitle>
              <ItemText>{data.text}</ItemText>
              <ItemAvailability>
                {data.availability ? <IconTick /> : <IconMinus />}
              </ItemAvailability>
            </ItemContainer>
          ))}
        </ComparisonColumn>
      ))}
    </MobileComparisonGridContainer>
  );
}

export default ComparisonGridMobile;
