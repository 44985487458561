const data = [
  {
    type: 'SLD-R.50.20',
    overallWidth: 569,
    overallHeight: 391,
    size: '569 x 391',
    geometricArea: 0.1,
    freeArea: 0.06,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.4,
        pressureDrop: 16,
        noiseLevel: 27
      },
      {
        velocity: 6,
        flowRate: 0.6,
        pressureDrop: 37,
        noiseLevel: 38
      },
      {
        velocity: 8,
        flowRate: 0.8,
        pressureDrop: 65,
        noiseLevel: 46
      },
      {
        velocity: 10,
        flowRate: 1,
        pressureDrop: 106,
        noiseLevel: 53
      }
    ]
  },
  {
    type: 'SLD-R.50.25',
    overallWidth: 569,
    overallHeight: 441,
    size: '569 x 441',
    geometricArea: 0.13,
    freeArea: 0.08,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.5,
        pressureDrop: 16,
        noiseLevel: 28
      },
      {
        velocity: 6,
        flowRate: 0.75,
        pressureDrop: 37,
        noiseLevel: 39
      },
      {
        velocity: 8,
        flowRate: 1,
        pressureDrop: 65,
        noiseLevel: 47
      },
      {
        velocity: 10,
        flowRate: 1.25,
        pressureDrop: 106,
        noiseLevel: 54
      }
    ]
  },
  {
    type: 'SLD-R.50.30',
    overallWidth: 569,
    overallHeight: 491,
    size: '569 x 491',
    geometricArea: 0.15,
    freeArea: 0.09,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.6,
        pressureDrop: 16,
        noiseLevel: 29
      },
      {
        velocity: 6,
        flowRate: 0.9,
        pressureDrop: 37,
        noiseLevel: 41
      },
      {
        velocity: 8,
        flowRate: 1.2,
        pressureDrop: 65,
        noiseLevel: 48
      },
      {
        velocity: 10,
        flowRate: 1.5,
        pressureDrop: 106,
        noiseLevel: 54
      }
    ]
  },
  {
    type: 'SLD-R.50.35',
    overallWidth: 569,
    overallHeight: 541,
    size: '569 x 541',
    geometricArea: 0.18,
    freeArea: 0.11,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.7,
        pressureDrop: 16,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.05,
        pressureDrop: 37,
        noiseLevel: 42
      },
      {
        velocity: 8,
        flowRate: 1.4,
        pressureDrop: 65,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 1.75,
        pressureDrop: 106,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.50.40',
    overallWidth: 569,
    overallHeight: 591,
    size: '569 x 591',
    geometricArea: 0.2,
    freeArea: 0.12,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.8,
        pressureDrop: 16,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.2,
        pressureDrop: 37,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 1.6,
        pressureDrop: 65,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 2,
        pressureDrop: 106,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.50.45',
    overallWidth: 569,
    overallHeight: 641,
    size: '569 x 641',
    geometricArea: 0.23,
    freeArea: 0.14,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.9,
        pressureDrop: 16,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.35,
        pressureDrop: 37,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 1.8,
        pressureDrop: 65,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 2.25,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.50',
    overallWidth: 569,
    overallHeight: 691,
    size: '569 x 691',
    geometricArea: 0.25,
    freeArea: 0.15,
    velocities: [
      {
        velocity: 4,
        flowRate: 1,
        pressureDrop: 16,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.5,
        pressureDrop: 37,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2,
        pressureDrop: 65,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 2.5,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.55',
    overallWidth: 569,
    overallHeight: 741,
    size: '569 x 741',
    geometricArea: 0.28,
    freeArea: 0.17,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.1,
        pressureDrop: 16,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.65,
        pressureDrop: 37,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.2,
        pressureDrop: 65,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 2.75,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.60',
    overallWidth: 569,
    overallHeight: 791,
    size: '569 x 791',
    geometricArea: 0.3,
    freeArea: 0.18,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.2,
        pressureDrop: 16,
        noiseLevel: 30
      },
      {
        velocity: 6,
        flowRate: 1.8,
        pressureDrop: 37,
        noiseLevel: 42
      },
      {
        velocity: 8,
        flowRate: 2.4,
        pressureDrop: 65,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 3,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.65',
    overallWidth: 569,
    overallHeight: 841,
    size: '569 x 841',
    geometricArea: 0.33,
    freeArea: 0.2,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.3,
        pressureDrop: 16,
        noiseLevel: 32
      },
      {
        velocity: 6,
        flowRate: 1.95,
        pressureDrop: 37,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.6,
        pressureDrop: 65,
        noiseLevel: 50
      },
      {
        velocity: 10,
        flowRate: 3.25,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.70',
    overallWidth: 569,
    overallHeight: 891,
    size: '569 x 891',
    geometricArea: 0.35,
    freeArea: 0.21,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.4,
        pressureDrop: 16,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 2.1,
        pressureDrop: 37,
        noiseLevel: 44
      },
      {
        velocity: 8,
        flowRate: 2.8,
        pressureDrop: 65,
        noiseLevel: 51
      },
      {
        velocity: 10,
        flowRate: 3.5,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.75',
    overallWidth: 569,
    overallHeight: 941,
    size: '569 x 941',
    geometricArea: 0.38,
    freeArea: 0.23,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.5,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 2.25,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3,
        pressureDrop: 65,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 3.75,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.80',
    overallWidth: 569,
    overallHeight: 991,
    size: '569 x 991',
    geometricArea: 0.4,
    freeArea: 0.24,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.6,
        pressureDrop: 16,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 2.4,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.2,
        pressureDrop: 65,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 4,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.85',
    overallWidth: 569,
    overallHeight: 1041,
    size: '569 x 1041',
    geometricArea: 0.43,
    freeArea: 0.26,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.7,
        pressureDrop: 16,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 2.55,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.4,
        pressureDrop: 65,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 4.25,
        pressureDrop: 106,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.50.90',
    overallWidth: 569,
    overallHeight: 1091,
    size: '569 x 1091',
    geometricArea: 0.45,
    freeArea: 0.27,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.8,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 2.7,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.6,
        pressureDrop: 65,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 4.5,
        pressureDrop: 106,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.50.95',
    overallWidth: 569,
    overallHeight: 1141,
    size: '569 x 1141',
    geometricArea: 0.48,
    freeArea: 0.29,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.9,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 2.85,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.8,
        pressureDrop: 65,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 4.75,
        pressureDrop: 106,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.50.100',
    overallWidth: 569,
    overallHeight: 1191,
    size: '569 x 1191',
    geometricArea: 0.5,
    freeArea: 0.3,
    velocities: [
      {
        velocity: 4,
        flowRate: 2,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4,
        pressureDrop: 65,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 5,
        pressureDrop: 106,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.50.105',
    overallWidth: 569,
    overallHeight: 1241,
    size: '569 x 1241',
    geometricArea: 0.53,
    freeArea: 0.32,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.1,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.15,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.2,
        pressureDrop: 65,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 5.25,
        pressureDrop: 106,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.50.110',
    overallWidth: 569,
    overallHeight: 1291,
    size: '569 x 1291',
    geometricArea: 0.55,
    freeArea: 0.33,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.2,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.3,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.4,
        pressureDrop: 65,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 5.5,
        pressureDrop: 106,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.50.115',
    overallWidth: 569,
    overallHeight: 1341,
    size: '569 x 1341',
    geometricArea: 0.58,
    freeArea: 0.35,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.3,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.45,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.6,
        pressureDrop: 65,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 5.75,
        pressureDrop: 106,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.50.120',
    overallWidth: 569,
    overallHeight: 1391,
    size: '569 x 1391',
    geometricArea: 0.6,
    freeArea: 0.36,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.4,
        pressureDrop: 16,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 3.6,
        pressureDrop: 37,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.8,
        pressureDrop: 65,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 6,
        pressureDrop: 106,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.20',
    overallWidth: 669,
    overallHeight: 391,
    size: '669 x 391',
    geometricArea: 0.12,
    freeArea: 0.07,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.48,
        pressureDrop: 16,
        noiseLevel: 27
      },
      {
        velocity: 6,
        flowRate: 0.72,
        pressureDrop: 36,
        noiseLevel: 38
      },
      {
        velocity: 8,
        flowRate: 0.96,
        pressureDrop: 63,
        noiseLevel: 46
      },
      {
        velocity: 10,
        flowRate: 1.2,
        pressureDrop: 105,
        noiseLevel: 53
      }
    ]
  },
  {
    type: 'SLD-R.60.25',
    overallWidth: 669,
    overallHeight: 441,
    size: '669 x 441',
    geometricArea: 0.15,
    freeArea: 0.09,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.6,
        pressureDrop: 16,
        noiseLevel: 28
      },
      {
        velocity: 6,
        flowRate: 0.9,
        pressureDrop: 36,
        noiseLevel: 39
      },
      {
        velocity: 8,
        flowRate: 1.2,
        pressureDrop: 63,
        noiseLevel: 46
      },
      {
        velocity: 10,
        flowRate: 1.5,
        pressureDrop: 105,
        noiseLevel: 53
      }
    ]
  },
  {
    type: 'SLD-R.60.30',
    overallWidth: 669,
    overallHeight: 491,
    size: '669 x 491',
    geometricArea: 0.18,
    freeArea: 0.11,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.72,
        pressureDrop: 16,
        noiseLevel: 29
      },
      {
        velocity: 6,
        flowRate: 1.08,
        pressureDrop: 36,
        noiseLevel: 40
      },
      {
        velocity: 8,
        flowRate: 1.44,
        pressureDrop: 63,
        noiseLevel: 47
      },
      {
        velocity: 10,
        flowRate: 1.8,
        pressureDrop: 105,
        noiseLevel: 54
      }
    ]
  },
  {
    type: 'SLD-R.60.35',
    overallWidth: 669,
    overallHeight: 541,
    size: '669 x 541',
    geometricArea: 0.21,
    freeArea: 0.13,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.84,
        pressureDrop: 16,
        noiseLevel: 30
      },
      {
        velocity: 6,
        flowRate: 1.26,
        pressureDrop: 36,
        noiseLevel: 42
      },
      {
        velocity: 8,
        flowRate: 1.68,
        pressureDrop: 63,
        noiseLevel: 48
      },
      {
        velocity: 10,
        flowRate: 2.1,
        pressureDrop: 105,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.60.40',
    overallWidth: 669,
    overallHeight: 591,
    size: '669 x 591',
    geometricArea: 0.24,
    freeArea: 0.14,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.96,
        pressureDrop: 16,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.44,
        pressureDrop: 36,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 1.92,
        pressureDrop: 63,
        noiseLevel: 48
      },
      {
        velocity: 10,
        flowRate: 2.4,
        pressureDrop: 105,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.60.45',
    overallWidth: 669,
    overallHeight: 641,
    size: '669 x 641',
    geometricArea: 0.27,
    freeArea: 0.16,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.08,
        pressureDrop: 16,
        noiseLevel: 32
      },
      {
        velocity: 6,
        flowRate: 1.62,
        pressureDrop: 36,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.16,
        pressureDrop: 63,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 2.7,
        pressureDrop: 105,
        noiseLevel: 56
      }
    ]
  },
  {
    type: 'SLD-R.60.50',
    overallWidth: 669,
    overallHeight: 691,
    size: '669 x 691',
    geometricArea: 0.3,
    freeArea: 0.18,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.2,
        pressureDrop: 16,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 1.8,
        pressureDrop: 36,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.4,
        pressureDrop: 63,
        noiseLevel: 50
      },
      {
        velocity: 10,
        flowRate: 3,
        pressureDrop: 105,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.60.55',
    overallWidth: 669,
    overallHeight: 741,
    size: '669 x 741',
    geometricArea: 0.33,
    freeArea: 0.2,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.32,
        pressureDrop: 16,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 1.98,
        pressureDrop: 36,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.64,
        pressureDrop: 63,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 3.3,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.60',
    overallWidth: 669,
    overallHeight: 791,
    size: '669 x 791',
    geometricArea: 0.36,
    freeArea: 0.22,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.44,
        pressureDrop: 16,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 2.16,
        pressureDrop: 36,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.88,
        pressureDrop: 63,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 3.6,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.65',
    overallWidth: 669,
    overallHeight: 841,
    size: '669 x 841',
    geometricArea: 0.39,
    freeArea: 0.24,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.56,
        pressureDrop: 16,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 2.34,
        pressureDrop: 36,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 3.12,
        pressureDrop: 63,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 3.9,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.70',
    overallWidth: 669,
    overallHeight: 891,
    size: '669 x 891',
    geometricArea: 0.42,
    freeArea: 0.25,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.68,
        pressureDrop: 16,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 2.52,
        pressureDrop: 36,
        noiseLevel: 44
      },
      {
        velocity: 8,
        flowRate: 3.36,
        pressureDrop: 63,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 4.2,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.75',
    overallWidth: 669,
    overallHeight: 941,
    size: '669 x 941',
    geometricArea: 0.45,
    freeArea: 0.27,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.8,
        pressureDrop: 16,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 2.7,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.6,
        pressureDrop: 63,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 4.5,
        pressureDrop: 105,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.60.80',
    overallWidth: 669,
    overallHeight: 991,
    size: '669 x 991',
    geometricArea: 0.48,
    freeArea: 0.29,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.92,
        pressureDrop: 16,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 2.88,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.84,
        pressureDrop: 63,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 4.8,
        pressureDrop: 105,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.60.85',
    overallWidth: 669,
    overallHeight: 1041,
    size: '669 x 1041',
    geometricArea: 0.51,
    freeArea: 0.31,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.04,
        pressureDrop: 16,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 3.06,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.08,
        pressureDrop: 63,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 5.1,
        pressureDrop: 105,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.60.90',
    overallWidth: 669,
    overallHeight: 1091,
    size: '669 x 1091',
    geometricArea: 0.54,
    freeArea: 0.32,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.16,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.24,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.32,
        pressureDrop: 63,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 5.4,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.95',
    overallWidth: 669,
    overallHeight: 1141,
    size: '669 x 1141',
    geometricArea: 0.57,
    freeArea: 0.34,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.28,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.42,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.56,
        pressureDrop: 63,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 5.7,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.100',
    overallWidth: 669,
    overallHeight: 1191,
    size: '669 x 1191',
    geometricArea: 0.6,
    freeArea: 0.36,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.4,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.6,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.8,
        pressureDrop: 63,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 6,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.105',
    overallWidth: 669,
    overallHeight: 1241,
    size: '669 x 1241',
    geometricArea: 0.63,
    freeArea: 0.38,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.52,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.78,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.04,
        pressureDrop: 63,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 6.3,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.110',
    overallWidth: 669,
    overallHeight: 1291,
    size: '669 x 1291',
    geometricArea: 0.66,
    freeArea: 0.4,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.64,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.96,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.28,
        pressureDrop: 63,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 6.6,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.115',
    overallWidth: 669,
    overallHeight: 1341,
    size: '669 x 1341',
    geometricArea: 0.69,
    freeArea: 0.42,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.76,
        pressureDrop: 16,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.14,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.52,
        pressureDrop: 63,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 6.9,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.60.120',
    overallWidth: 669,
    overallHeight: 1391,
    size: '669 x 1391',
    geometricArea: 0.72,
    freeArea: 0.43,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.88,
        pressureDrop: 16,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 4.32,
        pressureDrop: 36,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.76,
        pressureDrop: 63,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 7.2,
        pressureDrop: 105,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.70.20',
    overallWidth: 769,
    overallHeight: 391,
    size: '769 x 391',
    geometricArea: 0.14,
    freeArea: 0.08,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.56,
        pressureDrop: 15,
        noiseLevel: 28
      },
      {
        velocity: 6,
        flowRate: 0.84,
        pressureDrop: 35,
        noiseLevel: 40
      },
      {
        velocity: 8,
        flowRate: 1.12,
        pressureDrop: 61,
        noiseLevel: 47
      },
      {
        velocity: 10,
        flowRate: 1.4,
        pressureDrop: 99,
        noiseLevel: 54
      }
    ]
  },
  {
    type: 'SLD-R.70.25',
    overallWidth: 769,
    overallHeight: 441,
    size: '769 x 441',
    geometricArea: 0.18,
    freeArea: 0.11,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.7,
        pressureDrop: 15,
        noiseLevel: 29
      },
      {
        velocity: 6,
        flowRate: 1.05,
        pressureDrop: 35,
        noiseLevel: 41
      },
      {
        velocity: 8,
        flowRate: 1.4,
        pressureDrop: 61,
        noiseLevel: 48
      },
      {
        velocity: 10,
        flowRate: 1.75,
        pressureDrop: 99,
        noiseLevel: 54
      }
    ]
  },
  {
    type: 'SLD-R.70.30',
    overallWidth: 769,
    overallHeight: 491,
    size: '769 x 491',
    geometricArea: 0.21,
    freeArea: 0.13,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.84,
        pressureDrop: 15,
        noiseLevel: 30
      },
      {
        velocity: 6,
        flowRate: 1.26,
        pressureDrop: 35,
        noiseLevel: 42
      },
      {
        velocity: 8,
        flowRate: 1.68,
        pressureDrop: 61,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 2.1,
        pressureDrop: 99,
        noiseLevel: 54
      }
    ]
  },
  {
    type: 'SLD-R.70.35',
    overallWidth: 769,
    overallHeight: 541,
    size: '769 x 541',
    geometricArea: 0.25,
    freeArea: 0.15,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.98,
        pressureDrop: 15,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.47,
        pressureDrop: 35,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 1.96,
        pressureDrop: 61,
        noiseLevel: 50
      },
      {
        velocity: 10,
        flowRate: 2.45,
        pressureDrop: 99,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.70.40',
    overallWidth: 769,
    overallHeight: 591,
    size: '769 x 591',
    geometricArea: 0.28,
    freeArea: 0.17,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.12,
        pressureDrop: 15,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.68,
        pressureDrop: 35,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.24,
        pressureDrop: 61,
        noiseLevel: 50
      },
      {
        velocity: 10,
        flowRate: 2.8,
        pressureDrop: 99,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.70.45',
    overallWidth: 769,
    overallHeight: 641,
    size: '769 x 641',
    geometricArea: 0.32,
    freeArea: 0.19,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.26,
        pressureDrop: 15,
        noiseLevel: 32
      },
      {
        velocity: 6,
        flowRate: 1.89,
        pressureDrop: 35,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.52,
        pressureDrop: 61,
        noiseLevel: 51
      },
      {
        velocity: 10,
        flowRate: 3.15,
        pressureDrop: 99,
        noiseLevel: 56
      }
    ]
  },
  {
    type: 'SLD-R.70.50',
    overallWidth: 769,
    overallHeight: 691,
    size: '769 x 691',
    geometricArea: 0.35,
    freeArea: 0.21,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.4,
        pressureDrop: 15,
        noiseLevel: 32
      },
      {
        velocity: 6,
        flowRate: 2.1,
        pressureDrop: 35,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.8,
        pressureDrop: 61,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 3.5,
        pressureDrop: 99,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.70.55',
    overallWidth: 769,
    overallHeight: 741,
    size: '769 x 741',
    geometricArea: 0.39,
    freeArea: 0.23,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.54,
        pressureDrop: 15,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 2.31,
        pressureDrop: 35,
        noiseLevel: 44
      },
      {
        velocity: 8,
        flowRate: 3.08,
        pressureDrop: 61,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 3.85,
        pressureDrop: 99,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.70.60',
    overallWidth: 769,
    overallHeight: 791,
    size: '769 x 791',
    geometricArea: 0.42,
    freeArea: 0.25,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.68,
        pressureDrop: 15,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 2.52,
        pressureDrop: 35,
        noiseLevel: 44
      },
      {
        velocity: 8,
        flowRate: 3.36,
        pressureDrop: 61,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 4.2,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.70.65',
    overallWidth: 769,
    overallHeight: 841,
    size: '769 x 841',
    geometricArea: 0.46,
    freeArea: 0.27,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.82,
        pressureDrop: 15,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 2.73,
        pressureDrop: 35,
        noiseLevel: 44
      },
      {
        velocity: 8,
        flowRate: 3.64,
        pressureDrop: 61,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 4.55,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.70.70',
    overallWidth: 769,
    overallHeight: 891,
    size: '769 x 891',
    geometricArea: 0.49,
    freeArea: 0.29,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.96,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 2.94,
        pressureDrop: 35,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.92,
        pressureDrop: 61,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 4.9,
        pressureDrop: 99,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.70.75',
    overallWidth: 769,
    overallHeight: 941,
    size: '769 x 941',
    geometricArea: 0.53,
    freeArea: 0.32,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.1,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.15,
        pressureDrop: 35,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.2,
        pressureDrop: 61,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 5.25,
        pressureDrop: 99,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.70.80',
    overallWidth: 769,
    overallHeight: 991,
    size: '769 x 991',
    geometricArea: 0.56,
    freeArea: 0.34,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.24,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.36,
        pressureDrop: 35,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.48,
        pressureDrop: 61,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 5.6,
        pressureDrop: 99,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.70.85',
    overallWidth: 769,
    overallHeight: 1041,
    size: '769 x 1041',
    geometricArea: 0.6,
    freeArea: 0.36,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.38,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.57,
        pressureDrop: 35,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.76,
        pressureDrop: 61,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 5.95,
        pressureDrop: 99,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.70.90',
    overallWidth: 769,
    overallHeight: 1091,
    size: '769 x 1091',
    geometricArea: 0.63,
    freeArea: 0.38,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.52,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.78,
        pressureDrop: 35,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.04,
        pressureDrop: 61,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 6.3,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.70.95',
    overallWidth: 769,
    overallHeight: 1141,
    size: '769 x 1141',
    geometricArea: 0.67,
    freeArea: 0.4,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.66,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.99,
        pressureDrop: 35,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.32,
        pressureDrop: 61,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 6.65,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.70.100',
    overallWidth: 769,
    overallHeight: 1191,
    size: '769 x 1191',
    geometricArea: 0.7,
    freeArea: 0.42,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.8,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.2,
        pressureDrop: 35,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.6,
        pressureDrop: 61,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 7,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.70.105',
    overallWidth: 769,
    overallHeight: 1241,
    size: '769 x 1241',
    geometricArea: 0.74,
    freeArea: 0.44,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.94,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.41,
        pressureDrop: 35,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.88,
        pressureDrop: 61,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 7.35,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.70.110',
    overallWidth: 769,
    overallHeight: 1291,
    size: '769 x 1291',
    geometricArea: 0.77,
    freeArea: 0.46,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.08,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.62,
        pressureDrop: 35,
        noiseLevel: 46
      },
      {
        velocity: 8,
        flowRate: 6.16,
        pressureDrop: 61,
        noiseLevel: 54
      },
      {
        velocity: 10,
        flowRate: 7.7,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.70.115',
    overallWidth: 769,
    overallHeight: 1341,
    size: '769 x 1341',
    geometricArea: 0.81,
    freeArea: 0.48,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.22,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.83,
        pressureDrop: 35,
        noiseLevel: 46
      },
      {
        velocity: 8,
        flowRate: 6.44,
        pressureDrop: 61,
        noiseLevel: 54
      },
      {
        velocity: 10,
        flowRate: 8.05,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.70.120',
    overallWidth: 769,
    overallHeight: 1391,
    size: '769 x 1391',
    geometricArea: 0.84,
    freeArea: 0.5,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.36,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 5.04,
        pressureDrop: 35,
        noiseLevel: 46
      },
      {
        velocity: 8,
        flowRate: 6.72,
        pressureDrop: 61,
        noiseLevel: 54
      },
      {
        velocity: 10,
        flowRate: 8.4,
        pressureDrop: 99,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.20',
    overallWidth: 869,
    overallHeight: 391,
    size: '869 x 391',
    geometricArea: 0.16,
    freeArea: 0.1,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.64,
        pressureDrop: 15,
        noiseLevel: 29
      },
      {
        velocity: 6,
        flowRate: 0.96,
        pressureDrop: 33,
        noiseLevel: 41
      },
      {
        velocity: 8,
        flowRate: 1.28,
        pressureDrop: 60,
        noiseLevel: 47
      },
      {
        velocity: 10,
        flowRate: 1.6,
        pressureDrop: 97,
        noiseLevel: 54
      }
    ]
  },
  {
    type: 'SLD-R.80.25',
    overallWidth: 869,
    overallHeight: 441,
    size: '869 x 441',
    geometricArea: 0.2,
    freeArea: 0.12,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.8,
        pressureDrop: 15,
        noiseLevel: 30
      },
      {
        velocity: 6,
        flowRate: 1.2,
        pressureDrop: 33,
        noiseLevel: 42
      },
      {
        velocity: 8,
        flowRate: 1.6,
        pressureDrop: 60,
        noiseLevel: 48
      },
      {
        velocity: 10,
        flowRate: 2,
        pressureDrop: 97,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.80.30',
    overallWidth: 869,
    overallHeight: 491,
    size: '869 x 491',
    geometricArea: 0.24,
    freeArea: 0.14,
    velocities: [
      {
        velocity: 4,
        flowRate: 0.96,
        pressureDrop: 15,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.44,
        pressureDrop: 33,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 1.92,
        pressureDrop: 60,
        noiseLevel: 49
      },
      {
        velocity: 10,
        flowRate: 2.4,
        pressureDrop: 97,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.80.35',
    overallWidth: 869,
    overallHeight: 541,
    size: '869 x 541',
    geometricArea: 0.28,
    freeArea: 0.17,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.12,
        pressureDrop: 15,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.68,
        pressureDrop: 33,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.24,
        pressureDrop: 60,
        noiseLevel: 51
      },
      {
        velocity: 10,
        flowRate: 2.8,
        pressureDrop: 97,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.80.40',
    overallWidth: 869,
    overallHeight: 591,
    size: '869 x 591',
    geometricArea: 0.32,
    freeArea: 0.19,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.28,
        pressureDrop: 15,
        noiseLevel: 31
      },
      {
        velocity: 6,
        flowRate: 1.92,
        pressureDrop: 33,
        noiseLevel: 43
      },
      {
        velocity: 8,
        flowRate: 2.56,
        pressureDrop: 60,
        noiseLevel: 51
      },
      {
        velocity: 10,
        flowRate: 3.2,
        pressureDrop: 97,
        noiseLevel: 55
      }
    ]
  },
  {
    type: 'SLD-R.80.45',
    overallWidth: 869,
    overallHeight: 641,
    size: '869 x 641',
    geometricArea: 0.36,
    freeArea: 0.22,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.44,
        pressureDrop: 15,
        noiseLevel: 32
      },
      {
        velocity: 6,
        flowRate: 2.16,
        pressureDrop: 33,
        noiseLevel: 44
      },
      {
        velocity: 8,
        flowRate: 2.88,
        pressureDrop: 60,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 3.6,
        pressureDrop: 97,
        noiseLevel: 57
      }
    ]
  },
  {
    type: 'SLD-R.80.50',
    overallWidth: 869,
    overallHeight: 691,
    size: '869 x 691',
    geometricArea: 0.4,
    freeArea: 0.24,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.6,
        pressureDrop: 15,
        noiseLevel: 32
      },
      {
        velocity: 6,
        flowRate: 2.4,
        pressureDrop: 33,
        noiseLevel: 44
      },
      {
        velocity: 8,
        flowRate: 3.2,
        pressureDrop: 60,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 4,
        pressureDrop: 97,
        noiseLevel: 58
      }
    ]
  },
  {
    type: 'SLD-R.80.55',
    overallWidth: 869,
    overallHeight: 741,
    size: '869 x 741',
    geometricArea: 0.44,
    freeArea: 0.27,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.76,
        pressureDrop: 15,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 2.64,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.52,
        pressureDrop: 60,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 4.4,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.60',
    overallWidth: 869,
    overallHeight: 791,
    size: '869 x 791',
    geometricArea: 0.48,
    freeArea: 0.29,
    velocities: [
      {
        velocity: 4,
        flowRate: 1.92,
        pressureDrop: 15,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 2.88,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 3.84,
        pressureDrop: 60,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 4.8,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.65',
    overallWidth: 869,
    overallHeight: 841,
    size: '869 x 841',
    geometricArea: 0.52,
    freeArea: 0.32,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.08,
        pressureDrop: 15,
        noiseLevel: 33
      },
      {
        velocity: 6,
        flowRate: 3.12,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.16,
        pressureDrop: 60,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 5.2,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.70',
    overallWidth: 869,
    overallHeight: 891,
    size: '869 x 891',
    geometricArea: 0.56,
    freeArea: 0.34,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.24,
        pressureDrop: 15,
        noiseLevel: 34
      },
      {
        velocity: 6,
        flowRate: 3.36,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.48,
        pressureDrop: 60,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 5.6,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.75',
    overallWidth: 869,
    overallHeight: 941,
    size: '869 x 941',
    geometricArea: 0.6,
    freeArea: 0.36,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.4,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.6,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 4.8,
        pressureDrop: 60,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 6,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.80',
    overallWidth: 869,
    overallHeight: 991,
    size: '869 x 991',
    geometricArea: 0.64,
    freeArea: 0.38,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.56,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 3.84,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.12,
        pressureDrop: 60,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 6.4,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.85',
    overallWidth: 869,
    overallHeight: 1041,
    size: '869 x 1041',
    geometricArea: 0.68,
    freeArea: 0.41,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.72,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.08,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.44,
        pressureDrop: 60,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 6.8,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.90',
    overallWidth: 869,
    overallHeight: 1091,
    size: '869 x 1091',
    geometricArea: 0.72,
    freeArea: 0.43,
    velocities: [
      {
        velocity: 4,
        flowRate: 2.88,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.32,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 5.76,
        pressureDrop: 60,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 7.2,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.95',
    overallWidth: 869,
    overallHeight: 1141,
    size: '869 x 1141',
    geometricArea: 0.76,
    freeArea: 0.46,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.04,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.56,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 6.08,
        pressureDrop: 60,
        noiseLevel: 52
      },
      {
        velocity: 10,
        flowRate: 7.6,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.100',
    overallWidth: 869,
    overallHeight: 1191,
    size: '869 x 1191',
    geometricArea: 0.8,
    freeArea: 0.48,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.2,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 4.8,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 6.4,
        pressureDrop: 60,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 8,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.105',
    overallWidth: 869,
    overallHeight: 1241,
    size: '869 x 1241',
    geometricArea: 0.84,
    freeArea: 0.51,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.36,
        pressureDrop: 15,
        noiseLevel: 35
      },
      {
        velocity: 6,
        flowRate: 5.04,
        pressureDrop: 33,
        noiseLevel: 45
      },
      {
        velocity: 8,
        flowRate: 6.72,
        pressureDrop: 60,
        noiseLevel: 53
      },
      {
        velocity: 10,
        flowRate: 8.4,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.110',
    overallWidth: 869,
    overallHeight: 1291,
    size: '869 x 1291',
    geometricArea: 0.88,
    freeArea: 0.53,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.52,
        pressureDrop: 15,
        noiseLevel: 36
      },
      {
        velocity: 6,
        flowRate: 5.28,
        pressureDrop: 33,
        noiseLevel: 46
      },
      {
        velocity: 8,
        flowRate: 7.04,
        pressureDrop: 60,
        noiseLevel: 54
      },
      {
        velocity: 10,
        flowRate: 8.8,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.115',
    overallWidth: 869,
    overallHeight: 1341,
    size: '869 x 1341',
    geometricArea: 0.92,
    freeArea: 0.56,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.68,
        pressureDrop: 15,
        noiseLevel: 36
      },
      {
        velocity: 6,
        flowRate: 5.52,
        pressureDrop: 33,
        noiseLevel: 46
      },
      {
        velocity: 8,
        flowRate: 7.36,
        pressureDrop: 60,
        noiseLevel: 54
      },
      {
        velocity: 10,
        flowRate: 9.2,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  },
  {
    type: 'SLD-R.80.120',
    overallWidth: 869,
    overallHeight: 1391,
    size: '869 x 1391',
    geometricArea: 0.96,
    freeArea: 0.58,
    velocities: [
      {
        velocity: 4,
        flowRate: 3.84,
        pressureDrop: 15,
        noiseLevel: 36
      },
      {
        velocity: 6,
        flowRate: 5.76,
        pressureDrop: 33,
        noiseLevel: 46
      },
      {
        velocity: 8,
        flowRate: 7.68,
        pressureDrop: 60,
        noiseLevel: 54
      },
      {
        velocity: 10,
        flowRate: 9.6,
        pressureDrop: 97,
        noiseLevel: 59
      }
    ]
  }
]

export default data
