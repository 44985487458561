import { Dispatch, SetStateAction } from 'react'
import { Richtext } from 'storyblok-js-client'

export interface StoryBlokContent {
  _uid: string
  component: string
  sections: unknown[]
}

export interface StoryBlokForm {
  _uid: string
  text: Richtext
  title: string
  component: string
  disclaimer: Richtext
  backgroundImage: Image
  formTitle: string
  projectNamePlaceholder: string
  emailPlaceholder: string
  firstnamePlaceholder: string
  lastnamePlaceholder: string
  buttonText: string
}

export interface Image {
  id: number
  alt: string
  name: string
  focus?: null
  title: string
  filename: string
  copyright: string
  fieldtype: string
}

export interface Feature {
  _uid: string
  uuid: string
  icon: Image
  title: string
  helpText?: string
  type?: 'horizontal' | 'vertical' | 'both' | null
}

export interface Product {
  _uid: string
  uuid: string
  name: string
  short_name: string
  description: Richtext
  image?: Image
  video?: Image
  gallery: Image[]
  features: string[]
  bimDownload: Image
  cadDownload: Image
  pdf?: Image
  pdf_vertical?: Image
  type?: 'horizontal' | 'vertical'
}

// We have a number of different views to go through:
export enum View {
  ENTRY = 'entry', // An entry form to fill out project details
  TYPE = 'type', // A form to choose which type of wizard to show,
  SIMPLE_FORM = 'simpleForm',
  ADVANCED_FORM = 'advancedForm',
  SPECIFICATION = 'specification'
}

export interface FormStepActionsContext {
  steps: FormStep[]
  setCurrentStep: Dispatch<SetStateAction<number>>
}
export interface FormStepAction<Value> {
  (value: Value, context: FormStepActionsContext): void
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FormStep<Value = any> extends Record<string, unknown> {
  title: string
  key: string
  type: string
  tip?: {
    title: string
    text: string
  }
  actions?: {
    afterSelect?: FormStepAction<Value>
    default?: (input: Record<string, unknown>) => Value
    preselected?: (input: Record<string, unknown>) => Value
    skip?: (value: Value) => number | undefined
  }
}

// HS Form
export interface HSFormPayload {
  submittedAt?: string
  fields: FieldsEntity[]
  context?: Context
}
export interface FieldsEntity {
  name: HSFormFieldKeys | string
  value: string
}
export interface Context {
  email: string
  hutk?: string
  pageUri?: string
  pageName?: string
}

export enum HSFormFieldKeys {
  doors = 'doors',
  extract_rate_at_damper = 'extract_rate_at_damper',
  extract_rate_required = 'extract_rate_required',
  features = 'features',
  form_type = 'form_type',
  lift_doors = 'lift_doors',
  shaft_depth = 'shaft_depth',
  shaft_width = 'shaft_width',
  storeys = 'storeys',
  total_smoke_damper_leakage = 'total_smoke_damper_leakage',
  total_smoke_shaft_damper_leakage = 'total_smoke_shaft_damper_leakage',
  total_smoke_shaft_leakage = 'total_smoke_shaft_leakage',
  total_surface_area = 'total_surface_area',
  total_system_leakage = 'total_system_leakage',
  estimated_extract_rate = 'estimated_extract_rate',
  inflated_extract_rate = 'inflated_extract_rate',
  // Required fields
  firstname = 'firstname',
  lastname = 'lastname',
  email = 'email',
  project_name = 'project_name'
}

// Simple Form
export interface DownloadSpecificationPayload {
  url: string
  specifications: {
    width: string
    height: string
    depth: string
    apartmentDoors: string
    shaftDampers: string
    liftDoors: string
    environmentalDampers: string
    name: string
    extractRate?: string
    staticPressure?: string
    extractRate2?: string
    staticPressure2?: string
    weight?: string
    roofLoadPressure?: string
    inverterSize?: string
    startingCurrent?: string
    loadCurrent?: string
    fanDiameter?: string
    electricalSupply?: string
  }
}

export interface SimpleFormInput extends Record<string, unknown> {
  doors: number
  liftDoors: number
  dimensions: { shaftWidth: number; shaftDepth: number }
  storeys: number
  features?: string[]
}
