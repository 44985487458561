import { useMemo } from 'react';

import StoryblokPage from './storyblok-page';
import CaseStudy from '../case-study';
import Navigation from '../navigation';
import NavItem from '../navigation/nav-item';
import StoryblokIntroAdapter from './adapters/storyblok-intro-adapter';
import StoryblokLogoBlockAdapter from './adapters/storyblok-logo-block-adapter';
import StoryblokLeftImageRightContentAdapter from './adapters/storyblok-left-image-right-content-adapter.jsx';
import StoryblokBannerWithCta from './adapters/storyblok-banner-with-cta-adapter';
import StoryblokLeftIconsRightImage from './adapters/storyblok-left-icons-right-image-adapter';
import StoryblokThreeCardIconBlock from './adapters/storyblok-three-card-icon-block-adapter';
import StoryblokLeftImageRightContent2 from './adapters/storyblok-left-image-right-content-2-adapter';
import StoryblokFeaturedCaseStudies from './adapters/storyblok-featured-case-studies-adapter';
import StoryblokQuoteSection from './adapters/storyblok-quote-section-adapter';
import StoryblokCtaBanner from './adapters/storyblok-cta-banner-adapter';
import StoryblokProductIntroAdapter from './adapters/storyblok-product-intro-adapter';
import StoryblokSubpageNavigationAdapter from './adapters/storyblok-subpage-navigation-adapter';
import StoryblokTeamCarouselAdapter from './adapters/storyblok-team-carousel-adapter';
import StoryblokAboutCompanyAdapter from './adapters/storyblok-about-company-adapter';
import StoryblokCoreValuesAdapter from './adapters/storyblok-core-values-adapter';
import StoryblokVideoSplashAdapter from './adapters/storyblok-video-splash-adapter';
import StoryblokLeftTitleRightTextAdapter from './adapters/storyblok-left-title-right-text-adapter';
import StoryblokTeamGrid from './adapters/storyblok-team-grid';
import StoryblokProductsGrid from './adapters/storyblok-products-grid';
import StoryblokCaseStudiesBrowser from './adapters/storyblok-case-studies-browser-adapter';
import StoryblokBlankSpace from './adapters/storyblok-blank-space-adapter';
import StoryblokTitleWithSubtextAdapter from './adapters/storyblok-title-with-subtext-adapter.jsx';
import StoryblokLogoTileListAdapter from './adapters/storyblok-logo-tile-list-adapter';
import StoryblokVacanciesAdapter from './adapters/storyblok-vacancies-adapter';
import StoryblokGridTileListAdapter from './adapters/storyblok-grid-tile-list-adapter';
import StoryblokCaseStudyNextProjectAdapter from './adapters/storyblok-case-study-next-project-adapter';
import Course from './storyblok-course-page';
import CoursesRoot from './storyblok-courses-root-page';
import StoryblokHowItWorksStagesAdapter from './adapters/storyblok-how-it-works-stages-adapter';
import StoryblokFeatureBlocksAdapter from './adapters/storyblok-feature-blocks-adapter';
import StoryblokTitleThinBannerWithCtaAdapter from './adapters/storyblok-title-thin-banner-with-cta-adapter';
import StoryblokRelatedProductsCarouselAdapter from './adapters/storyblok-related-products-carousel-adapter';
import StoryblokBlogPostPage from './storyblok-blog-post-page';
import StoryblokFaqBlockAdapter from './adapters/storyblok-faq-block-adapter';
import StoryblokSystemsComparisonGridAdapter from './adapters/storyblok-systems-comparison-grid-adapter';
import StoryblokSystemsTechnicalInformationAdapter from './adapters/storyblok-systems-technical-information-adapter';
import StoryblokCtaWithTextBlockAdapter from './adapters/storyblok-cta-with-text-block-adapter';
import StoryblokSystemsLegislationAdapter from './adapters/storyblok-systems-legislation-adapter';
import Storyblok404Adapter from './adapters/storyblok-404-adapter';
import StoryblokTermsOfService from './storyblok-terms-of-service';
import StoryblokRichTextContentAdapter from './adapters/storyblok-rich-text-content-adapter';
import StoryblokCarouselAdapter from './adapters/storyblok-carousel-adapter';
import StoryblokVideoResourceGridAdapter from './adapters/storyblok-video-resource-grid-adapter';
import StoryblokIntroIconicLinksAdapter from './adapters/storyblok-intro-iconic-links-adapter';
import StoryblokSectionContactAdapter from './adapters/storyblok-section-contact-adapter';
import StoryblokAccordionTabsAdapter from './adapters/storyblok-accordion-tabs-adapter';
import StoryblokSpecToolAdapter from './adapters/storyblok-spec-tool-adapter';
import StoryblokTabsAdapter from './adapters/storyblok-tabs-adapter';
import StoryblokContactRootPage from './storyblok-contact-root-page';
import StoryblokLookingToJoin from './adapters/storyblok-looking-to-join';
import StoryblokServicesHeaderAdapter from './adapters/storyblok-services-header-adapter';
import StoryblokProductSelectorAdapter from './adapters/storyblok-product-selector-adapter'
import StoryblokVideoHeroAdapter from './adapters/storyblok-video-hero-adapater';
import StoryblokPodCalculatorTool from './storyblok-pod-tool-root-page';
import StoryblokPageCarousel from '../page-carousel'
import StoryblokPageImageCarousel from '../page-image-carousel'

export default function useStoryblokComponents(componentName) {
  // Add new components here

  const STORYBLOK_COMPONENTS = {
    podCalculatorTool: StoryblokPodCalculatorTool,
    page: StoryblokPage,
    caseStudy: CaseStudy,
    course: Course,
    coursesRoot: CoursesRoot,
    termsOfService: StoryblokTermsOfService,
    product: StoryblokPage,
    global_navigation: Navigation,
    navigation_item: NavItem,
    intro: StoryblokIntroAdapter,
    logoBlock: StoryblokLogoBlockAdapter,
    leftImageRightContent: StoryblokLeftImageRightContentAdapter,
    bannerWithCta: StoryblokBannerWithCta,
    leftIconsRightImage: StoryblokLeftIconsRightImage,
    threeCardIconBlock: StoryblokThreeCardIconBlock,
    leftImageRightContent2: StoryblokLeftImageRightContent2,
    featuredCaseStudies: StoryblokFeaturedCaseStudies,
    quoteSection: StoryblokQuoteSection,
    ctaBanner: StoryblokCtaBanner,
    productIntro: StoryblokProductIntroAdapter,
    subpageNavigation: StoryblokSubpageNavigationAdapter,
    teamCarousel: StoryblokTeamCarouselAdapter,
    aboutCompany: StoryblokAboutCompanyAdapter,
    coreValuesBlock: StoryblokCoreValuesAdapter,
    videoSplash: StoryblokVideoSplashAdapter,
    leftTitleRightText: StoryblokLeftTitleRightTextAdapter,
    teamGrid: StoryblokTeamGrid,
    productsGrid: StoryblokProductsGrid,
    caseStudiesBrowser: StoryblokCaseStudiesBrowser,
    blankSpace: StoryblokBlankSpace,
    titleWithSubtext: StoryblokTitleWithSubtextAdapter,
    logoTileList: StoryblokLogoTileListAdapter,
    vacancies: StoryblokVacanciesAdapter,
    gridTileList: StoryblokGridTileListAdapter,
    caseStudyNextProject: StoryblokCaseStudyNextProjectAdapter,
    howItWorksStages: StoryblokHowItWorksStagesAdapter,
    featureBlocks: StoryblokFeatureBlocksAdapter,
    titleThinBannerWithCTA: StoryblokTitleThinBannerWithCtaAdapter,
    relatedProductsCarousel: StoryblokRelatedProductsCarouselAdapter,
    blogPost: StoryblokBlogPostPage,
    faqBlock: StoryblokFaqBlockAdapter,
    systemsComparisonGrid: StoryblokSystemsComparisonGridAdapter,
    systemsTechnicalInformation: StoryblokSystemsTechnicalInformationAdapter,
    ctaWithTextBlock: StoryblokCtaWithTextBlockAdapter,
    systemsLegislation: StoryblokSystemsLegislationAdapter,
    page404: Storyblok404Adapter,
    richTextContent: StoryblokRichTextContentAdapter,
    carousel: StoryblokCarouselAdapter,
    videoResourceGrid: StoryblokVideoResourceGridAdapter,
    introIconicLinks: StoryblokIntroIconicLinksAdapter,
    sectionContact: StoryblokSectionContactAdapter,
    accordionTabs: StoryblokAccordionTabsAdapter,
    specTool: StoryblokSpecToolAdapter,
    tabs: StoryblokTabsAdapter,
    contactRoot: StoryblokContactRootPage,
    lookingToJoinButton: StoryblokLookingToJoin,
    servicesHeader: StoryblokServicesHeaderAdapter,
    productSelector: StoryblokProductSelectorAdapter,
    videoHero: StoryblokVideoHeroAdapter,
    pageCarousel: StoryblokPageCarousel,
    imageCarousel: StoryblokPageImageCarousel,
  };
  return useMemo(() => {
    return STORYBLOK_COMPONENTS[componentName];
  }, [componentName]);
}
