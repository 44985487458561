import styled from 'styled-components';

import { getThemeColor } from '../utils/styles';
import { Box } from './UIKit/system';

const Subtitle = styled(Box)`
  color: ${getThemeColor('primary')};
  font-weight: 700;
`;

export default Subtitle;
