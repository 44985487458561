import React from 'react';
import styled, { css } from 'styled-components';

import Title from './title';
import { Box, Flex, Paragraph, Text } from './UIKit/system';
import LazyImage from './lazy-image';
import Container from './container';
import { supportsGrid } from '../utils/styles';
import mq from '../utils/mq';

const StyledItem = styled.div`
  width: 50%;
  background-color: #f8f8f8;
  border-right: 23px solid transparent;
  margin-bottom: 23px;
  background-clip: padding-box;

  &:nth-child(2n) {
    border-right: none;
  }

  ${supportsGrid(css`
    border-right: none;
    margin-bottom: 0;
    width: auto;
  `)}
  ${mq.mobile(css`
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `)};
`;

const StyledTitle = styled(Title)`
  ${mq.mobile(css`
    text-align: left;
  `)};
  a {
    color: #7649bf;
  }
`;

const StyledParagraph = styled(Paragraph)`
  ${mq.mobile(css`
    text-align: left;
  `)};
`;

const ItemImage = styled(LazyImage)`
  max-width: 36px;
  margin-right: 18px;
  width: 36px;
  ${mq.mobile(css`
    max-width: 29px;
    margin-bottom: 5px;
  `)};
`;

const DesktopText = styled(Text)`
  letter-spacing: 0px;
  span {
    color: #666666;
  }
  ${mq.mobile(css`
    display: none;
  `)};
`;

const MobileTextContainer = styled.div`
  display: none;
  ${mq.mobile(css`
    display: block;
  `)};
`;

const MobileItemTitle = styled(Text)`
  font-size: 20px;
`;

const MobileItemSubtitle = styled(Text)`
  font-size: 16px;
`;

function Item({ icon, title, subtitle }) {
  return (
    <StyledItem
      as={Flex}
      px={[16, 32, 32, 32]}
      alignItems={'center'}
      height={120}
    >
      <ItemImage fluid={icon} />
      <DesktopText html={title + ' - ' + subtitle} />
      <MobileTextContainer>
        <MobileItemTitle html={title} />
        <MobileItemSubtitle html={subtitle} color={'#666666'} />
      </MobileTextContainer>
    </StyledItem>
  );
}

const StyledSectionFeatures = styled(Box)``;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${supportsGrid(css`
    display: grid;
    grid-gap: 23px;
    grid-template-columns: 1fr 1fr;
    ${mq.mobile(css`
      grid-template-columns: 1fr;
    `)};
  `)}
`;

function SectionFeatures({ title, text, values, subtext }) {
  return (
    <StyledSectionFeatures
      as={'section'}
      pt={[83, 154, 154, 154]}
      pb={[70, 70, 100]}
    >
      <Container>
        <Box mb={48} ml={[15, 0, 0]} textAlign={'center'}>
          <StyledTitle mb={10} fontSizeMedium html={title} />
          <StyledParagraph grey html={text} />
        </Box>
        <Items>
          {values !== undefined &&
            values.map((value, i) => (
              <Item
                key={i}
                icon={value.icon}
                title={'<strong>' + value.title + '</strong>'}
                subtitle={'<span>' + value.subtitle + '</span>'}
              />
            ))}
        </Items>
        <Paragraph
          marginTop={45}
          fontSizeSmall
          textAlign={'center'}
          color={'#959595'}
          html={subtext}
        />
      </Container>
    </StyledSectionFeatures>
  );
}

export default SectionFeatures;
