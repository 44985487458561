import React from 'react';
import styled, { css } from 'styled-components';

import TeamItem from './team-item';
import Container from './container';
import { supportsGrid } from '../utils/styles';
import mq from '../utils/mq';

const StyledTeamList = styled.div``;

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
  `)};
  ${mq.tablet(css`
    grid-template-columns: 1fr 1fr;
    max-width: 772px;
  `)};
  ${mq.mobile(css`
    grid-template-columns: 1fr;
    max-width: 330px;
  `)};
`;

const StyledTeamItem = styled(TeamItem)`
  width: calc(33.33% - 32px);
  margin-right: 32px;
  margin-bottom: 32px;
  max-width: 330px;
  &:nth-child(3n) {
    margin-right: 0;
  }

  ${supportsGrid(css`
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  `)}
`;

function TeamList({ items }) {
  return (
    <StyledTeamList>
      <StyledContainer>
        {items &&
          items.map((item, index) => <StyledTeamItem key={index} {...item} />)}
      </StyledContainer>
    </StyledTeamList>
  );
}

export default TeamList;
