import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import gsap, { Power1 } from 'gsap';
import imagesLoaded from 'imagesloaded';

import { Box } from './UIKit/system';

const StyledWipeAnimation = styled(Box)`
  position: relative;
  z-index: 2;
  overflow: hidden;
  clip-path: inset(0 100% 0 0);
  ${(props) =>
    props.overflow &&
    css`
      overflow: visible;
    `};
`;

function WipeAnimation(props) {
  const containerNode = useRef(null);
  const [imagesLoadedFlag, setImagesLoadedFlag] = useState(false);
  let threshold = 0.15;

  if (props.threshold !== undefined) {
    threshold = props.threshold;
  }

  const [inViewNode, inView] = useInView({
    threshold: threshold,
    triggerOnce: true,
  });

  useEffect(() => {
    imagesLoaded(containerNode.current, () => {
      setImagesLoadedFlag(true);
    });
  });

  useEffect(() => {
    if (!imagesLoadedFlag || !inView) {
      return;
    }
    animate();
  }, [inView, imagesLoadedFlag]);

  const setRefs = useCallback(
    (node) => {
      containerNode.current = node;
      inViewNode(node);
    },
    [inViewNode]
  );

  function animate() {
    gsap.to(containerNode.current, 0.6, {
      clipPath: 'inset(0 0% 0 0)',
      webkitClipPath: 'inset(0 0% 0 0)',
      ease: Power1.easeOut,
      onComplete: () => {
        gsap.set(containerNode.current, {
          clipPath: 'none',
          webkitClipPath: 'none',
        });
      },
    });
  }

  return (
    <StyledWipeAnimation overflow={props.overflow} ref={setRefs} {...props} />
  );
}

export default WipeAnimation;
