import React from 'react';

import RichTextField from '../../rich-text-field';
import FormattedTextBlock from '../../formatted-text-block';
import { Box } from '../../UIKit/system';

function StoryblokRichTextContentAdapter({ content }) {
  return (
    <Box pb={80}>
      <FormattedTextBlock>
        <RichTextField data={content} />
      </FormattedTextBlock>
    </Box>
  );
}

export default StoryblokRichTextContentAdapter;
