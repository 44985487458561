import React from 'react';

import ProductIntro from '../../product-intro';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed';
import { CONTAINER_WIDTH } from '../../../constants';

function StoryblokProductIntroAdapter({
  pagination,
  bottomImage,
  videoLink,
  carouselImages,
  ...rest
}) {
  const fluidBottomImage = getFluidGatsbyImage(bottomImage, {
    maxWidth: CONTAINER_WIDTH,
  });

  return (
    <ProductIntro
      pagination={pagination.map((v) => ({ link: v.link.url, name: v.name }))}
      videoLink={videoLink.url}
      bottomImage={fluidBottomImage}
      carouselImages={
        carouselImages &&
        carouselImages.map((v) =>
          getFluidGatsbyImage(v, {
            maxWidth: 604,
          })
        )
      }
      {...rest}
    />
  );
}

export default StoryblokProductIntroAdapter;
