import styled, { css } from 'styled-components';
import React from 'react';

import { hoverScaleDefault } from '../../utils/styles';
import ArrowRight from '../../images/svg/arrow-right.svg';

const StyledControlButton = styled.div`
  ${hoverScaleDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #d4d4d4;
  width: 48px;
  height: 48px;
  cursor: pointer;

  ${(props) =>
    props.prev &&
    css`
      border: none;
      margin-right: 10px;

      svg {
        transform: scaleX(-1);
      }
    `};
`;

const ControlButton = React.forwardRef(({ prev }, ref) => {
  return (
    <StyledControlButton prev={prev} ref={ref}>
      <ArrowRight stroke={'#fff'} />
    </StyledControlButton>
  );
});

export default ControlButton;
