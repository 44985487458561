import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Text, Paragraph } from './UIKit/system';
import Title from './title';
import mq from '../utils/mq';

const NotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 102px;
  ${mq.mobile(css`
    margin-top: 52px;
  `)};
`;

const Subtitle = styled(Text)`
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #753fbf;
`;

const StyledTitle = styled(Title)`
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  color: #161616;
  font-size: 52px;
  line-height: 65px;
  ${mq.mobile(css`
    font-size: 28px;
    line-height: 36px;
  `)};
`;

const StyledParagraph = styled(Paragraph)`
  max-width: 669px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #161616;
  margin-top: 16px;
  margin-bottom: 228px;
  a {
    text-decoration: underline;
  }

  ${mq.mobile(css`
    max-width: 90%;
    margin-top: 11px;
    margin-bottom: 128px;
  `)};
`;

function Section404({ title, children }) {
  return (
    <Box pt={70} pb={100}>
      <NotFoundContainer>
        <TextContainer>
          <Subtitle>404</Subtitle>
          <StyledTitle>{title}</StyledTitle>
          <StyledParagraph fontSizeSmall>{children}</StyledParagraph>
        </TextContainer>
      </NotFoundContainer>
    </Box>
  );
}

export default Section404;
