import React from 'react';
import RichtextParser from '../../../utils/richtext-parser';

import PageHeader from '../../page-header';

function StoryblokTitleWithSubtextAdapter({ title, text }) {
  let parsedText = RichtextParser(text);
  return <PageHeader title={title} text={parsedText} />;
}

export default StoryblokTitleWithSubtextAdapter;
