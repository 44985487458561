import React from 'react';
import styled, { css } from 'styled-components';

import GridCol from './grid-col';
import Title from './title';
import Container from './container';
import { Box, Flex, Paragraph } from './UIKit/system';
import Accordion from './accordion';
import mq from '../utils/mq';

const StyledSectionAccordion = styled(Box)``;

const StyledGridCol = styled(GridCol)`
  ${mq.tablet(css`
    width: 100%;
  `)};
`;

function SectionAccordion({ title, subtext, bodyText, data }) {
  return (
    <StyledSectionAccordion pt={[50, 60, 85]} pb={[60, 80, 100]}>
      <Container as={Flex} flexWrap={['wrap', 'wrap', 'unset']}>
        <StyledGridCol>
          <Title as={'h2'} mb={30} html={title} />
          <Paragraph html={subtext} />
        </StyledGridCol>
        <Box pt={10}>
          {bodyText && <Paragraph mb={55} grey html={bodyText} />}
          <Box pt={10}>
            <Accordion data={data} />
          </Box>
        </Box>
      </Container>
    </StyledSectionAccordion>
  );
}

export default SectionAccordion;
