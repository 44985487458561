import styled from "styled-components"

// Styles
export const StyledProductSelectorContainer = styled.div``

export const StyledProductSelector = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  overflow: hidden;
  margin-top: 30px;
  padding-bottom: 60px;
  flex-wrap: wrap;

  @media(max-width: 768px) {
    justify-content: center;
  }
`
export const StyledProductSelectorItem = styled.a`
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: min(280px, 50vw);

  &.active {
    .preview {
      background-color: rgba(212, 212, 212, 0.25);
    }
  }

  .preview {
    padding: 20px;
    background-color: white;
    min-height: 280px;
    width: 100%;
    position: relative;

    .thumbnail {
      width: 100%;

      img {
        width: 100%;
        height: 80%;
        aspect-ratio: 1;
        object-fit: contain;
      }
    }

    .title {
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
      height: 20%;
      width: min-content;
      margin: 0 auto;
    }
  }
`

export const StyledProductSelectorItemPrice = styled.div`
  margin-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  gap: 0.5rem;

  .price {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #666666;
  }

  .popup {
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      .popup-text {
        opacity: 1;
        visibility: visible;
      }
    }

    .popup-text {
      position: absolute;
      transform: translate3d(-40%, 40px, 0);
      background: rgba(196, 196, 196, 0.25);
      padding: 8px 10px;
      opacity: 0;
      visibility: hidden;
      transition: all 250ms ease-in-out;
      text-align: center;
      max-width: 250px;
      width: max-content;
      border-radius: 8px;

      font-family: Muli;
      font-size: 10px;
      font-style: italic;
      font-weight: 400;
      letter-spacing: -0.02em;
      text-align: center;
      color: #666666;
      background-color: rgb(196, 196, 196);

      @media (max-width: 500px) {
        transform: translate3d(-70%, 40px, 0);
      }
    }
  }
`

export const StyledProductSelectorContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  margin: 100px 0 0 150px;

  @media(max-width: 500px) {
    flex-direction: column;
    margin: 50px 30px;
  }

  .content {
    width: 40%;

    @media(max-width: 500px) {
      width: 100%;
    }

    .title {
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      letter-spacing: -0.02em;
      color: #161616;
    }

    .text {
      margin-top: 50px;
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      letter-spacing: -0.01em;
      color: #666666;

      a, p {
        margin-top: 0.8rem;
      }

      br {
        height: 0;
      }
    }
  }

  .image {
    width: 45%;

    @media(max-width: 500px) {
      width: 100%;
    }

    img {
      height: auto;
      width: 100%;
    }
  }
`

export const StyledProductSelectorTabs = styled.div`
  background-color: #f8f8f8;
  padding-top: 100px;
  font-family: Muli;

  .title {
    font-weight: bold;
    font-size: 42px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #161616;
  }

  .text {
    font-size: 18px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #666666;
    width: 50%;
    margin: 20px auto -50px;
  }
`

export const StyledToggle = styled.label`
  margin-top: 50px;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 5px;

  .label {
    text-transform: capitalize;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.01em;
    color: #666666;
  }

  .react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    user-select: none;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: transparent;
    transition: all 0.2s ease;
    outline: solid 2px #753FBF;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #753FBF;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 2px;
    left: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #753FBF;

    box-sizing: border-box;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    background-color: white;
  }
`
