import styled from 'styled-components';

import { Box } from './UIKit/system';

const Separator = styled(Box)`
  width: 2px;
  height: 17px;
  background-color: #959595;
  font-weight: bold;
`;

export default Separator;
