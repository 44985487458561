import React from 'react';
import linkParser from '../../../utils/parse-storyblok-link';

import StickySideButton from '../../sticky-side-button';

function storyblokLookingToJoinAdapter({ text, link }) {
  return <StickySideButton text={text} link={linkParser(link)} />;
}

export default storyblokLookingToJoinAdapter;
