import React from 'react';

import SectionFeatureColumns from '../../section-feature-columns';
import RichtextParser from '../../../utils/richtext-parser';
import { parseStoryblokIcon } from '../../../utils/utils';

function StoryblokFeatureBlocksAdapter({ title, subtitle, blocks }) {
  const parsedBlocks = blocks.map((block) => {
    return {
      title: block.title,
      text: RichtextParser(block.paragraph),
      icon: parseStoryblokIcon(block.icon),
    };
  });

  return (
    <SectionFeatureColumns
      title={title}
      text={subtitle}
      columns={parsedBlocks}
    />
  );
}

export default StoryblokFeatureBlocksAdapter;
