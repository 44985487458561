import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'

// Styles
import { StyledFormButton } from '../../styles'

const StyledInputContainer = styled.form`
  max-width: 500px;
  margin: 2rem auto;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;

    .inputs {
      flex-grow: 1;

      .text {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #666666;

        a {
          display: inline-block;
          margin-top: 0.5rem;
          font-size: 16px;
          color: #753fbf;

          &:hover {
            color: #753fbf;
          }
        }
      }

      .help {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #666666;
        margin-top: 1rem;
      }
    }

    .image {
      flex-basis: 40%;

      @media (max-width: 500px) {
        display: none;
      }
    }
  }
`

const StyledSelect = styled.select`
  background-color: #fff;
  border: solid 1px #fff;
  border-radius: 1px;
  height: 52px;
  width: 100%;
  margin-top: 18px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #666666;

  &::placeholder {
    color: rgba(102, 102, 102, 0.33);
  }
`

const StyledWarnings = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: white;

  div:not(:first-child) {
    margin-top: 1.5rem;

    p:not(:first-child) {
      margin-top: 1rem;
    }
  }
`

interface Option {
  title: string
  default: boolean
  value: string | Record<'name' | string, unknown>
}
interface Props {
  step: number
  title: string
  text?: string
  type: 'input'
  key: string
  button: string
  image: string
  callback: (option: Option['value']) => void
  options: Option[]
  input: Option['value'] | null
}

const Input: FunctionComponent<Props> = ({ title, text, button, image, callback, options, input }) => {
  const defaultValue = options.findIndex((option) => option.default)
  const initialValue = input
    ? options.findIndex((option) => (typeof option.value === 'object' && typeof input === 'object' ? option.value.name === input.name : option.value === input))
    : defaultValue

  const [selectedIndex, setSelectedIndex] = useState(initialValue !== -1 ? initialValue : defaultValue !== -1 ? defaultValue : 0)
  const [errors] = useState<string[]>([])

  const handleChange = useCallback(
    (event) => {
      const index = Number(event.target.value)
      setSelectedIndex(index)
    },
    [selectedIndex]
  )

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      const selectedOption = options[selectedIndex]
      if (selectedOption?.value) return callback(selectedOption.value)
    },
    [selectedIndex]
  )

  return (
    <StyledInputContainer>
      <form onSubmit={handleSubmit} className="form">
        <div className="content">
          <div className="inputs">
            <p className="text" dangerouslySetInnerHTML={{ __html: text }}></p>
            <StyledSelect value={selectedIndex} onChange={handleChange}>
              {options.map((option, i) => (
                <option key={i} value={i}>
                  {option.title}
                </option>
              ))}
            </StyledSelect>
            {selectedIndex === defaultValue && <p className="help">This is our reccommended default.</p>}
          </div>
          {image && (
            <div className="image">
              <img src={image} alt={title} />
            </div>
          )}
        </div>
        {!!errors.length && (
          <StyledWarnings>
            {errors.map((error, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: error }}></div>
            ))}
          </StyledWarnings>
        )}
        <StyledFormButton type="submit">{button}</StyledFormButton>
      </form>
    </StyledInputContainer>
  )
}

export default Input
