import React from 'react';
import SectionTitleAndText from '../../section-title-and-text';
import RichtextParser from '../../../utils/richtext-parser';

function StoryblokLeftTitleRightTextAdapter({ paragraph, ...rest }) {
  let paragraphParsed = RichtextParser(paragraph);
  return <SectionTitleAndText text={paragraphParsed} {...rest} />;
}

export default StoryblokLeftTitleRightTextAdapter;
