import React from 'react';
import SectionCaseStudyPreview from '../../section-case-study-preview';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';

function StoryblokCaseStudyNextProjectAdapter({
  title,
  paragraph,
  image,
  ctaText,
  ctaLink,
}) {
  let fluidCaseStudyImage = getFluidGatsbyImage(image, {
    maxWidth: 700,
  });

  return (
    <SectionCaseStudyPreview
      ctaText={ctaText}
      ctaLink={parseStoryblokLink(ctaLink)}
      title={title}
      text={paragraph}
      image={fluidCaseStudyImage}
    />
  );
}

export default StoryblokCaseStudyNextProjectAdapter;
