import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Flex } from '../UIKit/system';
import { getThemeColor } from '../../utils/styles';
import mq from '../../utils/mq';

const ProgressBarContainer = styled(Flex)`
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const Num = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const BarContainer = styled.div`
  height: 4px;
  width: 180px;
  background-color: #222;
  margin: 0 12px 0 10px;
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const Bar = styled.div`
  background-color: ${getThemeColor('primary')};
  height: 100%;
  transform: scaleX(${(props) => props.scaleX});
  transform-origin: left center;
  will-change: transform;
  transition: transform 0.35s linear;
`;

function ProgressBar({ progress, total }) {
  const scaleX = useMemo(() => {
    let result = progress;
    if (progress < 0) {
      result = 0;
    } else if (progress > 1) {
      result = 1;
    }
    return result;
  }, [progress]);

  return (
    <ProgressBarContainer color={'#fff'} alignItems={'center'}>
      <Num>01</Num>
      <BarContainer>
        <Bar scaleX={scaleX} />
      </BarContainer>
      <Num>{total < 10 ? `0${total}` : total}</Num>
    </ProgressBarContainer>
  );
}

export default ProgressBar;
