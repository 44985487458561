import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Sticky } from 'react-sticky';

import Container from './container';
import { getThemeColor } from '../utils/styles';

import ChevronDown from '../images/svg/chevron-down.svg';
import CloseCross from '../images/svg/close-cross.svg';

const CaseStudiesMobileFilterContainerOuter = styled.div``;

const CaseStudiesMobileFilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding-top: 27px;
`;

const ShowingContainer = styled.div``;

const TriggerContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
`;

const CaseStudiesMenuContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 250;
  background-color: #fff;
  overflow-y: auto;
`;

const TriggerText = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;
  color: #161616;
`;

const ChevronDownContainer = styled.div`
  position: relative;
  top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
`;

const StyledChevronDown = styled(ChevronDown)`
  fill: ${getThemeColor('purple')};
`;

const InnerMenuContainer = styled.div`
  position: relative;
`;

const UpperContent = styled.div`
  width: 100%;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #161616;
`;

const CloseIconContainer = styled.div``;

const ResetButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  color: #753fbf;
  margin-top: 7px;
`;

const Sections = styled.div`
  margin-top: 0px;
`;

const SectionContainer = styled.div`
  margin-top: 36px;
`;

const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #959595;
  margin-bottom: 14px;
`;

const SectionOptions = styled.div``;

const SectionOptionItem = styled.div`
  border: 1px solid #ebebeb;
  border-left-width: 0px;
  border-right-width: 0px;
  ${(props) =>
    props.active &&
    css`
      background-color: #f8f8f8;
    `}
`;

const SectionOptionItemInner = styled.div`
  padding: 20px 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SectionOptionBullet = styled.div`
  width: 16px;
  height: 16px;
  border: 1.5px solid #dadada;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 15px;
`;

const SectionOptionBulletActive = styled.div`
  width: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;
  background: #753fbf;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

const SectionOptionBulletActiveInner = styled.div`
  background: #f8f8f8;
  border-radius: 10px;
  width: 6px;
  height: 6px;
`;

const SectionOptionItemTitle = styled.div`
  font-size: 13px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #161616;
`;

const ShowResultsButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: 26px;
  padding-top: 26px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 5;
`;

const ShowResultsButton = styled.div`
  width: 85%;
  height: 56px;
  background: #753fbf;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

const MenuAnim = {
  hidden: {
    y: '-100vh',
    transition: {
      duration: 0.4,
      ease: 'easeIn',
    },
  },
  visible: {
    y: '0',
    transition: {
      duration: 0.4,
      ease: 'easeIn',
    },
  },
};

export default function CaseStudiesBrowserFilterMobile({
  locations,
  propertyTypes,
  handleFilterUpdate,
  propertyType,
  projectType,
  location,
  setFilters,
}) {
  const [menuOpen, setmenuOpen] = useState(false);

  function handleMenuToggle() {
    if (menuOpen) {
      setmenuOpen(false);
    } else {
      setmenuOpen(true);
    }
  }

  useEffect(() => {
    document.documentElement.style.overflow = menuOpen ? 'hidden' : '';
  }, [menuOpen]);

  function handleLocationSelect(location) {
    handleFilterUpdate('location', location);
  }

  function handlePropertyTypeChange(type) {
    handleFilterUpdate('propertyType', type);
  }

  function handleBuildingTypeSelect(type) {
    handleFilterUpdate('projectType', type);
  }

  function resetAll() {
    let filter = {
      projectType: '',
      propertyType: '',
      location: '',
    };
    setFilters(filter);
  }

  return (
    <CaseStudiesMobileFilterContainerOuter>
      <Container>
        <CaseStudiesMobileFilterContainer>
          <ShowingContainer>Showing 23 items</ShowingContainer>
          <TriggerContainer onClick={() => handleMenuToggle()}>
            <TriggerText> Filters</TriggerText>
            <ChevronDownContainer>
              <StyledChevronDown />
            </ChevronDownContainer>
          </TriggerContainer>
        </CaseStudiesMobileFilterContainer>
        <CaseStudiesMenuContainer
          initial="hidden"
          animate={menuOpen ? 'visible' : 'hidden'}
          variants={MenuAnim}
        >
          <InnerMenuContainer>
            <Container>
              <Sticky topOffset={0}>
                {({ style }) => {
                  const { transform, ...restStyles } = style; // eslint-disable-line
                  return (
                    <UpperContent style={restStyles}>
                      <TitleBar>
                        <Title>Filter Case Studies</Title>
                        <CloseIconContainer onClick={() => handleMenuToggle()}>
                          <CloseCross
                            stroke={'#959595'}
                            height={'15px'}
                            width={'15px'}
                          />
                        </CloseIconContainer>
                      </TitleBar>
                      <ResetButton onClick={() => resetAll()}>
                        Reset
                      </ResetButton>
                    </UpperContent>
                  );
                }}
              </Sticky>
            </Container>
            <Sections>
              <SectionContainer>
                <Container>
                  <SectionTitle>System Type</SectionTitle>
                </Container>
                <SectionOptions>
                  <SectionOptionItem
                    onClick={() => handleBuildingTypeSelect('')}
                    active={
                      projectType === '' || projectType === undefined
                        ? true
                        : false
                    }
                  >
                    <SectionOptionItemInner>
                      {projectType === '' || projectType === undefined ? (
                        <SectionOptionBulletActive>
                          <SectionOptionBulletActiveInner />
                        </SectionOptionBulletActive>
                      ) : (
                        <SectionOptionBullet />
                      )}

                      <SectionOptionItemTitle>All</SectionOptionItemTitle>
                    </SectionOptionItemInner>
                  </SectionOptionItem>
                  <SectionOptionItem
                    onClick={() => handleBuildingTypeSelect('natural')}
                    active={projectType === 'natural' ? true : false}
                  >
                    <SectionOptionItemInner>
                      {projectType === 'natural' ? (
                        <SectionOptionBulletActive>
                          <SectionOptionBulletActiveInner />
                        </SectionOptionBulletActive>
                      ) : (
                        <SectionOptionBullet />
                      )}

                      <SectionOptionItemTitle>Natural</SectionOptionItemTitle>
                    </SectionOptionItemInner>
                  </SectionOptionItem>
                  <SectionOptionItem
                    onClick={() => handleBuildingTypeSelect('mechanical')}
                    active={projectType === 'mechanical' ? true : false}
                  >
                    <SectionOptionItemInner>
                      {projectType === 'mechanical' ? (
                        <SectionOptionBulletActive>
                          <SectionOptionBulletActiveInner />
                        </SectionOptionBulletActive>
                      ) : (
                        <SectionOptionBullet />
                      )}
                      <SectionOptionItemTitle>
                        Mechanical
                      </SectionOptionItemTitle>
                    </SectionOptionItemInner>
                  </SectionOptionItem>
                </SectionOptions>
              </SectionContainer>
              <SectionContainer>
                <Container>
                  <SectionTitle>Building Type</SectionTitle>
                </Container>
                <SectionOptions>
                  <SectionOptionItem
                    active={propertyType === '' ? true : false}
                    onClick={() => handlePropertyTypeChange('')}
                  >
                    <SectionOptionItemInner>
                      {propertyType === '' ? (
                        <SectionOptionBulletActive>
                          <SectionOptionBulletActiveInner />
                        </SectionOptionBulletActive>
                      ) : (
                        <SectionOptionBullet />
                      )}
                      <SectionOptionItemTitle>
                        All Building Types
                      </SectionOptionItemTitle>
                    </SectionOptionItemInner>
                  </SectionOptionItem>
                  {propertyTypes.edges.map((item, i) => (
                    <SectionOptionItem
                      key={i}
                      active={
                        propertyType === item.node.name.toLowerCase()
                          ? true
                          : false
                      }
                      onClick={() =>
                        handlePropertyTypeChange(item.node.name.toLowerCase())
                      }
                    >
                      <SectionOptionItemInner>
                        {propertyType === item.node.name.toLowerCase() ? (
                          <SectionOptionBulletActive>
                            <SectionOptionBulletActiveInner />
                          </SectionOptionBulletActive>
                        ) : (
                          <SectionOptionBullet />
                        )}

                        <SectionOptionItemTitle>
                          {item.node.name}
                        </SectionOptionItemTitle>
                      </SectionOptionItemInner>
                    </SectionOptionItem>
                  ))}
                </SectionOptions>
              </SectionContainer>
              <SectionContainer>
                <Container>
                  <SectionTitle>Location</SectionTitle>
                </Container>
                <SectionOptions>
                  <SectionOptionItem
                    active={location === '' ? true : false}
                    onClick={() => handleLocationSelect('')}
                  >
                    <SectionOptionItemInner>
                      {location === '' ? (
                        <SectionOptionBulletActive>
                          <SectionOptionBulletActiveInner />
                        </SectionOptionBulletActive>
                      ) : (
                        <SectionOptionBullet />
                      )}

                      <SectionOptionItemTitle>
                        Any Location
                      </SectionOptionItemTitle>
                    </SectionOptionItemInner>
                  </SectionOptionItem>
                  {locations.edges.map((item, i) => (
                    <SectionOptionItem
                      active={
                        location === item.node.name.toLowerCase() ? true : false
                      }
                      onClick={() =>
                        handleLocationSelect(item.node.name.toLowerCase())
                      }
                      key={i}
                    >
                      <SectionOptionItemInner>
                        {location === item.node.name.toLowerCase() ? (
                          <SectionOptionBulletActive>
                            <SectionOptionBulletActiveInner />
                          </SectionOptionBulletActive>
                        ) : (
                          <SectionOptionBullet />
                        )}

                        <SectionOptionItemTitle>
                          {item.node.name}
                        </SectionOptionItemTitle>
                      </SectionOptionItemInner>
                    </SectionOptionItem>
                  ))}
                </SectionOptions>
              </SectionContainer>
            </Sections>
            <ShowResultsButtonContainer>
              <ShowResultsButton onClick={() => handleMenuToggle()}>
                Show Results
              </ShowResultsButton>
            </ShowResultsButtonContainer>
          </InnerMenuContainer>
        </CaseStudiesMenuContainer>
      </Container>
    </CaseStudiesMobileFilterContainerOuter>
  );
}
