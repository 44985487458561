import React from 'react';

import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import PageSubnavBar from '../../page-subnav-bar';
import withLocation from '../../with-location';

function StoryblokSubpageNavigationAdapter({
  pages,
  ctaLink,
  location,
  ...rest
}) {
  let pagesArr = [];
  let activePage;
  let ctaLinkParsed = parseStoryblokLink(ctaLink);
  pages.map((page, i) => {
    let active;
    let link = null;
    if (page.link !== undefined) {
      link = parseStoryblokLink(page.link);
      if (location.pathname && location) {
        if (location.pathname.slice(-1) === '/') {
          link = link + '/';
        } else {
          if (link) {
            if (link.slice(-1) === '/') {
              link = link.substring(0, link.length - 1);
            }
          }
        }
        if (link === location.pathname) {
          active = true;
          activePage = page.title;
        } else {
          active = false;
        }
      }
    }
    let pageItem = {
      active: active,
      link: link,
      title: page.title,
    };
    pagesArr.push(pageItem);
  });

  return (
    <PageSubnavBar
      pages={pagesArr}
      activePage={activePage}
      ctaLink={ctaLinkParsed}
      {...rest}
    />
  );
}

export default withLocation(StoryblokSubpageNavigationAdapter);
