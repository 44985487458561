import React, { FunctionComponent, useCallback, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Types
import { Feature } from '../../types'

// Styles
import { StyledFormButton } from '../../styles'

const StyledFeaturesContainer = styled.div`
  .grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 250px));
    grid-auto-rows: 80px;
    grid-gap: 1.5rem;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 500px) {
      grid-template-columns: 80%;
      justify-content: center;
    }
  }

  .help {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top: 1rem;
    color: #f0bc43;
  }
`

const StyledFeaturesButton = styled.button`
  cursor: pointer;
  background: #fff;
  padding: 0 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border: 2px solid #fff;
  font-family: Muli;

  &.is-active {
    border-color: #753fbf;
  }
`

const StyledFeaturesButtonToolTip = styled.a`
  position: relative;
  display: inline-block;

  &:hover {
    div {
      opacity: 1;
      visibility: visible;
    }
  }

  div {
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    background: white;
    padding: 1rem;
    width: max-content;
    max-width: 300px;
    margin: auto;
    border: 1px solid lightgray;
    height: fit-content;
    opacity: 0;
    visibility: hidden;
    transform: translateX(30px);
    transition: all 250ms ease-in-out;

    @media (max-width: 500px) {
      transform: translate(-40%, 50px);
    }
  }
`

// Types
interface FeatureButtonProps extends Feature {
  selected: boolean
  select: (value: string) => void
}

interface Props {
  // TODO: move to a generic proptype for simple steps
  text: string
  button: string
  features: Feature[]
  callback: (value: string[]) => void
  preselected?: string[]
  input: string[]
  allInput: Record<string, unknown>
}

const FeatureButton: FunctionComponent<FeatureButtonProps> = ({ select, selected, icon, title, uuid, helpText }) => {
  return (
    <StyledFeaturesButton className={selected ? 'is-active' : ''} onClick={() => select(uuid)}>
      <img src={icon.filename} alt={icon.alt || title} />
      <p>{title}</p>
      {helpText && (
        <StyledFeaturesButtonToolTip>
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.98496 14C9.56467 14 10.0478 13.8058 10.4342 13.4175L10.1444 13.2233C9.88673 13.493 9.56466 13.644 9.17819 13.6764L10.5115 7.55987L10.241 7.60841C9.79011 7.67314 9.08801 7.7055 8.13471 7.7055H7.3038L7.26515 7.99676H8.07674L7.43906 10.8932C7.23294 11.9288 7.12988 12.5167 7.12988 12.657C7.12988 13.0561 7.29091 13.3797 7.61297 13.6278C7.94792 13.8759 8.40524 14 8.98496 14Z"
              fill="#666666"
            />
            <path
              d="M8.32795 5.18123C8.32795 5.50485 8.46322 5.77993 8.73375 6.00647C9.00428 6.23301 9.33278 6.34628 9.71926 6.34628C10.1057 6.34628 10.4342 6.23301 10.7048 6.00647C10.9882 5.77993 11.1299 5.50485 11.1299 5.18123C11.1299 4.8576 10.9882 4.58252 10.7048 4.35599C10.4342 4.11866 10.1057 4 9.71926 4C9.33278 4 9.00428 4.11866 8.73375 4.35599C8.46322 4.58252 8.32795 4.8576 8.32795 5.18123Z"
              fill="#666666"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.1299 9C18.1299 13.9706 14.1004 18 9.12988 18C4.15932 18 0.129883 13.9706 0.129883 9C0.129883 4.02944 4.15932 0 9.12988 0C14.1004 0 18.1299 4.02944 18.1299 9ZM16.5299 9C16.5299 13.0869 13.2168 16.4 9.12988 16.4C5.04298 16.4 1.72988 13.0869 1.72988 9C1.72988 4.91309 5.04298 1.6 9.12988 1.6C13.2168 1.6 16.5299 4.91309 16.5299 9Z"
              fill="#666666"
            />
          </svg>
          <div>
            <p>{helpText}</p>
          </div>
        </StyledFeaturesButtonToolTip>
      )}
    </StyledFeaturesButton>
  )
}

const Features: FunctionComponent<Props> = ({ button, callback, input, preselected, allInput }) => {
  const verticalPodType = useMemo(() => allInput.verticalPod, [allInput])

  const [selected, setSelected] = useState<string[]>(input ?? [])
  const [triedToDeselect, setTriedToDeselect] = useState(false)

  const data = useStaticQuery(graphql`
    {
      features: allStoryblokEntry(filter: { field_component: { eq: "featureTableRow" } }) {
        nodes {
          id
          uuid
          name
          field_component
          content
        }
      }
    }
  `)

  const { features, preselectedIds } = useMemo(() => {
    const features: Feature[] = data.features.nodes.map(({ uuid, content }: { uuid: string; content: string }) => ({ uuid, ...JSON.parse(content) }))

    const horizontalFeatures = features.filter((feature) => !feature.type || feature.type === 'horizontal' || feature.type === 'both')
    const verticalFeatures = features.filter((feature) => !feature.type || feature.type === 'vertical' || feature.type === 'both')

    const selectedFeatures = verticalPodType ? verticalFeatures : horizontalFeatures

    const preselectedResolved = preselected ?? []

    const preselectedIds = preselectedResolved.flatMap((str) => {
      const feature = selectedFeatures.find((feature) => feature.title === str)
      return feature ? [feature.uuid] : []
    })

    selectedFeatures.sort((a, b) => {
      const aPreselected = preselectedIds.includes(a.uuid)
      const bPreselected = preselectedIds.includes(b.uuid)
      return bPreselected ? 1 : aPreselected ? -1 : 0
    })

    return { features: selectedFeatures, preselectedIds }
  }, [data])

  useEffect(() => {
    if (!preselectedIds.length) return
    if (!selected.length) return setSelected(preselectedIds)

    const preselectedToAdd = preselectedIds.filter((id) => !selected.includes(id))
    if (!preselectedToAdd.length) return
    setSelected([...preselectedToAdd, ...selected])
    setTriedToDeselect(true)
  }, [selected, preselectedIds])

  useEffect(() => {
    if (triedToDeselect) {
      setTimeout(() => setTriedToDeselect(false), 2500)
    }
  }, [triedToDeselect])

  const handleSelect = useCallback(
    (value: string) => {
      if (!selected.includes(value)) return setSelected([...selected, value])
      const updated = selected.filter((item) => item !== value)
      setSelected(updated)
    },
    [selected]
  )

  const handleClick = useCallback(() => callback(selected), [selected])

  return (
    <StyledFeaturesContainer>
      <div className="grid">
        {features.map((feature) => (
          <FeatureButton key={feature.uuid} {...feature} select={handleSelect} selected={selected.includes(feature.uuid)} />
        ))}
      </div>
      {triedToDeselect && <p className="help">All Pod variants come with these options as standard.</p>}
      <StyledFormButton type="button" onClick={handleClick}>
        {button}
      </StyledFormButton>
    </StyledFeaturesContainer>
  )
}

export default Features
