import React from 'react';
import styled, { css } from 'styled-components';

import { buttonReset, getThemeColor, hoverDefault } from '../../utils/styles';
import IconArrowRight from '../../images/svg/arrow-right.svg';
import mq from '../../utils/mq';

const StyledControlButton = styled.button`
  ${buttonReset};
  ${hoverDefault};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  cursor: pointer;
  left: 64px;

  :focus {
    outline: none;
  }

  ${mq.mobile(css`
    position: static;
    width: 40px;
    height: 40px;
  `)};
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  stroke: ${getThemeColor('black')};
  transform: scaleX(-1);
`;

function WizardButtonBack(props) {
  return (
    <StyledControlButton {...props}>
      <StyledIconArrowRight />
    </StyledControlButton>
  );
}

export default WizardButtonBack;
