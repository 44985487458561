import React from 'react';
import styled from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import Title from './title';

const StyledPageHeader = styled(Box)``;

function PageHeader({title, text}) {
  return (
    <StyledPageHeader pt={66} pb={66}>
      <Container textAlign={'center'}>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <Title mb={20} html={title} />
          <Paragraph grey maxWidth={650} html={text} />
        </Flex>
      </Container>
    </StyledPageHeader>
  );
}

export default PageHeader;
