import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import mq from '../utils/mq';

import Link from './link';

const SideButton = styled(motion.div)`
  width: 56px;
  position: fixed;
  top: 50vh;
  right: 0;
  background-color: #3b1954;
  padding: 60px 20px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 18px;
  line-height: 46px;
  color: #ffffff;
  transform: rotate(-90deg);
  display: flex;
  width: 100%;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;

function StickySideButton({ text, link }) {
  return (
    <SideButton
      initial={{ x: 80 }}
      animate={{ x: 0 }}
      transition={{ delay: 1, duration: 0.6 }}
    >
      <StyledLink to={link}>{text}</StyledLink>
    </SideButton>
  );
}

export default StickySideButton;
