import React from 'react';
import styled, { css } from 'styled-components';

import ArrowInCircle from '../arrow-in-circle';
import { Paragraph, Text } from '../UIKit/system';
import { hoverDefault } from '../../utils/styles';

const StyledQuestion = styled(Paragraph)`
  ${hoverDefault};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  cursor: pointer;
  user-select: none;
`;

const ArrowContainer = styled.div`
  will-change: transform;
  transition: transform 0.35s ease-out;

  ${(props) =>
    props.collapsed &&
    css`
      transform: rotate(90deg);
    `};
`;

function Question({ collapsed, children, ...rest }) {
  return (
    <StyledQuestion {...rest}>
      <Text fontSizeMedium bold>
        {children}
      </Text>
      <ArrowContainer collapsed={collapsed}>
        <ArrowInCircle small />
      </ArrowContainer>
    </StyledQuestion>
  );
}

export default Question;
