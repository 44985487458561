import React, { useState } from 'react';
import styled from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import LazyImage from './lazy-image';
import Title from './title';
import Container from './container';
import ButtonPlay from './button-play';
import VideoPlayerOverlay from './video-player-overlay';

const StyledSectionVideo = styled(Box)`
  position: relative;
  height: 600px;
  color: #fff;
`;

const BackgroundImage = styled(LazyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(50%);
`;

function SectionVideo({
  backgroundImage,
  title,
  subtitle,
  buttonText,
  videoLink,
}) {
  const [videoOpen, setvideoOpen] = useState(false);

  function handlePopupClose() {
    setvideoOpen(false);
  }
  return (
    <StyledSectionVideo as={'section'} pt={184} pb={100}>
      {videoOpen ? (
        <VideoPlayerOverlay
          videoLink={videoLink}
          handlePopupClose={handlePopupClose}
        />
      ) : null}
      <BackgroundImage fluid={backgroundImage} />

      <Container
        as={Flex}
        position={'relative'}
        height={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Flex
          flexDirection={'column'}
          alignItems={'center'}
          textAlign={'center'}
        >
          <Title
            as={'h2'}
            mb={14}
            maxWidth={775}
            fontSize={[28, 38, 42]}
            html={title}
          />
          <Paragraph maxWidth={520} fontSize={[16, 20, 20]} html={subtitle} />
        </Flex>
        <div onClick={() => setvideoOpen(true)}>
          <ButtonPlay semiTransparent>Watch the Video</ButtonPlay>
        </div>
      </Container>
    </StyledSectionVideo>
  );
}

export default SectionVideo;
