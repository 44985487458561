import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  CONTAINER_WIDTH,
  MOCKUP_WIDTH,
} from '../constants';

export const em = (value, context) => `${value / context}em`;

const calculatePercentageValue = (value, context) => (100 * value) / context;

export const vw = (value, context = MOCKUP_WIDTH) =>
  `${calculatePercentageValue(value, context)}vw`;

export const vwDesktopSmall = (value, context = BREAKPOINT_LG) =>
  `${calculatePercentageValue(value, context)}vw`;

export const vwTablet = (value, context = BREAKPOINT_MD) =>
  `${calculatePercentageValue(value, context)}vw`;

export const vh = (value, context = 768) =>
  `${calculatePercentageValue(value, context)}vh`;

export const percents = (value, context = CONTAINER_WIDTH) =>
  `${calculatePercentageValue(value, context)}%`;
