export const HUBSPOT_FORM = {
  portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
  formId: process.env.GATSBY_HUBSPOT_FORM_ID,
  fields: [
    'shaftWidth',
    'shaftDepth',
    'doors',
    'liftDoors',
    'storeys',
    'features',
    'estimatedExtractRate',
    'totalSurfaceArea',
    'totalSmokeShaftDamperLeakage',
    'extractRateAtDamper',
    'extractRateRequired',
    'totalSystemLeakage',
    'totalSmokeDamperLeakage',
    'totalSmokeShaftLeakage'
  ]
}

export const HUBSPOT_FORM_ENDPOINT = `/.netlify/functions/hubspot-submission`
