import React from 'react';

import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';
import RichtextParser from '../../../utils/richtext-parser';
import LeftIconsRightImage from '../../left-icons-right-image';

function StoryblokLeftIconsRightImageAdapter({ image, iconBlocks, ...rest }) {
  const fluidImage = getFluidGatsbyImage(image, {
    maxWidth: 1000,
  });
  let items = [];
  iconBlocks.map((iconBlock) => {
    let text = RichtextParser(iconBlock.text);
    let item = {
      ...iconBlock,
      title: iconBlock.title,
      text: text,
    };
    items.push(item);
  });

  return <LeftIconsRightImage items={items} image={fluidImage} {...rest} />;
}

export default StoryblokLeftIconsRightImageAdapter;
