import React, { FunctionComponent } from 'react'

export const DoorsIcon: FunctionComponent = () => (
  <svg width="193" height="193" viewBox="0 0 193 193" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M165.518 1.38623H10.482C5.45883 1.38623 1.38672 5.45834 1.38672 10.4816V165.518C1.38672 170.541 5.45883 174.613 10.482 174.613H165.518C170.542 174.613 174.614 170.541 174.614 165.518V10.4816C174.614 5.45834 170.542 1.38623 165.518 1.38623Z"
      stroke="black"
      strokeWidth="2.21384"
      strokeMiterlimit="10"
    />
    <path d="M46.8223 152.152V23.5146H129.013V152.152" stroke="black" strokeWidth="2.21384" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M54.5312 152.153V30.5444H121.457V152.153" stroke="black" strokeWidth="2.21384" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.4219 152.153H140.99" stroke="black" strokeWidth="2.21384" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M93.6711 94.5166H66.2188V102.239H93.6711V94.5166Z" stroke="black" strokeWidth="2.21384" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M107.341 102.239C109.469 102.239 111.195 100.513 111.195 98.3847C111.195 96.256 109.469 94.5303 107.341 94.5303C105.212 94.5303 103.486 96.256 103.486 98.3847C103.486 100.513 105.212 102.239 107.341 102.239Z"
      stroke="#8A55FB"
      strokeWidth="2.21384"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.196 65.2617C111.196 59.2973 108.827 53.5772 104.609 49.3597C100.392 45.1423 94.6719 42.7729 88.7075 42.7729C82.7431 42.7729 77.023 45.1423 72.8055 49.3597C68.5881 53.5772 66.2188 59.2973 66.2188 65.2617H111.196Z"
      stroke="black"
      strokeWidth="2.21384"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M88.707 42.7729V65.2617L104.527 49.2894" stroke="black" strokeWidth="2.21384" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M73.1367 49.0537L88.7069 65.2617" stroke="black" strokeWidth="2.21384" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const LiftDoorsIcon: FunctionComponent = () => (
  <svg width="193" height="193" viewBox="0 0 193 193" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M173.518 9.38672H18.482C13.4588 9.38672 9.38672 13.4588 9.38672 18.482V173.518C9.38672 178.542 13.4588 182.614 18.482 182.614H173.518C178.542 182.614 182.614 178.542 182.614 173.518V18.482C182.614 13.4588 178.542 9.38672 173.518 9.38672Z"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M160.916 31.5146H32.5V159.931H160.916V31.5146Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M46.7246 159.931V54.8076H122.953V159.931" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M91.494 38.7104H78.1699C76.9294 38.7104 75.9238 39.7161 75.9238 40.9566V45.3101C75.9238 46.5506 76.9294 47.5562 78.1699 47.5562H91.494C92.7345 47.5562 93.7401 46.5506 93.7401 45.3101V40.9566C93.7401 39.7161 92.7345 38.7104 91.494 38.7104Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M84.8379 54.8076V159.931" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M150.641 102.974L141.782 92.9634L132.908 102.974H150.641Z" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M132.908 111.764L141.782 121.775L150.641 111.764H132.908Z" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
export const YesIcon: FunctionComponent = () => (
  <svg width="77" height="76" viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="38.5" cy="38" r="36.75" fill="white" stroke="black" strokeWidth="2.5" />
    <path d="M24.5 35.5L36.5 47L53.5 30" stroke="#8A55FB" strokeWidth="2.5" strokeLinecap="round" />
  </svg>
)
export const NoIcon: FunctionComponent = () => (
  <svg width="77" height="76" viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="38.5" cy="38" r="36.75" fill="white" stroke="black" strokeWidth="2.5" />
    <line x1="26.2667" y1="26.3643" x2="51.4125" y2="49.8068" stroke="#8A55FB" strokeWidth="2.5" strokeLinecap="round" />
    <line x1="51.2173" y1="25.7677" x2="26.7546" y2="49.9221" stroke="#8A55FB" strokeWidth="2.5" strokeLinecap="round" />
  </svg>
)
export const DuctworkIcon: FunctionComponent = () => (
  <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.678223 29.0774H19.8782" stroke="#8A55FB" strokeWidth="1.35497" strokeLinejoin="round" />
    <path d="M66.1221 29.0774H85.322" stroke="#8A55FB" strokeWidth="1.35497" strokeLinejoin="round" />
    <path d="M0.678223 56.8679H19.8782" stroke="#8A55FB" strokeWidth="1.35497" strokeLinejoin="round" />
    <path d="M66.1221 56.8679H85.322" stroke="#8A55FB" strokeWidth="1.35497" strokeLinejoin="round" />
    <path d="M66.1231 0.67749V13.3261H19.8779V0.67749H66.1231Z" stroke="black" strokeWidth="1.35497" strokeLinejoin="round" />
    <path
      d="M80.8789 0.67749H5.12253C2.66801 0.67749 0.678223 2.66727 0.678223 5.12179V80.8782C0.678223 83.3327 2.66801 85.3225 5.12253 85.3225H80.8789C83.3335 85.3225 85.3232 83.3327 85.3232 80.8782V5.12179C85.3232 2.66727 83.3335 0.67749 80.8789 0.67749Z"
      stroke="#231F20"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M67.0574 13.3259H18.9356C18.2209 13.3259 17.6416 13.9053 17.6416 14.6199V15.7378C17.6416 16.4524 18.2209 17.0318 18.9356 17.0318H67.0574C67.772 17.0318 68.3514 16.4524 68.3514 15.7378V14.6199C68.3514 13.9053 67.772 13.3259 67.0574 13.3259Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeLinejoin="round"
    />
    <path d="M66.1231 17.032V41.1166H19.8779V17.032H66.1231Z" stroke="black" strokeWidth="1.35497" strokeLinejoin="round" />
    <path
      d="M67.0574 41.1165H18.9356C18.2209 41.1165 17.6416 41.6958 17.6416 42.4105V43.5283C17.6416 44.243 18.2209 44.8223 18.9356 44.8223H67.0574C67.772 44.8223 68.3514 44.243 68.3514 43.5283V42.4105C68.3514 41.6958 67.772 41.1165 67.0574 41.1165Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeLinejoin="round"
    />
    <path d="M66.1231 44.8225V68.9071H19.8779V44.8225H66.1231Z" stroke="black" strokeWidth="1.35497" strokeLinejoin="round" />
    <path
      d="M67.0574 68.9067H18.9356C18.2209 68.9067 17.6416 69.4861 17.6416 70.2007V71.3186C17.6416 72.0332 18.2209 72.6126 18.9356 72.6126H67.0574C67.772 72.6126 68.3514 72.0332 68.3514 71.3186V70.2007C68.3514 69.4861 67.772 68.9067 67.0574 68.9067Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeLinejoin="round"
    />
    <path d="M66.1231 72.6128V85.3156H19.8779V72.6128H66.1231Z" stroke="black" strokeWidth="1.35497" strokeLinejoin="round" />
  </svg>
)
export const DrylinedIcon: FunctionComponent = () => (
  <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M80.8782 0.677246H5.12179C2.66727 0.677246 0.67749 2.66703 0.67749 5.12155V80.878C0.67749 83.3325 2.66727 85.3223 5.12179 85.3223H80.8782C83.3327 85.3223 85.3225 83.3325 85.3225 80.878V5.12155C85.3225 2.66703 83.3327 0.677246 80.8782 0.677246Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path d="M13.2307 68.0057V0.677246" stroke="black" strokeWidth="1.35497" strokeMiterlimit="10" />
    <path d="M62.4504 68.0057V0.677246" stroke="black" strokeWidth="1.35497" strokeMiterlimit="10" />
    <path
      d="M27.8172 22.8513H18.2241C17.8312 22.8513 17.5127 23.1698 17.5127 23.5627V46.3059C17.5127 46.6987 17.8312 47.0172 18.2241 47.0172H27.8172C28.2101 47.0172 28.5286 46.6987 28.5286 46.3059V23.5627C28.5286 23.1698 28.2101 22.8513 27.8172 22.8513Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M18.2918 51.9832H27.736C27.9444 51.9832 28.1443 52.0659 28.2917 52.2133C28.4391 52.3607 28.5219 52.5606 28.5219 52.769V68.0057H17.5127V52.7758C17.5118 52.6726 17.5313 52.5702 17.5699 52.4745C17.6086 52.3789 17.6658 52.2917 17.7381 52.2181C17.8105 52.1445 17.8966 52.0859 17.9916 52.0456C18.0866 52.0052 18.1886 51.984 18.2918 51.9832V51.9832Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M33.8339 33.1965H43.1019C43.3337 33.1965 43.556 33.2886 43.7199 33.4525C43.8838 33.6164 43.9759 33.8387 43.9759 34.0705V56.4275C43.9759 56.6809 43.8752 56.9238 43.6961 57.103C43.5169 57.2821 43.274 57.3828 43.0206 57.3828H33.9152C33.6619 57.3828 33.4189 57.2821 33.2397 57.103C33.0606 56.9238 32.96 56.6809 32.96 56.4275V34.0705C32.96 33.8387 33.052 33.6164 33.2159 33.4525C33.3798 33.2886 33.6021 33.1965 33.8339 33.1965V33.1965Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M33.7797 61.3596H43.1561C43.3735 61.3596 43.582 61.446 43.7358 61.5997C43.8895 61.7535 43.9759 61.962 43.9759 62.1794V68.0057H32.96V62.1794C32.96 61.962 33.0463 61.7535 33.2001 61.5997C33.3538 61.446 33.5623 61.3596 33.7797 61.3596Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M57.9859 22.8513H48.447C48.0391 22.8513 47.7085 23.1819 47.7085 23.5898V46.2788C47.7085 46.6866 48.0391 47.0172 48.447 47.0172H57.9859C58.3938 47.0172 58.7244 46.6866 58.7244 46.2788V23.5898C58.7244 23.1819 58.3938 22.8513 57.9859 22.8513Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M48.3997 51.9833H58.0267C58.2064 51.9833 58.3787 52.0547 58.5058 52.1817C58.6328 52.3088 58.7042 52.4811 58.7042 52.6608V67.9923H47.7086V52.6743C47.7068 52.5831 47.7234 52.4924 47.7575 52.4077C47.7916 52.323 47.8424 52.2461 47.9069 52.1816C47.9715 52.117 48.0484 52.0662 48.1331 52.0321C48.2177 51.9981 48.3084 51.9814 48.3997 51.9833Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path d="M23.0212 26.2117V26.5504" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.0212 30.1414V41.2657" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.72 3.59" />
    <path d="M23.0212 43.054V43.3927" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.0212 55.2556V55.5944" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.0212 59.144V65.8918" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.71 3.55" />
    <path d="M23.0212 67.667V68.0057" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M53.176 26.2117V26.5504" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M53.176 30.1414V41.2657" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.72 3.59" />
    <path d="M53.176 43.054V43.3927" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M53.176 55.2556V55.5944" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M53.176 59.144V65.8918" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.71 3.55" />
    <path d="M53.176 67.667V68.0057" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M38.467 36.6719V37.0106" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M38.467 40.5947V51.719" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.72 3.59" />
    <path d="M38.467 53.5144V53.8531" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M38.467 64.2593V64.598" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M38.467 67.667V68.0057" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M33.8339 4.83716H43.1019C43.3337 4.83716 43.556 4.92924 43.7199 5.09313C43.8838 5.25703 43.9759 5.47933 43.9759 5.71111V28.0681C43.9759 28.3215 43.8752 28.5645 43.6961 28.7436C43.5169 28.9227 43.274 29.0234 43.0206 29.0234H33.9152C33.6619 29.0234 33.4189 28.9227 33.2397 28.7436C33.0606 28.5645 32.96 28.3215 32.96 28.0681V5.71111C32.96 5.47933 33.052 5.25703 33.2159 5.09313C33.3798 4.92924 33.6021 4.83716 33.8339 4.83716V4.83716Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path d="M38.467 8.29248V8.63122" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M38.467 12.2146V23.3389" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.72 3.59" />
    <path d="M38.467 25.1345V25.4733" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M27.791 17.8787H18.4078C18.1928 17.8769 17.987 17.7907 17.8349 17.6386C17.6829 17.4865 17.5966 17.2807 17.5949 17.0657V0.677307H28.604L28.604 17.0657C28.6022 17.2807 28.516 17.4865 28.3639 17.6386C28.2118 17.7907 28.0061 17.8769 27.791 17.8787V17.8787Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path d="M23.0955 14.6063V14.2676" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.0955 10.7178V3.97681" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.71 3.55" />
    <path d="M23.0955 2.19494V1.8562" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M57.843 17.8784H48.487C48.2731 17.8749 48.069 17.7879 47.9184 17.636C47.7678 17.4841 47.6825 17.2793 47.6807 17.0654V0.677053H58.6831V17.0654C58.6822 17.1742 58.6597 17.2818 58.6169 17.3818C58.574 17.4818 58.5116 17.5723 58.4335 17.648C58.3553 17.7237 58.2628 17.783 58.1614 17.8226C58.06 17.8621 57.9518 17.8811 57.843 17.8784Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path d="M53.176 14.6063V14.2676" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M53.176 10.7178V3.97681" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.71 3.55" />
    <path d="M53.176 2.19494V1.8562" stroke="#8A55FB" strokeWidth="1.35497" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.34473 84.3466L13.2251 68.0056H62.4512L82.8774 84.8411" stroke="black" strokeWidth="1.35497" strokeMiterlimit="10" />
  </svg>
)

export const ConcreteIcon: FunctionComponent = () => (
  <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M80.8777 0.677002H5.12131C2.66678 0.677002 0.677002 2.66678 0.677002 5.12131V80.8777C0.677002 83.3322 2.66678 85.322 5.12131 85.322H80.8777C83.3322 85.322 85.322 83.3322 85.322 80.8777V5.12131C85.322 2.66678 83.3322 0.677002 80.8777 0.677002Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M20.2037 65.5805C23.5188 65.5805 26.2062 62.8931 26.2062 59.578C26.2062 56.2629 23.5188 53.5754 20.2037 53.5754C16.8886 53.5754 14.2012 56.2629 14.2012 59.578C14.2012 62.8931 16.8886 65.5805 20.2037 65.5805Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M32.8519 65.5805C36.167 65.5805 38.8544 62.8931 38.8544 59.578C38.8544 56.2629 36.167 53.5754 32.8519 53.5754C29.5368 53.5754 26.8494 56.2629 26.8494 59.578C26.8494 62.8931 29.5368 65.5805 32.8519 65.5805Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M64.138 65.5805C67.4531 65.5805 70.1405 62.8931 70.1405 59.578C70.1405 56.2629 67.4531 53.5754 64.138 53.5754C60.8229 53.5754 58.1355 56.2629 58.1355 59.578C58.1355 62.8931 60.8229 65.5805 64.138 65.5805Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M58.135 59.5779H54.5376V33.5285C54.5375 32.8157 54.8184 32.1315 55.3193 31.6242C55.8202 31.117 56.5008 30.8275 57.2137 30.8186H69.5439C70.0523 30.819 70.55 30.9646 70.9785 31.2383C71.4069 31.5119 71.7483 31.9022 71.9625 32.3633L78.6696 46.7327C79.2323 47.9327 79.5238 49.2419 79.5233 50.5673V57.5048C79.5233 58.0546 79.3048 58.5819 78.9161 58.9707C78.5273 59.3594 78 59.5779 77.4502 59.5779H70.1333"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path d="M54.5439 43.86L77.2871 43.7991" stroke="black" strokeWidth="1.35497" strokeMiterlimit="10" />
    <path d="M61.1233 30.8523L61.1978 43.8465" stroke="black" strokeWidth="1.35497" strokeMiterlimit="10" />
    <path
      d="M15.9274 39.3821C16.6383 39.3821 17.2146 38.8058 17.2146 38.0948C17.2146 37.3839 16.6383 36.8076 15.9274 36.8076C15.2164 36.8076 14.6401 37.3839 14.6401 38.0948C14.6401 38.8058 15.2164 39.3821 15.9274 39.3821Z"
      stroke="#8A55FB"
      strokeWidth="1.01623"
      strokeMiterlimit="10"
    />
    <path
      d="M20.0881 41.4551L22.9742 22.1332L23.1436 21.0018C23.1854 20.7254 23.3351 20.4767 23.5598 20.3104C23.7846 20.144 24.0661 20.0735 24.3427 20.1143L25.4403 20.2769C25.7167 20.3188 25.9654 20.4684 26.1317 20.6932C26.2981 20.9179 26.3686 21.1995 26.3278 21.4761L22.29 48.5077"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M40.5612 52.7219L39.4637 52.5728C39.3267 52.5538 39.1948 52.5077 39.0758 52.4372C38.9568 52.3668 38.8529 52.2733 38.7704 52.1624C38.6878 52.0514 38.6281 51.9252 38.5947 51.7909C38.5614 51.6567 38.5551 51.5172 38.5761 51.3805L39.5585 44.8292L42.614 24.3691C42.6298 24.2286 42.6736 24.0927 42.7429 23.9694C42.8122 23.8462 42.9055 23.7381 43.0173 23.6515C43.1291 23.565 43.2572 23.5017 43.3939 23.4656C43.5306 23.4294 43.6731 23.421 43.8131 23.441L44.9106 23.6103C45.0499 23.63 45.1838 23.6775 45.3044 23.75C45.425 23.8225 45.5298 23.9184 45.6126 24.0322C45.6954 24.1459 45.7545 24.2751 45.7863 24.4121C45.8182 24.5492 45.8222 24.6912 45.7981 24.8298L41.7332 51.8344C41.6913 52.1055 41.5455 52.3497 41.3268 52.5153C41.1081 52.6809 40.8335 52.755 40.5612 52.7219V52.7219Z"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M22.9737 22.1331L9.79664 25.5205C9.27513 25.6488 8.8035 25.9292 8.44163 26.326C8.07976 26.7229 7.84397 27.2183 7.76419 27.7494L6.31437 37.6678C6.24662 38.9279 6.99186 40.5539 7.53384 40.3777L12.7844 43.7652"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M45.7986 26.1509L51.4352 31.9976C51.7178 32.2948 51.9293 32.6522 52.0537 33.043C52.1781 33.4338 52.2122 33.8477 52.1534 34.2536L50.541 44.8766C50.479 45.2688 50.3316 45.6427 50.1091 45.9716C49.8867 46.3006 49.5947 46.5767 49.2537 46.7803L41.8691 51.1298"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path d="M26.2332 22.1331L42.5809 24.572" stroke="black" strokeWidth="1.35497" strokeMiterlimit="10" />
    <path d="M38.5701 51.5496L22.0598 49.1174" stroke="black" strokeWidth="1.35497" strokeMiterlimit="10" />
    <path
      d="M12.7432 52.8979L12.7838 36.1505C12.7838 35.7456 12.9442 35.3572 13.2299 35.0702C13.5155 34.7833 13.9033 34.6212 14.3082 34.6194H17.14C17.4798 34.6205 17.8097 34.7341 18.0781 34.9424C18.3466 35.1508 18.5384 35.4422 18.6237 35.7711L23.0748 53.0741"
      stroke="#8A55FB"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
    <path
      d="M14.2073 59.578H12.1003C11.811 59.578 11.5336 59.463 11.329 59.2585C11.1244 59.0539 11.0095 58.7765 11.0095 58.4872V54.6662C11.0095 54.3769 11.1244 54.0995 11.329 53.8949C11.5336 53.6904 11.811 53.5754 12.1003 53.5754H54.538V59.578H38.8474"
      stroke="black"
      strokeWidth="1.35497"
      strokeMiterlimit="10"
    />
  </svg>
)

export const YesGrilleIcon: FunctionComponent = () => (
  <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2837)">
      <path
        d="M80.8782 0.67749H5.12179C2.66727 0.67749 0.677483 2.66727 0.677483 5.12179V80.8782C0.677483 83.3327 2.66727 85.3225 5.12179 85.3225H80.8782C83.3327 85.3225 85.3225 83.3327 85.3225 80.8782V5.12179C85.3225 2.66727 83.3327 0.67749 80.8782 0.67749Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M73.5885 12.4116H12.4116V73.5886H73.5885V12.4116Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4115 12.4116H0.677483" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.3225 12.4116H73.5884" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4115 73.5884H0.677483" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.3225 73.5884H73.5884" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4115 33.0139H0.677483" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.3225 33.0139H73.5884" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4115 53.6162H0.677483" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.3225 53.6162H73.5884" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4116 0.67749V12.4115" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M73.5884 0.67749V12.4115" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M31.6318 0.67749V12.4115" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M52.6068 0.67749V12.4115" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M31.6318 73.5884V85.3224" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M52.6068 73.5884V85.3224" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4116 73.5884V85.3224" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M73.5884 73.5884V85.3224" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70.1808 15.8259H15.8261V70.1806H70.1808V15.8259Z" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70.174 52.512H15.8261" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70.174 33.7251H15.8261" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70.174 43.3184H15.8261" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70.174 24.1321H15.8261" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70.174 61.7122H15.8261" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M52.5932 15.8259V70.1738" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.7997 15.8259V70.1738" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M43.393 15.8259V70.1738" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.2066 15.8259V70.1738" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M61.7935 15.8259V70.1738" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.2066 15.8259V70.1738" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1_2837">
        <rect width="86" height="86" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export const NoGrilleIcon: FunctionComponent = () => (
  <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2872)">
      <path
        d="M80.8782 0.67749H5.12179C2.66727 0.67749 0.67749 2.66727 0.67749 5.12179V80.8782C0.67749 83.3327 2.66727 85.3225 5.12179 85.3225H80.8782C83.3327 85.3225 85.3225 83.3327 85.3225 80.8782V5.12179C85.3225 2.66727 83.3327 0.67749 80.8782 0.67749Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M73.5885 12.4116H12.4116V73.5886H73.5885V12.4116Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4115 12.4116H0.67749" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.3225 12.4116H73.5884" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4115 73.5884H0.67749" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.3225 73.5884H73.5884" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4115 33.0139H0.67749" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.3225 33.0139H73.5884" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4115 53.6162H0.67749" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85.3225 53.6162H73.5884" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4116 0.67749V12.4115" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M73.5884 0.67749V12.4115" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M31.6318 0.67749V12.4115" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M52.6068 0.67749V12.4115" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M31.6318 73.5884V85.3224" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M52.6068 73.5884V85.3224" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.4116 73.5884V85.3224" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M73.5884 73.5884V85.3224" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1_2872">
        <rect width="86" height="86" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const StraightInletIcon: FunctionComponent = () => (
  <svg width="95" height="66" viewBox="0 0 95 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.9" clipPath="url(#clip0_1_3136)">
      <path d="M85.271 1.10498L93.7058 36.5947L85.271 27.8113L93.7058 64.7106" stroke="#161616" strokeWidth="1.3733" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.98242 15.7236H87.9519" stroke="#161616" strokeWidth="2.75411" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M90.1117 52.4412H1.98242" stroke="#161616" strokeWidth="2.75411" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.4541 15.7236V52.441" stroke="#161616" strokeWidth="1.3733" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M29.1377 34.0254L61.452 34.1391" stroke="#8A55FB" strokeWidth="1.3733" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M56.8282 39.808L55.8809 38.785L60.9129 34.1394L55.9188 29.4559L56.8661 28.4404L62.9515 34.147L56.8282 39.808Z" fill="#8A55FB" />
    </g>
    <defs>
      <clipPath id="clip0_1_3136">
        <rect width="93.8055" height="65" fill="white" transform="translate(0.597168 0.407715)" />
      </clipPath>
    </defs>
  </svg>
)

export const FlangedInletIcon: FunctionComponent = () => (
  <svg width="92" height="64" viewBox="0 0 92 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M83.062 0.839844L91.2977 35.5109L83.062 26.9295L91.2977 62.9757" stroke="#161616" strokeWidth="1.37512" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M85.6825 15.1244H1.70264V5.73901" stroke="#161616" strokeWidth="2.75775" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M87.7866 50.9905H1.70264V60.5186" stroke="#161616" strokeWidth="2.75775" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.13965 15.1243V50.9901" stroke="#161616" strokeWidth="1.37512" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M34.9019 33.0161L66.4394 33.1363" stroke="#8A55FB" strokeWidth="1.37512" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M61.8538 38.7491L60.9146 37.7346L65.9116 33.1359L60.9521 28.492L61.8914 27.4851L67.9329 33.1434L61.8538 38.7491Z" fill="#8A55FB" />
  </svg>
)

export const ConicalInletIcon: FunctionComponent = () => (
  <svg width="99" height="66" viewBox="0 0 99 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_3160)">
      <path d="M89.0386 0.952881L97.5141 36.6101L89.0386 27.7882L97.5141 64.8619" stroke="#161616" strokeWidth="1.44014" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M91.7262 15.6455H22.033L2.20947 4.17163" stroke="#161616" strokeWidth="2.88814" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M93.8982 52.5303H21.8048L2.20947 63.9569" stroke="#161616" strokeWidth="2.88814" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.04492 5.22607V62.8945" stroke="#161616" strokeWidth="1.44014" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.4971 34.0525L71.912 34.1705" stroke="#8A55FB" strokeWidth="1.44014" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M67.1116 40.0492L66.1279 38.9947L71.3533 34.1706L66.1673 29.3072L67.151 28.2527L73.4781 34.1785L67.1116 40.0492Z" fill="#8A55FB" />
      <path d="M22.0339 15.6455L21.8057 52.5303" stroke="#161616" strokeWidth="1.39292" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5469 26.3562C12.1215 23.0832 13.1993 19.9189 14.7419 16.9756" stroke="#8A55FB" strokeWidth="1.44014" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.0784 23.9005L16.7013 24.3254L14.6158 17.5182L7.98961 20.0916L7.47021 18.7459L15.5444 15.6138L18.0784 23.9005Z" fill="#8A55FB" />
      <path d="M14.978 50.9647C13.1287 47.5348 11.9005 43.8049 11.3501 39.9473" stroke="#8A55FB" strokeWidth="1.44014" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.228 43.9915L16.843 43.5823L14.8362 50.4052L8.17852 47.9027L7.66699 49.2563L15.7806 52.3018L18.228 43.9915Z" fill="#8A55FB" />
      <path
        d="M10.5596 32.3373C10.8348 32.4958 11.0608 32.7273 11.2128 33.0062C11.3674 33.3136 11.4457 33.6537 11.441 33.9978C11.4495 34.3501 11.3625 34.6982 11.1892 35.0051C11.0275 35.2941 10.7874 35.5314 10.4966 35.6897C10.1901 35.8638 9.84175 35.9509 9.48932 35.9416C9.16379 35.9552 8.83972 35.8909 8.54406 35.754C8.24839 35.6172 7.98969 35.4116 7.78948 35.1546C7.35021 34.525 7.1392 33.7643 7.19139 32.9983C7.17965 32.4146 7.27294 31.8335 7.46683 31.2828C7.61731 30.8494 7.89077 30.4693 8.25379 30.1889C8.62591 29.9337 9.06983 29.8041 9.52079 29.819C9.87005 29.8158 10.2169 29.8771 10.5438 30C10.866 30.1172 11.1606 30.2994 11.4095 30.5352L11.1026 31.1726C10.6716 30.7757 10.114 30.5443 9.52866 30.5194C9.30182 30.5128 9.07754 30.5688 8.88041 30.6812C8.68329 30.7937 8.52094 30.9582 8.41118 31.1569C8.11293 31.7173 7.97411 32.3488 8.00983 32.9826C8.13699 32.6973 8.35208 32.4601 8.62366 32.3058C8.90743 32.1399 9.23148 32.0555 9.56014 32.0619C9.91251 32.0582 10.2588 32.1537 10.5596 32.3373V32.3373ZM10.2841 34.8792C10.3937 34.7594 10.4786 34.6192 10.534 34.4666C10.5893 34.3141 10.6141 34.1521 10.6068 33.9899C10.6137 33.8302 10.5887 33.6707 10.5333 33.5207C10.4779 33.3707 10.3932 33.2333 10.2841 33.1164C10.1765 33.0026 10.0455 32.9134 9.90016 32.855C9.75482 32.7966 9.59855 32.7703 9.4421 32.778C9.28253 32.7738 9.12373 32.8016 8.97506 32.8597C8.82639 32.9178 8.69085 33.0051 8.57644 33.1164C8.46341 33.2312 8.37508 33.3679 8.31688 33.5181C8.25869 33.6684 8.23186 33.8289 8.23805 33.9899C8.2266 34.1527 8.24998 34.3161 8.30661 34.4692C8.36325 34.6223 8.45185 34.7615 8.56652 34.8777C8.68118 34.9938 8.81928 35.0842 8.97161 35.1428C9.12395 35.2014 9.28702 35.2269 9.44997 35.2176C9.60518 35.2246 9.76009 35.198 9.90407 35.1396C10.048 35.0812 10.1777 34.9924 10.2841 34.8792V34.8792Z"
        fill="black"
      />
      <path
        d="M12.7408 35.1624C12.3841 34.4601 12.1982 33.6836 12.1982 32.8959C12.1982 32.1083 12.3841 31.3317 12.7408 30.6295C12.9278 30.3906 13.1667 30.1975 13.4394 30.0647C13.7121 29.9318 14.0114 29.8628 14.3147 29.8628C14.6181 29.8628 14.9174 29.9318 15.1901 30.0647C15.4628 30.1975 15.7017 30.3906 15.8887 30.6295C16.2503 31.3316 16.439 32.11 16.439 32.8999C16.439 33.6897 16.2503 34.4681 15.8887 35.1702C15.7017 35.4091 15.4628 35.6022 15.1901 35.7351C14.9174 35.8679 14.6181 35.9369 14.3147 35.9369C14.0114 35.9369 13.7121 35.8679 13.4394 35.7351C13.1667 35.6022 12.9278 35.4091 12.7408 35.1702V35.1624ZM15.2748 34.6745C15.5148 34.111 15.6198 33.4993 15.5817 32.8881C15.618 32.2802 15.5157 31.6721 15.2827 31.1095C15.1935 30.9338 15.0554 30.7876 14.8851 30.6885C14.7148 30.5894 14.5194 30.5416 14.3226 30.5508C14.127 30.5424 13.9332 30.5916 13.7653 30.6923C13.5975 30.793 13.4629 30.9408 13.3782 31.1174C13.1388 31.6755 13.0336 32.282 13.0713 32.8881C13.0332 33.4993 13.1383 34.111 13.3782 34.6745C13.4609 34.8531 13.5948 35.0032 13.7629 35.1055C13.9311 35.2078 14.1259 35.2577 14.3226 35.2489C14.5202 35.2572 14.7158 35.2072 14.8852 35.105C15.0545 35.0029 15.19 34.8531 15.2748 34.6745V34.6745Z"
        fill="black"
      />
      <path
        d="M17.4861 30.9438C17.4019 30.866 17.3358 30.7708 17.2923 30.6647C17.2488 30.5587 17.229 30.4445 17.2342 30.33C17.2301 30.2168 17.2505 30.1041 17.294 29.9995C17.3374 29.8949 17.4029 29.801 17.4861 29.724C17.5681 29.6439 17.6654 29.581 17.7722 29.5391C17.879 29.4972 17.9931 29.4771 18.1078 29.4801C18.2213 29.4768 18.3343 29.4968 18.4398 29.5387C18.5454 29.5807 18.6413 29.6437 18.7216 29.724C18.8061 29.8003 18.8731 29.8939 18.9179 29.9985C18.9628 30.1031 18.9844 30.2162 18.9813 30.33C18.9855 30.4451 18.9645 30.5597 18.9196 30.6658C18.8747 30.7718 18.8071 30.8667 18.7216 30.9438C18.6419 31.0256 18.5464 31.0901 18.4408 31.1334C18.3352 31.1767 18.2219 31.1979 18.1078 31.1957C17.9926 31.1972 17.8783 31.1756 17.7715 31.1324C17.6648 31.0892 17.5677 31.0251 17.4861 30.9438ZM18.3911 30.6369C18.4657 30.5525 18.5051 30.4426 18.5012 30.33C18.5022 30.2249 18.4628 30.1235 18.3911 30.0467C18.3544 30.0087 18.3104 29.9786 18.2617 29.9583C18.2129 29.938 18.1605 29.9279 18.1078 29.9286C18.0548 29.9264 18.0019 29.9358 17.953 29.9562C17.904 29.9766 17.8601 30.0075 17.8244 30.0467C17.7864 30.0833 17.7563 30.1274 17.736 30.1761C17.7157 30.2248 17.7056 30.2772 17.7064 30.33C17.7043 30.3865 17.7136 30.4428 17.7339 30.4956C17.7542 30.5483 17.785 30.5964 17.8244 30.6369C17.8601 30.6761 17.904 30.707 17.953 30.7274C18.0019 30.7478 18.0548 30.7572 18.1078 30.755C18.16 30.7567 18.212 30.7479 18.2607 30.729C18.3094 30.71 18.3538 30.6814 18.3911 30.6448V30.6369Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_3160">
        <rect width="97.4572" height="65.3571" fill="white" transform="translate(0.771484 0.229248)" />
      </clipPath>
    </defs>
  </svg>
)

export const OutletIcon: FunctionComponent = () => (
  <svg width="102" height="70" viewBox="0 0 102 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.44727 1.4436L10.3163 38.795L1.44727 29.5477L10.3163 68.3719" stroke="#161616" strokeWidth="1.47281" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M99.5522 16.8318H5.87988" stroke="#161616" strokeWidth="2.95367" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.13867 55.4626H99.5495" stroke="#161616" strokeWidth="2.95367" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M98.0059 16.8318V55.4629" stroke="#161616" strokeWidth="1.47281" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M31.6182 36.0828L65.5974 36.2035" stroke="#8A55FB" strokeWidth="1.47281" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M60.6874 42.2236L59.6895 41.1371L65.0334 36.2036L59.7216 31.2298L60.7357 30.1514L67.1984 36.2116L60.6874 42.2236Z" fill="#8A55FB" />
  </svg>
)

export const OutletDiffuserIcon: FunctionComponent = () => (
  <svg width="94" height="64" viewBox="0 0 94 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.967773 0.941162L9.17993 35.5007L0.967773 26.9463L9.17993 62.8745" stroke="#161616" strokeWidth="1.69237" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M92.0315 5.64819L38.9761 15.1736H5.07324" stroke="#161616" strokeWidth="3.39399" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.16309 50.9265H38.9759L92.0313 60.5351" stroke="#161616" strokeWidth="3.39399" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M90.3184 5.95337V60.2202" stroke="#161616" strokeWidth="1.69237" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.6826 32.9851L32.6316 33.0498" stroke="#8A55FB" strokeWidth="1.69237" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26.9895 39.9676L25.8428 38.7191L31.9834 33.0501L25.8798 27.3349L27.045 26.0957L34.4711 33.0594L26.9895 39.9676Z" fill="#8A55FB" />
    <path d="M39.052 15.1741L38.9873 50.9265" stroke="#161616" strokeWidth="1.69237" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const VerticalContractionIcon: FunctionComponent = () => (
  <svg width="107" height="95" viewBox="0 0 107 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.917969 1.63354L12.5265 50.5138L4.12479 41.7577L16.1136 94.1819" stroke="#161616" strokeWidth="1.5818" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M100.353 29.7171H50.859L50.7812 11.79H4.02734" stroke="#161616" strokeWidth="3.17225" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M51.5156 30.054V66.6342" stroke="#161616" strokeWidth="1.5818" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M56.0273 47.8689L81.7338 47.964" stroke="#8A55FB" strokeWidth="1.383" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M77.1297 53.6087L76.1875 52.5887L81.2009 47.9643L76.2221 43.2967L77.1729 42.2854L83.2408 47.973L77.1297 53.6087Z" fill="#8A55FB" />
    <path d="M14.5654 84.2244H50.7135L50.7913 66.2974H102.515" stroke="#161616" strokeWidth="3.17225" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M97.741 15.7317L106.082 50.7993L97.6719 42.0432L105.849 77.8023" stroke="#161616" strokeWidth="1.5818" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const GradualContractionIcon: FunctionComponent = () => (
  <svg width="103" height="80" viewBox="0 0 103 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_3462)">
      <path d="M1.23242 0.840576L11.0347 42.1054L3.93996 34.7098L14.0598 78.9747" stroke="#161616" strokeWidth="1.52926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M96.9401 24.4646L62.9956 24.381L25.7503 8.7124H3.69727" stroke="#161616" strokeWidth="3.06687" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M62.9375 55.2841V24.4065" stroke="#161616" strokeWidth="1.52926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M68.2256 39.8662L89.5349 39.9414" stroke="#8A55FB" strokeWidth="1.52926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M84.4362 46.1919L83.3916 45.0637L88.9487 39.9411L83.4334 34.7768L84.478 33.657L91.1967 39.9495L84.4362 46.1919Z" fill="#8A55FB" />
      <path d="M98.7594 55.3507L62.9932 55.2671L25.2548 71.036H12.8369" stroke="#161616" strokeWidth="3.06687" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M94.7411 12.657L101.777 42.2644L94.6826 34.8772L101.577 65.0612" stroke="#161616" strokeWidth="1.52926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.2578 71.0361L25.3832 8.96338" stroke="#161616" strokeWidth="1.52926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.1436 31.6682C39.7766 28.1115 40.9518 24.6732 42.6283 21.4731" stroke="#8A55FB" strokeWidth="1.52926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.1387 28.8268L44.6763 29.2781L42.4702 22.0496L35.4256 24.7739L34.874 23.3449L43.4562 20.0273L46.1387 28.8268Z" fill="#8A55FB" />
      <path d="M42.8549 58.359C40.8396 54.622 39.5067 50.5555 38.9189 46.3506" stroke="#8A55FB" strokeWidth="1.52926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.299 50.9553L44.8282 50.5208L42.7056 57.7743L35.6276 55.1252L35.0928 56.5626L43.7168 59.7799L46.299 50.9553Z" fill="#8A55FB" />
      <path
        d="M37.2565 40.0496V40.6263H36.5212V41.6625H35.8109V40.6263H33.5713V40.0914L35.9446 36.6736H36.5212V40.0162L37.2565 40.0496ZM34.2816 40.0496H35.8109V37.8435L34.2816 40.0496Z"
        fill="black"
      />
      <path
        d="M40.6233 38.7042C40.8597 38.8306 41.0521 39.0258 41.1749 39.2641C41.3058 39.523 41.3717 39.8098 41.3671 40.0998C41.3691 40.3926 41.2942 40.6807 41.1498 40.9354C41.0071 41.181 40.7948 41.3787 40.5398 41.5037C40.2479 41.6433 39.9272 41.712 39.6038 41.7043C39.2932 41.7051 38.9849 41.6514 38.693 41.5455C38.4178 41.4492 38.1649 41.2986 37.9492 41.1026L38.2083 40.5678C38.598 40.909 39.0944 41.104 39.6122 41.1193C39.7536 41.128 39.8953 41.108 40.0289 41.0607C40.1624 41.0133 40.285 40.9394 40.3893 40.8435C40.4843 40.7454 40.5578 40.6286 40.6053 40.5006C40.6528 40.3726 40.6731 40.2361 40.6651 40.0998C40.671 39.965 40.6496 39.8303 40.6022 39.704C40.5548 39.5776 40.4824 39.4621 40.3893 39.3644C40.2911 39.2692 40.1745 39.1949 40.0466 39.1461C39.9188 39.0972 39.7824 39.0749 39.6456 39.0803C39.4378 39.0757 39.2319 39.1202 39.0446 39.2103C38.8573 39.3003 38.6938 39.4333 38.5676 39.5984H38.0996V36.6736H41.1582V37.2585H38.8183V38.8296C39.0945 38.6003 39.4457 38.4812 39.8044 38.4953C40.0908 38.4936 40.3728 38.5656 40.6233 38.7042Z"
        fill="black"
      />
      <path
        d="M42.2105 37.5428C42.1408 37.4777 42.0855 37.3987 42.048 37.3109C42.0106 37.2231 41.992 37.1285 41.9932 37.0331C41.9925 36.9377 42.0114 36.8433 42.0487 36.7556C42.0861 36.6678 42.1412 36.5888 42.2105 36.5233C42.3466 36.396 42.5255 36.3244 42.7119 36.3228C42.8064 36.3223 42.9001 36.3406 42.9876 36.3764C43.0751 36.4123 43.1546 36.465 43.2216 36.5317C43.291 36.5971 43.346 36.6762 43.3834 36.7639C43.4208 36.8516 43.4397 36.9461 43.4389 37.0414C43.4367 37.2328 43.3592 37.4156 43.223 37.5501C43.0869 37.6847 42.9033 37.7601 42.7119 37.7601C42.6181 37.7588 42.5255 37.7389 42.4395 37.7016C42.3534 37.6643 42.2756 37.6103 42.2105 37.5428ZM42.9626 37.2838C42.9954 37.251 43.0212 37.2118 43.0385 37.1687C43.0557 37.1256 43.064 37.0795 43.0629 37.0331C43.0634 36.988 43.0547 36.9433 43.0375 36.9017C43.0203 36.86 42.9948 36.8223 42.9626 36.7907C42.9332 36.7609 42.898 36.7374 42.8592 36.7216C42.8204 36.7058 42.7788 36.698 42.737 36.6988C42.6921 36.6965 42.6472 36.7035 42.6051 36.7193C42.563 36.7352 42.5247 36.7596 42.4925 36.791C42.4604 36.8224 42.435 36.8601 42.4181 36.9018C42.4012 36.9434 42.3931 36.9881 42.3943 37.0331C42.3932 37.0795 42.4015 37.1256 42.4187 37.1687C42.436 37.2118 42.4618 37.251 42.4946 37.2838C42.5252 37.3172 42.5628 37.3435 42.6046 37.3609C42.6465 37.3782 42.6917 37.3861 42.737 37.384C42.7797 37.3854 42.8222 37.3771 42.8612 37.3597C42.9003 37.3423 42.9349 37.3164 42.9626 37.2838Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_3462">
        <rect width="102.076" height="79.6718" fill="white" transform="translate(0.461914 0.0717773)" />
      </clipPath>
    </defs>
  </svg>
)

export const VerticalExpansionIcon: FunctionComponent = () => (
  <svg width="105" height="94" viewBox="0 0 105 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_3607)">
      <path d="M15.0615 46.8059L40.084 46.8993" stroke="#8A55FB" strokeWidth="1.55329" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.9047 53.2398L33.8438 52.0939L39.4882 46.8908L33.8862 41.6537L34.9472 40.5164L41.7715 46.8993L34.9047 53.2398Z" fill="#8A55FB" />
      <path d="M89.125 1.48877L100.524 49.4626L92.2825 40.8728L104.038 92.3352" stroke="#161616" strokeWidth="1.55329" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.08789 28.9048H48.2746L48.3425 11.3008H90.7143" stroke="#161616" strokeWidth="3.11508" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M47.627 29.2358V65.1398" stroke="#161616" strokeWidth="1.55329" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M100.771 82.4043H48.4086L48.3407 64.8088H7.04688" stroke="#161616" strokeWidth="3.11508" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.03079 15.3242L9.20468 49.7513L0.962891 41.153L8.984 76.2507" stroke="#161616" strokeWidth="1.55329" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1_3607">
        <rect width="104.639" height="92.3998" fill="white" transform="translate(0.180664 0.707764)" />
      </clipPath>
    </defs>
  </svg>
)

export const GradualExpansionIcon: FunctionComponent = () => (
  <svg width="103" height="74" viewBox="0 0 103 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_3620)">
      <path d="M11.5703 36.5747L26.133 36.6234" stroke="#8A55FB" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.1807 42.6956L20.166 41.5998L25.5641 36.6238L20.2066 31.6072L21.2213 30.5276L27.7477 36.6319L21.1807 42.6956Z" fill="#8A55FB" />
      <path d="M89.8965 1.4668L98.7932 38.8963L92.3561 32.1913L101.529 72.3483" stroke="#161616" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.68555 23.5706L30.7507 23.5057L78.4731 9.28394H90.9415" stroke="#161616" strokeWidth="2.9791" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.6875 51.4054V23.384" stroke="#161616" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.3916 51.5839L30.7602 51.5271L78.5881 65.8219H99.4094" stroke="#161616" strokeWidth="2.9791" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.52234 12.8313L7.91077 39.6919L1.47363 32.9869L7.73218 60.3671" stroke="#161616" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M78.6273 65.5542L78.5137 9.23535" stroke="#161616" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1_3620">
        <rect width="101.549" height="72.3751" fill="white" transform="translate(0.725586 0.720215)" />
      </clipPath>
    </defs>
  </svg>
)

export const RectangularBendIcon: FunctionComponent = () => (
  <svg width="103" height="74" viewBox="0 0 103 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5684 36.5747L26.1311 36.6234" stroke="#8A55FB" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.1788 42.6956L20.1641 41.5998L25.5622 36.6238L20.2047 31.6072L21.2194 30.5276L27.7458 36.6319L21.1788 42.6956Z" fill="#8A55FB" />
    <path d="M89.8965 1.4668L98.7932 38.8963L92.3561 32.1913L101.529 72.3483" stroke="#161616" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.68555 23.5706L30.7507 23.5057L78.4731 9.28394H90.9415" stroke="#161616" strokeWidth="2.9791" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30.6875 51.4054V23.384" stroke="#161616" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.39062 51.5839L30.7592 51.5271L78.5871 65.8219H99.4084" stroke="#161616" strokeWidth="2.9791" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.52137 12.8313L7.9098 39.6919L1.47266 32.9869L7.73121 60.3671" stroke="#161616" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M78.6273 65.5542L78.5137 9.23535" stroke="#161616" strokeWidth="1.48549" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const RectangularBendTwoVanesIcon: FunctionComponent = () => (
  <svg width="99" height="100" viewBox="0 0 99 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.1416L9.92719 36.619L1.5 27.8324L9.92719 64.7191" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.4141 34.7087L49.3283 34.8317" stroke="#8A55FB" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M43.5595 41.897L42.3867 40.6201L48.6669 34.8223L42.4245 28.9866L43.6163 27.7192L51.2112 34.8412L43.5595 41.897Z" fill="#8A55FB" />
    <path d="M33.9219 98.6738L69.3993 90.2466L60.6127 98.6738L97.4994 90.2466" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.23047 16.502H84.1276V92.2048" stroke="#161616" strokeWidth="3.67" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.02344 52.3193H49.119V93.8027" stroke="#161616" strokeWidth="3.67" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M63.3281 22.4131C69.7666 25.4596 74.9767 30.6063 78.1017 37.007" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M54.1328 38.9932C57.3868 40.5309 60.0215 43.1289 61.6047 46.361" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const RectangularBendTwoPlusVanesIcon: FunctionComponent = () => (
  <svg width="99" height="100" viewBox="0 0 99 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.1416L9.92719 36.619L1.5 27.8324L9.92719 64.7191" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.4141 34.7087L49.3283 34.8317" stroke="#8A55FB" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M43.5595 41.897L42.3867 40.6201L48.6669 34.8223L42.4245 28.9866L43.6163 27.7192L51.2112 34.8412L43.5595 41.897Z" fill="#8A55FB" />
    <path d="M33.9219 98.6738L69.3993 90.2466L60.6127 98.6738L97.4994 90.2466" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.23047 16.502H84.1276V92.2048" stroke="#161616" strokeWidth="3.67" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.02344 52.3193H49.119V93.8027" stroke="#161616" strokeWidth="3.67" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M49.1289 45.4057C49.9123 45.2819 50.7138 45.3413 51.4704 45.5791C52.2271 45.8169 52.9183 46.2268 53.4901 46.7765C54.0618 47.3262 54.4984 48.0009 54.7657 48.7477C55.033 49.4944 55.1238 50.2929 55.0308 51.0806"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.9336 41.7077C53.7187 41.604 54.517 41.6758 55.2709 41.9179C56.0249 42.1599 56.7158 42.5662 57.2938 43.1075C57.8474 43.6685 58.2688 44.3459 58.5273 45.0904C58.7858 45.8349 58.8749 46.6277 58.7882 47.411"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.5273 38.0095C57.3108 37.8857 58.1122 37.945 58.8689 38.1829C59.6255 38.4207 60.3168 38.8305 60.8885 39.3803C61.4602 39.93 61.8969 40.6047 62.1642 41.3514C62.4315 42.0982 62.5222 42.8967 62.4292 43.6843"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.3281 33.8859C61.1115 33.7621 61.913 33.8215 62.6696 34.0593C63.4263 34.2972 64.1176 34.707 64.6893 35.2567C65.261 35.8065 65.6976 36.4812 65.9649 37.2279C66.2323 37.9746 66.323 38.7731 66.23 39.5608"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.2617 30.0361C65.0498 29.9295 65.8517 29.9998 66.6092 30.242C67.3667 30.4841 68.0607 30.892 68.6408 31.4359C69.1944 31.9969 69.6158 32.6743 69.8743 33.4188C70.1329 34.1632 70.222 34.956 70.1352 35.7393"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.2266 26.3095C69.01 26.1857 69.8114 26.2451 70.5681 26.4829C71.3247 26.7207 72.016 27.1306 72.5877 27.6803C73.1594 28.2301 73.5961 28.9047 73.8634 29.6515C74.1307 30.3982 74.2214 31.1967 74.1284 31.9844"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.2305 22.3847C73.0139 22.2609 73.8153 22.3203 74.572 22.5581C75.3286 22.7959 76.0199 23.2058 76.5916 23.7555C77.1633 24.3052 77.6 24.9799 77.8673 25.7267C78.1346 26.4734 78.2253 27.2719 78.1323 28.0596"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.207 18.7055C76.9905 18.5817 77.7919 18.6411 78.5485 18.8789C79.3052 19.1167 79.9965 19.5266 80.5682 20.0763C81.1399 20.626 81.5765 21.3007 81.8439 22.0475C82.1112 22.7942 82.2019 23.5927 82.1089 24.3804"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const RectangularCurveIcon: FunctionComponent = () => (
  <svg width="99" height="100" viewBox="0 0 99 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.1416L9.92719 36.619L1.5 27.8324L9.92719 64.7191" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.3242 34.7087L38.8964 34.7939" stroke="#8A55FB" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.1276 41.8682L31.9453 40.5913L38.2255 34.7935L31.9926 28.9578L33.1749 27.6904L40.7792 34.8029L33.1276 41.8682Z" fill="#8A55FB" />
    <path d="M33.9219 98.6738L69.3993 90.2466L60.6127 98.6738L97.4994 90.2466" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.23047 16.502H43.6374C54.376 16.502 64.6749 20.7679 72.2683 28.3613C79.8617 35.9547 84.1276 46.2535 84.1276 56.9922V92.2048"
      stroke="#161616"
      strokeWidth="3.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.02344 52.3193H44.4278C45.682 52.3193 46.8849 52.8176 47.7717 53.7045C48.6586 54.5913 49.1568 55.7942 49.1568 57.0484V93.8405"
      stroke="#161616"
      strokeWidth="3.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.3516 37.8013C46.8831 37.7003 49.4084 38.1212 51.7703 39.0377C54.1323 39.9543 56.2805 41.347 58.0813 43.1291C59.882 44.9113 61.297 47.0449 62.238 49.3972C63.1791 51.7495 63.6262 54.2702 63.5516 56.8027"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const RectangularCurveTwoVanesIcon: FunctionComponent = () => (
  <svg width="99" height="100" viewBox="0 0 99 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.1416L9.92719 36.619L1.5 27.8324L9.92719 64.7191" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.3242 34.7087L38.8964 34.7939" stroke="#8A55FB" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.1276 41.8682L31.9453 40.5913L38.2255 34.7935L31.9926 28.9578L33.1749 27.6904L40.7792 34.8029L33.1276 41.8682Z" fill="#8A55FB" />
    <path d="M33.9219 98.6738L69.3993 90.2466L60.6127 98.6738L97.4994 90.2466" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.23047 16.502H43.6374C54.376 16.502 64.6749 20.7679 72.2683 28.3613C79.8617 35.9547 84.1276 46.2535 84.1276 56.9922V92.2048"
      stroke="#161616"
      strokeWidth="3.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.02344 52.3193H44.4561C45.7104 52.3193 46.9132 52.8176 47.8001 53.7045C48.687 54.5913 49.1852 55.7942 49.1852 57.0484V93.8405"
      stroke="#161616"
      strokeWidth="3.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.3281 37.8015C46.8597 37.7006 49.3849 38.1214 51.7469 39.038C54.1089 39.9546 56.257 41.3472 58.0578 43.1294C59.8586 44.9115 61.2735 47.0451 62.2146 49.3974C63.1557 51.7497 63.6028 54.2705 63.5281 56.8029"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.1758 24.8815C49.1023 24.7255 53.0189 25.3787 56.6823 26.8004C60.3457 28.2222 63.6776 30.3822 66.4708 33.1462C69.2641 35.9102 71.4591 39.2191 72.9194 42.8673C74.3796 46.5155 75.0741 50.4251 74.9594 54.353"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const RectangularCurveTwoPlusVanesIcon: FunctionComponent = () => (
  <svg width="99" height="100" viewBox="0 0 99 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.1416L9.92719 36.619L1.5 27.8324L9.92719 64.7191" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.3242 34.7087L38.8964 34.7939" stroke="#8A55FB" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.1276 41.8682L31.9453 40.5913L38.2255 34.7935L31.9926 28.9578L33.1749 27.6904L40.7792 34.8029L33.1276 41.8682Z" fill="#8A55FB" />
    <path d="M33.9219 98.6738L69.3993 90.2466L60.6127 98.6738L97.4994 90.2466" stroke="#161616" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.23047 16.502H43.6374C54.3744 16.502 64.6718 20.7666 72.2649 28.3579C79.8581 35.9493 84.1251 46.2457 84.1276 56.9827V92.1954"
      stroke="#161616"
      strokeWidth="3.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.04688 52.3193H44.4607C45.7149 52.3193 46.9178 52.8176 47.8046 53.7045C48.6915 54.5913 49.1897 55.7942 49.1897 57.0484V93.8405"
      stroke="#161616"
      strokeWidth="3.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.2148 49.104C48.843 49.0358 49.4784 49.099 50.0809 49.2895C50.6833 49.48 51.2396 49.7937 51.7143 50.2106C52.2194 50.6937 52.6079 51.2853 52.8504 51.9408C53.093 52.5963 53.1831 53.2983 53.1142 53.9938"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9141 45.4057C52.5415 45.3368 53.1764 45.3988 53.7788 45.5876C54.3811 45.7765 54.9377 46.0881 55.4136 46.5028C55.9175 46.9867 56.3052 47.5785 56.5477 48.2338C56.7901 48.889 56.881 49.5907 56.8134 50.286"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.1367 41.5845C55.7642 41.5162 56.3989 41.5785 57.0011 41.7673C57.6034 41.9561 58.1601 42.2674 58.6362 42.6816C59.1385 43.1668 59.5251 43.7587 59.7674 44.4137C60.0097 45.0686 60.1015 45.7696 60.036 46.4648"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.7695 38.3028C59.3977 38.2335 60.0335 38.2961 60.6361 38.4867C61.2387 38.6772 61.7949 38.9915 62.269 39.4094C62.7747 39.8924 63.1642 40.4838 63.4083 41.1391C63.6524 41.7944 63.7447 42.4965 63.6783 43.1926"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.4102 34.7839C63.0378 34.7097 63.6741 34.7692 64.2772 34.9582C64.8803 35.1473 65.4366 35.4618 65.9097 35.881C66.4141 36.3645 66.8023 36.9563 67.0447 37.6116C67.2872 38.267 67.3777 38.9688 67.3095 39.6643"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.0156 31.3038C66.644 31.2318 67.2804 31.2913 67.8845 31.4786C68.4886 31.6658 69.0471 31.9767 69.5246 32.3915C70.0279 32.8759 70.4153 33.4677 70.6577 34.1228C70.9001 34.778 70.9913 35.4794 70.9244 36.1748"
      stroke="#161616"
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CircularPerpendicularBendIcon: FunctionComponent = () => (
  <svg width="95" height="92" viewBox="0 0 95 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.7695 19.594L55.5695 19.724" stroke="#8A55FB" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M49.4705 27.1939L48.2305 25.8539L54.8705 19.7239L48.2705 13.5439L49.5305 12.2039L57.5605 19.7339L49.4705 27.1939Z" fill="#8A55FB" />
    <path
      d="M7.05078 2.34399H92.3508V80.574C92.356 81.8814 91.8666 83.1424 90.9808 84.104C88.7577 86.5548 85.747 88.1504 82.4708 88.614C80.306 88.8495 78.116 88.6022 76.0583 87.8898C74.0006 87.1774 72.1265 86.0176 70.5708 84.494C68.3819 82.8019 65.7408 81.7962 62.9808 81.604C59.6108 81.444 54.6608 81.604 54.1008 85.094C54.0462 86.1416 54.4053 87.1687 55.1008 87.954C58.7908 92.294 71.6308 86.214 72.3708 85.954"
      stroke="#161616"
      strokeWidth="3.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.5312 38.7839H53.9813V85.0939" stroke="#161616" strokeWidth="3.67" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.6097 38.7839C10.3146 38.7912 9.06067 38.3294 8.0797 37.4839C5.65774 35.4045 4.05829 32.5284 3.5697 29.3739C2.6597 22.9139 7.1697 18.5339 7.6897 18.0439C9.36185 15.9773 10.357 13.4459 10.5397 10.7939C10.6997 7.58387 10.5397 2.86387 7.0497 2.34387C6.01705 2.27303 4.99569 2.59444 4.1897 3.24387C-0.150299 6.76387 5.9797 18.9839 6.2397 19.6939"
      stroke="#161616"
      strokeWidth="3.58"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M53.9805 38.784L92.3605 2.30396" stroke="#161616" strokeWidth="1.83" strokeMiterlimit="10" />
  </svg>
)

export const CircularCurvedBendIcon: FunctionComponent = () => (
  <svg width="95" height="92" viewBox="0 0 95 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.7695 19.5991L55.5695 19.7191" stroke="#8A55FB" strokeWidth="1.83" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M49.4705 27.199L48.2305 25.849L54.8705 19.719L48.2705 13.539L49.5305 12.199L57.5605 19.729L49.4705 27.199Z" fill="#8A55FB" />
    <path
      d="M7.05078 2.33887H74.5908L92.3508 17.5389V80.5389C92.356 81.8463 91.8666 83.1072 90.9808 84.0689C88.7577 86.5197 85.747 88.1152 82.4708 88.5789C80.306 88.8144 78.116 88.5671 76.0583 87.8547C74.0006 87.1423 72.1265 85.9825 70.5708 84.4589C68.3784 82.7775 65.7376 81.7824 62.9808 81.5989C59.6108 81.4389 54.6608 81.5989 54.1008 85.0889C54.0462 86.1364 54.4053 87.1635 55.1008 87.9489C58.7908 92.2889 71.6308 86.2089 72.3708 85.9489"
      stroke="#161616"
      strokeWidth="3.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.5312 38.7793H53.9813V85.0893" stroke="#161616" strokeWidth="3.67" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.6097 38.779C10.3146 38.7864 9.06067 38.3246 8.0797 37.479C5.65774 35.3997 4.05829 32.5235 3.5697 29.369C2.6597 22.909 7.1697 18.529 7.6897 18.039C9.36185 15.9724 10.357 13.441 10.5397 10.789C10.6997 7.57899 10.5397 2.85899 7.0497 2.33899C6.01705 2.26815 4.99569 2.58956 4.1897 3.23899C-0.150299 6.75899 5.9797 18.979 6.2397 19.689"
      stroke="#161616"
      strokeWidth="3.58"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M53.9805 38.7791L74.6005 2.30908" stroke="#161616" strokeWidth="1.83" strokeMiterlimit="10" />
    <path d="M53.9805 39.669L92.3605 17.509" stroke="#161616" strokeWidth="1.83" strokeMiterlimit="10" />
  </svg>
)

export const TPiece1Icon: FunctionComponent = () => (
  <svg width="104" height="70" viewBox="0 0 104 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M101.968 33.8014H74.3237C73.6397 33.8014 72.9624 33.6666 72.3306 33.4046C71.6988 33.1425 71.1249 32.7585 70.6417 32.2744C70.1585 31.7904 69.7754 31.2158 69.5145 30.5835C69.2536 29.9513 69.1199 29.2738 69.1211 28.5898V1.98779"
      stroke="#161616"
      strokeWidth="3.36628"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 33.8014H29.6715C30.3575 33.8038 31.0371 33.6708 31.6715 33.4099C32.3059 33.1491 32.8826 32.7656 33.3684 32.2814C33.8543 31.7972 34.2398 31.2219 34.5029 30.5884C34.7659 29.9549 34.9013 29.2757 34.9013 28.5898V1.98779"
      stroke="#161616"
      strokeWidth="3.36628"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 67.8276H102" stroke="#161616" strokeWidth="3.36628" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.9922 50.1438C35.7517 49.7192 37.462 49.1116 39.0951 48.3311" stroke="#8A55FB" strokeWidth="1.67856" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M44.2812 44.9319C45.1087 44.2309 45.887 43.4738 46.6106 42.666C48.8811 40.1278 50.5947 37.1422 51.641 33.9014V33.9014C51.8789 32.8177 52.0004 31.7117 52.0035 30.6022V13.1545"
      stroke="#8A55FB"
      strokeWidth="1.67856"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="12.58 6.29"
    />
    <path d="M52.0508 10.0559L52.0598 4.61768" stroke="#8A55FB" strokeWidth="1.67856" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M58.8456 10.1284L57.622 11.2614L52.0569 5.2521L46.4645 11.2432L45.25 10.1012L52.0659 2.81396L58.8456 10.1284Z" fill="#8A55FB" />
    <path d="M7.32812 50.1431H33.9664" stroke="#8A55FB" strokeWidth="1.67856" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.9648 50.1431H99.6496" stroke="#8A55FB" strokeWidth="1.67856" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
export const TPiece2Icon: FunctionComponent = () => (
  <svg width="104" height="70" viewBox="0 0 104 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M101.995 33.8003H74.3237C73.6397 33.8003 72.9624 33.6652 72.3306 33.4027C71.6988 33.1403 71.1249 32.7556 70.6417 32.2707C70.1585 31.7859 69.7754 31.2103 69.5145 30.577C69.2536 29.9437 69.1199 29.2651 69.1211 28.58V1.93384"
      stroke="#161616"
      strokeWidth="3.3319"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 33.8003H29.6715C30.3555 33.8003 31.0328 33.6652 31.6646 33.4027C32.2964 33.1403 32.8703 32.7556 33.3535 32.2707C33.8367 31.7859 34.2198 31.2103 34.4807 30.577C34.7416 29.9437 34.8753 29.2651 34.8741 28.58V1.93384"
      stroke="#161616"
      strokeWidth="3.3319"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 67.8818H102" stroke="#161616" strokeWidth="3.3319" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M33.9648 50.17C38.851 49.0102 43.2738 46.3996 46.654 42.6801C48.9281 40.1402 50.6421 37.1488 51.6844 33.9009C51.9223 32.8154 52.0439 31.7076 52.047 30.5962L52.0923 4.60376"
      stroke="#8A55FB"
      strokeWidth="1.66141"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M58.8495 10.0866L57.6259 11.2214L52.0608 5.2022L46.4684 11.2033L45.2539 10.0593L52.0698 2.76001L58.8495 10.0866Z" fill="#8A55FB" />
    <path d="M7.32812 50.1689H33.9664" stroke="#8A55FB" strokeWidth="1.66141" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.9648 50.1689H39.4031" stroke="#8A55FB" strokeWidth="1.66141" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M44.7109 50.1689H89.7576" stroke="#8A55FB" strokeWidth="1.66141" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="10.62 5.31" />
    <path d="M92.4141 50.1689H97.8523" stroke="#8A55FB" strokeWidth="1.66141" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M92.34 56.9782L91.207 55.7616L97.2163 50.1691L91.207 44.5857L92.34 43.3691L99.6544 50.1691L92.34 56.9782Z" fill="#8A55FB" />
  </svg>
)
export const TPiece3Icon: FunctionComponent = () => (
  <svg width="104" height="70" viewBox="0 0 104 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M101.995 33.8003H74.3237C73.6397 33.8003 72.9624 33.6652 72.3306 33.4027C71.6988 33.1403 71.1249 32.7556 70.6417 32.2707C70.1585 31.7859 69.7754 31.2103 69.5145 30.577C69.2536 29.9437 69.1199 29.2651 69.1211 28.58V1.93384"
      stroke="#161616"
      strokeWidth="3.3319"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 33.8003H29.6715C30.3555 33.8003 31.0328 33.6652 31.6646 33.4027C32.2964 33.1403 32.8703 32.7556 33.3535 32.2707C33.8367 31.7859 34.2198 31.2103 34.4807 30.577C34.7416 29.9437 34.8753 29.2651 34.8741 28.58V1.93384"
      stroke="#161616"
      strokeWidth="3.3319"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 67.8818H102" stroke="#161616" strokeWidth="3.3319" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M52.6445 32.2112C53.8024 37.1055 56.4087 41.5355 60.1221 44.9214C62.6601 47.1959 65.6458 48.9124 68.8867 49.9601C69.9704 50.1984 71.0764 50.3202 72.1859 50.3233L98.1807 50.3778"
      stroke="#8A55FB"
      strokeWidth="1.66141"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M92.6642 57.1325L91.5312 55.9159L97.5496 50.3416L91.5584 44.74L92.6914 43.5234L99.9877 50.3416L92.6642 57.1325Z" fill="#8A55FB" />
    <path d="M52.6445 5.52954V32.2211" stroke="#8A55FB" strokeWidth="1.66141" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M52.6467 32.2112C52.2324 33.9796 51.6245 35.6968 50.834 37.3316" stroke="#8A55FB" strokeWidth="1.66141" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M47.4415 42.5518C46.7372 43.3806 45.9771 44.1602 45.1665 44.885C42.6285 47.1595 39.6427 48.8759 36.4018 49.9237C35.3183 50.163 34.2122 50.2847 33.1027 50.2869H15.6641"
      stroke="#8A55FB"
      strokeWidth="1.66141"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="12.46 6.23"
    />
    <path d="M12.5593 50.332L7.12109 50.3411" stroke="#8A55FB" strokeWidth="1.66141" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.6166 43.5234L13.7496 44.74L7.75845 50.3416L13.7768 55.9159L12.6438 57.1325L5.32031 50.3416L12.6166 43.5234Z" fill="#8A55FB" />
  </svg>
)
export const TPiece4Icon: FunctionComponent = () => (
  <svg width="104" height="71" viewBox="0 0 104 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M101.999 34.7891H74.3276C73.6436 34.7891 72.9663 34.6526 72.3345 34.3876C71.7028 34.1225 71.1288 33.734 70.6456 33.2443C70.1624 32.7546 69.7793 32.1733 69.5184 31.5336C69.2575 30.894 69.1238 30.2086 69.125 29.5167V2.60449"
      stroke="#161616"
      strokeWidth="3.36517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 34.7891H29.6715C30.3555 34.7891 31.0328 34.6526 31.6646 34.3876C32.2964 34.1225 32.8703 33.734 33.3535 33.2443C33.8367 32.7546 34.2198 32.1733 34.4807 31.5336C34.7416 30.894 34.8753 30.2086 34.8741 29.5167V2.60449"
      stroke="#161616"
      strokeWidth="3.36517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 69.2104H102" stroke="#161616" strokeWidth="3.36517" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M52.6484 33.1836C53.8064 38.1268 56.4126 42.6011 60.126 46.0207C62.664 48.3179 65.6497 50.0515 68.8906 51.1098C69.9743 51.3504 71.0803 51.4734 72.1898 51.4765L98.1846 51.5315"
      stroke="#8A55FB"
      strokeWidth="1.678"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M92.6681 58.3543L91.5352 57.1256L97.5535 51.4956L91.5623 45.8381L92.6953 44.6094L99.9916 51.4956L92.6681 58.3543Z" fill="#8A55FB" />
    <path d="M52.6484 6.23535V33.1934" stroke="#8A55FB" strokeWidth="1.678" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.22656 51.4961H60.3309" stroke="#8A55FB" strokeWidth="1.678" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="11 5.5" />
    <path d="M54.8322 58.3729L53.6992 57.1442L59.6994 51.505L53.6992 45.8566L54.8322 44.6279L62.1375 51.505L54.8322 58.3729Z" fill="#8A55FB" />
  </svg>
)
export const TPiece5Icon: FunctionComponent = () => (
  <svg width="104" height="70" viewBox="0 0 104 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M101.999 33.7985H74.3276C73.6436 33.7985 72.9663 33.6632 72.3345 33.4003C71.7028 33.1374 71.1288 32.7521 70.6456 32.2665C70.1624 31.7808 69.7793 31.2043 69.5184 30.57C69.2575 29.9356 69.1238 29.2559 69.125 28.5697V1.87988"
      stroke="#161616"
      strokeWidth="3.33736"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 33.7985H29.6715C30.3555 33.7985 31.0328 33.6632 31.6646 33.4003C32.2964 33.1374 32.8703 32.7521 33.3535 32.2665C33.8367 31.7808 34.2198 31.2043 34.4807 30.57C34.7416 29.9356 34.8753 29.2559 34.8741 28.5697V1.87988"
      stroke="#161616"
      strokeWidth="3.33736"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 67.9355H102" stroke="#161616" strokeWidth="3.33736" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M53.6094 32.207C54.6342 36.5009 56.7659 40.4491 59.7908 43.6559" stroke="#8A55FB" strokeWidth="1.66413" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M51.168 43.774L51.3583 42.1099L59.4975 43.0465L59.8238 34.835L61.4915 34.8986L61.0837 44.9016L51.168 43.774Z" fill="#8A55FB" />
    <path d="M52.6445 2.98047V26.2874" stroke="#8A55FB" strokeWidth="1.66413" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="9.09 5.46" />
    <path d="M7.23047 50.3672H97.5957" stroke="#8A55FB" strokeWidth="1.66413" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M92.0861 57.187L90.9531 55.9685L96.9533 50.3759L90.9531 44.7742L92.0861 43.5557L99.3914 50.3759L92.0861 57.187Z" fill="#8A55FB" />
  </svg>
)
export const TPiece6Icon: FunctionComponent = () => (
  <svg width="104" height="70" viewBox="0 0 104 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M101.999 33.7985H74.3276C73.6436 33.7985 72.9663 33.6632 72.3345 33.4003C71.7028 33.1374 71.1288 32.7521 70.6456 32.2665C70.1624 31.7808 69.7793 31.2043 69.5184 30.57C69.2575 29.9356 69.1238 29.2559 69.125 28.5697V1.87988"
      stroke="#161616"
      strokeWidth="3.33736"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 33.799H29.6715C30.3555 33.799 31.0328 33.6637 31.6646 33.4008C32.2964 33.1379 32.8703 32.7526 33.3535 32.267C33.8367 31.7813 34.2198 31.2048 34.4807 30.5705C34.7416 29.9361 34.8753 29.2564 34.8741 28.5702V1.88037"
      stroke="#161616"
      strokeWidth="3.33736"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 67.9355H102" stroke="#161616" strokeWidth="3.33736" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M70.749 49.9492C65.8639 48.79 61.4413 46.1784 58.0598 42.456C55.8034 39.902 54.1025 36.904 53.0657 33.6534C52.8317 32.5686 52.7102 31.4624 52.7031 30.3524V13.2109"
      stroke="#8A55FB"
      strokeWidth="1.66413"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M59.481 18.7208L58.2665 19.8575L52.6742 13.8466L47.1091 19.8847L45.8945 18.748L52.6742 11.4004L59.481 18.7208Z" fill="#8A55FB" />
    <path d="M97.3883 49.958H70.75" stroke="#8A55FB" strokeWidth="1.66413" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.8164 49.112C41.0877 48.0757 45.0151 45.9378 48.2095 42.9102" stroke="#8A55FB" strokeWidth="1.66413" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M48.3315 51.5852L46.6819 51.3942L47.6064 43.2281L39.4219 42.9008L39.4944 41.2275L49.4645 41.6368L48.3315 51.5852Z" fill="#8A55FB" />
    <path d="M7.68359 50.0938H30.9048" stroke="#8A55FB" strokeWidth="1.66413" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="9.09 5.46" />
  </svg>
)

export const HorizontalPodIcon: FunctionComponent = () => (
  <svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="10"
    version="1.1"
    viewBox="0 0 57 57"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(40.844 15.331)">
        <path d="m0 24.586h9.748v-24.586h-46.697v24.586h9.171" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="translate(50.592 15.331)">
        <path d="M0,24.586L3.753,22.071L3.753,2.015L0,0" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="translate(3.8952 39.917)">
        <path d="m0-24.586-1.547 1.635v21.687l1.547 1.264" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="matrix(0 1 1 0 39.988 28.376)">
        <path d="m-13.046-13.046h26.092" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="matrix(0 1 1 0 10.286 41.52)">
        <path d="m-1.604-1.604h3.208" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="matrix(0 1 1 0 47.408 41.52)">
        <path d="m-1.604-1.604h3.208" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="translate(13.067 41.37)">
        <path d="m0-26.039v26.039h25.871" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="translate(40.844 41.37)">
        <path d="m0-26.039v26.039h-1.906" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="matrix(-1,0,0,1,39.581,1.7839)">
        <rect x="16.879" y="24.543" width="5.823" height="5.823" fill="none" stroke="#8a55fb" strokeWidth="1.25px" />
      </g>
      <g transform="translate(11.018 13.472)">
        <path d="M0,1.859C0,0.832 0.459,0 1.024,0C1.59,0 2.049,0.832 2.049,1.859" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="translate(40.844 13.472)">
        <path d="M0,1.859C0,0.832 0.459,0 1.024,0C1.59,0 2.049,0.832 2.049,1.859" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="translate(41.874 43.221)">
        <path d="m0 0h7.861" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="translate(4.7525 43.221)">
        <path d="m0 0h7.861" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
      <g transform="matrix(-1,0,0,1,53.912,1.7839)">
        <rect x="25.035" y="24.543" width="3.842" height="5.823" fill="none" stroke="#000" strokeWidth="1.25px" />
      </g>
    </g>
  </svg>
)

export const VerticalPodIcon: FunctionComponent = () => (
  <svg
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="10"
    version="1.1"
    viewBox="0 0 57 57"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(40.475 5.6096)">
        <path d="m0 1.832-1.936-1.832h-20.386l-1.497 1.832" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="translate(9.8666 51.084)">
        <path d="m0 0h3.921" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="matrix(-1 0 0 1 56.693 13.915)">
        <rect x="13.788" y="5.61" width="29.117" height="31.558" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="matrix(0 1 1 0 41.672 33.241)">
        <path d="m-13.339-13.339h26.678" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="translate(13.788 46.581)">
        <path d="m0 0h29.117" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="matrix(-1,0,0,1,39.726,9.9769)">
        <rect x="16.415" y="19.91" width="6.896" height="6.896" fill="none" stroke="#8a55fb" strokeWidth="1.5px" />
      </g>
      <g transform="translate(12.575 10.885)">
        <path d="m0 2.202c0-1.216 0.543-2.202 1.213-2.202s1.213 0.986 1.213 2.202" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="matrix(-1,0,0,1,56.695,9.9769)">
        <rect x="26.072" y="19.91" width="4.551" height="6.896" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="translate(42.905 51.084)">
        <path d="m0 0h3.921" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="matrix(0 1 1 0 17.007 16.306)">
        <path d="m-3.219-3.219h6.438" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="matrix(0 1 1 0 46.124 16.306)">
        <path d="m-3.219-3.219h6.438" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="translate(9.8666 13.087)">
        <path d="m0 0h36.96" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="translate(41.692 10.885)">
        <path d="m0 2.202c0-1.216 0.543-2.202 1.213-2.202s1.213 0.986 1.213 2.202" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
      <g transform="translate(40.574 7.6922)">
        <path d="M0,5.395L0.065,0L-24.095,0L-24.16,5.395" fill="none" stroke="#000" strokeWidth="1.5px" />
      </g>
    </g>
  </svg>
)
