import Button from "../button"
import React, { FunctionComponent } from "react"
import RichTextField from "../rich-text-field"
import TextWithBar from "../text-with-bar"
import Title from "../title"
import mq from '../../utils/mq'
import parseStoryblokLink from "../../utils/parse-storyblok-link"
import styled, { css } from "styled-components"
import { CarouselItem } from "./types"
import { Flex, Paragraph } from "../UIKit/system"
import { getFluidGatsbyImage } from "../../utils/gatsby-storyblok-image-fixed"

interface Props {
  item: CarouselItem
}

const StyledItem = styled.div`
  max-height: 800px;
  min-height: 400px;
  aspect-ratio: 2 / 1;
  postition: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: hidden;
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  filter: brightness(0.65);

  > div {
    height: 100%;
  }

  .background-image-mobile {
    display: none;
  }

  ${mq.mobile(css`
    .background-image-desktop {
      display: none;
    }
    .background-image-mobile {
      display: block;
    }
  `)};
`

const TextContainer = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;
  margin-left: 10%;
`

const CtaContainer = styled.div`
  ${mq.mobile(css`
    margin-top: 19px;
  `)};
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const Item: FunctionComponent<Props> = ({ item }) => {
  const desktopImage = getFluidGatsbyImage(item.desktopImage, { maxWidth: 1800 })
  const mobileImage = getFluidGatsbyImage(item.mobileImage?.id ? item.mobileImage : item.desktopImage, { maxWidth: 500 })

  const link = item.buttonLink && parseStoryblokLink(item.buttonLink)

  return (
    <StyledItem>
      <ImageContainer>
        <Img src={desktopImage?.src} alt="" className="background-image-desktop" />
        <Img src={mobileImage?.src} alt="" className="background-image-mobile" />
      </ImageContainer>
      <TextContainer
          initial={{
            y: 40,
            opacity: 0
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.5,
              duration: 0.35
            }
          }}
        >
          <Flex
            flexDirection={'column'}
            alignItems={'flex-start'}
            flexShrink={0}
            pr={30}
            maxWidth={522}
            width={'100%'}
            pt={[50, 0, 0]}
        >
          {item.subtitle && <TextWithBar mb={15} color={'#fff'}>{item.subtitle}</TextWithBar>}
          {item.title && <Title
            maxWidth={570}
            mb={[14, null, 26]}
            lineHeight={1.1}
            html={item.title}
            color={'#fff'}
          />}
          {item.text && (
            <Paragraph
              semibold
              color={'#fff'}
              fontSize={[14, 18]}
            >
              <RichTextField data={item.text} />
            </Paragraph>
          )}
          {item.buttonText && (
            <CtaContainer>
              <Button to={link}>
                {item.buttonText}
              </Button>
            </CtaContainer>
          )}
        </Flex>
      </TextContainer>
    </StyledItem>
  )
}

export default Item
