import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import GridCol from './grid-col';
import Title from './title';
import TextWithBar from './text-with-bar';
import mq from '../utils/mq';

const StyledSectionTitleAndText = styled(Box)``;

const StyledSectionTitleAndTextInnerContainer = styled(Container)`
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const TitleSectionContainer = styled(GridCol)`
  ${mq.mobile(css`
    width: 100%;
    margin-bottom: 47px;
  `)};
`;

function SectionTitleAndText({ title, subtitle, text }) {
  return (
    <StyledSectionTitleAndText as={'section'} pt={70} pb={100}>
      <StyledSectionTitleAndTextInnerContainer as={Flex}>
        <TitleSectionContainer>
          <Box maxWidth={370}>
            <Title mb={25} fontSizeMedium html={title} />
            {subtitle && <TextWithBar>{subtitle}</TextWithBar>}
          </Box>
        </TitleSectionContainer>
        <Box>
          <Paragraph grey html={text} />
        </Box>
      </StyledSectionTitleAndTextInnerContainer>
    </StyledSectionTitleAndText>
  );
}

export default SectionTitleAndText;
