import React from 'react';
import styled, { css } from 'styled-components';

import { getContentComponent } from './content-types';

const StyledTabsContentItem = styled.div`
  display: none;

  ${(props) =>
    props.active &&
    css`
      display: block;
    `};
`;

function TabsContentItem ({ content, active, productName, current }) {
  return (
    <StyledTabsContentItem active={active}>
      {content.map((item, index) => {
        const { type, ...props } = item;
        const Component = getContentComponent(type);
        return <Component key={index} productName={productName} type={type} current={current} {...props} />;
      })}
    </StyledTabsContentItem>
  );
}

export default TabsContentItem;
