import React from 'react';
import styled, { css } from 'styled-components';

import GridTileItem from './grid-tile-item';
import { supportsGrid } from '../utils/styles';
import Title from './title';
import { Paragraph, Text } from './UIKit/system';
import mq from '../utils/mq';

const StyledGridTileList = styled.div``;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    ${(props) =>
      props.threeColumns &&
      css`
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
      `};

    ${(props) =>
      props.fourColumns &&
      css`
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
      `};
  `)}
  ${mq.tablet(
    supportsGrid(css`
      grid-gap: 20px;
      ${(props) =>
        props.fourColumns ||
        (props.threeColumns &&
          css`
            grid-template-columns: 1fr 1fr 1fr;
            > * {
              justify-self: center;
            }
          `)}
    `)
  )};
  @media (max-width: 880px) {
    ${supportsGrid(css`
      grid-template-columns: 1fr 1fr;

      > * :nth-of-type(2n + 1) {
        justify-self: flex-end;
      }
      > * :nth-of-type(2n) {
        justify-self: flex-start;
      }
    `)}
  }
  @media (max-width: 550px) {
    ${supportsGrid(css`
      grid-template-columns: 1fr;
      > * :nth-of-type(2n + 1) {
        justify-self: center;
      }
      > * :nth-of-type(2n) {
        justify-self: center;
      }
    `)}
  }
`;

const TitleContainer = styled.div`
  margin-top: 72px;
  margin-bottom: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const StyledTitle = styled(Title)`
  font-weight: bold;
  font-size: 42px;
  line-height: 46px;
  text-align: center;
  color: #161616;
  ${mq.mobile(css`
    font-size: 28px;
    line-height: 36px;
  `)};
`;
const StyledParagraph = styled(Paragraph)`
  margin-top: 18px;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #666666;
  ${mq.mobile(css`
    font-size: 16px;
    line-height: 28px;
  `)};
`;

function GridTileListDualWidth({
  items,
  threeColumns,
  fourColumns,
  title,
  paragraph,
  ...rest
}) {
  return (
    <StyledGridTileList {...rest}>
      {title && (
        <TitleContainer>
          <StyledTitle>{title}</StyledTitle>
          {paragraph && <StyledParagraph>{paragraph}</StyledParagraph>}
        </TitleContainer>
      )}
      <Items threeColumns={threeColumns} fourColumns={fourColumns}>
        {items.map((item, index) => (
          <GridTileItem
            key={index}
            small={threeColumns || fourColumns}
            wipeAnimation={true}
            {...item}
          />
        ))}
      </Items>
    </StyledGridTileList>
  );
}

export default GridTileListDualWidth;
