/* eslint-disable react/prop-types */
import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Range, getTrackBackground } from 'react-range'

// Styles
import { StyledFormButton } from '../../styles'

const StyledRangeContainer = styled.form`
  margin: 2rem auto;
  display: flex;

  .container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    margin: auto;

    @media (max-width: 1024px) {
      width: 100%;
      flex-direction: column-reverse;
    }
  }

  .video {
    max-width: 500px;
    width: 50%;
    height: auto;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .content {
    max-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;

    .left {
      flex-basis: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .right {
      flex-grow: 1;

      @media (max-width: 500px) {
        display: none;
      }
    }
  }
`

interface Props {
  text: string
  image: string | FunctionComponent
  video?: string
  thumbnail?: string
  options: {
    min: number
    max: number
    step: number
  }
  button: string
  callback: (value: number) => void
  required?: number
  input: number
}

const RangeInput: FunctionComponent<Props> = ({ options, text, image, video, thumbnail, button, callback, required, input }) => {
  const [values, setValues] = useState([input ?? 0])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      const [value] = values
      if (required && value < required) return
      callback(value)
    },
    [values]
  )

  const Image = (props: { children?: ReactNode }) => (typeof image === 'string' ? <img src={image} alt="" /> : image(props))

  return (
    <StyledRangeContainer onSubmit={handleSubmit}>
      <div className="container">
        <div>
          <div className="content">
            <div className="left">
              <p>{text}</p>
              <Range
                values={values}
                step={options.step}
                min={options.min}
                max={options.max}
                onChange={(values) => setValues(values)}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: '36px',
                      display: 'flex',
                      width: '100%',
                      marginBottom: '50px'
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: '5px',
                        width: '100%',
                        borderRadius: '4px',
                        background: getTrackBackground({
                          values,
                          colors: ['#753FBF', '#fff'],
                          min: options.min,
                          max: options.max
                        }),
                        alignSelf: 'center'
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '20px',
                      width: '34px',
                      borderRadius: '2px',
                      backgroundColor: '#753FBF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)'
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        width: '80px',
                        height: '50px',
                        bottom: '-70px',
                        color: '#000',
                        fontWeight: 'bold',
                        fontSize: '28px',
                        fontFamily: 'Muli',
                        padding: '4px',
                        borderRadius: '1px',
                        backgroundColor: '#fff',
                        boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.05)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {values[0]}
                    </div>
                    <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.25 8L15.25 5L12.25 2" stroke="white" strokeWidth="2" strokeMiterlimit="16" strokeLinecap="square" />
                      <path d="M4.75 2L1.75 5L4.75 8" stroke="white" strokeWidth="2" strokeMiterlimit="16" strokeLinecap="square" />
                    </svg>
                  </div>
                )}
              />
            </div>
            {image && (
              <div className="right">
                <Image />
              </div>
            )}
          </div>
          <StyledFormButton type="submit" disabled={Boolean(required && values[0] < required)}>
            {button}
          </StyledFormButton>
        </div>
        {video && (
          <div className="video">
            <video src={video} width="100%" height="auto" controls poster={thumbnail}></video>
          </div>
        )}
      </div>
    </StyledRangeContainer>
  )
}

export default RangeInput
