import React from 'react';
import styled, { css } from 'styled-components';

import mq from '../utils/mq';
import HubSpotForm from './hubspot-form';

const CourseSignupFormContainer = styled.div`
  max-width: 1442px;
  width: 100%;
  background-color: #fff;
  padding: 60px;
  ${mq.mobile(css`
    padding: 40px 20px;
  `)};
`;

function CourseSignupForm({ portalId, formId }) {
  return (
    <CourseSignupFormContainer>
      <HubSpotForm portalId={portalId} formId={formId} />
    </CourseSignupFormContainer>
  );
}

export default CourseSignupForm;
