import React from 'react';
import styled, { css } from 'styled-components';

import Title from '../title';
import { darkBackground, supportsGrid } from '../../utils/styles';
import { Box } from '../UIKit/system';
import mq from '../../utils/mq';

export const FormSection = styled.section`
  margin-bottom: 85px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

function FormSectionTitle({ morePadding, ...rest }) {
  return <Title mb={morePadding ? 25 : 12} fontSizeExtraSmall {...rest} />;
}

FormSection.Title = FormSectionTitle;

export const Row = styled.div`
  display: flex;
  margin-bottom: 70px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  `)}
  ${mq.mobile(css`
    ${supportsGrid(css`
      margin-top: 42px;
      grid-template-columns: 1fr;
      grid-gap: 42px;
    `)}
  `)};
`;

export const ContactScreen = styled(Box)`
  ${darkBackground};
  padding-top: 110px;
  padding-bottom: 105px;

  ${mq.tablet(css`
    padding-top: 80px;
    padding-bottom: 75px;
  `)};

  ${mq.mobile(css`
    padding-top: 65px;
    padding-bottom: 60px;
  `)};
`;
