/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Info } from '../root-page'
import { useStaticQuery, graphql } from 'gatsby'
import StoryblokClient from 'storyblok-js-client'
import Carousel from '../../carousel'

// Data
import RMPRData from '../data/rmpr-data'
import { useCalculateSimpleValues } from '../hooks/formula-simple'
import { AdvancedFormulaInput, useCalculatorAdvancedValues } from '../hooks/formula-advanced'

// Constants
import STORYBLOK_API_KEY from '../../../storyblok-api-key'
import { HUBSPOT_FORM_ENDPOINT } from '../consts'

// Types
import { DownloadSpecificationPayload, Feature, HSFormPayload, Product, SimpleFormInput, View } from '../types'
import { FormAdvancedPayloadInput, FormSimplePayloadInput, useAdvancedFormPayload, useSimpleFormPayload } from '../hooks/form-payload'

// Styles
import {
  StyledSpecificationLayout,
  StyledLayoutNav,
  StyledSpecificationHeader,
  StyledSpecificationProduct,
  StyledSpecificationContainer,
  StyledSpecificationOverview,
  StyledSpecificationCTA,
  StyledTableContent,
  StyledTable
} from './specification.styles'

const Storyblok = new StoryblokClient({
  accessToken: STORYBLOK_API_KEY
})

async function submitHSForm(payload: HSFormPayload) {
  try {
    const response = await fetch(HUBSPOT_FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    if (!response.ok) throw new Error(await response.text())
  } catch (error) {
    console.error(`Failed to submit data to HubSpot:`)
    console.error(error)
  }
}

async function downloadPDF(payload: DownloadSpecificationPayload) {
  try {
    const response = await fetch('/.netlify/functions/specification-pdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    if (!response.ok) throw new Error('Failed to create PDF.')

    const blob = await response.blob()
    const downloadURL = URL.createObjectURL(blob)
    return downloadURL
  } catch (error) {
    console.error(error)
    throw new Error(`Failed to create PDF. Please go back a step, and try again.`)
  }
}

// Types
interface Props {
  back: () => void
  restart: () => void
  info: Info
  input: SimpleFormInput | AdvancedFormulaInput
  type: View
}

const CheckMark: FunctionComponent<{ checked: boolean }> = ({ checked }) => {
  if (checked)
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.17712 15.2378L13.9206 20.0849L22.8007 10.9975M15.9926 1C18.9596 0.998538 21.8605 1.87703 24.3282 3.52436C26.7959 5.17169 28.7196 7.51386 29.8561 10.2546C30.9925 12.9954 31.2906 16.0116 30.7127 18.9218C30.1347 21.832 28.7067 24.5055 26.6092 26.604C24.5117 28.7025 21.839 30.1319 18.9291 30.7112C16.0192 31.2906 13.0028 30.994 10.2615 29.8589C7.52014 28.7238 5.17702 26.8012 3.52847 24.3343C1.87993 21.8674 1 18.967 1 16C0.99903 14.0305 1.38611 12.0801 2.13913 10.2603C2.89214 8.44047 3.99633 6.78683 5.38861 5.39386C6.7809 4.00088 8.43402 2.89586 10.2535 2.14195C12.073 1.38804 14.0231 1 15.9926 1V1Z"
          stroke="#8A55FB"
          strokeWidth="1.87"
        />
      </svg>
    )

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.74485 6.70873C9.20902 4.24457 12.5512 2.86021 16.036 2.86021C18.6348 2.86021 21.1752 3.63086 23.3361 5.07467C25.4969 6.51847 27.181 8.57061 28.1755 10.9716C29.1701 13.3725 29.4303 16.0145 28.9233 18.5633C28.4163 21.1122 27.1648 23.4534 25.3272 25.2911C23.4896 27.1287 21.1483 28.3801 18.5995 28.8871C16.0506 29.3941 13.4087 29.1339 11.0077 28.1394C8.60672 27.1449 6.55457 25.4607 5.11076 23.2999C3.66695 21.1391 2.89634 18.5987 2.89634 15.9999C2.89634 12.5151 4.28068 9.1729 6.74485 6.70873ZM16.036 0.960205C12.0473 0.960205 8.22184 2.54474 5.40135 5.36523C2.58085 8.18572 0.996338 12.0111 0.996338 15.9999C0.996338 18.9745 1.87838 21.8822 3.53097 24.3555C5.18355 26.8288 7.53245 28.7565 10.2806 29.8948C13.0287 31.0331 16.0527 31.3309 18.9701 30.7506C21.8875 30.1703 24.5674 28.7379 26.6707 26.6346C28.7741 24.5312 30.2064 21.8514 30.7868 18.934C31.3671 16.0166 31.0692 12.9926 29.9309 10.2445C28.7926 7.49632 26.8649 5.14745 24.3916 3.49487C21.9184 1.8423 19.0106 0.960205 16.036 0.960205ZM20.7349 15.0499H11.3441V16.9499H20.7349V15.0499Z"
        fill="#B2B2B2"
      />
    </svg>
  )
}

const Specification: FunctionComponent<Props> = ({ input, info, restart, back, type }) => {
  const [downloadLink, setDownloadLink] = useState('')
  const [downloadError, setDownloadError] = useState('')

  const data = useStaticQuery(graphql`
    {
      features: allStoryblokEntry(filter: { field_component: { eq: "featureTableRow" } }) {
        nodes {
          id
          uuid
          name
          field_component
          content
        }
      }
      products: allStoryblokEntry(filter: { field_component: { eq: "calculatorProduct" } }) {
        nodes {
          id
          uuid
          name
          field_component
          content
        }
      }
    }
  `)

  const features: Feature[] = data.features.nodes.map(({ uuid, content }: { uuid: string; content: string }) => ({ uuid, ...JSON.parse(content) }))
  const products: Product[] = data.products.nodes.map(({ content }: { content: string }) => JSON.parse(content))

  // Filter features + products by their orientation
  const filteredFeatures = features.filter(feature => {
    if (!feature.type) return true
    if (feature.type === 'both') return true
    return input.verticalPod ? feature.type === 'vertical' : feature.type === 'horizontal'
  })

  const filteredProducts = products.filter(product => {
    if (!product.type) return true
    return input.verticalPod ? product.type === 'vertical' : product.type === 'horizontal'
  })

  // Sort according to the number of features they have
  const sortedProducts = filteredProducts.sort((a, b) => a.features.length - b.features.length)
  const sortedFeatures = filteredFeatures.sort((a, b) => {
    const aTotal = sortedProducts.filter(product => product.features.includes(a.uuid))
    const bTotal = sortedProducts.filter(product => product.features.includes(b.uuid))
    return bTotal.length - aTotal.length
  })

  // Formulas
  const calculated = type === View.ADVANCED_FORM ? useCalculatorAdvancedValues(input as AdvancedFormulaInput) : useCalculateSimpleValues(input as SimpleFormInput)
  console.log(calculated)
  // Selected Product
  const selectedProduct = useMemo(() => {
    const bestMatch = sortedProducts.find((product) => input.features?.every((uuid) => product.features.includes(uuid)) ?? true)
    if (!bestMatch) console.warn('No best match, falling back')
    return bestMatch ?? sortedProducts[sortedProducts.length - 1]
  }, [products, input])

  // RMP-R product
  const selectedProductData = useMemo(() => {
    if (!selectedProduct) return

    const type = input.verticalPod ? 'vertical' : 'horizontal'

    const matchingTypeProducts = RMPRData.filter((product) => product.type.toLowerCase() === type).sort((a, b) => Number(a.maxRate) - Number(b.maxRate))
    const matchingProducts = matchingTypeProducts.filter((product) => product.product.toLowerCase() === selectedProduct?.short_name.toLowerCase())

    const productData = matchingProducts.find((product) => Number(product.maxRate) >= calculated.extractRateRequired)
    if (!productData) {
      console.warn(`No products matching ${selectedProduct.short_name} ${type} - falling back`)
      return matchingTypeProducts[0]
    }

    console.log({ productData })

    return productData
  }, [calculated, selectedProduct])

  const exceedsExtractRateLimits = useMemo(() => {
    return calculated.extractRateRequired > Number(selectedProductData?.maxRate)
  }, [calculated, selectedProductData])

  const exceedsPressureLossLimits = useMemo(() => {
    if (type === View.SIMPLE_FORM || !selectedProductData?.staticPressure) return
    const { totalSystemPressureDrop } = calculated as ReturnType<typeof useCalculatorAdvancedValues>
    return totalSystemPressureDrop > Number(selectedProductData.staticPressure)
  }, [calculated, type, selectedProductData])

  const descriptionHTML = selectedProduct?.description && Storyblok.richTextResolver.render(selectedProduct.description)
  const galleryImages = selectedProduct?.gallery.map((image) => ({ ...image, src: image.filename })) ?? []

  const formPayload =
    type === View.ADVANCED_FORM
      ? useAdvancedFormPayload({ info, input, features, calculated } as FormAdvancedPayloadInput)
      : useSimpleFormPayload({ info, input, features, calculated } as FormSimplePayloadInput)

  useEffect(() => {
    // Submit form to Hubspot
    ;(async () => {
      await submitHSForm(formPayload)
    })()

    // Set download link for PDF
    ;(async () => {
      try {
        if (!selectedProduct || !selectedProductData) {
          console.error(`No product found.`)
          return
        }

        const pdfUrl = input.verticalPod ? selectedProduct.pdf_vertical?.filename : selectedProduct.pdf?.filename
        if (!pdfUrl) {
          console.error(`No PDF found for this result.`)
          return
        }

        const payload: DownloadSpecificationPayload = {
          url: pdfUrl,
          specifications: {
            name: selectedProduct.name,
            width: `${input.dimensions.shaftWidth}mm`,
            depth: `${input.dimensions.shaftDepth}mm`,
            height: `${calculated.formulaValues?.smokeShaftHeight / 1000}m`,
            environmentalDampers: typeof input.environmentalDamperQuantity === 'number' ? input.environmentalDamperQuantity.toString() : '0',
            apartmentDoors: input.doors.toString(),
            shaftDampers: calculated.formulaValues?.shaftDampersQuantity.toString(),
            liftDoors: input.liftDoors.toString(),
            electricalSupply: selectedProductData.electricalSupply,
            extractRate: calculated.extractRateRequiredFormatted,
            extractRate2: selectedProductData.maxRate,
            fanDiameter: selectedProductData.fanMotorDiameter,
            inverterSize: selectedProductData.inverterSize,
            loadCurrent: selectedProductData.fullLoadCurrentAmp,
            roofLoadPressure: selectedProductData.roofLoadPressure,
            startingCurrent: selectedProductData.startingCurrentInverterKVa,
            staticPressure: selectedProductData.staticPressure,
            staticPressure2: selectedProductData.staticPressure,
            weight: selectedProductData.weight
          }

        }

        console.log({ payload })
        const downloadURL = await downloadPDF(payload)
        if (downloadURL) setDownloadLink(downloadURL)
      } catch (err) {
        const error = err as Error
        setDownloadError(error.message)
      }
    })()
  }, [])

  return (
    <StyledSpecificationLayout>
      <StyledLayoutNav>
        <button onClick={back}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 2.25L1.5 6L5.25 9.75" stroke="#161616" strokeWidth="2" strokeLinecap="square" />
            <path d="M1.5 6L12 6" stroke="#161616" strokeWidth="2" />
          </svg>
        </button>
      </StyledLayoutNav>
      <StyledSpecificationHeader>
        <p className="subtitle">Completed</p>
        <p className="title">Your specification is ready!</p>
        {selectedProduct && (
          <StyledSpecificationProduct>
            <div className="image">
              {selectedProduct.video && <video src={selectedProduct.video.filename} autoPlay muted loop controls={false}></video>}
              {selectedProduct.image && !selectedProduct.video && <img src={selectedProduct.image.filename} alt={selectedProduct.image.alt || selectedProduct.name} />}
            </div>
            <div className="info">
              <p className="title">{selectedProduct.name}</p>
              <p className="text">
                {info.projectName ? <strong>{info.projectName}</strong> : 'This project'}
                &nbsp;will require a Roof Mechanical Pod that achieves an extract rate of
                <strong>
                  &nbsp;{calculated.extractRateRequiredFormatted}m<sup>3</sup>/s&nbsp;
                </strong>
              </p>
              {exceedsExtractRateLimits && (
                <p className="error">
                  The recommended extract rate is{' '}
                  <strong>
                    &nbsp;{calculated.extractRateRequiredFormatted}m<sup>3</sup>/s&nbsp;
                  </strong>
                  , which is outside of our standard range for this pod. One of our team will be in touch to discuss creating a bespoke unit.
                </p>
              )}
              {exceedsPressureLossLimits && (
                <p className="error">
                  The total pressure loss is outside of acceptable ranges - please contact our team for further help.
                </p>
              )}
              {!!calculated.errors?.length && (
                <div>
                  <p className="error">Some of the selected values are outside of acceptable ranges - please contact our team for further help:</p>
                  <ul>
                    {calculated.errors.map((error, i) => (
                      <li key={i} className="error">
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {downloadError && <p className="error">{downloadError}</p>}
              <div className="buttons">
                {selectedProduct?.pdf && !downloadLink && !downloadError && (
                  <button className="button" type="button">
                    Preparing download
                  </button>
                )}
                {downloadLink && (
                  <a href={downloadLink} className="button" download={`Specification - ${info.projectName}.pdf`}>
                    Download the specification
                  </a>
                )}
                {downloadError && (
                  <button onClick={back} className="button">
                    Go Back
                  </button>
                )}
                <button className="button outlined" onClick={restart}>
                  Start over
                </button>
              </div>
            </div>
          </StyledSpecificationProduct>
        )}
      </StyledSpecificationHeader>
      <StyledSpecificationContainer>
        <StyledSpecificationOverview>
          <p className="title">
            <svg width="78" height="46" viewBox="0 0 78 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.529 6.38672H19.363V41.5633H54.529V6.38672Z" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M56.9351 39.4006H70.9517V4.0376H3.80591V39.4006H16.9938"
                stroke="#474747"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M70.9536 39.4006L76.3501 35.7837V6.93442L70.9536 4.0376"
                stroke="#474747"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.79342 4.0376L1.56934 6.38874V37.5831L3.79342 39.4006"
                stroke="#474747"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M36.957 6.51416V41.5632" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10.6648 39.3984V44.0104" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M64.0444 39.3984V44.0104" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16.9771 4.0376V41.4911H19.7185" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M56.9449 4.0376V41.4911H54.2034" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M30.8171 19.8506H22.4438V28.2263H30.8171V19.8506Z" stroke="#8A55FB" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M14.0444 4.03589C14.0444 2.55964 14.704 1.36182 15.517 1.36182C16.331 1.36182 16.9906 2.55964 16.9906 4.03589"
                stroke="#474747"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M56.937 4.03589C56.937 2.55964 57.5966 1.36182 58.4095 1.36182C59.2236 1.36182 59.8832 2.55964 59.8832 4.03589"
                stroke="#474747"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M58.3784 44.1528H69.682" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5.04883 44.1528H16.3524" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M39.6988 19.8506H34.1738V28.2263H39.6988V19.8506Z" stroke="#474747" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span>The Pod</span>
          </p>
          <div className="description">
            <p className="subtitle">Description</p>
            {descriptionHTML && <div className="content" dangerouslySetInnerHTML={{ __html: descriptionHTML }}></div>}
          </div>
          <div className="gallery">
            <Carousel items={galleryImages} className={``} swiperOptions={{}} />
          </div>
          <div className="actions">
            {selectedProduct?.bimDownload?.filename && (
              <a href={selectedProduct.bimDownload.filename} className="button" target="_blank" download rel="noreferrer">
                <svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M30.5769 3.11791C30.5769 1.94822 29.8111 1 28.8663 1H9.11539L1.5 8.61539V34.8821C1.5 36.0518 2.26587 37 3.21062 37H28.8663C29.8111 37 30.5769 36.0518 30.5769 34.8821V3.11791Z"
                    stroke="#474747"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M7.60376 17.1208L15.7038 11.8882L23.8038 17.1208L15.7038 22.3535L7.60376 17.1208Z" stroke="#8A55FB" strokeWidth="2" strokeLinejoin="round" />
                  <path d="M7.60381 21.6043L7.60381 25.0329L15.7038 30.2656L23.8038 25.0329L23.8038 23.1867" stroke="#8A55FB" strokeWidth="2" strokeLinejoin="round" />
                  <path d="M15.7039 22.3955V30.3076" stroke="#8A55FB" strokeWidth="2" />
                  <path d="M7.60376 25.0329L7.60376 17.1208" stroke="#8A55FB" strokeWidth="2" />
                  <path d="M23.804 25.0329L23.804 17.1208" stroke="#8A55FB" strokeWidth="2" />
                  <path d="M1.5 8.61538H9.11538V1" stroke="#474747" strokeWidth="2" strokeLinejoin="round" />
                </svg>
                <span>Download the BIM Model</span>
              </a>
            )}
            {selectedProduct?.cadDownload?.filename && (
              <a href={selectedProduct.cadDownload.filename} className="button" target="_blank" download rel="noreferrer">
                <svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M30.5769 3.11791C30.5769 1.94822 29.8111 1 28.8663 1H9.11539L1.5 8.61539V34.8821C1.5 36.0518 2.26587 37 3.21062 37H28.8663C29.8111 37 30.5769 36.0518 30.5769 34.8821V3.11791Z"
                    stroke="#474747"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M1.5 8.61538H9.11538V1" stroke="#474747" strokeWidth="2" strokeLinejoin="round" />
                  <path d="M16.0386 12.7693L16.0386 21.077" stroke="#8A55FB" strokeWidth="2" />
                  <path d="M8.4231 27.3077L16.0385 21.0769L23.6539 27.3077" stroke="#8A55FB" strokeWidth="2" />
                  <path d="M13.2693 14.8461L16.0385 12.0769L18.8077 14.8461" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M19.7497 28.0114L23.9156 27.476L23.3803 23.3101" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12.366 28.0114L8.20009 27.476L8.73543 23.3101" stroke="#8A55FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>Download the CAD Model</span>
              </a>
            )}
          </div>
        </StyledSpecificationOverview>
        <StyledSpecificationCTA>
          <p className="text">You can send this specification to one of our experts and get the ball rolling.</p>
          <a href="mailto:hello@sertus.uk" className="button">
            Speak to an Expert
          </a>
        </StyledSpecificationCTA>
        <StyledTableContent>
          <p className="subtitle">What are my other options?</p>
          <p className="content">We calculated that the {selectedProduct?.name} is the best fit for your needs, but you can see our full range below:</p>
        </StyledTableContent>
        <StyledTable>
          <thead className="head">
            <tr className="row">
              <td className="title">
                <p>Pod&trade; Type</p>
              </td>
              {sortedProducts.map((product) => (
                <td key={product._uid} className="pod">
                  {product.image && <img className="image" src={product.image.filename} alt={product.image.alt || product.name} />}
                  <p className="title">{product.name}</p>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="body">
            {sortedFeatures.map((feature) => (
              <tr key={feature._uid} className="row">
                <td>
                  <div className="feature">
                    <img src={feature.icon.filename} alt={feature.icon.alt} />
                    <p>{feature.title}</p>
                  </div>
                </td>
                {sortedProducts.map((product) => (
                  <td key={product._uid} className="checkmark">
                    <CheckMark checked={product.features.includes(feature.uuid)} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </StyledSpecificationContainer>
    </StyledSpecificationLayout>
  )
}

export default Specification
