import React from 'react';

import ResourceGridItem from './resource-grid-item';
import CustomStackGrid from '../custom-stack-grid';

function VideoGrid({ data, activeCategory }) {
  function filterItems() {
    if (activeCategory === '') {
      return data;
    }
    return data.filter((item) => {
      return (
        item.categories && activeCategory === item.categories.toLowerCase()
      );
    });
  }

  return (
    <CustomStackGrid>
      {filterItems().map((item, index) => {
        return (
          item.categories && (
            <ResourceGridItem key={index} video data={item} aspectRatioAlt />
          )
        );
      })}
    </CustomStackGrid>
  );
}

export default VideoGrid;
