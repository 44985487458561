import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getFluidGatsbyImage } from '../../utils/gatsby-storyblok-image-fixed';

import Intro5 from '../intro5';
import CourseFilter from '../course-filter';

function StoryblokCoursesRootPage(body) {
  const { items } = useStaticQuery(graphql`
    query {
      items: allStoryblokEntry(filter: { field_component: { eq: "course" } }) {
        edges {
          node {
            name
            slug
            content
          }
        }
      }
    }
  `);

  const fluidHeaderImg = getFluidGatsbyImage(body.headerImage, {
    maxWidth: 1080,
  });

  let coursesArr = [];

  items.edges.forEach((course) => {
    let data = JSON.parse(course.node.content);

    const fluidCourseImg = getFluidGatsbyImage(data.courseThumbnail, {
      maxWidth: 600,
    });
    let courseObj = {
      topText: data.courseType,
      title: data.courseTitle,
      image: fluidCourseImg,
      link: {
        to: '/course/' + course.node.slug,
        children: 'View Course',
      },
    };
    coursesArr.push(courseObj);
  });

  return (
    <React.Fragment>
      <Intro5
        subtitle={body.subtitle}
        title={body.title}
        text={body.paragraph}
        image={fluidHeaderImg}
      />
      <CourseFilter items={coursesArr} />
    </React.Fragment>
  );
}

export default StoryblokCoursesRootPage;
