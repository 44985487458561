import React from 'react';
import { format as formatDate } from 'date-fns';
import styled from 'styled-components';

import Intro6 from '../components/intro6';
import { getPath } from '../utils/paths';
import LazyImage from '../components/lazy-image';
import RelatedArticles from '../components/related-articles';
import StoryblokBloks from './storyblok/storyblok-bloks';

const StyledImage = styled(LazyImage)`
  max-height: 750px;
`;

function BlogPost({
  title,
  intro,
  image,
  author,
  minutesToRead,
  firstPublishedAt,
  flexibleContent,
  relatedArticles,
  relatedArticleParagraph,
}) {
  return (
    <>
      <Intro6
        pagination={[
          { link: getPath.blog(), name: 'Sertus Blog' },
          { name: 'Article' },
        ]}
        title={title}
        intro={intro}
        author={author}
        date={
          firstPublishedAt &&
          formatDate(new Date(firstPublishedAt), 'EEEE do MMMM, yyyy')
        }
        minutesToRead={minutesToRead}
      />
      <StyledImage fluid={image} />
      <StoryblokBloks body={flexibleContent} />
      {relatedArticles && (
        <RelatedArticles
          items={relatedArticles}
          relatedArticleParagraph={relatedArticleParagraph}
        />
      )}
    </>
  );
}

export default BlogPost;
