import React from 'react';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed';
import RichtextParser from '../../../utils/richtext-parser';

import LeftImageRightContent from '../../left-image-right-content';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

function StoryblokLeftImageRightContentAdapter({
  image,
  subText,
  paragraph,
  ctaButton,
  floatingImageMode,
  ...rest
}) {
  let fluidImage = getFluidGatsbyImage(image, {
    maxWidth: 500,
  });

  let paragraphText = RichtextParser(paragraph);
  let subTextParse = RichtextParser(subText);
  let parsedButton;
  if (ctaButton && ctaButton.length > 0) {
    parsedButton = ctaButton[0];
    parsedButton = {
      ...ctaButton[0],
      to: parseStoryblokLink(ctaButton[0].link),
    };
  }

  return (
    <LeftImageRightContent
      image={fluidImage}
      paragraph={paragraphText}
      subText={subTextParse}
      button={parsedButton}
      floatingImage={!floatingImageMode}
      {...rest}
    />
  );
}

export default StoryblokLeftImageRightContentAdapter;
