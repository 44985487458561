import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby-link';
import gsap from 'gsap';

import WizardBottomBar from './wizard-bottom-bar';
import Subtitle from '../subtitle';
import { STEPS_CONFIG, STEPS_ORDER } from './constants';
import { Box, Flex } from '../UIKit/system';
import FormikWrapper from './formik-wrapper';
import {
  dataIsValid,
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from './utils';
import { IS_BROWSER } from '../../constants';
import Container from '../container';
import { getPath } from '../../utils/paths';
import WizardButtonBack from './wizard-button-back';
import { scrollLocker } from '../../utils/scroll-locker';
import mq from '../../utils/mq';
import { withResponsiveContext } from '../responsive';

const StyledSpecToolWizard = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f1f4;
  z-index: 99;
  visibility: hidden;
  will-change: transform;
  padding-top: 106px;
  padding-bottom: 136px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq.mobile(css`
    padding-top: 0;
    padding-bottom: 0;
  `)};
`;

const WizardContentContainer = styled.div`
  width: 100%;
  height: 100%;

  ${mq.mobile(css`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `)};
`;

const WizardContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  ${mq.mobile(css`
    height: auto;
    padding-top: 130px;
    padding-bottom: 100px;
  `)};
`;

const StyledContainer = styled(Container)``;

function getInitialValues() {
  if (IS_BROWSER) {
    const localStorageData = getDataFromLocalStorage();

    if (localStorageData && dataIsValid(localStorageData)) {
      return localStorageData;
    }
  }

  return Object.keys(STEPS_CONFIG).reduce((acc, key) => {
    return {
      ...acc,
      [key]: STEPS_CONFIG[key].initialValue,
    };
  }, {});
}

const initialValues = getInitialValues();

function WizardInner({ formikState, onFinish, isMobileAll }) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentStepConfig, setCurrentStepConfig] = useState(
    STEPS_CONFIG[STEPS_ORDER[0]]
  );
  const [indexDisplayFix, setIndexDisplayFix] = useState(0);
  const prevIndex = useRef(-1);

  useEffect(() => {
    createHubSpotDeal();
  }, []);

  useEffect(() => {
    if (formikState.values === initialValues) {
      return;
    }
    saveDataToLocalStorage(formikState.values);
  }, [formikState.values]);

  useEffect(() => {
    const config = getStepConfig(currentStepIndex);
    setCurrentStepConfig(config);
    prevIndex.current = currentStepIndex;
  }, [currentStepIndex]);

  if (!currentStepConfig) {
    return null;
  }

  function getStepConfig(index) {
    const id = STEPS_ORDER[index];
    const config = STEPS_CONFIG[id];

    const direction = index > prevIndex.current ? 1 : -1;

    if (!config) {
      return;
    }

    if (config.dependentOn && !formikState.values[config.dependentOn]) {
      setIndexDisplayFix((state) => state - direction);
      setCurrentStepIndex(index + direction);
      return;
    }

    return config;
  }

  function handleNext() {
    if (currentStepIndex === STEPS_ORDER.length - 1) {
      finish();
      return;
    }

    setCurrentStepIndex((state) => {
      return state + 1;
    });
  }

  function handleBack() {
    if (currentStepIndex === 0) {
      return;
    }

    setCurrentStepIndex((state) => {
      return state - 1;
    });
  }

  async function sendDataToPdfGenerator() {
    // send an email here to the pdf generation service
    // (with Zapier probably)
  }

  async function createHubSpotDeal() {
    // create HubSpot deal with HS API
    // use the email from the spec tool form
    // to find HubSpot customer
  }

  async function addSpecCalculationDataToHubSpotDeal() {
    // update HubSpot deal, add calculation data
    // use the email from the spec tool form
    // to find HubSpot customer
  }

  async function finish() {
    await Promise.all([
      sendDataToPdfGenerator,
      addSpecCalculationDataToHubSpotDeal,
    ]);
    onFinish();
    navigate(getPath.specToolCalculation());
  }

  const needBackButton = currentStepIndex > 0;

  const { component: StepFactoryComponent } = currentStepConfig;

  return (
    <WizardContentContainer>
      <WizardContent>
        <StyledContainer width={'100%'}>
          {needBackButton && isMobileAll && (
            <Flex justifyContent={'center'}>
              <WizardButtonBack onClick={handleBack} />
            </Flex>
          )}
          <Box mb={10} textAlign={'center'}>
            <Subtitle>Step {currentStepIndex + 1 + indexDisplayFix}</Subtitle>
          </Box>
          <StepFactoryComponent
            {...currentStepConfig.props}
            fieldName={currentStepConfig.id}
            values={formikState.values}
            onNext={handleNext}
          />
          {needBackButton && !isMobileAll && (
            <WizardButtonBack onClick={handleBack} />
          )}
        </StyledContainer>
      </WizardContent>
    </WizardContentContainer>
  );
}

let Wizard = ({ visible, isMobileAll }) => {
  const wizardRef = useRef(null);

  useEffect(() => {
    visible ? showWizard() : hideWizard();
  }, [visible]);

  function handleSubmit() {}

  function showWizard() {
    scrollLocker.lock();
    gsap.fromTo(
      wizardRef.current,
      0.55,
      { y: '100%', autoAlpha: 1 },
      { y: '0%' }
    );
  }

  function hideWizard() {
    scrollLocker.unlock();
    gsap.to(wizardRef.current, 0.35, {
      y: '100%',
      onComplete: () => {
        gsap.set(wizardRef.current, { visibility: 'hidden' });
      },
    });
  }

  function handleFinish() {
    scrollLocker.unlock();
  }

  return (
    <StyledSpecToolWizard ref={wizardRef}>
      <FormikWrapper initialValues={initialValues} onSubmit={handleSubmit}>
        {(formikState) => (
          <WizardInner
            formikState={formikState}
            isMobileAll={isMobileAll}
            onFinish={handleFinish}
          />
        )}
      </FormikWrapper>
      <WizardBottomBar />
    </StyledSpecToolWizard>
  );
};

Wizard.displayName = 'Wizard';

Wizard = withResponsiveContext(Wizard);

export default Wizard;
