import React from 'react';
import BannerWithCta from '../../banner-with-cta';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

function StoryblokBannerWithCta({ button, ...rest }) {
  const parsedButton = button &&
    button.length > 0 && {
      children: button[0].children,
      to: parseStoryblokLink(button[0].link),
    };

  return <BannerWithCta button={parsedButton} {...rest} />;
}

export default StoryblokBannerWithCta;
