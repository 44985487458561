import React from 'react';
import styled, { css } from 'styled-components';

import Title from '../title';
import { Box, Flex, Paragraph } from '../UIKit/system';
import Button from '../button';
import mq from '../../utils/mq';
import Responsive from '../responsive';

const ShapesContainer = styled.div``;

const ShapesUpperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${mq.mobile(css`
    flex-direction: column;
  `)};
`;

const StyledTitle = styled(Title)`
  width: 200px;
  font-size: 24px;
  line-height: 38px;
  ${mq.mobile(css`
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 2px;
  `)};
`;

const StyledParagraph = styled(Paragraph)`
  width: 360px;
  ${mq.mobile(css`
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    margin-bottom: 30px;
  `)};
`;

const ShapesTableContainer = styled.div`
  padding-top: 60px;
  ${mq.mobile(css`
    padding-top: 30px;
    overflow-x: auto;
  `)};
`;

const ShapesTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: right;
  margin-bottom: 16px;

  th {
    width: 20%;
    font-weight: bold;
    font-size: 14px;

    color: #161616;
    border-width: 0px;
    border-top-width: 0px;

    ${(props) =>
      props.left &&
      css`
        text-align: left;
      `};
    ${mq.mobile(css`
      font-size: 14px;
      text-align: left;
      white-space: nowrap;
      min-width: fit-content;
      padding-right: 15px;
    `)};
  }

  tr {
    width: 20%;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #666666;
    border-width: 0px;
    border-top-width: 1px;
    border-style: solid;
    border-color: #ebebeb;
    &:first-of-type {
      border-top-width: 0px;
    }
    ${mq.mobile(css`
      font-size: 12px;
      text-align: left;
    `)};
  }

  td {
    padding: 10px 0px;
    border-color: #ebebeb;

    ${(props) =>
      props.left &&
      css`
        text-align: left;
      `};
  }
`;

const LeftTh = styled.th`
  text-align: left;
`;

const LeftTd = styled.td`
  text-align: left;
`;

const ViewMore = styled.a`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #666666;
  width: 100%;
  text-align: left;
  padding-top: 16px;
  ${mq.mobile(css`
    font-size: 14px;
  `)};
`;

const StyledButton = styled(Button)`
  color: #161616;
  border-color: #d4d4d4 !important;
  width: 192px;
`;

function shapes({ items }) {
  let data = items[0];
  return (
    <ShapesContainer>
      {data && (
        <>
          <ShapesUpperContainer>
            <StyledTitle>{data.title && data.title}</StyledTitle>
            <StyledParagraph>
              {data.paragraph && data.paragraph}
            </StyledParagraph>
            {!data.hideFullSizeGuideCta && data.button && (
              <StyledButton {...data.button} transparent />
            )}
          </ShapesUpperContainer>
          <ShapesTableContainer>
            {data.largeDataMode ? (
              <>
                <ShapesTable>
                  <tr>
                    <LeftTh>{data.largeDataColumnOneName}</LeftTh>
                    <th>{data.largeDataColumnTwoName}</th>
                    <th>{data.largeDataColumnThreeName}</th>
                    <Responsive.DesktopOrTablet>
                      <th>{data.largeDataColumnFourName}</th>
                      <th>{data.largeDataColumnFiveName}</th>
                    </Responsive.DesktopOrTablet>
                  </tr>

                  {data.largeDataTable.map((data, i) => (
                    <tr key={i}>
                      <LeftTd>{data.models}</LeftTd>
                      <td>{data.width}</td>
                      <td>{data.height}</td>
                      <Responsive.DesktopOrTablet>
                        <td>{data.area}</td>
                        <td>{data.weight}</td>
                      </Responsive.DesktopOrTablet>
                    </tr>
                  ))}
                </ShapesTable>
                <ViewMore href={data.sizeGuideFile.filename} target={'_blank'}>
                  View fill size guide for individual sizes
                </ViewMore>
              </>
            ) : (
              <ShapesTable>
                <tr>
                  <LeftTh>{data.columnOneName}</LeftTh>
                  <th>{data.columnTwoName}</th>
                  <th>{data.columnThreeName}</th>
                  <Responsive.DesktopOrTablet>
                    <th>{data.columnFourName}</th>
                    <th>{data.columnFiveName}</th>
                  </Responsive.DesktopOrTablet>
                </tr>

                {data.tableData &&
                  data.tableData.map((data, i) => (
                    <tr key={i}>
                      <LeftTd>{data.type}</LeftTd>
                      <td>{data.width}</td>
                      <td>{data.height}</td>
                      <Responsive.DesktopOrTablet>
                        <td>{data.area}</td>
                        <td>{data.columnFive}</td>
                      </Responsive.DesktopOrTablet>
                    </tr>
                  ))}
              </ShapesTable>
            )}
          </ShapesTableContainer>
        </>
      )}
    </ShapesContainer>
  );
}

export default shapes;
