import React from 'react';

import Button from '../button';

function ButtonContinue(props) {
  return (
    <Button height={[64]} chevronRight {...props}>
      Continue
    </Button>
  );
}

export default ButtonContinue;
