import React, { FunctionComponent, useMemo } from "react"
import { CarouselItem as CarouselItemType } from "./types"
import ReactSwiper from "../react-swiper"

import CarouselItem from "./item"

interface Props {
  items: CarouselItemType[]
  duration?: number
  autoplay?: boolean
}

const Carousel: FunctionComponent<Props> = ({ items, duration = 3000, autoplay = true }) => {
  const slides = useMemo(() => items.filter(item => !!item.desktopImage), [items])

  const swiperOptions = {
    autoplay: autoplay ? {
      delay: duration,
      pauseOnMouseEnter: true
    } : false,
    loop: true,
    slidesPerView: 1
  }

  return (
    <ReactSwiper options={swiperOptions} className="" wrapperClassName="" onChange={undefined} onProgressChange={undefined}>
      {slides.map((item, index) => (
        <ReactSwiper.Item key={index}>
          <CarouselItem item={item} />
        </ReactSwiper.Item>
      ))}
    </ReactSwiper>
  )
}


export default Carousel
