import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Container from './container';
import Title from './title';
import { Box, Text } from './UIKit/system';
import CaseStudyWithCta from './case-study-with-cta';
import TileGrid from './tile-grid';
import mq from '../utils/mq';

const CaseStudiesContainer = styled(Box)`
  background: #161616;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;

  ${mq.mobile(css`
    flex-direction: column;
    margin-bottom: 20px;
  `)};
`;

const StyledTitle = styled(Title)`
  color: #fff;
  max-width: 540px;
  font-weight: bold;
  font-size: 42px;
  line-height: 56px;
  padding-right: 10px;
  ${mq.tablet(css`
    font-size: 32px;
    line-height: 36px;
  `)};
  ${mq.mobile(css`
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 20px;
  `)};
`;

const Paragraph = styled(Text)`
  color: #fff;
  width: 100%;
  max-width: 525px;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: -0.01em;
  padding-top: 12px;
  ${mq.mobile(css`
    font-size: 16px;
    line-height: 28px;
  `)};
`;

function CaseStudies({ title, text, items }) {
  const [ref, inView] = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  return (
    <CaseStudiesContainer pt={98} pb={111} ref={ref}>
      <Container medium>
        <TextContent>
          <StyledTitle fontSize={[32, 22, 42]} html={title} />
          <Paragraph grey html={text} />
        </TextContent>
        <motion.div animate={inView && { opacity: 1 }} initial={{ opacity: 0 }}>
          <TileGrid>
            {items.map((item, index) => (
              <CaseStudyWithCta key={index} gatsbyImg {...item} dark />
            ))}
          </TileGrid>
        </motion.div>
      </Container>
    </CaseStudiesContainer>
  );
}

export default CaseStudies;
