import React from 'react';
import styled from 'styled-components';

import Title from '../title';
import { Box, Paragraph } from '../UIKit/system';

const StyledStep = styled.div``;

function StepBase({ name, children }) {
  return (
    <StyledStep>
      <Box mb={45} textAlign={'center'}>
        <Title mb={20}>{name}</Title>
        <Paragraph grey>
          Our online spec tool lets you easily define your scope.
        </Paragraph>
      </Box>
      <Box>{children}</Box>
    </StyledStep>
  );
}

export default StepBase;
