import React from 'react';
import styled, { keyframes } from 'styled-components';

const bounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  animation: ${bounceAnimation} 1.5s infinite ease-in-out both;
  background-color: #726f79;
  margin-right: 7px;

  &:last-child {
    margin-right: 0;
  }

  &:nth-of-type(1) {
    animation-delay: -0.45s;
  }

  &:nth-of-type(2) {
    animation-delay: -0.225s;
  }
`;

const Loader = React.forwardRef((props, ref) => {
  return (
    <StyledLoader ref={ref} {...props}>
      <Circle />
      <Circle />
      <Circle />
    </StyledLoader>
  );
});

Loader.displayName = 'Loader';

export default Loader;
