import React from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

import Title from './title';
import CaseStudyWithCta from './case-study-with-cta';
import { Box, Flex, Text } from './UIKit/system';
import { getThemeColor } from '../utils/styles';
import Container from './container';
import mq from '../utils/mq';

const LeftQuoteRightCaseStudyContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  ${(props) =>
    props.grey &&
    css`
      background-color: #f8f8f8;
    `};
`;

const QuoteContainerOuter = styled(Container)`
  flex-direction: row;
  ${(props) =>
    props.rev &&
    css`
      flex-direction: row-reverse;
    `};
  ${mq.mobile(css`
    flex-wrap: wrap;
    margin: 0px;
  `)};
`;

const QuoteContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  padding-right: 60px;
  max-width: 620px;
  ${(props) =>
    props.rev &&
    css`
      align-items: flex-end;
    `};

  @media (max-width: 960px) {
    width: 100%;
    max-width: unset;
    flex: unset;
    ${(props) =>
      props.rev &&
      css`
        align-items: flex-end;
      `};
  }
  ${mq.mobile(css`
    max-width: unset;
    padding-right: 0px;
    padding-left: 0px;
  `)};
`;

const QuoteBody = styled(Title)`
  position: relative;
  display: inline;
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;

  &:before {
    content: '“';
    display: block;
    position: absolute;
    color: ${getThemeColor('primary')};
    left: -6px;
    top: 0;
    transform: translateX(-100%);
    ${mq.mobile(css`
      left: 15px;
      top: -30px;
      font-size: 42px;
    `)};
  }

  &:after {
    content: '”';
    display: inline;
    color: ${getThemeColor('primary')};
    margin-left: 8px;
    ${mq.mobile(css`
      display: none;
    `)};
  }

  ${(props) =>
    props.largeQuoteMode &&
    css`
      font-size: 27px;
      line-height: 32px;
    `};

  ${mq.mobile(css`
    font-size: 32px;
    line-height: 36px;
    ${(props) =>
      props.largeQuoteMode &&
      css`
        font-size: 20px;
        line-height: 24px;
      `};
  `)};
`;

const QuoteAuthor = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 5px;

  ${mq.tablet(css`
    margin-top: 33px;
  `)};
  ${mq.mobile(css`
    font-size: 16px;
  `)};
`;

const QuoteCompany = styled(Text)`
  font-size: 15px;

  color: #666;
  ${mq.tablet(css`
    margin-bottom: 50px;
  `)};
  ${mq.mobile(css`
    font-size: 14px;
  `)};
`;

const CaseStudyItemContainer = styled(Box)`
  ${mq.tablet(css`
    width: 50%;
  `)};
  ${mq.mobile(css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `)};
`;

const GatsbyImg = styled(Img)`
  max-width: 100%;
  width: 350px;
`;

function SectionQuote({
  text,
  author,
  company,
  caseStudy,
  image,
  rev,
  grey,
  largeQuoteMode,
}) {
  return (
    <LeftQuoteRightCaseStudyContainer py={[65, null, 125]} grey={grey}>
      <QuoteContainerOuter rev={rev} as={Flex} justifyContent={'space-between'}>
        <QuoteContainer rev={rev}>
          <QuoteBody html={text} largeQuoteMode={largeQuoteMode} />
          <QuoteAuthor html={author} />
          <QuoteCompany html={company} />
        </QuoteContainer>

        <CaseStudyItemContainer flexShrink={[1, 1, 1, 0]}>
          {caseStudy && (
            <CaseStudyWithCta
              zoomOut={true}
              width={350}
              maxWidth={'100%'}
              {...caseStudy}
            />
          )}
          {image && <GatsbyImg fixed={image} />}
        </CaseStudyItemContainer>
      </QuoteContainerOuter>
    </LeftQuoteRightCaseStudyContainer>
  );
}

export default SectionQuote;
