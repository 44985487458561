import React, { FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'

// Styles
const StyledSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: fit-content;
  gap: 2rem;
  margin: 2rem;
  max-width: 600px;

  @media (max-width: 500px) {
    margin: 2rem 0;
    gap: 1rem;
    justify-content: flex-start;
    overflow: auto;
    max-width: 90vw;
  }
`

const StyledSelectOption = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  min-width: 240px;
  text-align: center;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 1px;
  padding-top: 1.5rem;

  @media (max-width: 500px) {
    min-width: 40vw;
  }

  .title {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 93.5%;
    color: #161616;
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }

  .image {
    margin-top: 1.5rem;
    padding: 0 1.5rem;

    &.top {
      margin-bottom: 3rem;
    }

    svg {
      max-width: 120px;
    }
  }

  .text {
    margin: 1rem 0;
    padding: 0 1.5rem;
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #666666;
  }

  .button {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    text-align: center;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;

    color: #753fbf;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }

  &.is-active {
    border: 2px solid #753fbf;

    .button {
      color: #fff;
      background-color: #753fbf;
    }
  }
`

// Types
interface Option {
  title: string
  image: string | FunctionComponent
  text?: string
  value: unknown
}
interface Props {
  options: Option[]
  callback: (value: unknown) => void
  input: unknown
}

interface SelectOptionProps extends Option {
  active: boolean
  setOption: (value: unknown) => void
}
const SelectOption: FunctionComponent<SelectOptionProps> = ({ active, title, image, text, value, setOption }) => {
  return (
    <StyledSelectOption className={active && 'is-active'}>
      {typeof image === 'function' && <div className="image top">{image({})}</div>}
      <p className="title">{title}</p>
      {typeof image === 'string' && <div className="image" dangerouslySetInnerHTML={{ __html: image }}></div>}
      {text && <p className="text">{text}</p>}
      <button className="button" onClick={() => setOption(value)} type="button">
        Select
      </button>
    </StyledSelectOption>
  )
}

export const SelectCard: FunctionComponent<Props> = ({ options, callback, input }) => {
  const [activeOption, setActiveOption] = useState<unknown>(input)

  const handleSetActiveOption = useCallback(
    (value: unknown) => {
      setActiveOption(value)
      callback(value)
    },
    [options]
  )
  return (
    <StyledSelect>
      {options.map((option, i) => (
        <SelectOption key={i} {...option} active={option.value === activeOption} setOption={handleSetActiveOption} />
      ))}
    </StyledSelect>
  )
}

export default SelectCard
