import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Text } from '../UIKit/system';
import { buttonReset, hoverDefault } from '../../utils/styles';
import IconInfo from '../../images/svg/icon-info.svg';
import InfoPopup from './info-popup';
import mq from '../../utils/mq';

const BottomBar = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 52px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq.mobile(css`
    height: 46px;
  `)};
`;

const Button = styled.button`
  ${hoverDefault};
  ${buttonReset};
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  :focus {
    outline: none;
  }
`;

const StyledIconInfo = styled(IconInfo)`
  margin-right: 8px;
`;

function WizardBottomBar() {
  const [popupIsVisible, setPopupIsVisible] = useState(false);

  return (
    <>
      <BottomBar>
        <Button onClick={() => setPopupIsVisible(true)}>
          <StyledIconInfo />
          <Text fontSizeExtraSmall bold>
            Why is this important?
          </Text>
        </Button>
      </BottomBar>
      {popupIsVisible && <InfoPopup onClose={() => setPopupIsVisible(false)} />}
    </>
  );
}

export default WizardBottomBar;
