import React from 'react';
import RichtextParser from '../../../utils/richtext-parser';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

import SectionVacancies from '../../section-vacancies';

function StoryblokVacanciesAdapter({ title, paragraph, vacancies }) {
  let parsedParagraph = RichtextParser(paragraph);
  let parsedVacancies = [];
  vacancies.forEach((vacancy) => {
    let vacancyObj = {
      title: vacancy.positionTitle,
      link: parseStoryblokLink(vacancy.link),
    };
    parsedVacancies.push(vacancyObj);
  });
  return (
    <SectionVacancies
      title={title}
      paragraph={parsedParagraph}
      items={parsedVacancies}
    />
  );
}

export default StoryblokVacanciesAdapter;
