import React from 'react';
import styled, { css } from 'styled-components';

import { vw } from '../utils/size-functions';
import mq from '../utils/mq';
import { Box } from './UIKit/system';

const StyledGridCol = styled(Box)`
  width: ${vw(545)};
  flex-shrink: 0;
  padding-right: ${vw(60)};

  ${mq.desktopLarge(css`
    width: 555px;
    padding-right: 60px;
  `)}
`;

function GridCol(props) {
  return <StyledGridCol {...props} />;
}

export default GridCol;
