import React from 'react';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed';
import RelatedProducts from '../../related-products';

function StoryblokRelatedProductsCarouselAdapter({ title, products }) {
  let relatedProductsParsed = [];

  products.forEach((product) => {
    if (product && product.content) {
      const productImg = getFluidGatsbyImage(product.content.productThumbnail, {
        maxWidth: 500,
      });
      let productParsed = {
        name: product.content.productName,
        link: '/' + product.full_slug,
        image: productImg,
      };
      relatedProductsParsed.push(productParsed);
    }
  });
  return <RelatedProducts title={title} products={relatedProductsParsed} />;
}

export default StoryblokRelatedProductsCarouselAdapter;
