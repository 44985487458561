import React from 'react';
import styled, { css } from 'styled-components';

import Title from './title';
import { Text } from './UIKit/system';
import IconCard from './section-icon-card';
import mq from '../utils/mq';

const ThreeCardIconBlockContainer = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  padding-top: 96px;
  padding-bottom: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledTitle = styled(Title)`
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.02em;
  color: #161616;
  margin-bottom: 18px;
`;

const Paragraph = styled(Text)`
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #666666;
  margin-bottom: 39px;
  max-width: 718px;
  text-align: center;
  ${mq.mobile(css`
    max-width: 90%;
  `)};
`;

const CardItemsContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  ${mq.desktopSmall(css`
    flex-wrap: wrap;
  `)};
  ${mq.tablet(css`
    justify-content: flex-start;
    width: 600px;
  `)};
  ${mq.mobile(css`
    width: 100%;
    justify-content: center;
  `)};
`;

function ThreeCardIconBlock({ title, paragraph, cards }) {
  return (
    <ThreeCardIconBlockContainer>
      <StyledTitle html={title} />
      <Paragraph grey html={paragraph} />
      <CardItemsContainerOuter>
        <CardItemsContainer>
          {cards.map((card, index) => (
            <IconCard
              key={index}
              title={card.title}
              paragraph={card.paragraph}
              ctaText={card.ctaText}
              ctaLink={card.ctaLink}
              icon={card.icon}
            />
          ))}
        </CardItemsContainer>
      </CardItemsContainerOuter>
    </ThreeCardIconBlockContainer>
  );
}
export default ThreeCardIconBlock;
