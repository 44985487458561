import React from 'react';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

import Intro from '../../intro';

function StoryblokIntroAdapter({
  backgroundImage,
  backgroundImageMode,
  ctaEnabled,
  ctaText,
  ctaLink,
  videoEnabled,
  videoLink,
  ctaMode,
  downloadPopupTitle,
  ctaDownloadFile,
  ...rest
}) {
  const fluidBackgroundImage = getFluidGatsbyImage(backgroundImage, {
    maxWidth: 2560,
  });

  if (backgroundImageMode) {
    if (ctaEnabled) {
      if (ctaMode === 'link') {
        return (
          <Intro
            imageTop
            backgroundImage={fluidBackgroundImage}
            link={{
              to: parseStoryblokLink(ctaLink),
              children: ctaText,
            }}
            {...rest}
          />
        );
      } else if (ctaMode === 'video') {
        return (
          <Intro
            imageTop
            backgroundImage={fluidBackgroundImage}
            ctaText={ctaText}
            video
            videoLink={videoLink}
            {...rest}
          />
        );
      } else if (ctaMode === 'download') {
        return (
          <Intro
            imageTop
            backgroundImage={fluidBackgroundImage}
            ctaText={ctaText}
            download
            popupTitle={downloadPopupTitle}
            filePath={ctaDownloadFile && ctaDownloadFile.filePath}
            {...rest}
          />
        );
      }
    } else {
      return (
        <Intro imageTop backgroundImage={fluidBackgroundImage} {...rest} />
      );
    }
  } else {
    if (ctaEnabled) {
      if (ctaMode === 'link') {
        return (
          <Intro
            image={fluidBackgroundImage}
            link={{
              to: parseStoryblokLink(ctaLink),
              children: ctaText,
            }}
            {...rest}
          />
        );
      } else if (ctaMode === 'video') {
        return (
          <Intro
            image={fluidBackgroundImage}
            video
            videoLink={videoLink}
            ctaText={ctaText}
            {...rest}
          />
        );
      } else if (ctaMode === 'download') {
        return (
          <Intro
            image={fluidBackgroundImage}
            ctaText={ctaText}
            download
            popupTitle={downloadPopupTitle}
            filePath={ctaDownloadFile && ctaDownloadFile.filePath}
            {...rest}
          />
        );
      }
    } else {
      return <Intro image={fluidBackgroundImage} {...rest} />;
    }
  }
}

export default StoryblokIntroAdapter;
