import React from 'react';

import BlogPost from '../blog-post';
import { getFluidGatsbyImage } from '../../utils/gatsby-storyblok-image-fixed';
import parseStoryblokLink from '../../utils/parse-storyblok-link';

function StoryblokBlogPostPage({
  pageName,
  author,
  image,
  relatedArticles,
  relatedArticlesParagraph,
  ...rest
}) {
  return (
    <BlogPost
      {...rest}
      title={pageName}
      image={getFluidGatsbyImage(image, { maxWidth: 2560 })}
      author={author.content}
      relatedArticlesParagraph={relatedArticlesParagraph}
      relatedArticles={
        relatedArticles &&
        relatedArticles.length !== 0 &&
        relatedArticles.map((v) => {
          const content = v.link && v.link.content;

          if (!content) {
            return null;
          }

          return {
            ...content,
            title: content.title || content.customTitle,
            gridImage: getFluidGatsbyImage(
              (content.gridImage &&
                content.gridImage.filename &&
                content.gridImage) ||
                content.image,
              { maxWidth: 300 }
            ),
            to: parseStoryblokLink(v.link.full_slug),
          };
        })
      }
    />
  );
}

export default StoryblokBlogPostPage;
