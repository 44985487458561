// Types
import { Feature, HSFormFieldKeys, HSFormPayload, SimpleFormInput } from '../types'
import { Info } from '../root-page'
import { useCalculateSimpleValues } from './formula-simple'
import { AdvancedFormulaInput, formatNumber } from './formula-advanced'

import { useCalculatorAdvancedValues } from './formula-advanced'

export interface FormPayloadInput {
  info: Info
  features: Feature[]
}

export interface FormSimplePayloadInput extends FormPayloadInput {
  input: SimpleFormInput
  calculated: ReturnType<typeof useCalculateSimpleValues>
}
export interface FormAdvancedPayloadInput extends FormPayloadInput {
  input: AdvancedFormulaInput
  calculated: ReturnType<typeof useCalculatorAdvancedValues>
}

export function useSimpleFormPayload({ info, input, features, calculated }: FormSimplePayloadInput): HSFormPayload {
  const cookies = new Map<string, string>(document.cookie.split(';').map((str) => str.split('=') as [string, string]))
  return {
    context: {
      email: info.email,
      hutk: cookies.get('hubspotutk'),
      pageName: document.title,
      pageUri: document.location.href
    },
    fields: [
      {
        name: HSFormFieldKeys.email,
        value: info.email
      },
      {
        name: HSFormFieldKeys.project_name,
        value: info.projectName
      },
      {
        name: HSFormFieldKeys.firstname,
        value: info.firstname
      },
      {
        name: HSFormFieldKeys.lastname,
        value: info.lastname
      },
      {
        name: HSFormFieldKeys.doors,
        value: input.doors.toString()
      },
      {
        name: HSFormFieldKeys.estimated_extract_rate,
        value: `${calculated.estimatedExtractRate.toFixed(2)}m3/s`
      },
      {
        name: HSFormFieldKeys.extract_rate_at_damper,
        value: `${calculated.extractRateAtDamper.toFixed(2)}m3/s`
      },
      {
        name: HSFormFieldKeys.extract_rate_required,
        value: `${calculated.extractRateRequired.toFixed(2)}m3/s`
      },
      {
        name: HSFormFieldKeys.features,
        value: (input.features ?? []).reduce((str, uuid) => {
          const feature = features.find((feature) => feature.uuid === uuid)
          return feature ? str.concat(feature.title, ', ') : str
        }, '')
      },
      {
        name: HSFormFieldKeys.form_type,
        value: 'Early Stage'
      },
      {
        name: HSFormFieldKeys.lift_doors,
        value: input.liftDoors.toString()
      },
      {
        name: HSFormFieldKeys.shaft_depth,
        value: `${input.dimensions.shaftDepth}mm`
      },
      {
        name: HSFormFieldKeys.shaft_width,
        value: `${input.dimensions.shaftWidth}mm`
      },
      {
        name: HSFormFieldKeys.storeys,
        value: input.storeys.toString()
      },
      {
        name: HSFormFieldKeys.total_smoke_damper_leakage,
        value: `${calculated.totalSmokeDamperLeakage.toFixed(2)}m3/s`
      },
      {
        name: HSFormFieldKeys.total_smoke_shaft_damper_leakage,
        value: `${calculated.totalSmokeShaftDamperLeakage.toFixed(2)}m3/s`
      },
      {
        name: HSFormFieldKeys.total_smoke_shaft_leakage,
        value: `${calculated.totalSmokeShaftLeakage.toFixed(2)}m3/s`
      },
      {
        name: HSFormFieldKeys.total_surface_area,
        value: calculated.totalSurfaceArea.toString()
      },
      {
        name: HSFormFieldKeys.total_system_leakage,
        value: `${calculated.totalSystemLeakage.toFixed(2)}m3/s`
      }
    ]
  }
}

export function useAdvancedFormPayload({ info, input, features, calculated }: FormAdvancedPayloadInput): HSFormPayload {
  const cookies = new Map<string, string>(document.cookie.split(';').map((str) => str.split('=') as [string, string]))

  const formattedCalculatedValues = Object.entries(calculated).flatMap(([name, value]) => (typeof value === 'number' ? [{ name, value: formatNumber(value) }] : []))

  return {
    context: {
      email: info.email,
      hutk: cookies.get('hubspotutk'),
      pageName: document.title,
      pageUri: document.location.href
    },
    fields: [
      {
        name: HSFormFieldKeys.email,
        value: info.email
      },
      {
        name: HSFormFieldKeys.project_name,
        value: info.projectName
      },
      {
        name: HSFormFieldKeys.firstname,
        value: info.firstname
      },
      {
        name: HSFormFieldKeys.lastname,
        value: info.lastname
      },
      {
        name: HSFormFieldKeys.doors,
        value: input.doors.toString()
      },
      {
        name: HSFormFieldKeys.extract_rate_required,
        value: `${calculated.extractRateRequired.toFixed(2)}m3/s`
      },
      {
        name: HSFormFieldKeys.features,
        value: (input.features ?? []).reduce((str, uuid) => {
          const feature = features.find((feature) => feature.uuid === uuid)
          return feature ? str.concat(feature.title, ', ') : str
        }, '')
      },
      {
        name: HSFormFieldKeys.form_type,
        value: 'Early Stage'
      },
      {
        name: HSFormFieldKeys.lift_doors,
        value: input.liftDoors.toString()
      },
      {
        name: HSFormFieldKeys.shaft_depth,
        value: `${input.dimensions.shaftDepth}mm`
      },
      {
        name: HSFormFieldKeys.shaft_width,
        value: `${input.dimensions.shaftWidth}mm`
      },
      {
        name: HSFormFieldKeys.storeys,
        value: input.storeys.toString()
      },
      ...formattedCalculatedValues
    ]
  }
}
