import React from 'react';
import styled, { css } from 'styled-components';

import { Flex, Text } from './UIKit/system';
import PlayIcon from '../images/svg/play.svg';
import { getThemeColor, transitionDefault } from '../utils/styles';

const config = {
  shouldForwardProp: (prop) => !['semiTransparent'].includes(prop),
};

const Circle = styled.div.withConfig(config)`
  ${transitionDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: #e8e8e8;
`;

const StyledPlayIcon = styled(PlayIcon).withConfig(config)`
  ${transitionDefault};
  fill: ${(props) =>
    props.semiTransparent ? '#fff' : getThemeColor('black')(props)};
`;

const StyledButtonPlay = styled.div`
  cursor: pointer;
  user-select: none;

  &:hover {
    transition: none;
    color: ${getThemeColor('primary')};

    ${Circle} {
      transition: none;
      background-color: ${getThemeColor('primary')};
    }

    ${StyledPlayIcon} {
      transition: none;
      fill: #fff;
    }
  }

  ${(props) =>
    props.semiTransparent &&
    css`
      ${Circle} {
        background-color: rgba(255, 255, 255, 0.3);
      }

      &:hover {
        color: #fff;

        ${Circle} {
          background-color: #fff;
        }

        ${StyledPlayIcon} {
          fill: ${getThemeColor('black')(props)};
        }
      }
    `};
`;

function ButtonPlay({ children, semiTransparent, ...rest }) {
  return (
    <StyledButtonPlay
      as={Flex}
      alignItems={'center'}
      semiTransparent={semiTransparent}
      {...rest}
    >
      <Circle>
        <StyledPlayIcon semiTransparent={semiTransparent} />
      </Circle>
      <Text bold>{children}</Text>
    </StyledButtonPlay>
  );
}

export default ButtonPlay;
