import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph, Text } from '../UIKit/system';
import Title from '../title';
import { IconRow, TitleRow } from './shared-components';
import IconTick from '../../images/svg/tick-in-circle.svg';
import IconMinus from '../../images/svg/minus-in-circle.svg';
import LazyImage from '../lazy-image';
import mq from '../../utils/mq';

const StyledComparisonGrid = styled.div``;

function FirstColumn(props) {
  return (
    <Flex flexShrink={0} width={['null', '33%', '43.77%']} mr={36} {...props} />
  );
}

const DataColumn = styled.div`
  flex: 1;
  padding: 0 26px;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  ${(props) =>
    (props.isValueColumn || props.isNameColumn) &&
    css`
      background-color: #f7f7f8;
    `};
`;

const DataCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 44px;
  padding-bottom: 15px;
  border-top: 1px solid #ebebeb;
  width: 100%;

  ${(props) =>
    props.isValueCell &&
    css`
      align-items: center;
      border-top-color: #ddd;
      text-align: center;
    `};
`;

const Row = styled.div`
  display: flex;

  &:first-of-type {
    ${DataCell} {
      border-top: none;
    }
  }

  &:last-of-type {
    ${DataCell} {
      padding-bottom: 37px;
    }
  }
`;

const IconRowOuter = styled.div`
  height: 120px;
`;

const StyledIconRow = styled.div`
  display: block;
  margin-left: 70px;
`;

function ComparisonGrid({ params, data }) {
  return (
    <StyledComparisonGrid>
      <Box>
        <Flex>
          <FirstColumn>
            <IconRow />
          </FirstColumn>
          {data.map((columnData, index) => {
            return (
              <DataColumn key={index}>
                <IconRowOuter>
                  <StyledIconRow>
                    {columnData.icon && (
                      <LazyImage maxWidth={90} fluid={columnData.icon} />
                    )}
                  </StyledIconRow>
                </IconRowOuter>
              </DataColumn>
            );
          })}
        </Flex>
        <Flex>
          <FirstColumn>
            <TitleRow>
              <Title color={'primary'} fontSizeExtraSmall2>
                System Type
              </Title>
            </TitleRow>
          </FirstColumn>
          {data.map((columnData, index) => {
            return (
              <DataColumn key={index} isNameColumn>
                <TitleRow justifyContent={'center'}>
                  <Title fontSizeExtraSmall2>{columnData.title}</Title>
                </TitleRow>
              </DataColumn>
            );
          })}
        </Flex>
        <Box>
          {params.map((param, paramIndex) => {
            return (
              <Row key={paramIndex}>
                <FirstColumn>
                  <DataCell>
                    <Title mb={5} fontSizeExtraSmall2 html={param.title} />
                    <Text grey html={param.subtitle} />
                  </DataCell>
                </FirstColumn>
                {data.map((columnData, index) => {
                  const item = columnData.data[paramIndex];
                  return (
                    <DataColumn key={index} isValueColumn>
                      <DataCell isValueCell>
                        {item.availability ? <IconTick /> : <IconMinus />}
                        <Paragraph mt={12} mb={6} fontSizeSmall grey>
                          {item.text}
                        </Paragraph>
                      </DataCell>
                    </DataColumn>
                  );
                })}
              </Row>
            );
          })}
        </Box>
      </Box>
    </StyledComparisonGrid>
  );
}

export default ComparisonGrid;
