import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../UIKit/system';
import { lightGreyBackground } from '../../utils/styles';
import Container from '../container';
import TabsHeader from './tabs-header';
import TabsContent from './tabs-content';
import mq from '../../utils/mq';

const StyledTabs = styled(Box)`
  ${lightGreyBackground};
`;

const StyledContainer = styled(Container)`
  ${mq.mobile(css`
    border-left-width: 0px;
    border-right-width: 0px;
  `)};
`;

function SectionTabs({ items, extended, productName, current }) {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <StyledTabs pt={[75, 100, 126]} pb={[75, 95]}>
      <StyledContainer>
        <TabsHeader
          items={items}
          activeIndex={activeIndex}
          extended={extended}
          onSwitch={(index) => setActiveIndex(index)}
        />
        <TabsContent items={items} productName={productName} activeIndex={activeIndex} current={current} />
      </StyledContainer>
    </StyledTabs>
  );
}

export default SectionTabs;
