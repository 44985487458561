import React from 'react';
import styled, { css } from 'styled-components';
import mq from '../utils/mq';

const SpacerItem = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  ${mq.tablet(css`
    height: ${(props) => props.tabletHeight};
  `)};
  ${mq.mobile(css`
    height: ${(props) => props.mobileHeight};
  `)};
`;

function Spacer(props) {
  return (
    <SpacerItem
      height={props.height}
      tabletHeight={props.tabletHeight ? props.tabletHeight : props.height}
      mobileHeight={props.mobileHeight ? props.mobileHeight : props.height}
    />
  );
}

export default Spacer;
