import React from 'react';
import styled, { css } from 'styled-components';

import Container from '../container';
import { Box, Flex, Paragraph } from '../UIKit/system';
import TextWithBar from '../text-with-bar';
import Title from '../title';
import RichTextField from '../rich-text-field';
import Form from './form';
import { darkBackground } from '../../utils/styles';
import LazyImage from '../lazy-image';
import mq from '../../utils/mq';

const StyledSpecToolIntro = styled(Box)`
  ${darkBackground};
  position: relative;
  color: #fff;
  min-height: 750px;
  display: flex;
  align-items: center;
`;

const StyledTitle = styled(Title)`
  ${mq.tablet(css`
    font-size: 42px;
  `)};

  ${mq.mobile(css`
    font-size: 32px;
  `)};
`;

const BackgroundImage = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

function Intro({
  title,
  subtitle,
  backgroundImage,
  text,
  bottomText,
  onFormSubmit,
}) {
  return (
    <StyledSpecToolIntro pt={63} pb={[60, 80, 105]}>
      <BackgroundImage fluid={backgroundImage.childImageSharp.fluid} />
      <Container position={'relative'}>
        <Flex
          flexDirection={['column', 'row']}
          alignItems={['flex-start', 'center']}
          justifyContent={'space-between'}
        >
          <Box maxWidth={[420, null, 480]} pr={[0, 30]} mb={[30, 0]}>
            <TextWithBar mb={10}>{subtitle}</TextWithBar>
            <Box mb={25}>
              <StyledTitle as={'h1'}>{title}</StyledTitle>
            </Box>
            <Paragraph mb={[40, null, 60]}>
              <RichTextField data={text} />
            </Paragraph>
            <Paragraph mb={0} fontSizeExtraSmall lightGrey>
              <RichTextField data={bottomText} />
            </Paragraph>
          </Box>
          <Form onSubmit={onFormSubmit} />
        </Flex>
      </Container>
    </StyledSpecToolIntro>
  );
}

export default Intro;
