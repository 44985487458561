import React from 'react';
import styled, { css } from 'styled-components';
import mq from '../../utils/mq';

import { buttonReset, getThemeColor, hoverDefault } from '../../utils/styles';

const StyledTabsHeader = styled.div`
  display: flex;
  justify-content: center;
  ${mq.mobile(css`
    overflow-x: auto;
    justify-content: flex-start;
    scroll-snap-type: x mandatory;
  `)};
`;

const StyledItem = styled.button`
  ${hoverDefault};
  ${buttonReset};
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 25px;
  color: #959595;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${getThemeColor('black')(props)};
      border-bottom-color: ${getThemeColor('primary')(props)};
      pointer-events: none;
    `};
  ${mq.mobile(css`
    min-width: 40%;
    justify-content: center;
    font-size: 13px;
    padding: 0 18px;
    scroll-snap-align: start;
  `)};
`;

function TabsHeader({ data, activeIndex, onSwitch }) {
  return (
    <StyledTabsHeader>
      {data.map((item, index) => {
        return (
          <StyledItem
            key={index}
            active={activeIndex === index}
            onClick={() => onSwitch(index)}
          >
            {item.sectionName}
          </StyledItem>
        );
      })}
    </StyledTabsHeader>
  );
}

export default TabsHeader;
