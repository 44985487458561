import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import StackGrid, { transitions } from 'react-stack-grid';
import { useInView } from 'react-intersection-observer';

import FeaturedProject from '../../featured-projects';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';
import Container from '../../container';
import Title from '../../title';
import RefreshMessage from '../../refresh-message';
import PreviewTile from '../../preview-tile';
import { Paragraph } from '../../UIKit/system';
import CaseStudiesBrowserFilter from '../../case-studies-browser-filter';
import Responsive, { withResponsiveContext } from '../../responsive';
import CaseStudiesBrowserFilterMobile from '../../case-studies-browser-filter-mobile';

const StyledContainer = styled(Container)`
  max-width: 1195px;
`;

const FeaturedProjectsContainer = styled(motion.div)`
  overflow: hidden;
`;

const NoItems = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  padding: 0px 10px;
`;

const StyledTitle = styled(Title)`
  text-transform: capitalize;
  margin-bottom: 6px;
`;

const StyledParagraph = styled(Paragraph)`
  text-transform: capitalize;
  margin-bottom: 30px;
  min-height: 28px;
`;

const StoryblokCaseStudiesBrowserAdapter = ({
  featuredProjectsTitle,
  featuredProjectsSubtitle,
  featuredProjects,
  isMobile,
}) => {
  const data = useStaticQuery(graphql`
    query CaseStudiesQuery {
      caseStudies: allStoryblokEntry(
        filter: { field_component: { eq: "caseStudy" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      locations: allStoryblokEntry(
        filter: { field_component: { eq: "location" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      properties: allStoryblokEntry(
        filter: { field_component: { eq: "propertyType" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `);

  const [relationFailed, setrelationFailed] = useState(false);

  const { scaleDown, scaleUp } = transitions;

  const [isInView, setIsInView] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      setIsInView(true);
    }, 10);
  }, []);

  const [filters, setFilters] = useState({
    projectType: '',
    propertyType: '',
    location: '',
  });

  let featuredCaseStudies = [];
  featuredProjects.forEach((project) => {
    if (!relationFailed) {
      if (project.content !== undefined) {
        let fluidProjectImage = getFluidGatsbyImage(project.content.gridImage, {
          maxWidth: 1080,
        });

        let parsedProject = {
          title: project.name,
          image: fluidProjectImage,
          to: '/' + project.full_slug,
        };
        featuredCaseStudies.push(parsedProject);
      } else {
        setrelationFailed(true);
      }
    }
  });

  let allCaseStudies = [];

  data.caseStudies.edges.forEach((project) => {
    let projectData = JSON.parse(project.node.content);
    let fluidProjectImage;
    if (projectData !== 'undefined' && projectData.gridImage !== 'undefined') {
      fluidProjectImage = getFluidGatsbyImage(projectData.gridImage, {
        maxWidth: 1080,
      });
    } else {
      fluidProjectImage = null;
    }
    let parsedProject = {
      title: project.node.name,
      image: fluidProjectImage,
      to: '/' + project.node.full_slug,
      location: projectData.location.name.toLowerCase(),
      projectType: projectData.projectType.toLowerCase(),
      propertyType: projectData.propertyType.name.toLowerCase(),
    };
    allCaseStudies.push(parsedProject);
  });

  function handleFilterUpdate(key, value) {
    const keyUpd = key;
    const valueUpd = value;
    setFilters((state) => ({
      ...state,
      [keyUpd]: valueUpd,
    }));
  }

  function filtersSelected(filters) {
    return Object.keys(filters).some((key) => !!filters[key]);
  }

  function renderFiltered() {
    const selectedFilterKeys = Object.keys(filters).filter(
      (key) => !!filters[key]
    );
    const filteredItems = allCaseStudies.filter((v) => {
      return selectedFilterKeys.every((key) => v[key] === filters[key]);
    });

    if (filteredItems.length === 0) {
      return (
        <NoItems>
          <div>
            No items matched your filtering - try removing some filters.
          </div>
        </NoItems>
      );
    }

    let projectTypeTitle;
    let projectSubtitle;

    if (filters.projectType === '') {
      projectTypeTitle = 'All Projects';
    } else {
      projectTypeTitle = filters.projectType + ' Projects';
    }

    if (filters.propertyType === '') {
      if (filters.location === '') {
        projectSubtitle = ' ';
      } else {
        projectSubtitle = 'In ' + filters.location;
      }
    } else {
      if (filters.location === '') {
        projectSubtitle = filters.propertyType + ' Buildings';
      } else {
        projectSubtitle =
          filters.propertyType + ' Buildings in ' + filters.location;
      }
    }

    return (
      <StyledContainer pt={[80, 103]} mb={[80, 130, 156]}>
        <StyledTitle mb={40} fontSizeMedium textAlign={'center'}>
          {projectTypeTitle}
        </StyledTitle>
        <StyledParagraph grey textAlign={'center'}>
          {projectSubtitle}
        </StyledParagraph>
        <StackGrid
          columnWidth={346}
          gutterWidth={26}
          gutterHeight={26}
          style={{ minHeight: '372px' }}
          appear={scaleUp.appear}
          appeared={scaleUp.appeared}
          enter={scaleUp.enter}
          entered={scaleUp.entered}
          leaved={scaleDown.leaved}
        >
          {filteredItems.map((data, i) => (
            <PreviewTile key={i} {...data} gatsbyImg />
          ))}
        </StackGrid>
      </StyledContainer>
    );
  }

  function getGutterWidth() {
    if (isMobile) {
      return 14;
    }

    return 26;
  }

  const gutterWidth = getGutterWidth();

  return (
    <React.Fragment>
      <div ref={ref}>
        {isInView && (
          <>
            <Responsive.DesktopOrTablet>
              <CaseStudiesBrowserFilter
                projectType={filters.projectType}
                locations={data.locations}
                location={filters.location}
                propertyTypes={data.properties}
                propertyType={filters.propertyType}
                handleFilterUpdate={handleFilterUpdate}
              />
            </Responsive.DesktopOrTablet>
            <Responsive.Mobile>
              <CaseStudiesBrowserFilterMobile
                projectType={filters.projectType}
                locations={data.locations}
                location={filters.location}
                propertyTypes={data.properties}
                propertyType={filters.propertyType}
                handleFilterUpdate={handleFilterUpdate}
                setFilters={setFilters}
              />
            </Responsive.Mobile>
          </>
        )}
      </div>

      {filtersSelected(filters) ? (
        renderFiltered()
      ) : (
        <div ref={ref}>
          {isInView && (
            <>
              <FeaturedProjectsContainer>
                <FeaturedProject
                  pt={[80, 103]}
                  mb={[80, 130, 156]}
                  title={featuredProjectsTitle}
                  subtitle={featuredProjectsSubtitle}
                  items={featuredCaseStudies}
                />
                {relationFailed ? <RefreshMessage /> : null}
              </FeaturedProjectsContainer>

              <StyledContainer>
                <Title mb={40} fontSizeMedium textAlign={'center'}>
                  All Projects
                </Title>
                <StackGrid
                  columnWidth={isMobile ? '100%' : '33.33%'}
                  gutterWidth={gutterWidth}
                  gutterHeight={gutterWidth}
                  appear={scaleUp.appear}
                  appeared={scaleUp.appeared}
                  enter={scaleUp.enter}
                  entered={scaleUp.entered}
                  leaved={scaleDown.leaved}
                >
                  {allCaseStudies.map((data, i) => (
                    <PreviewTile key={i} {...data} gatsbyImg />
                  ))}
                </StackGrid>
              </StyledContainer>
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withResponsiveContext(StoryblokCaseStudiesBrowserAdapter);
