import { FunctionComponent } from 'react'

import SelectCard from './SelectCard'
import Range from './Range'
import Features from './Features'
import Input from './Input'
import Content from './Content'
import Select from './SelectDropdown'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inputComponents = new Map<string, FunctionComponent<any>>([
  ['selectCard', SelectCard],
  ['range', Range],
  ['features', Features],
  ['input', Input],
  ['content', Content],
  ['select', Select]
])

export { SelectCard, Range, Features }
