import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import PageNavBar from './page-nav-bar';
import { Box } from './UIKit/system';
import Container from './container';
import GridTileList from './grid-tile-list';
import Spacer from '../components/spacer';
import Responsive from './responsive';
import PageNavBarMobile from './page-nav-bar-mobile';
import withLocation from './with-location';

const StyledCourseFilter = styled(Box)``;

const FILTERS = ['all', 'CPD'];
const MOBILEFILTERS = [
  {
    title: 'All',
  },
  {
    title: 'CPD',
  },
];

function CourseFilter({ items, location }) {
  const [activeFilter, setActiveFilter] = useState('all');
  const [activeItems, setActiveItems] = useState([]);
  const [loaded, setLoaded] = useState(false);

  function renderFilters() {
    return (
      <PageNavBar.Filters withTitle>
        {FILTERS.map((filter, index) => {
          return (
            <PageNavBar.FilterLink
              key={index}
              fontSizeSmall
              active={filter.toLowerCase() === activeFilter.toLowerCase()}
              onClick={() => setActiveFilter(filter.toLowerCase())}
            >
              {filter === 'CPD'
                ? 'CPD'
                : filter.toLowerCase()}
            </PageNavBar.FilterLink>
          );
        })}
      </PageNavBar.Filters>
    );
  }

  useEffect(() => {
    if (activeFilter === 'all') {
      setActiveItems(items);
    } else {
      let parsedItems = [];
      items.forEach((item, i) => {
        if (item.topText.toLowerCase() === activeFilter.toLowerCase()) {
          parsedItems.push(item);
        }
        if (i === items.length - 1) {
          setActiveItems(parsedItems);
        }
      });
    }
  }, [activeFilter]);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  });

  return (
    <StyledCourseFilter pb={77}>
      <Spacer height={'2px'} />
      <Responsive.DesktopOrTablet>
        <PageNavBar
          title={'Training & CPDs'}
          filters={renderFilters()}
          largeMode
        />
      </Responsive.DesktopOrTablet>
      <Responsive.Mobile>
        <PageNavBarMobile
          title={'Training & CPDs'}
          filters={MOBILEFILTERS}
          activeFilter={activeFilter}
          setActiveCategory={setActiveFilter}
        />
      </Responsive.Mobile>
      <Spacer height={'40px'} />
      {loaded && <GridTileList fourColumns items={activeItems} />}
    </StyledCourseFilter>
  );
}

export default withLocation(CourseFilter);
