import React, { useState } from 'react';
import styled from 'styled-components';

import TabsHeader from './tabs-header';
import TabsContent from './tabs-content';
import { Box } from '../UIKit/system';
import Title from '../title';

const StyledAccordionTabs = styled(Box)``;

function AccordionTabs({ title, data }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <StyledAccordionTabs id="accordion-tabs" pt={[40, 70, 92]} pb={[40, 70, 100]}>
      <Title mb={35} textAlign={'center'} fontSizeMedium html={title} />
      <TabsHeader
        data={data}
        activeIndex={activeTabIndex}
        onSwitch={(index) => setActiveTabIndex(index)}
      />
      <TabsContent activeIndex={activeTabIndex} data={data[activeTabIndex]} />
    </StyledAccordionTabs>
  );
}

export default AccordionTabs;
