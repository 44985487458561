import React from 'react';
import styled, { css } from 'styled-components';

import { getThemeColor } from '../utils/styles';
import { Box } from './UIKit/system';
import mq from '../utils/mq';

const TextWithBar = styled(Box)`
  display: flex;
  align-items: center;
  font-weight: 600;

  &:after {
    content: '';
    position: relative;
    top: 0.1em;
    display: block;
    height: 2px;
    width: 18px;
    background-color: ${getThemeColor('primary')};
    margin-left: 10px;
  }

  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `};
  ${mq.mobile(css`
    font-size: 16px;
    line-height: 140%;
  `)};
`;

export default TextWithBar;
