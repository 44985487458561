import { css } from 'styled-components';

import {
  button,
  buttonReset,
  fontFamilySans,
  getThemeColor,
  hoverDefault,
  inputCommonAlt,
  inputLabel,
  inputReset,
  inputSelect,
  textarea,
} from '../utils/styles';
import chevronDownIcon from '../images/svg/chevron-down-white.url-loader.svg';
import mq from '../utils/mq';

export const hubSpotFormStylesAlt = () => css`
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 50px;
    letter-spacing: -0.02em;
    font-weight: 600;
    font-size: 20px;
    
    ${mq.mobile(css`
      margin-bottom: 35px;
    `)};
  }

  .field {
    margin-bottom: 60px;
  }

  .hs-input {
    width: 100% !important;
  }

  fieldset.form-columns-1 {
    max-width: calc(50% - 32px) !important;

    .input {
      margin-top: 0;
      margin-right: 0 !important;
    }

    ${mq.mobile(css`
      max-width: 100% !important;
    `)};
  }

  fieldset.form-columns-2 {
    max-width: none !important;
  }

  fieldset.form-columns-2 .hs-form-field {
    &:last-of-type {
      .input {
        margin-right: 0 !important;
      }
    }

    .input {
      margin-right: 32px !important;
    }

    ${mq.mobile(css`
      .input {
        margin-right: 0 !important;
      }
    `)};
  }

  .hs-form-field {
    //width: calc(50% - 32px);
    //width: 100%;
    //margin-right: 32px;
    //display: inline-block;

    &.hs-fieldtype-booleancheckbox,
    &.hs-fieldtype-radio,
    &.hs-fieldtype-checkbox,
    &.hs-fieldtype-file,
    &.hs-fieldtype-textarea {
      width: 100%;
    }

    ${mq.mobile(css`
      width: 100%;
      margin-right: 0;
    `)};
  }

  label {
    ${inputLabel};
    margin-bottom: 32px;
    font-size: 16px;
    color: #d4d4d4;
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='tel'],
  textarea {
    ${inputReset};
    ${inputCommonAlt};
    margin-top: -10px;
  }

  textarea {
    ${textarea};
    min-height: 120px;
  }

  input[type='submit'] {
    ${fontFamilySans};
    ${buttonReset};
    ${hoverDefault};
    ${button};
    color: ${getThemeColor('black')};
    background-color: #fff;
    min-width: 200px;
    margin-top: 80px;

    ${mq.tablet(css`
      margin-top: 50px;
    `)};

    ${mq.mobile(css`
      margin-top: 20px;
    `)};
  }

  .multi-container,
  .inputs-list {
    list-style: none;
  }

  .hs-form-radio,
  .hs-form-checkbox,
  .hs-form-booleancheckbox {
    label {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 28px;
      margin-bottom: 20px;
    }

    input {
      position: absolute;
      left: 0;
      display: block;
    }

    &:last-child {
      label {
        margin-bottom: 0;
      }
    }
  }

  .legal-consent-container .hs-form-booleancheckbox-display > span {
    margin-left: 0;
  }

  .hs-richtext {
    margin-bottom: 20px;
  }

  .hs-fieldtype-select {
    .input {
      position: relative;
      ${inputSelect};

      &:after {
        content: '';
        display: block;
        width: 11px;
        height: 7px;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        background-image: url(${chevronDownIcon});
        background-size: cover;
      }

      select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }

      .selected {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 700;
        padding: 0 35px;
        display: flex;
        align-items: center;
      }
    }
  }

  .hs-fieldtype-file {
    .input {
      margin-top: -15px;
    }
  }

  .submitted-message {
    text-align: center;
    font-size: 18px;
  }

  .hs-error-msgs {
    position: absolute;
    margin-top: 10px;
    list-style: none;

    label {
      font-size: 14px;
      color: ${getThemeColor('danger')};
    }
  }

  .legal-consent-container .hs-error-msgs label {
    color: ${getThemeColor('danger')};
  }
`;
