import React from 'react';
import Item from './item';

function Accordion({ metaId, data }) {
  return data.map((item, index) => {
    return <Item key={metaId ? `${metaId}-${index}` : index} {...item} />;
  });
}

export default Accordion;
