import { ConcreteIcon, DrylinedIcon, DuctworkIcon, NoGrilleIcon, YesGrilleIcon, NoIcon, YesIcon, DoorsIcon, LiftDoorsIcon, VerticalPodIcon, HorizontalPodIcon } from './icons'
// @ts-expect-error - Gatsby will bundle this
import SmokeShaftIcon from './images/smoke_shaft_height.png'

import sldrData from './sldr-data'

import type { FormStep } from '../types'

export const steps: FormStep[] = [
  {
    key: 'infoContent',
    type: 'content',
    title: 'Information',
    content: `This simple calculator has been created to provide an estimate of the extract rate required for a MSVS (Mechanical Smoke Ventilation System) with one mechanical extract shaft.<br><br> For more complex systems, please use our live chat and we'll help you out!`,
    button: 'Continue'
  },
  {
    title: 'Smoke shaft dimensions',
    text: 'Enter the shaft width and shaft depth of the smoke shaft (in millimetres).',
    type: 'input',
    key: 'dimensions',
    button: 'Next',
    inputs: [
      {
        key: 'shaftWidth',
        type: 'number',
        placeholder: 'Enter Width (mm)',
        required: true,
        step: 0.1
      },
      {
        key: 'shaftDepth',
        type: 'number',
        placeholder: 'Enter Depth (mm)',
        required: true,
        step: 0.1
      }
    ],
    validations: [
      (data: Record<string, string | number>): boolean | string | undefined => {
        const { shaftWidth, shaftDepth } = data
        if (!shaftWidth || !shaftDepth) return
        // Formula: (W * D) / 1,000,000 (to get m²)
        const crossSectionalArea = (Number(shaftWidth) * Number(shaftDepth)) / 1000000
        const lessThan = crossSectionalArea < 0.6
        return (
          lessThan &&
          `
          <p>⚠️ These dimensions have a cross-sectional area of less than 0.6m², which will result in noisier, more expensive fan sets that are less energy efficient.</p>
          <p><strong>For the best results, increase the shaft size to 0.6m².</strong></p>
        `
        )
      },
      (data: Record<string, string | number>): boolean | string | undefined => {
        const { shaftWidth, shaftDepth } = data
        if (!shaftWidth || !shaftDepth) return
        // Formula: W / D = AR (expressed as a decimal)
        const aspectRatio1 = Number(shaftWidth) / Number(shaftDepth)
        const aspectRatio2 = Number(shaftDepth) / Number(shaftWidth)
        const greaterThan = aspectRatio1 > 2 || aspectRatio2 > 2
        return (
          greaterThan &&
          `
          <p>⚠️ The aspect ratio of these dimensions is greater than 2:1, which will result in noisier, more expensive fan sets that are less energy efficient.</p>
          <p><strong>For the best results, decrease the shaft aspect ratio.</strong></p>
        `
        )
      },
      (data: Record<string, string | number>): boolean | string | undefined => {
        const values = Object.values(data).filter(Boolean)
        const hasSmallerThan = values.some((val) => Number(val) < 850)
        return (
          hasSmallerThan &&
          `
          <p>
            <svg width="18" height="18" viewBox="0 0 72 72" style="position:relative;top:3px;fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:10;">
              <g id="line">
                <g transform="matrix(1.33333,0,0,1.33333,-12,-12)">
                  <path d="M59.035,60L12.965,60C12.434,60 12,59.566 12,59.035L12,12.965C12,12.434 12.434,12 12.965,12L59.035,12C59.566,12 60,12.434 60,12.965L60,59.035C60,59.566 59.566,60 59.035,60Z" style="fill:rgb(0,181,255);fill-rule:nonzero;stroke:rgb(0,181,255);stroke-width:2px;"/>
                </g>
                <g transform="matrix(1.33333,0,0,1.6,-12,-17.7333)">
                  <path d="M36.5,31.5L36.5,46.5" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:8.02px;stroke-linecap:round;stroke-linejoin:miter;"/>
                </g>
                <g transform="matrix(3.11111,0,0,3.11111,-76.8889,-63.5556)">
                  <circle cx="36.5" cy="26" r="1.5" style="fill:white;stroke:white;stroke-width:0.33px;stroke-miterlimit:2;"/>
                </g>
              </g>
            </svg>
            <span>The update to BS 9991, due in Q4 2022, will require mechanical shafts to have a minimum dimension of 850mm in any direction.</span>
          </p>
          <p><strong>Would you like to amend the shaft size?</strong></p>
        `
        )
      }
    ]
  },
  {
    title: 'Storeys',
    text: 'Enter the number of storeys in the building.',
    type: 'input',
    key: 'storeys',
    button: 'Next',
    inputs: [
      {
        key: 'storeys',
        type: 'number',
        placeholder: 'Number of Storeys',
        required: true
      }
    ]
  },
  {
    title: 'Smoke shaft height',
    text: 'Enter the height of the smoke shaft from base to roof level (in metres).',
    type: 'input',
    key: 'shaftHeight',
    button: 'Next',
    image: SmokeShaftIcon,
    inputs: [
      {
        key: 'height',
        type: 'number',
        placeholder: 'Enter Height (m)',
        required: true,
        step: 1
      }
    ]
  },
  {
    title: 'Smoke shaft construction',
    text: 'What is your smoke shaft constructed from?',
    type: 'selectCard',
    key: 'shaftConstruction',
    options: [
      {
        title: 'Ductwork',
        image: DuctworkIcon,
        text: '',
        value: 'ductwork'
      },
      {
        title: 'Concrete',
        image: ConcreteIcon,
        text: '',
        value: 'concrete'
      },
      {
        title: 'Drylined',
        image: DrylinedIcon,
        text: '',
        value: 'drylined'
      }
    ]
  },
  {
    title: 'Damper Extract Rate',
    text: 'Enter the required damper extract rate (m³/s)',
    type: 'input',
    key: 'damperExtractRate',
    button: 'Next',
    inputs: [
      {
        key: 'damperExtractRate',
        type: 'number',
        required: true
      }
    ]
  },
  {
    title: 'Safety grilles',
    text: 'Are you planning to leave safety grilles in the shaft?',
    type: 'selectCard',
    key: 'safetyGrilles',
    options: [
      {
        title: 'Yes',
        image: YesGrilleIcon,
        text: '',
        value: true
      },
      {
        title: 'No',
        image: NoGrilleIcon,
        text: '',
        value: false
      }
    ],
    actions: {
      skip(value) {
        if (!value) return 2
        return -1
      }
    }
  } as FormStep<boolean>,
  {
    title: 'Safety grilles',
    subtitle: 'Quantity',
    text: `Choose the number of safety grilles that will be installed into the shaft.`,
    type: 'range',
    options: {
      min: 0,
      max: 60,
      step: 1
    },
    key: 'safetyGrillesQuantity',
    button: 'Next',
    required: 1
  } as FormStep<number>,
  {
    title: 'Safety grilles',
    subtitle: 'Free area',
    text: 'What is the percentage free area of the grilles?',
    type: 'select',
    key: 'safetyGrillesFreeArea',
    button: 'Next',
    options: [
      {
        title: '25%',
        value: 0.25
      },
      {
        title: '50%',
        value: 0.5
      },
      {
        title: '75%',
        default: true,
        value: 0.75
      }
    ]
  },
  {
    title: 'Shaft contractions',
    text: 'Will the smoke shaft have any contractions?',
    type: 'selectCard',
    key: 'shaftContractions',
    options: [
      {
        title: 'Yes',
        image: YesIcon,
        text: '',
        value: true
      },
      {
        title: 'No',
        image: NoIcon,
        text: '',
        value: false
      }
    ],
    actions: {
      skip(value) {
        if (!value) return 2
        return -1
      }
    }
  } as FormStep<boolean>,
  {
    title: 'Shaft contractions',
    text: 'Is the shaft a gradual contraction (45°)?',
    type: 'select',
    key: 'shaftContractionType',
    button: 'Next',
    options: [
      {
        title: 'Gradual Contraction',
        value: 'gradual'
      },
      {
        title: 'Vertical',
        value: 'vertical'
      }
    ]
  },
  {
    title: 'Shaft contractions',
    text: 'Enter the smaller and larger area of the contraction.',
    type: 'input',
    key: 'shaftContractionDimensions',
    button: 'Next',
    inputs: [
      {
        key: 'smaller',
        type: 'number',
        placeholder: 'Enter smaller area (m²)',
        required: true,
        step: 1,
        title: 'Smaller Area (m²)'
      },
      {
        key: 'larger',
        type: 'number',
        placeholder: 'Enter larger area (m²)',
        required: true,
        step: 1,
        title: 'Larger Area (m²)'
      }
    ]
  },
  {
    title: 'Shaft expansions',
    text: 'Will the smoke shaft have any expansions?',
    type: 'selectCard',
    key: 'shaftExpansions',
    options: [
      {
        title: 'Yes',
        image: YesIcon,
        text: '',
        value: true
      },
      {
        title: 'No',
        image: NoIcon,
        text: '',
        value: false
      }
    ],
    actions: {
      skip(value) {
        if (!value) return 2
        return -1
      }
    }
  } as FormStep<boolean>,
  {
    title: 'Shaft expansions',
    text: 'Is the shaft a gradual expansion (30°)?',
    type: 'select',
    key: 'shaftExpansionType',
    button: 'Next',
    options: [
      {
        title: 'Gradual Expansion',
        value: 'gradual'
      },
      {
        title: 'Vertical',
        value: 'vertical'
      }
    ]
  },
  {
    title: 'Shaft expansions',
    text: 'Enter the smaller and larger area of the expansion.',
    type: 'input',
    key: 'shaftExpansionDimensions',
    button: 'Next',
    inputs: [
      {
        key: 'smaller',
        type: 'number',
        placeholder: 'Enter smaller area (m²)',
        required: true,
        step: 1,
        title: 'Smaller Area (m²)'
      },
      {
        key: 'larger',
        type: 'number',
        placeholder: 'Enter larger area (m²)',
        required: true,
        step: 1,
        title: 'Larger Area (m²)'
      }
    ]
  },
  {
    title: 'How many doors?',
    text: `Please enter the total number of apartment doors per floor. If you're not sure of the exact number, an estimate is fine.`,
    type: 'range',
    options: {
      min: 0,
      max: 25,
      step: 1
    },
    key: 'doors',
    button: 'Next',
    image: DoorsIcon,
    required: 1,
    video: 'https://a.storyblok.com/f/79904/x/7bb8bda92c/sertus-rmp-r-turn.mov'
  },
  {
    title: 'How many lift doors?',
    text: `Please enter the total number of lift doors per floor. If you're not sure of an exact number, an estimate is fine.`,
    type: 'range',
    options: {
      min: 0,
      max: 5,
      step: 1
    },
    key: 'liftDoors',
    button: 'Next',
    image: LiftDoorsIcon
  },
  {
    title: 'Smoke control damper',
    text: `
      Select the type and size of smoke control damper you require. The sizes shown below are the overall damper sizes.
      <br />
      <a href="https://sertus.uk/products/shaft-louvre-damper-residential-sld-r" target="_blank">View Damper Size Guide</a>
    `,
    type: 'select',
    key: 'smokeControlDamperSize',
    button: 'Next',
    image: SmokeShaftIcon,
    options: sldrData.map((item) => ({
      title: `${item.overallWidth}mm x ${item.overallHeight}mm`,
      value: item,
      default: item.size === '669 x 1391'
    }))
  },
  {
    title: 'Smoke control damper',
    text: `Choose the number of smoke control dampers that will be installed into the shaft.`,
    type: 'range',
    options: {
      min: 0,
      max: 100,
      step: 1
    },
    key: 'smokeControlDamperQuantity',
    button: 'Next',
    required: 1,
    actions: {
      default: (input) => input.storeys
    }
  } as FormStep<number>,
  {
    title: 'Environmental dampers',
    text: 'Do you require separate environmental dampers above the ceiling?',
    type: 'selectCard',
    key: 'environmentalDamper',
    actions: {
      skip(value) {
        if (!value) return 2
        return -1
      }
    },
    options: [
      {
        title: 'Yes',
        image: YesIcon,
        text: '',
        value: true
      },
      {
        title: 'No',
        image: NoIcon,
        text: '',
        value: false
      }
    ]
  } as FormStep<boolean>,
  {
    title: 'Environmental dampers',
    text: `
      Select the type and size of environmental damper you require. The sizes shown below are the overall damper sizes.
      <br />
      <a href="https://sertus.uk/products/shaft-louvre-damper-residential-sld-r" target="_blank">View Damper Size Guide</a>
    `,
    type: 'select',
    key: 'environmentalDamperSize',
    button: 'Next',
    image: SmokeShaftIcon,
    options: sldrData.map((item) => ({
      title: `${item.overallWidth}mm x ${item.overallHeight}mm`,
      value: item,
      default: item.size === '569 x 1391'
    }))
  },
  {
    title: 'Environmental dampers',
    text: `Choose the number of environmental dampers that will be installed into the shaft.`,
    type: 'range',
    options: {
      min: 0,
      max: 100,
      step: 1
    },
    key: 'environmentalDamperQuantity',
    button: 'Next',
    // image: DoorsIcon,
    required: 1,
    actions: {
      default: (input) => input.smokeControlDamperQuantity
    }
  } as FormStep<number>,
  {
    title: 'Vertical Pod',
    text: 'Will you be using a vertical pod?',
    type: 'selectCard',
    key: 'verticalPod',
    options: [
      {
        title: 'Yes',
        image: VerticalPodIcon,
        text: '',
        value: true
      },
      {
        title: 'No',
        image: HorizontalPodIcon,
        text: '',
        value: false
      }
    ],
    actions: {
      skip(value) {
        if (!value) return 1
        return -1
      }
    }
  } as FormStep<boolean>,
  {
    title: 'Transition ductwork',
    subtitle: 'Quantity of Veins',
    text: `Choose the number of veins in the transition piece`,
    type: 'range',
    options: {
      min: 0,
      max: 20,
      step: 1
    },
    key: 'verticalVeinsQuantity',
    button: 'Next',
    required: 0
  },
  {
    title: 'Choose your features',
    subtitle: `Please select all that apply.`,
    type: 'features',
    key: 'features',
    button: 'Next',
    actions: {
      preselected: (data) => {
        // If vertical pod is selected, then return these defaults
        if (data.verticalPod) return ['Duty Fan', 'Standby Fan', 'Protective Enclosure', 'Powered Louvre']
        // Otherwise, fall back to these horizontal defaults
        return ['Duty Fan', 'Standby Fan']
      }
    }
  } as FormStep<string[]>
]

export default steps
