import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import throttle from 'lodash/throttle';

import Title from '../title';
import { Box, Flex } from '../UIKit/system';
import Container from '../container';
import ControlButton from './control-button';
import Carousel from './carousel';
import LinkWithArrow from '../link-with-arrow';
import { getPath } from '../../utils/paths';
import ProgressBar from './progress-bar';
import { BREAKPOINT_MD, BREAKPOINT_SM, OFFSET_SIDE_SM } from '../../constants';
import { darkBackground } from '../../utils/styles';
import mq from '../../utils/mq';

const StyledTeamCarousel = styled(Box)`
  ${darkBackground};
  overflow-x: hidden;
`;

const StyledTitle = styled(Title)``;

const TopLayerContainer = styled(Flex)`
  ${mq.mobile(css`
    margin-bottom: 36px;
  `)};
`;

const DesktopLinkWithArrow = styled.div`
  margin-top: 16px;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const MobileLinkWithArrow = styled.div`
  display: none;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 36px;
  ${mq.mobile(css`
    display: flex;
  `)};
`;

const ControlButtonsContainer = styled(Flex)`
  ${mq.mobile(css`
    display: none;
  `)};
`;

function TeamCarousel({ title, teamMembers, link }) {
  const [swiperOptions, setSwiperOptions] = useState(null);
  const [progress, setProgress] = useState(0);
  const buttonPrev = useRef(null);
  const buttonNext = useRef(null);

  useEffect(() => {
    setSwiperOptions(createSwiperOptions());
  }, []);

  function createSwiperOptions() {
    return {
      spaceBetween: 18,
      slidesPerView: 'auto',
      speed: 1000,
      loop: false,
      navigation: {
        prevEl: buttonPrev.current,
        nextEl: buttonNext.current,
      },
      breakpoints: {
        [BREAKPOINT_MD]: {
          spaceBetween: 40,
        },
        [BREAKPOINT_SM]: {
          spaceBetween: OFFSET_SIDE_SM,
          slidesPerView: 'auto',
        },
      },
    };
  }

  const handleProgressChange = useCallback(
    throttle((value) => {
      setProgress(value);
    }, 500),
    []
  );

  return (
    <StyledTeamCarousel pt={[93]} pb={[70]}>
      <Container>
        <TopLayerContainer
          mb={66}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <StyledTitle fontSizeMedium html={title} />
          <ControlButtonsContainer alignItems={'center'}>
            <ControlButton ref={buttonPrev} prev />
            <ControlButton ref={buttonNext} />
          </ControlButtonsContainer>
        </TopLayerContainer>
        <MobileLinkWithArrow>
          <LinkWithArrow to={getPath.team()} white>
            See Everybody
          </LinkWithArrow>
        </MobileLinkWithArrow>
        {swiperOptions && (
          <Carousel
            items={teamMembers}
            swiperOptions={swiperOptions}
            onProgressChange={handleProgressChange}
          />
        )}
        {link && (
          <Flex mt={29} alignItems={'center'} justifyContent={'space-between'}>
            <DesktopLinkWithArrow>
              <LinkWithArrow {...link} white />
            </DesktopLinkWithArrow>
            <ProgressBar progress={progress} total={teamMembers.length} />
          </Flex>
        )}
      </Container>
    </StyledTeamCarousel>
  );
}

export default TeamCarousel;
