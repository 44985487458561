import React from 'react';
import styled from 'styled-components';
import StackGrid, { transitions } from 'react-stack-grid';

import { withResponsiveContext } from './responsive';
import Container from './container';
import Breadcrumbs from './breadcrumbs';

const StyledContainer = styled(Container)``;

const { scaleDown, scaleUp } = transitions;

function CustomStackGrid({
  children,
  isTablet,
  isMobile,
  isMobileSmall,
  pagination,
  ...rest
}) {
  function getColumnWidth() {
    if (isMobile || isMobileSmall) {
      return '100%';
    }
    if (isTablet) {
      return '33.33%';
    }
    return '25%';
  }

  return (
    <StyledContainer pt={25} pb={[80, 100, 145]} large {...rest}>
      {pagination && <Breadcrumbs mb={22} items={pagination} />}
      <StackGrid
        columnWidth={getColumnWidth()}
        gutterWidth={18}
        gutterHeight={18}
        appear={scaleUp.appear}
        appeared={scaleUp.appeared}
        enter={scaleUp.enter}
        entered={scaleUp.entered}
        leaved={scaleDown.leaved}
      >
        {children}
      </StackGrid>
    </StyledContainer>
  );
}

CustomStackGrid = withResponsiveContext(CustomStackGrid);

export default CustomStackGrid;
