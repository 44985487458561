import React from 'react';
import styled, { css } from 'styled-components';

import { supportsGrid } from '../utils/styles';
import LazyImage from './lazy-image';
import { Box, Flex } from './UIKit/system';
import Title from './title';

const StyledLogoTile = styled(Flex)`
  margin-right: 19px;
  margin-bottom: 19px;
  width: calc(25% - 19px);
  text-align: center;

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  ${supportsGrid(css`
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  `)}
`;

const Image = styled(LazyImage)`
  mix-blend-mode: darken;
  max-width: 193px;
  width: 100%;
`;

const StyledLink = styled.a`
  font-weight: bold;
  font-size: 16px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #753fbf;
  text-decoration: none;
`;

function LogoTile({ image, title, link }) {
  return (
    <StyledLogoTile
      bg={'lightGrey'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      height={420}
      pt={62}
      pb={40}
      px={10}
    >
      <Box>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          height={155}
          mb={65}
        >
          <Image fluid={image} />
        </Flex>
        <Title fontSizeExtraSmall>{title}</Title>
      </Box>

      {link && (
        <StyledLink href={link.to} target={'_blank'}>
          {link.children}
        </StyledLink>
      )}
    </StyledLogoTile>
  );
}

export default LogoTile;
