import React from 'react';

import { Flex, Paragraph } from './UIKit/system';
import Breadcrumbs from './breadcrumbs';
import Title from './title';
import AuthorWithPhoto from './author-with-photo';
import Container from './container';
import { STANDARD_PADDING } from '../constants';
import Button from './button';
import RichTextField from './rich-text-field';

function Intro6({
  pagination,
  title,
  introduction,
  author,
  date,
  minutesToRead,
  button,
}) {
  return (
    <Container pt={STANDARD_PADDING} pb={48} narrow>
      <Flex flexDirection={'column'} alignItems={'flex-start'}>
        <Breadcrumbs ml={2} mb={19} items={pagination} />
        <Title as={'h1'} mb={16} lineHeight={1.1} maxWidth={765} html={title} />
        {introduction && <Paragraph maxWidth={550} html={introduction} grey />}
        {author && (
          <AuthorWithPhoto
            mt={40}
            {...author}
            date={date}
            minutesToRead={minutesToRead}
          />
        )}
        {button && <Button mt={36} {...button} />}
      </Flex>
    </Container>
  );
}

export default Intro6;
