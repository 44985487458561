import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Link from './link';
import LazyImage from './lazy-image';
import mq from '../utils/mq';

const ImageContainer = styled(motion.div)`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 94.3%;
  }
`;

const Image = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: 1000ms;
`;

const StyledPreviewTile = styled(Link)`
  position: relative;
  text-decoration: none;
  background-color: #000;
  display: flex;
  overflow: hidden;

  ${(props) =>
    props.large &&
    css`
      width: auto;
      grid-column-start: 1;
      grid-column-end: 3;
      
      ${mq.mobile(css`
        grid-column-start: auto;
        grid-column-end: auto;
      `)};
    `};

  &:hover {
    ${Image} {
      transition: 1000ms;
      transform: scale(1.1);
    }
  }
`;

const Gradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0.1));
  opacity: 0.25;
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 1;
`;

const TextContentContainer = styled.div`
  margin-left: 23px;
  margin-bottom: 30px;
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.14;
  text-decoration: none;
  text-align: left;
  padding-right: 10px;

  ${mq.tablet(css`
    font-size: 22px;
  `)};

  ${mq.mobile(css`
    font-size: 28px;
  `)};
`;

function PreviewTile({
  to,
  image,
  title,
  fullWidthResponsive,
  zoomOut,
  ...rest
}) {
  const [isInView, setIsInView] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.25,
  });

  const ZoomOutAnim = {
    hidden: {
      scale: 1.1,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.6,
        ease: 'easeIn',
      },
    },
  };

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);
  return (
    <StyledPreviewTile
      fullWidthResponsive={fullWidthResponsive}
      to={to}
      {...rest}
    >
      {zoomOut ? (
        <ImageContainer
          ref={ref}
          initial={'hidden'}
          animate={isInView ? 'visible' : 'hidden'}
          variants={ZoomOutAnim}
        >
          <Image fluid={image} />
        </ImageContainer>
      ) : (
        <ImageContainer>
          <Image fluid={image} />
        </ImageContainer>
      )}

      <Gradient />
      <Content>
        <TextContentContainer>{title}</TextContentContainer>
      </Content>
    </StyledPreviewTile>
  );
}

export default PreviewTile;
