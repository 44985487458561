import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper/dist/js/swiper';

import { Box } from './UIKit/system';

const Container = styled(Box)`
  position: relative;
  width: 100%;

  .swiper-notification {
    visibility: hidden;
  }
`;

const StyledWrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  will-change: transform;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
`;

function Wrapper({ children }) {
  return useMemo(
    () => (
      <StyledWrapper className={'swiper-wrapper'}>{children}</StyledWrapper>
    ),
    [children]
  );
}

function ReactSwiper({
  className = '',
  wrapperClassName = '',
  children,
  options,
  onChange,
  onProgressChange,
  ...rest
}) {
  const containerNode = React.createRef();
  const swiperInstance = React.createRef();

  useEffect(() => {
    if (!options) {
      return;
    }
    initSwiper();
  }, [options]);

  function initSwiper() {
    swiperInstance.current = new Swiper(containerNode.current, {
      init: false,
      ...options,
    } );

    swiperInstance.current.on('init', () => {
      onChange && onChange(swiperInstance.current.realIndex);
    });
    swiperInstance.current.init();
    swiperInstance.current.on('slideChange', () => {
      onChange && onChange(swiperInstance.current.realIndex);
    });
    swiperInstance.current.on('progress', (value) => {
      onProgressChange && onProgressChange(value);
    });
  }

  return (
    <Container className={className} ref={containerNode} {...rest}>
      <Wrapper className={wrapperClassName}>{children}</Wrapper>
    </Container>
  );
}

const StyledItem = styled(Box)`
  height: 100%;
  will-change: transform;
  flex-shrink: 0;
`;

const Item = ({ className = '', children }) => {
  return useMemo(
    () => (
      <StyledItem className={(className ? className : '') + ' swiper-slide'}>
        {children}
      </StyledItem>
    ),
    [children]
  );
};

ReactSwiper.Item = Item;

export default ReactSwiper;
