import styled from 'styled-components';

import { Flex } from '../UIKit/system';

export const IconRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 120px;
`;

export const TitleRow = styled(Flex)`
  height: 85px;
  align-items: center;
`;

export const DataRow = styled(Flex)`
  height: 128px;
  padding-bottom: 15px;
`;
