import { SimpleFormInput } from '../types'
import { formatNumber } from './formula-advanced'

export interface FormulaValues {
  airLeakage: number
  smokeDamperLeakage: number
  activeArea: number
  shaftDampersQuantity: number
  extractRateAtDamper: number | null
  floorHeight: number
  smokeShaftHeight: number
}

export interface SimpleFormValues {
  estimatedExtractRate: number
  extractRateAtDamper: number
  extractRateRequired: number
  extractRateRequiredFormatted: string
  surfaceAreaPerStorey: number
  totalArea: number
  totalSmokeDamperLeakage: number
  totalSmokeShaftDamperLeakage: number
  totalSmokeShaftLeakage: number
  totalSurfaceArea: number
  totalSurfaceLength: number
  totalSystemLeakage: number
  formulaValues: FormulaValues
  errors: unknown[]
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCalculateSimpleValues(input: SimpleFormInput): SimpleFormValues {
  const { doors, liftDoors, storeys, dimensions = { shaftWidth: 0, shaftDepth: 0 } } = input

  const formulaValues: FormulaValues = {
    airLeakage: 0.00105,
    smokeDamperLeakage: 0.0555,
    activeArea: 0.5,
    shaftDampersQuantity: storeys,
    extractRateAtDamper: null, // 7
    floorHeight: 3000, // in mm
    get smokeShaftHeight() {
      return this.floorHeight * storeys
    }
  }

  const dimensionsInM = {
    width: dimensions.shaftWidth / 1000,
    depth: dimensions.shaftWidth / 1000,
    height: formulaValues.smokeShaftHeight / 1000
  }

  const estimatedExtractRate = (3 + doors * 0.1 + liftDoors * 0.4) * 1.2

  const totalSurfaceLength = dimensionsInM.width * dimensionsInM.depth
  const surfaceAreaPerStorey = dimensionsInM.width * totalSurfaceLength
  const totalSurfaceArea = surfaceAreaPerStorey * storeys

  const totalArea = formulaValues.activeArea * formulaValues.shaftDampersQuantity
  const totalSmokeShaftDamperLeakage = totalArea * formulaValues.smokeDamperLeakage

  const totalSmokeShaftLeakage = formulaValues.airLeakage * totalSurfaceArea
  const totalSmokeDamperLeakage = totalSmokeShaftDamperLeakage
  const totalSystemLeakage = totalSmokeShaftLeakage + totalSmokeDamperLeakage

  const extractRateAtDamper: number = formulaValues.extractRateAtDamper || estimatedExtractRate
  const extractRateRequired = totalSystemLeakage + extractRateAtDamper

  // ? Not sure where this came from?
  // const inflatedExtractRate = 0.2 * extractRateRequired + extractRateRequired

  const extractRateRequiredFormatted = formatNumber(extractRateRequired, 1)

  // Limits
  // const extractRateLimits = { lower: 1.2, upper: 7.5 }
  // const exceedsExtractRateLimits = extractRateRequired < extractRateLimits.lower || extractRateRequired > extractRateLimits.upper

  return {
    formulaValues,
    estimatedExtractRate,
    extractRateAtDamper,
    extractRateRequired,
    extractRateRequiredFormatted,
    surfaceAreaPerStorey,
    totalArea,
    totalSmokeDamperLeakage,
    totalSmokeShaftDamperLeakage,
    totalSmokeShaftLeakage,
    totalSurfaceArea,
    totalSurfaceLength,
    totalSystemLeakage,
    errors: []
  }
}
