import React from 'react';
import styled, { css } from 'styled-components';

import Container from './container';
import { Box, Flex, Paragraph } from './UIKit/system';
import GridCol from './grid-col';
import Title from './title';
import LinkWithArrow from './link-with-arrow';
import { hoverDefault } from '../utils/styles';
import { STANDARD_PADDING } from '../constants';
import mq from '../utils/mq';

const StyledSectionLinksToDownload = styled(Box)``;

const LinkItem = styled(Box)`
  &:last-of-type {
    border-bottom: none;
  }
`;

const StyledLinkWithArrow = styled(LinkWithArrow)`
  ${hoverDefault};
`;

const StyledGridCol = styled(GridCol)`
  ${mq.tablet(css`
    width: 100%;
    margin-bottom: 22px;
  `)};
`;

function SectionLinks({ title, text, links }) {
  return (
    <StyledSectionLinksToDownload
      as={'section'}
      py={STANDARD_PADDING}
      pb={[55]}
    >
      <Container as={Flex} flexWrap={['wrap', 'wrap', 'unset']}>
        <StyledGridCol>
          <Title as={'h2'} fontSizeMedium html={title} />
        </StyledGridCol>
        <Box pt={9}>
          {text && <Paragraph mb={90} grey html={text} />}
          {links.map((item, index) => {
            return (
              <LinkItem key={index} borderBottom={'1px solid #ebebeb'}>
                <StyledLinkWithArrow
                  key={index}
                  py={18}
                  fontSizeLarge
                  spread
                  reverse
                  smallIcon
                  linkMode={true}
                  target={'_blank'}
                  {...item}
                />
              </LinkItem>
            );
          })}
        </Box>
      </Container>
    </StyledSectionLinksToDownload>
  );
}

export default SectionLinks;
