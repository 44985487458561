import React from 'react';
import styled from 'styled-components';

import { Box } from '../UIKit/system';
import Title from '../title';
import HubSpotForm from '../hubspot-form';
import { getThemeColor } from '../../utils/styles';

const StyledForm = styled(Box)`
  color: ${getThemeColor('black')};

  input[type='submit'] {
    width: 100%;
  }
`;

function Form({ onSubmit }) {
  return (
    <StyledForm p={40} borderRadius={4} maxWidth={[null, null, 523]} bg={'#fff'}>
      <Title
        maxWidth={'70%'}
        mx={'auto'}
        textAlign={'center'}
        mb={35}
        fontSizeSmall
      >
        Get your free specification using our tool.
      </Title>
      <HubSpotForm
        portalId={'5924647'}
        formId={'9f243687-e7df-4d30-83c5-f6c1d8f8f27f'}
        fieldsFullWidth
        onFormSubmitted={onSubmit}
      />
    </StyledForm>
  );
}

export default Form;
