import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import Title from '../title';
import { Box, Flex, Text } from '../UIKit/system';
import LazyImage from '../lazy-image';
import mq from '../../utils/mq';
import VideoPlayerOverlay from '../video-player-overlay';
import LinkWithArrow from '../link-with-arrow';
import { renderStringArray } from '../../utils/utils';
import ellipsis from '../../utils/ellipsed';
import ButtonPlay from '../button-play';
import withLocation from '../with-location';
import parseStoryblokLink from '../../utils/parse-storyblok-link'

const ResourceGridItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f8f8f8;
  min-height: 430px;

  ${(props) =>
    props.video &&
    css`
      min-height: 400px;
    `};
`;

const Category = styled(Text)`
  font-size: 14px;
  line-height: 1.4;
  color: #666666;
  text-transform: capitalize;

  ${mq.mobileSmall(css`
    font-size: 11px;
    line-height: 100%;
  `)};
`;

const TagSeparator = styled.div`
  margin: 0 6px;

  :before {
    content: '|';
    display: inline;
    position: relative;
    top: -2px;
    font-size: 14px;
  }
`;

const Tag = styled(Text)`
  font-size: 14px;
  line-height: 140.62%;
  color: #666666;
  text-transform: capitalize;
  ${mq.mobileSmall(css`
    font-size: 11px;
    line-height: 100%;
  `)};
`;

const Name = styled(Title)`
  cursor: pointer;
  ${mq.mobileSmall(css`
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
  `)};
`;

const ItemInfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  ${mq.mobileSmall(css`
    margin-bottom: 6px;
    margin-top: 6px;
  `)};
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  margin-bottom: 8px;

  &:before {
    content: '';
    display: block;
  }

  ${(props) =>
    props.video &&
    css`
      margin-bottom: 32px;
    `};

  ${(props) =>
    props.aspectRatioAlt &&
    css`
      &:before {
        padding-top: 56%;
      }
    `};
`;

const ImageContainerInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.video &&
    css`
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    `};
`;

const Image = styled(LazyImage)`
  display: block;
  max-width: 85%;
  height: 100%;
  width: 100%;
  max-height: 95%;

  ${(props) =>
    props.video &&
    css`
      max-width: none;
      max-height: none;
    `};
`;

const StyledLinkWithArrow = styled(LinkWithArrow)`
  cursor: pointer;

  ${mq.mobileSmall(css``)};
`;

const ItemInfoLayerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LengthIndicator = styled.div`
  font-size: 14px;
  line-height: 140.62%;
  text-align: right;
  color: #666666;
  width: 50%;

  ${mq.mobileSmall(css`
    font-size: 11px;
    line-height: 100%;
  `)};
`;

const StyledContentContainer = styled(Box)``;

function ContentContainer({ children, video }) {
  return (
    <StyledContentContainer px={video ? [20, 24] : [28, 32]}>
      {children}
    </StyledContentContainer>
  );
}

function ResourceGridItem({
  data,
  handleResourceClick,
  video,
  aspectRatioAlt,
  location,
}) {
  const [videoOpen, setVideoOpen] = useState(false);

  const titleRef = useRef(null);

  const isLink = useMemo(() => data.link && data.link.linktype && data.link.cached_url, [data])
  const linkProps = useMemo(() => {
    if (!isLink) return {}

    const categories = data.categories ? data.categories.map(({ name }) => name).join(', ') : ''
    const subject = `${data.name}${categories && `- ${categories}`}`

    return {
      ...data.link,
      to: parseStoryblokLink({ ...data.link, subject })
    }
  }, [data, isLink])

  const fileFormat = useMemo(() => {
    if (!data.file || !data.file.filename) {
      return '';
    }
    const splitArr = data.file.filename.split('.');
    return splitArr[splitArr.length - 1];
  }, [data.file]);

  useEffect(() => {
    if (!titleRef.current) {
      return;
    }

    ellipsis(titleRef.current, 2);
  }, []);

  useEffect(() => {
    if (location.hash === '#' + data.videoId) {
      setVideoOpen(true);
    }
  }, []);

  function handlePopupClose() {
    setVideoOpen(false);
  }

  function renderCategories() {
    return renderStringArray(data.categories, (arr) =>
      arr.map((v) => v.name).join(', ')
    );
  }

  function renderTags() {
    return renderStringArray(data.tags, (arr) => arr.join(', '));
  }

  return (
    <>
      <ScrollableAnchor id={data.videoId}>
        <ResourceGridItemContainer video={video}>
          <Box>
            <ImageContainer
              aspectRatioAlt={aspectRatioAlt}
              video={video}
              onClick={
                video
                  ? () => setVideoOpen(true)
                  : () => handleResourceClick(data)
              }
            >
              <ImageContainerInner video={video}>
                <Image fluid={data.image} alt={data.title} video={video} />
              </ImageContainerInner>
            </ImageContainer>
            <ContentContainer first video={video}>
              <ItemInfoLayerContainer>
                <ItemInfoContainer>
                  <Category>{renderCategories()}</Category>
                  {video === undefined ? (
                    <React.Fragment>
                      {data.tags.length > 0 ? (
                        <React.Fragment>
                          <TagSeparator />
                          <Tag>{renderTags()}</Tag>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </ItemInfoContainer>
                {video && (
                  <LengthIndicator>
                    {data.length} {data.length > 1 ? 'mins' : 'min'}
                  </LengthIndicator>
                )}
              </ItemInfoLayerContainer>
              <Name
                fontSize={data.name.length > 55 ? '13px' : 'medium'}
                onClick={
                  video
                    ? () => setVideoOpen(true)
                    : () => handleResourceClick(data)
                }
              >
                <span ref={titleRef}>{data.name}</span>
              </Name>
            </ContentContainer>
          </Box>
          <ContentContainer video={video}>
            <Flex py={28} flexDirection={'column'} alignItems={'flex-start'}>
              {video ? (
                <ButtonPlay onClick={() => setVideoOpen(true)}>
                  Watch Video
                </ButtonPlay>
              ) : isLink ? (
                <StyledLinkWithArrow {...linkProps}>
                  {`Download`}
                </StyledLinkWithArrow>
              ) : (
                <StyledLinkWithArrow onClick={() => handleResourceClick(data)}>
                  {`Download ${fileFormat}`}
                </StyledLinkWithArrow>
              )}
            </Flex>
          </ContentContainer>
          {videoOpen && (
            <VideoPlayerOverlay
              videoLink={data.videoLink}
              handlePopupClose={handlePopupClose}
            />
          )}
        </ResourceGridItemContainer>
      </ScrollableAnchor>
    </>
  );
}

export default withLocation(ResourceGridItem);
