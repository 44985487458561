import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power1, Power2 } from 'gsap';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import LazyImage from './lazy-image';
import { getThemeColor, hoverScaleDefault } from '../utils/styles';
import { Paragraph } from './UIKit/system';
import { createMailTo } from '../utils/utils';
import mq from '../utils/mq';

const StyledItem = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  color: #fff;
  flex-shrink: 0;
  transition: box-shadow 0.55s ease-out;

  &:after {
    content: '';
    display: block;
    padding-top: 147%;
  }

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15),
        0 6px 10px rgba(0, 0, 0, 0.05);
    `};
`;

const ItemInner = styled(motion.div)``;

const StyledImage = styled(LazyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(transparent, ${getThemeColor('black')});
  opacity: 0.5;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 6px;
  letter-spacing: 0px;
`;

const Subtitle = styled(Paragraph)`
  font-size: 15px;
  letter-spacing: 0px;
`;

const Description = styled(Paragraph)`
  margin-top: 36px;
`;

const Email = styled.div`
  margin-top: 38px;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0px;
`;

const ToggleButton = styled.div`
  ${hoverScaleDefault};
  position: absolute;
  top: 22px;
  right: 18px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: ${getThemeColor('black')};
  cursor: pointer;
  transition: background 0.35s ease-out;

  &,
  &:before,
  &:after {
    transition-delay: 0.1s;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 20px;
    height: 2px;
    background-color: #fff;
    will-change: transform;
    transition: 0.25s ease-out;
    transition-property: transform, background;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  ${(props) =>
    props.close &&
    css`
      background-color: transparent;

      &,
      &:before,
      &:after {
        transition-delay: 0.35s;
      }

      &:before,
      &:after {
        background-color: ${getThemeColor('black')};
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(135deg);
      }
    `};
`;

const MoreContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
  padding: 36px 30px;
  color: ${getThemeColor('black')};
  transform: translateY(100%);
  will-change: transform;

  [data-item] {
    opacity: 0;
    will-change: opacity;
  }

  ${mq.mobile(css`
    padding: 70px 30px 26px;
  `)};
`;

const TeamItemAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: 'easeIn',
    },
  },
};

function TeamItem({ title, subtitle, description, photo, email, ...rest }) {
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const moreContentNode = useRef(null);
  const initialRender = useRef(true);
  const itemsToAnimate = useRef(null);

  const [isInView, setIsInView] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  useEffect(() => {
    itemsToAnimate.current = moreContentNode.current.querySelectorAll(
      '[data-item]'
    );
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    shouldShowMore ? showMore() : hideMore();
  }, [shouldShowMore]);

  function showMore() {
    gsap.killTweensOf(moreContentNode.current);
    gsap.fromTo(
      moreContentNode.current,
      0.65,
      { y: '100%' },
      { y: 0, ease: Power2.easeOut }
    );
    gsap.fromTo(
      itemsToAnimate.current,
      0.45,
      { alpha: 0, y: 0 },
      { alpha: 1, y: 0, ease: Power1.easeOut, stagger: 0.06, delay: 0.35 }
    );
  }

  function hideMore() {
    gsap.to(moreContentNode.current, 0.35, { y: '100%', ease: Power1.easeIn });
  }

  return (
    <StyledItem {...rest} shadow={shouldShowMore} ref={ref}>
      <ItemInner
        initial="hidden"
        animate={isInView ? 'visible' : 'hidden'}
        variants={TeamItemAnim}
      >
        <StyledImage fluid={photo} />
        <Gradient />
        <Content>
          <div>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </div>
        </Content>
        <MoreContent ref={moreContentNode}>
          <div>
            <Title data-item>{title}</Title>
            <Subtitle grey data-item>
              {subtitle}
            </Subtitle>
            <Description grey fontSizeSmall data-item>
              {description}
            </Description>
          </div>
          {email && (
            <Email to={createMailTo(email)} data-item>
              {email}
            </Email>
          )}
        </MoreContent>
        <ToggleButton
          onClick={() => setShouldShowMore((state) => !state)}
          close={shouldShowMore}
        />
      </ItemInner>
    </StyledItem>
  );
}

export default TeamItem;
