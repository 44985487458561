import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import VideoTag from './video-tag';
import Loader from './loader';

const StyledVideoAutoplay = styled.div`
  position: relative;

  ${(props) =>
    props.cover &&
    css`
      > div:first-child,
      video {
        height: 100%;
      }

      video {
        object-fit: cover;
      }
    `};
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 13px 15px;

  ${(props) =>
    props.hidden &&
    css`
      opacity: 0;
      visibility: hidden;
    `};
`;

const StyledVideoTag = styled(VideoTag)`
  pointer-events: none;

  video {
    display: block;
    width: 100%;
  }

  ${(props) =>
    props.videoBlockCutDown &&
    css`
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        padding-top: 46%;
      }

      video {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    `};
`;

function VideoAutoplay({
  className,
  videoBlockCutDown,
  playing: playingProp,
  rewind,
  cover,
  withSound,
  noPreloader,
  noAutoPlay,
  videoRef: videoRefProp,
  onCanPlay,
  ...rest
}) {
  const [playing, setPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const videoNode = useRef(null);

  useEffect(() => {
    update();
  }, [playing]);

  useEffect(() => {
    setPlaying(playingProp);
  }, [playingProp]);

  useEffect(() => {
    if (rewind) {
      videoNode.current.currentTime = 0;
    }
  }, [rewind]);

  function handleVisibilityChange(isVisible) {
    setPlaying(isVisible);
  }

  function update() {
    if (!videoNode.current) {
      return;
    }

    playing ? play() : pause();
  }

  function play() {
    if (videoNode.current.paused) {
      videoNode.current.play().catch((e) => console.warn(e));
    }
  }

  function pause() {
    if (!videoNode.current.paused) {
      videoNode.current.pause();
    }
  }

  function handleCanPlay() {
    onCanPlay && onCanPlay();
    setCanPlay(true);
  }

  function handleVideoRefChange(node) {
    videoNode.current = node;
    if (videoRefProp) {
      videoRefProp(node);
    }
  }

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      minTopValue={200}
      active={!noAutoPlay}
    >
      <StyledVideoAutoplay className={className} cover={cover}>
        <StyledVideoTag
          {...rest}
          videoRef={handleVideoRefChange}
          onCanPlay={handleCanPlay}
          videoBlockCutDown={videoBlockCutDown}
          withSound={withSound}
        />
        {!noPreloader && <StyledLoader hidden={canPlay} />}
      </StyledVideoAutoplay>
    </VisibilitySensor>
  );
}

VideoAutoplay.propTypes = {
  src: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

export default VideoAutoplay;
