import React from 'react';
import SectionAboutCompany from '../../section-about-company';
import RichtextParser from '../../../utils/richtext-parser';

function StoryblokAboutCompanyAdapter({ timeline, paragraph, ...rest }) {
  let paragraphParsed = RichtextParser(paragraph);
  return (
    <SectionAboutCompany
      paragraph={paragraphParsed}
      items={timeline}
      {...rest}
    />
  );
}

export default StoryblokAboutCompanyAdapter;
