import React from 'react';

import TeamList from '../../team-list';
import { parseTeamMembers } from '../../../hooks/parse-team-members';

function StoryblokTeamGrid({ teamMembers }) {
  const teamMembersParsed = parseTeamMembers(
    teamMembers[0].data &&
      teamMembers[0].data.content &&
      teamMembers[0].data.content.team
  );
  return <TeamList items={teamMembersParsed} />;
}

export default StoryblokTeamGrid;
