/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { IframeHTMLAttributes, ReactElement, useState } from 'react'
import styled from 'styled-components'
import StoryblokClient from 'storyblok-js-client'
import { useEffect } from 'react'
import { createHubSpotForm } from '../../../utils/utils'
import { camelizeKeys } from 'humps'

// Components
import Button from '../../button'

// Constants
import STORYBLOK_API_KEY from '../../../storyblok-api-key'

// Types
import { StoryBlokForm } from '../types'
import { Info } from '../root-page'
import { HUBSPOT_FORM } from '../consts'

interface EntryFormProps extends StoryBlokForm {
  callback: (info: Info) => void
  info?: Info
}

const Storyblok = new StoryblokClient({
  accessToken: STORYBLOK_API_KEY
})

// Styles
const StyledHeader = styled.section`
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 800px));
  grid-template-rows: 1fr;
  justify-content: center;
  min-height: 600px;
  background-color: #161616;
  color: #fff;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
`

const StyledHeaderImage = styled.div`
  grid-column: 1;
  grid-row: 1;
  z-index: 0;
`

const StyledHeaderContent = styled.div`
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  padding: 2rem;
  width: 65%;
  margin: auto;

  h3 {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: #ffffff;

    &:after {
      content: '';
      position: relative;
      top: 0.1em;
      display: block;
      height: 2px;
      width: 18px;
      background-color: #753fbf;
      margin-left: 10px;
    }
  }

  h1 {
    margin-top: 0.7rem;
    font-weight: bold;
    font-size: 52px;
    line-height: 56px;
    letter-spacing: -0.02em;
    color: #ffffff;
  }

  .content {
    margin-top: 1.4rem;

    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #ffffff;
    }

    p {
      margin-top: 1.7rem;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #d4d4d4;
    }

    a {
      color: #8a55fb;
      text-decoration: none;
    }
  }
`

const StyledHeaderForm = styled.div`
  grid-column: 2;
  grid-row: 1;
  padding: 2rem;
  width: 70%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 4px;

  @media (max-width: 768px) {
    grid-column: 1;
    grid-row: 2;
    max-width: 80vw;
    margin-bottom: 2.5rem;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 2.5rem;
  }

  #entry-form {
    fieldset {
      &.form-columns-2 {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }

      .hs-error-msg {
        color: #8a55fb;
      }

      .input {
        width: 100%;
        position: relative;

        span {
          position: absolute;
          visibility: hidden;
        }

        input {
          margin-bottom: 1rem;
          width: 100%;
          height: 64px;
          color: #666666;
          font-family: Muli;
          font-weight: 600;
          font-size: 16px;
          border: 1.5px solid #d4d4d4;
          box-sizing: border-box;
          text-align: center;
          padding: 1rem;
        }
      }
    }

    .actions {
      input {
        width: 100%;
        height: 64px;
        background: #753fbf;
        border-radius: 1px;
        border: none;
        font-family: Muli;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
`

const StyledHeaderFormDisclaimer = styled.div`
  width: 100%;
  display: flex;
  margin: 1rem 0;

  .checkbox {
    margin-top: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 20px;
    position: relative;
    background: #753fbf;

    svg {
      stroke: #ffffff;
    }
  }

  .content {
    margin-left: 1rem;

    p {
      font-weight: normal;
      font-size: 13px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #959595;
    }

    a {
      color: #8a55fb;
      text-decoration: none;
    }
  }
`

function EntryFormHeader(props: EntryFormProps): ReactElement {
  const contentHTML = Storyblok.richTextResolver.render(props.text)
  const disclaimerHTML = Storyblok.richTextResolver.render(props.disclaimer)

  useEffect(() => {
    let info: Info
    createHubSpotForm({
      portalId: HUBSPOT_FORM.portalId,
      formId: HUBSPOT_FORM.formId,
      targetId: 'entry-form',
      onFormSubmit: () => {
        const form = document.querySelector<HTMLFormElement>('#entry-form form') || document.querySelector<HTMLIFrameElement>('#entry-form iframe')?.contentWindow?.document
        if (!form) throw new Error('Missing form element.')
        const formInputs = form.querySelectorAll('input')

        if (!formInputs?.length) throw new Error('Missing form inputs.')

        const inputs = Array.from(formInputs)
          .filter((node) => node.id && node.name)
          .map((input) => [input.name, input.value])
        info = camelizeKeys(Object.fromEntries(inputs)) as unknown as Info
      },
      onFormSubmitted: () => {
        props.callback(info)
      }
    })
  }, [])

  return (
    <StyledHeader>
      <StyledHeaderImage>
        <img src={props.backgroundImage.filename} alt={props.backgroundImage.alt} />
      </StyledHeaderImage>
      <StyledHeaderContent>
        <h3>Sertus Tools</h3>
        <h1>{props.title}</h1>
        <div className="content" dangerouslySetInnerHTML={{ __html: contentHTML }}></div>
      </StyledHeaderContent>
      <StyledHeaderForm>
        <h3>{props.formTitle}</h3>
        <div id="entry-form"></div>
        <StyledHeaderFormDisclaimer>
          <div className="checkbox">
            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 4.11111L4.92857 8L12 1" stroke="white" strokeWidth="2" />
            </svg>
          </div>
          <div className="content" dangerouslySetInnerHTML={{ __html: disclaimerHTML }}></div>
        </StyledHeaderFormDisclaimer>
      </StyledHeaderForm>
    </StyledHeader>
  )
}

export default EntryFormHeader
