import React from 'react';
import SectionVideo from '../../section-video';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed';

function StoryblokVideoSplashAdapter({ backgroundImage, ...rest }) {
  let fluidImage = getFluidGatsbyImage(backgroundImage, {
    maxWidth: 1920,
  });
  return <SectionVideo backgroundImage={fluidImage} {...rest} />;
}
export default StoryblokVideoSplashAdapter;
