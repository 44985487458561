import StepInputSelectFactory from './step-input-select-factory';
import StepInputCardsFactory from './step-input-cards-factory';
import { YES_NO_TYPE } from '../../constants';

export const STEPS = {
  PURPOSE_GROUP: 'purposeGroup',
  ACCOMMODATION_TYPE: 'accommodationType',
  BUILDING_HEIGHT: 'buildingHeight',
  STAIRCASES: 'staircases',
  BASEMENT: 'basement',
  BASEMENT_PURPOSE: 'basementPurpose',
  BASEMENT_DEPTH: 'basementDepth',
  BS_EN_12845_ACCORDANCE: 'bsEn12845Accordance',
  TRAVEL_DISTANCE_WITHIN_ACCOMMODATION: 'travelDistanceWithinAccommodation',
  FIRE_ALARM_LEVEL: 'fireAlarmLevel',
  ENVIRONMENTAL_VENTILATION: 'environmentalVentilation',
  BUILDING_AREA_PER_FLOOR: 'buildingAreaPerFloor',
};

export const STEPS_ORDER = [
  STEPS.PURPOSE_GROUP,
  STEPS.ACCOMMODATION_TYPE,
  STEPS.BUILDING_HEIGHT,
  STEPS.STAIRCASES,
  STEPS.BASEMENT,
  STEPS.BASEMENT_PURPOSE,
  STEPS.BASEMENT_DEPTH,
  STEPS.BS_EN_12845_ACCORDANCE,
  STEPS.TRAVEL_DISTANCE_WITHIN_ACCOMMODATION,
  STEPS.FIRE_ALARM_LEVEL,
  STEPS.ENVIRONMENTAL_VENTILATION,
  STEPS.BUILDING_AREA_PER_FLOOR,
];

const OPTIONS_TMP = [
  { name: '0', value: '0' },
  { name: '1', value: '1' },
];

const STEPS_CONFIG_BASE = {
  [STEPS.PURPOSE_GROUP]: {
    initialValue: '',
    component: StepInputSelectFactory,
    props: {
      name: 'Purpose group',
      placeholder: 'Select Purpose Group Level',
      options: OPTIONS_TMP,
    },
  },

  [STEPS.ACCOMMODATION_TYPE]: {
    initialValue: '',
    component: StepInputSelectFactory,
    props: {
      name: 'Accommodation type',
      placeholder: 'Select Accommodation type',
      options: OPTIONS_TMP,
    },
  },

  [STEPS.BUILDING_HEIGHT]: {
    initialValue: '',
    component: StepInputCardsFactory,
    props: {
      name: 'Building height',
      options: [
        { title: 'Up to 11m' },
        { title: '4.5m - 18m' },
        { title: '18m - 30m' },
      ],
    },
  },

  [STEPS.STAIRCASES]: {
    initialValue: '',
    component: StepInputCardsFactory,
    props: {
      name: 'How many staircases?',
      fontSizeLarge: true,
      options: [{ title: '1' }, { title: '2' }, { title: '3+' }],
    },
  },

  [STEPS.BASEMENT]: {
    initialValue: '',
    component: StepInputCardsFactory,
    props: {
      name: 'Is there a basement?',
      yesNoType: true,
    },
  },

  [STEPS.BASEMENT_PURPOSE]: {
    initialValue: '',
    component: StepInputCardsFactory,
    dependentOn: STEPS.BASEMENT,
    props: {
      name: 'What is the basement used for?',
      options: [
        { title: 'Services' },
        { title: 'Commercial' },
        { title: 'Residential' },
        { title: 'Carpark' },
      ],
    },
  },

  [STEPS.BASEMENT_DEPTH]: {
    initialValue: '',
    component: StepInputCardsFactory,
    dependentOn: STEPS.BASEMENT,
    props: {
      name: 'Basement depth.',
      options: [
        { title: 'Less than 10m below the FRS access.' },
        { title: 'Less than 10m, but storey area is 900+m2' },
        { title: 'More than 10m below.' },
        { title: 'More than 10m and storey area is 900+m2' },
      ],
    },
  },

  [STEPS.BS_EN_12845_ACCORDANCE]: {
    initialValue: '',
    component: StepInputCardsFactory,
    props: {
      name: 'Sprinkled in accordance with BS EN 12845?',
      yesNoType: true,
    },
  },

  [STEPS.TRAVEL_DISTANCE_WITHIN_ACCOMMODATION]: {
    initialValue: '',
    component: StepInputCardsFactory,
    props: {
      name: 'Travel Distance within accommodation?',
      options: [
        { title: 'Less than 4.5m' },
        { title: 'Less than 7.5m' },
        { title: 'Less than 30m' },
      ],
    },
  },

  [STEPS.FIRE_ALARM_LEVEL]: {
    initialValue: '',
    component: StepInputSelectFactory,
    props: {
      name: 'Fire alarm level.',
      placeholder: 'Select fire alarm level',
      options: OPTIONS_TMP,
    },
  },

  [STEPS.ENVIRONMENTAL_VENTILATION]: {
    initialValue: '',
    component: StepInputCardsFactory,
    props: {
      name:
        'Will the smoke ventilation system be used for environmental ventilation?',
      fontSizeLarge: true,
      options: [{ title: 'Yes' }, { title: 'No' }, { title: 'TBC' }],
    },
  },

  [STEPS.BUILDING_AREA_PER_FLOOR]: {
    initialValue: '',
    component: StepInputCardsFactory,
    props: {
      name: 'Building area per floor.',
      fontSizeLarge: true,
      options: [
        { title: 'More', subtitle: 'than 900m2', value: '> 900m2' },
        { title: 'Less', subtitle: 'than 900m2', value: '< 900m2' },
      ],
    },
  },
};

export const STEPS_CONFIG = Object.keys(STEPS_CONFIG_BASE).reduce(
  (acc, key) => {
    return { ...acc, [key]: { ...STEPS_CONFIG_BASE[key], id: key } };
  },
  {}
);
