import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import GridCol from './grid-col';
import Title from './title';
import ComparisonGrid from './comparison-grid/comparison-grid';
import ComparisonGridMobile from './comparison-grid/comparison-grid-mobile';
import Responsive from '../components/responsive';
import mq from '../utils/mq';

const StyledSectionComparisonGrid = styled(Box)``;

const StyledGridCol = styled(GridCol)`
  ${mq.mobile(css`
    width: 100%;
    margin-bottom: 22px;
  `)};
`;

function SectionComparisonGrid({ title, text, params, data }) {
  return (
    <StyledSectionComparisonGrid pt={[70, 90, 112]} pb={[40, 85]}>
      <Container>
        <Flex mb={[59, 90, 120]} flexWrap={['wrap', 'unset', 'unset']}>
          <StyledGridCol>
            <Title as={'h2'} fontSizeMedium html={title} />
          </StyledGridCol>
          <Paragraph grey html={text} />
        </Flex>
        <Responsive.DesktopOrTablet>
          <ComparisonGrid params={params} data={data} />
        </Responsive.DesktopOrTablet>
      </Container>
      <Responsive.Mobile>
        <ComparisonGridMobile params={params} data={data} />
      </Responsive.Mobile>
    </StyledSectionComparisonGrid>
  );
}

export default SectionComparisonGrid;
