import React from 'react';
import styled from 'styled-components';

import Title from './title';
import Container from './container';
import { Box, Paragraph } from './UIKit/system';
import TileGrid from './tile-grid';

const StyledFeaturedProjects = styled(Box)``;

function FeaturedProjects({ items, title, subtitle, ...rest }) {
  return (
    <StyledFeaturedProjects {...rest}>
      <Container>
        <Box mb={45}>
          <Title mb={10} fontSizeMedium textAlign={'center'}>
            {title}
          </Title>
          <Paragraph grey textAlign={'center'}>
            {subtitle}
          </Paragraph>
        </Box>
        <TileGrid featured>
          <TileGrid.Items items={items} featured />
        </TileGrid>
      </Container>
    </StyledFeaturedProjects>
  );
}

export default FeaturedProjects;
