import { useMemo } from 'react';

import { getFluidGatsbyImage } from '../utils/gatsby-storyblok-image-fixed';

export function parseTeamMembers(teamMembers) {
  return useMemo(() => {
    if (!teamMembers) {
      return [];
    }

    return teamMembers.map((member) => {
      return {
        title: member.title,
        subtitle: member.position,
        photo: getFluidGatsbyImage(member.photo, {
          width: 330,
        }),
        description: member.description,
        email: member.email,
      };
    });
  }, [teamMembers]);
}
