import React from 'react';

import getFluidGatsbyImage from '../../../utils/gatsby-storyblok-image-fixed/getFluidGatsbyImage';
import CaseStudies from '../../case-studies';

function StoryblokFeaturedCaseStudies({ caseStudies, ...rest }) {
  const caseStudiesParsed = caseStudies.reduce((acc, obj) => {
    if (obj && obj.caseStudy && obj.caseStudy.content) {
      const caseStudy = obj.caseStudy;

      return [
        ...acc,
        {
          ...caseStudy.content,
          link: `/${caseStudy.full_slug}`,
          image: getFluidGatsbyImage(caseStudy.content.gridImage, {
            width: 350,
          }),
          text: caseStudy.content.subtitle,
        },
      ];
    }

    return acc;
  }, []);

  return (
    caseStudies &&
    caseStudies.length > 1 && (
      <CaseStudies gatsbyImg items={caseStudiesParsed} {...rest} />
    )
  );
}

export default StoryblokFeaturedCaseStudies;
