import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import LazyImage from './lazy-image';
import { Box, Flex, Paragraph, Text } from './UIKit/system';
import Title from './title';
import LinkWithArrow from './link-with-arrow';
import { supportsGrid } from '../utils/styles';
import mq from '../utils/mq';
import ellipsis from '../utils/ellipsed';
import Link from './link';
import WipeAnimation from './wipe-animation';

const StyledArticleTileItem = styled.div`
  width: calc(50% - 40px);
  margin-right: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  ${supportsGrid(css`
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  `)};
  ${mq.mobile(css`
    width: calc(100% - 20px);
  `)};
`;

const StyledTitle = styled(Title)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ImageAnimationLayer = styled(motion.div)``;

const ImageItemAnim = {
  hidden: {
    scale: 1.1,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.6,
      ease: 'easeIn',
    },
  },
};

const StyledLink = styled.a``;

function ArticleTileItem({
  topText,
  title,
  text,
  image,
  link,
  small,
  wipeAnimation,
}) {
  const [isInView, setIsInView] = useState(false);
  const [linkMode, setLinkMode] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  useEffect(() => {
    if (link.to.includes('a.storyblok.com')) {
      setLinkMode(true);
    }
  });

  return (
    <StyledArticleTileItem small={small}>
      <StyledLink href={link.to} target={linkMode ? '_blank' : null}>
        {wipeAnimation ? (
          <WipeAnimation
            threshold={0}
            width={'100%'}
            height={small ? 208 : 380}
          >
            <ImageAnimationLayer
              ref={ref}
              initial="hidden"
              animate={isInView ? 'visible' : 'hidden'}
              variants={ImageItemAnim}
            >
              <LazyImage height={small ? 208 : 380} fluid={image} />
            </ImageAnimationLayer>
          </WipeAnimation>
        ) : (
          <LazyImage height={small ? 208 : 380} fluid={image} />
        )}
      </StyledLink>
      <Flex
        flex={1}
        minHeight={small ? [0, 0, 272] : 300}
        flexDirection={'column'}
        justifyContent={'space-between'}
        backgroundColor={'lightGrey'}
        pt={small ? [14, 14, 35] : 40}
        pb={small ? [14, 14, 30] : 40}
        pl={small ? [24, 24, 30] : [15, 30, 60, 60]}
        pr={small ? [24, 24, 30] : [15, 30, 60, 60]}
      >
        <Box mb={20}>
          {topText && (
            <Text mb={[6, 6, 20]} fontSizeSmall grey>
              {topText === 'trainingCourse' ? 'Training Course' : topText}
            </Text>
          )}
          <StyledTitle
            lineHeight={1.5}
            fontSizeSmall={!small}
            fontSizeExtraSmall={small}
          >
            {title}
          </StyledTitle>
          {text && (
            <Paragraph mt={14} lineHeight={1.6} grey>
              {text}
            </Paragraph>
          )}
        </Box>
        {link && <LinkWithArrow {...link} linkMode={linkMode} />}
      </Flex>
    </StyledArticleTileItem>
  );
}

export default ArticleTileItem;
