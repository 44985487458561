import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';

import Title from './title';
import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import Button from './button';
import { vw } from '../utils/size-functions';
import GridCol from './grid-col';
import { STANDARD_PADDING } from '../constants';
import mq from '../utils/mq';
import { withResponsiveContext } from './responsive';

const StyledLeftImageRightContent2Outer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLeftImageRightContent2 = styled(Box)`
  width: 100%;
  max-width: 1850px;
  ${mq.mobile(css`
    padding-bottom: 0px;
  `)};
`;

const StyledContainer = styled(Container)`
  ${mq.mobile(css`
    border-left-width: 0px;
    border-right-width: 0px;
  `)};
`;

const StyledFlex = styled(Flex)`
  ${mq.desktopSmall(css`
    justify-content: space-evenly;
  `)};
  ${mq.mobile(css`
    flex-wrap: wrap;
    justify-content: flex-start;
  `)};
`;

const StyledGridCol = styled(GridCol)`
  ${mq.desktopSmall(css`
    width: 455px;
    padding-right: 60px;
  `)}
  ${mq.tablet(css`
    width: 45vw;
  `)};
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const ImageContainer = styled(motion.div)``;

const InnerImageContainer = styled(motion.div)`
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: ${vw(650)};
  max-width: 40%;
  overflow: hidden;
  ${mq.desktopSmall(css`
    max-width: unset;
  `)};
  ${mq.mobile(css`
    height: 100%;
    width: 100%;
    position: relative !important;
    max-height: 500px;
    object-position: right;
  `)};
`;

const ImageWipe = styled(motion.div)`
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`;

const ImageZoonAnim = styled(motion.div)``;

const Image = styled(Img)`
  height: 100%;
  width: 100%;
`;

const StyledParagraph = styled(Paragraph)`
  ${mq.mobile(css`
    font-size: 16px;
    line-height: 28px;
  `)};
`;

const Content = styled(Flex)``;

function LeftImageRightContent2({ title, paragraph, button, image, isMobile }) {
  const [isInView, setIsInView] = useState(false);

  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  const ImageOverlayAnim = {
    hidden: { x: 0 },
    visible: {
      x: '100vw',
      transition: {
        delay: 0.2,
        duration: 0.6,
        type: 'tween',
      },
    },
  };

  const ImageAnim = {
    hidden: { scale: 1.1 },
    visible: {
      scale: 1,
      transition: {
        delay: 0.3,
        duration: 0.8,
        type: 'tween',
      },
    },
  };

  return (
    <StyledLeftImageRightContent2Outer>
      <StyledLeftImageRightContent2
        as={'section'}
        pt={STANDARD_PADDING}
        pb={[80, 100, 120]}
        ref={ref}
      >
        <Box position={'relative'}>
          <StyledContainer>
            <StyledFlex>
              <StyledGridCol>
                <ImageContainer>
                  <InnerImageContainer>
                    <ImageWipe
                      initial="hidden"
                      animate={isInView ? 'visible' : 'hidden'}
                      variants={ImageOverlayAnim}
                    />
                    <ImageZoonAnim
                      initial="hidden"
                      animate={isInView ? 'visible' : 'hidden'}
                      variants={ImageAnim}
                    >
                      <Image fluid={image} />
                    </ImageZoonAnim>
                  </InnerImageContainer>
                </ImageContainer>
              </StyledGridCol>
              <Content
                pt={[33, 152, 152, 152]}
                pb={[61, 152, 152, 152]}
                pl={[20, 0, 0, 0]}
                pr={[20, 0, 0, 0]}
                minHeight={[0, 749, 749, 749]}
                justifyContent={'center'}
                alignItems={'flex-start'}
                flexDirection={'column'}
              >
                <Title
                  as={'h2'}
                  mb={[20, 60, 60]}
                  maxWidth={460}
                  fontSizeMedium
                  html={title}
                />
                <StyledParagraph
                  maxWidth={409}
                  grey
                  html={paragraph}
                  mb={[0, 50, 50]}
                />
                {button && <Button {...button} />}
              </Content>
            </StyledFlex>
          </StyledContainer>
        </Box>
      </StyledLeftImageRightContent2>
    </StyledLeftImageRightContent2Outer>
  );
}

export default withResponsiveContext(LeftImageRightContent2);
