import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Title from '../title';

import CloseCross from '../../images/svg/close-cross.svg';
import { useDownloadsSubscriptionForm } from '../../hooks/use-downloads-subscription-form';
import HubSpotForm from '../hubspot-form';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { CAD_FILE_TYPE } from '../../constants';
import { getPath } from '../../utils/paths';
import { navigate } from 'gatsby-link';

const DownloadPopupOuterContainer = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: #dddddd7d;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadPopupContainer = styled(motion.div)`
  max-width: 443px;
  width: 100%;
  min-height: 266px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #161616;
  padding: 40px 30px 45px 30px;
`;

const CloseCrossContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 17px;
  margin-right: 16px;
  cursor: pointer;
`;

const StyledTitle = styled(Title)`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 40px;

  span {
    color: #7549bf;
  }
`;

const FormContainer = styled.div`
  color: #fff;
  width: 100%;

  input[type='submit'] {
    width: 100%;
  }

  .hs-form-field {
    width: 100%;
    margin-right: 0;
  }

  .submitted-message {
    text-align: left;
    color: lightgreen;
  }
`;

const backgroundAnim = {
  false: {
    opacity: 0,
    transition: {
      delay: 0.1,
      duration: 0.2,
    },
  },
  true: {
    opacity: 1,
  },
};

const containerAnim = {
  hidden: { y: -20 },
  visible: {
    y: 0,
  },
};

function DownloadPopup({ selectedItem, filePath, title, handlePopupClose }) {
  const [popupNode, setPopupNode] = useState(null);
  const linkRef = useRef(null);
  const [displayPopup, setDisplayPopup] = useState(true);

  const formData = useDownloadsSubscriptionForm();

  useEffect(() => {
    document.addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'Escape') {
          close();
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    const handleFormEvent = (event) => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmit' &&
        event.data.id === formData.formId
      ) {
        if (!window.FS || !event.data.data) return;

        const data = Object.fromEntries(
          event.data.data.map(({ name, value }) => [name, value])
        );

        const user = {};
        if (data.firstname || data.lastname) user.displayName = `${data.firstname} ${data.lastname}`;
        if (data.email) user.email = data.email;

        window.FS.setUserVars(user);
        window.FS.event('Downloads Subscription Form', { ...data, ...user });
      }
    };

    window.addEventListener('message', handleFormEvent);
    return () => window.removeEventListener('message', handleFormEvent);
  });

  useOutsideClick(close, {
    node: popupNode,
    enabled: displayPopup,
  });

  function handleFormSubmit() {
    setTimeout(() => {
      close();
      if (selectedItem && selectedItem.filetype === CAD_FILE_TYPE) {
        openCadViewer();
      } else {
        linkRef.current && linkRef.current.click();
      }
    }, 1100);
  }

  function close() {
    setDisplayPopup(false);
    setTimeout(() => {
      handlePopupClose();
    }, 180);
  }

  function handlePopupNodeChange(node) {
    if (popupNode !== node) {
      setPopupNode(node);
    }
  }

  function openCadViewer() {
    navigate(getPath.cadViewer({ slug: selectedItem.slug }));
  }

  function handleFormReady(form) {
    if (!selectedItem) return;

    const productEl = form.querySelector('input[name="last_download_product"]');
    const categoryEl = form.querySelector(
      'input[name="last_download_category"]'
    );

    // Hardcoded, for now
    const categoryTypes = new Map([
      ['Data Sheets', 'data sheet'],
      ['White Papers', 'white paper'],
      ['BIM Models', 'BIM model'],
      ['CAD Models', 'CAD model'],
      ['User Manuals', 'user manual'],
      ['Declarations of Performance', 'Declaration of Performance'],
      ['Installation Guides', 'installation guide'],
      ['Operation Manuals', 'operation manual'],
    ]);

    const { name, type, categories: [category] = [] } = selectedItem;

    productEl.value = name;
    categoryEl.value = type
      ? categoryTypes.get(type) || type
      : categoryTypes.get(category.name) || category.name;
  }

  return (
    <DownloadPopupOuterContainer
      initial="false"
      animate={displayPopup ? 'true' : 'false'}
      variants={backgroundAnim}
    >
      <div ref={handlePopupNodeChange}>
        <DownloadPopupContainer
          initial="hidden"
          animate={displayPopup ? 'visible' : 'hidden'}
          variants={containerAnim}
        >
          <CloseCrossContainer onClick={close}>
            <CloseCross stroke={'#474747'} />
          </CloseCrossContainer>
          <StyledTitle html={title} />
          <FormContainer>
            <HubSpotForm
              portalId={formData.portalId}
              formId={formData.formId}
              onFormSubmitted={handleFormSubmit}
              onFormReady={handleFormReady}
              darkBackground
              fieldsFullWidth
            />
          </FormContainer>
          <a
            href={filePath}
            target="_blank"
            download
            rel={'noreferrer'}
            ref={linkRef}
          />
        </DownloadPopupContainer>
      </div>
    </DownloadPopupOuterContainer>
  );
}

DownloadPopup.defaultProps = {
  title:
    'To get access to this <span>free</span> resource, please enter your email address.',
};

export default DownloadPopup;
