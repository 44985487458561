import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import {
  getThemeColor,
  inputSelect,
  inputSelectWhite,
} from '../../utils/styles';
import ChevronDown from '../../images/svg/chevron-down.svg';
import { Flex } from '../UIKit/system';
import InputLabel from './input-label';

const StyledInputSelect = styled.div`
  ${inputSelect};
  overflow: hidden;
  border: 1px solid transparent;

  ${(props) =>
    props.white &&
    css`
      ${inputSelectWhite(props)}
    `};

  ${(props) =>
    props.invalid &&
    css`
      border: 1px solid ${getThemeColor('danger')};
    `};
`;

const StyledChevronDown = styled(ChevronDown)`
  fill: #fff;

  ${(props) =>
    props.white &&
    css`
      fill: #666;
    `};
`;

const Current = styled.div`
  ${(props) =>
    props.textAlignCenter &&
    css`
      width: 100%;
      text-align: center;
      padding-left: 11px;
    `};
`;

const Select = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 128px;
  border-top: 64px solid transparent;
`;

function InputSelect({
  field,
  options,
  placeholder,
  white,
  textAlignCenter,
  invalid,
}) {
  const selectedOption = useMemo(() => {
    if (!field.value) {
      return null;
    }
    return options.find((v) => v.value === field.value);
  }, [field.value]);

  return (
    <StyledInputSelect white={white} invalid={invalid}>
      <Flex
        height={'100%'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Current textAlignCenter={textAlignCenter}>
          {selectedOption
            ? selectedOption.name
            : placeholder || 'Please, select'}
        </Current>
        <StyledChevronDown white={white} />
      </Flex>
      <Select {...field}>
        <option value="">None</option>
        {options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </Select>
    </StyledInputSelect>
  );
}

InputSelect.Label = InputLabel;

export default InputSelect;
