import styled from 'styled-components'

export const StyledFormButton = styled.button`
  align-items: center;
  background: #753fbf;
  border-radius: 1px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: Muli;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  letter-spacing: -0.01em;
  line-height: 18px;
  margin: 2rem auto 0;
  padding: 17px 160px;
  text-align: center;
  width: 100%;
  max-width: 500px;

  &:disabled {
    filter: opacity(0.3);
  }
`
