import React from 'react';
import styled from 'styled-components';

import Carousel from './carousel';
import { Box } from './UIKit/system';
import { darkBackground } from '../utils/styles';
import Container from './container';

const StyledSectionCarousel = styled(Box)`
  ${darkBackground};
  overflow-x: hidden;
`;

const StyledContainer = styled(Container)`
  overflow-x: hidden;
`;

function SectionCarousel({ images, ...rest }) {
  return (
    <StyledSectionCarousel py={66} {...rest}>
      <StyledContainer>
        <Carousel items={images} />
      </StyledContainer>
    </StyledSectionCarousel>
  );
}

export default SectionCarousel;
