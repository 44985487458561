import styled, { css } from 'styled-components';
import React from 'react';

import { Box, Flex, Text } from '../UIKit/system';
import { getThemeColor, transitionDefault } from '../../utils/styles';
import mq from '../../utils/mq';

const StyledCard = styled.div`
  background-color: #fff;
  height: 298px;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq.tablet(css`
    height: 255px;
  `)};

  ${mq.mobile(css`
    height: 220px;
  `)};
`;

const SelectButton = styled(Box)`
  ${transitionDefault};
  font-weight: 700;
  cursor: pointer;
  padding: 5px;

  &:hover {
    transition: none;
    color: ${getThemeColor('black')};
  }
`;

function InputCardsItem({ title, fontSizeLarge, subtitle, onClick, ...rest }) {
  return (
    <StyledCard {...rest}>
      <Flex
        width={'100%'}
        flex={1}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        pt={25}
        px={20}
      >
        <Box textAlign={'center'}>
          <Text fontSize={fontSizeLarge ? [32, 44, 52] : [20, 24]} bold>
            {title}
          </Text>
          {subtitle && (
            <Text mt={12} fontSize={18} bold>
              {subtitle}
            </Text>
          )}
        </Box>
      </Flex>
      <Flex height={75} justifyContent={'center'} alignItems={'center'}>
        <SelectButton color={'primary'} onClick={onClick}>
          Select
        </SelectButton>
      </Flex>
    </StyledCard>
  );
}

export default InputCardsItem;
