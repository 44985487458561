import React from 'react';
import styled, { css } from 'styled-components';

import Title from './title';
import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import mq from '../utils/mq';
import { STANDARD_PADDING } from '../constants';
import GridCol from './grid-col';
import ArticleTileList from './article-tile-list';

const StyledRelatedArticles = styled(Box)``;

const StyledContainer = styled(Container)`
  ${mq.tablet(css`
    border-right-width: 0px;
    border-left-width: 0px;
  `)};
  ${mq.mobile(css`
    border-right-width: 0px;
    border-left-width: 0px;
  `)};
`;

const StyledFlex = styled(Flex)`
  ${mq.tablet(css`
    padding-right: 20px;
    padding-left: 20px;
    flex-wrap: wrap;
  `)};
  ${mq.mobile(css`
    padding-right: 20px;
    padding-left: 20px;
  `)};
`;

const StyledGridCol = styled(GridCol)`
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

function RelatedArticles({ items, relatedArticleParagraph }) {
  return (
    <StyledRelatedArticles pt={[60, 75, 92]} pb={STANDARD_PADDING}>
      <StyledContainer>
        <StyledFlex mb={65} justifyContent={'space-between'}>
          <StyledGridCol>
            <Title fontSizeMedium>Related Articles.</Title>
          </StyledGridCol>
          <Box pt={9}>
            <Paragraph maxWidth={535} grey>
              {relatedArticleParagraph}
            </Paragraph>
          </Box>
        </StyledFlex>
        <ArticleTileList threeColumns items={items} related />
      </StyledContainer>
    </StyledRelatedArticles>
  );
}

export default RelatedArticles;
