import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player/lazy';
import BarLoader from 'react-spinners/BarLoader';

import CloseIcon from '../images/svg/close-cross.svg';
import Portal from './portal';
import useOnclickOutside from 'react-cool-onclickoutside';
import mq from '../utils/mq';
import { hoverDefault } from '../utils/styles';

const VideoPlayerOverlayOuterContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  background-color: rgba(35, 35, 35, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoPlayerOverlayContainer = styled(motion.div)`
  background: #000;
  width: 1012px;
  height: 564px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mq.tablet(css`
    max-width: calc(79.5vh);
    height: calc(56.25vw);
  `)};
`;

const CloseIconContainer = styled.div`
  ${hoverDefault};
  position: absolute;
  top: -15px;
  right: -35px;
  cursor: pointer;
  font-size: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
  }

  ${mq.desktopSmall(css`
    right: 5px;
    top: -30px;
  `)};
`;

const VideoContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

const ErrorContainer = styled.div`
  font-size: 17px;
  color: #000;
`;

const backgroundAnim = {
  false: {
    opacity: 0,
    transition: {
      delay: 0.1,
      duration: 0.2,
    },
  },
  true: {
    opacity: 1,
  },
};

const containerAnim = {
  hidden: { y: -20 },
  visible: {
    y: 0,
  },
};

function VideoPlayerOverlay({ handlePopupClose, videoLink }) {
  const modalNode = useRef(null);
  const [displayPopup, setdisplayPopup] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    document.addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      },
      false
    );
  }, []);

  const ref = useOnclickOutside(() => {
    handleClose();
  });

  function handleClose() {
    setdisplayPopup(false);
    setTimeout(() => {
      handlePopupClose();
    }, 180);
  }

  function handleError(e) {
    let notFound = 'Error: “' + videoLink + '” was not found.';
    if (e.toString() === notFound.toString()) {
      setErrorMessage('Error: Invalid Video Link.');
    } else {
      setErrorMessage(e.toString());
    }
    setError(true);
  }

  return (
    <Portal>
      <VideoPlayerOverlayOuterContainer
        initial="false"
        animate={displayPopup ? 'true' : 'false'}
        variants={backgroundAnim}
      >
        <VideoPlayerOverlayContainer
          ref={ref}
          initial="hidden"
          animate={displayPopup ? 'visible' : 'hidden'}
          variants={containerAnim}
        >
          <CloseIconContainer onClick={handleClose}>
            <CloseIcon stroke={'#fff'} />
          </CloseIconContainer>
          <VideoContainer>
            <ReactPlayer
              url={videoLink}
              width={'100%'}
              height={'100%'}
              playing={true}
              controls={true}
              playsinline={true}
              onError={(e) => handleError(e)}
              vimeoConfig={{
                iframeParams: { fullscreen: 0 },
                playsinline: true,
              }}
            />
          </VideoContainer>
          {error ? (
            <ErrorContainer>{errorMessage}</ErrorContainer>
          ) : (
            <BarLoader color={'#7549BF'} />
          )}
        </VideoPlayerOverlayContainer>
      </VideoPlayerOverlayOuterContainer>
    </Portal>
  );
}

export default VideoPlayerOverlay;
