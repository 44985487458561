import React from 'react';

import Container from '../container';
import Intro from './intro';
import Header from './header';
import { ContactScreen } from './shared-components';

function ContactRootPage({ title, subtitle, optionsTitle }) {
  return (
    <ContactScreen>
      <Container narrow>
        <Header title={title} subtitle={subtitle} />
        <Intro optionsTitle={optionsTitle} />
      </Container>
    </ContactScreen>
  );
}

export default ContactRootPage;
