import React from 'react';

import RichtextParser from '../../../utils/richtext-parser';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import ThreeCardIconBlock from '../../three-card-icon-block';

function StoryblokLeftIconsRightImageAdapter({ paragraph, cards, ...rest }) {
  let parsedCards = [];
  cards.map((card) => {
    let paragraphText = RichtextParser(card.paragraph);
    let parsedLink = parseStoryblokLink(card.ctaLink);

    let cardData = {
      ...card,
      paragraph: paragraphText,
      title: card.title,
      ctaText: card.ctaText,
      ctaLink: parsedLink,
    };
    parsedCards.push(cardData);
  });
  return (
    <ThreeCardIconBlock
      paragraph={RichtextParser(paragraph)}
      cards={parsedCards}
      {...rest}
    />
  );
}

export default StoryblokLeftIconsRightImageAdapter;
