import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { Box, Flex, Paragraph, Text } from './UIKit/system';
import mq from '../utils/mq';

const lineAnimation = keyframes`
  0% {
    transform: scaleY(0);
  }
  
  100% {
    transform: scaleY(1);
  }
`;

const StyledTimelineItem = styled.div`
  position: relative;
  margin-bottom: 77px;
  padding-left: 37px;
  ${mq.mobile(css`
    padding-left: 0px;
  `)};

  &:last-of-type {
    margin-bottom: 0;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;

    ${mq.mobile(css`
      display: none;
    `)};
  }

  &:before {
    background-color: #d4d4d4;
  }

  &:after {
    background-color: #8a55fb;
    transform: scaleY(0);
    will-change: transform;
    transform-origin: top center;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }
  ${(props) =>
    props.active &&
    css`
      &:after {
        animation-name: ${lineAnimation};
        animation-duration: 5s;
      }
    `};

  ${(props) =>
    props.filled &&
    css`
      &:after {
        transform: scaleY(1);
        transition: transform 0.55s ease-out;
      }
    `};
`;

const TimelineItemInner = styled(motion.div)``;

const TimelineItemAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: 'easeIn',
    },
  },
};

function TimelineItem({ title, text, activeItem, filled, totalLength, delay }) {
  const [isInView, setIsInView] = useState(false);
  const [animate, setAnimate] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    if (inView) {
      setIsInView(true);
      triggerAnim();
    }
  }, [inView]);

  function triggerAnim() {
    setTimeout(() => {
      playAnim();
    }, delay);
    setTimeout(() => {
      resetAnim();
    }, totalLength);
  }

  function playAnim() {
    setAnimate(true);
  }
  function resetAnim() {
    setAnimate(false);
    triggerAnim();
  }

  return (
    <StyledTimelineItem active={animate ? true : false} ref={ref}>
      <TimelineItemInner
        initial="hidden"
        animate={isInView ? 'visible' : 'hidden'}
        variants={TimelineItemAnim}
      >
        <Text fontSize={24} bold mb={7}>
          {title}
        </Text>
        <Paragraph lightGrey>{text}</Paragraph>
      </TimelineItemInner>
    </StyledTimelineItem>
  );
}

export default TimelineItem;
