import { STEPS_ORDER } from './constants';

const DATA_KEY = 'spec-tool-data';

export function saveDataToLocalStorage(data) {
  return localStorage.setItem(DATA_KEY, JSON.stringify(data));
}

export function getDataFromLocalStorage() {
  return JSON.parse(localStorage.getItem(DATA_KEY));
}

export function dataIsValid(data) {
  return Object.keys(data).every((key) => {
    STEPS_ORDER.includes(key);
    return STEPS_ORDER.includes(key);
  });
}
