import { graphql, useStaticQuery } from 'gatsby';

import { parseStoryblokContent } from './parse-storyblok-content';

export function useDownloadsSubscriptionForm() {
  const { formData } = useStaticQuery(graphql`
    query {
      formData: storyblokEntry(
        field_component: { eq: "downloadsSubscriptionForm" }
      ) {
        id
        name
        uuid
        field_component
        content
      }
    }
  `);

  return parseStoryblokContent(formData);
}
