import React from 'react';
import styled from 'styled-components';

import { Box, Flex } from '../UIKit/system';
import Title from '../title';
import Button from '../button';
import { getPath } from '../../utils/paths';

const StyledButton = styled(Button)`
  margin-bottom: 8px;
  height: 64px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

function Intro({ optionsTitle }) {
  return (
    <Box>
      <Title mb={24} fontSizeExtraSmall textAlign={'center'}>
        {optionsTitle}
      </Title>
      <Flex
        flexDirection={'column'}
        margin={'0 auto'}
        maxWidth={406}
        textAlign={'center'}
      >
        <StyledButton to={getPath.contactGeneralQuestions()} transparent>
          General Questions
        </StyledButton>
        <StyledButton to={getPath.contactStartProject()} transparent>
          Start Project
        </StyledButton>
        <StyledButton to={getPath.contactSiteSupport()} transparent>
          Site Support
        </StyledButton>
      </Flex>
    </Box>
  );
}

export default Intro;
