import React from 'react';
import styled, { css } from 'styled-components';
import mq from '../../utils/mq';

import TabsContentItem from './tabs-content-item';

const StyledTabsContent = styled.div`
  background-color: #fff;
  padding: 60px 70px;
  ${mq.tablet(css`
    padding: 30px 30px;
  `)};
`;

function TabsContent({ items, productName, activeIndex, current }) {
  return (
    <StyledTabsContent>
      {items.map((item, index) => {
        return (
          <TabsContentItem
            key={index}
            productName={productName}
            active={index === activeIndex}
            current={current}
            {...item}
          />
        );
      })}
    </StyledTabsContent>
  );
}

export default TabsContent;
