import Button from "../button"
import React, { FunctionComponent } from "react"
import RichTextField from "../rich-text-field"
import TextWithBar from "../text-with-bar"
import Title from "../title"
import mq from '../../utils/mq'
import parseStoryblokLink from "../../utils/parse-storyblok-link"
import styled, { css } from "styled-components"
import { CarouselContent } from "./types"
import { Flex, Paragraph } from "../UIKit/system"

interface Props {
  content: CarouselContent
}

const StyledItem = styled.div`
  max-height: 800px;
  min-height: 400px;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: hidden;
`

const TextContainer = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;
  margin-left: 10%;

  ${mq.mobile(css`
    width: 80%;
    margin-left: 0;
    margin: auto;
  `)};
`

const CtaContainer = styled.div`
  ${mq.mobile(css`
    margin-top: 19px;
  `)};
`

const Content: FunctionComponent<Props> = ({ content }) => {
  const link = content.buttonLink && parseStoryblokLink(content.buttonLink)

  return (
    <StyledItem>
      <TextContainer
        initial={{
          y: 40,
          opacity: 0
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            delay: 0.5,
            duration: 0.35
          }
        }}
      >
        <Flex
          flexDirection={'column'}
          alignItems={'flex-start'}
          flexShrink={0}
          pr={30}
          maxWidth={522}
          width={'100%'}
          pt={[50, 0, 0]}
        >
          {content.subtitle && <TextWithBar mb={15} color={'#fff'}>{content.subtitle}</TextWithBar>}
          {content.title && <Title
            maxWidth={570}
            mb={[14, null, 26]}
            lineHeight={1.1}
            html={content.title}
            color={'#fff'}
          />}
          {content.text && (
            <Paragraph
              semibold
              color={'#fff'}
              fontSize={[14, 18]}
            >
              <RichTextField data={content.text} />
            </Paragraph>
          )}
          {content.buttonText && (
            <CtaContainer>
              <Button to={link}>
                {content.buttonText}
              </Button>
            </CtaContainer>
          )}
        </Flex>
      </TextContainer>
    </StyledItem>
  )
}

export default Content
