import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Paragraph } from '../UIKit/system';

const StyledAnswer = styled(motion.div)`
  max-width: 90%;
  height: 0;
  overflow: hidden;
  will-change: opacity;

  &:after {
    content: '';
    display: block;
    height: 30px;
  }
`;

const animationVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: 'auto' },
};

function Answer({ children, collapsed }) {
  return (
    <StyledAnswer
      initial="hidden"
      animate={collapsed ? 'visible' : 'hidden'}
      variants={animationVariants}
      transition={{ duration: 0.35, type: 'tween' }}
    >
      <Paragraph fontSizeSmall html={children} />
    </StyledAnswer>
  );
}

export default Answer;
