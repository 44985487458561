import { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { IS_BROWSER } from '../constants';

const portalRoot = IS_BROWSER ? document.getElementById('modal-root') : null;

function createEl() {
  return IS_BROWSER ? document.createElement('div') : null;
}

function Portal(props) {
  const el = useRef(createEl());

  useEffect(() => {
    if (!el.current) {
      return;
    }

    portalRoot.appendChild(el.current);

    return () => {
      portalRoot.removeChild(el.current);
    };
  }, []);

  if (!el.current) {
    return null;
  }

  return ReactDOM.createPortal(props.children, el.current);
}

export default Portal;
