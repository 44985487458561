import React from 'react';
import styled, { css } from 'styled-components';

import mq from '../utils/mq';
import { Link } from 'gatsby';
import Title from './title';
import { Box, Text } from './UIKit/system';
import { OFFSET_SIDE_SM } from '../constants';

const StyledTitle = styled.h2`
  font-weight: bold;
  font-size: 42px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #161616;
  margin-bottom: 18px;
`;

const Subtitle = styled(Text)`
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #666666;
  max-width: 575px;
`;

const LogoBlockContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mq.mobile(css`
    font-size: 32px;
    line-height: 36px;
    padding-left: ${OFFSET_SIDE_SM}px;
    padding-right: ${OFFSET_SIDE_SM}px;
  `)};
`;

const LogoRowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const LogoItem = styled.img`
  max-width: 160px;
  max-height: 40px;
`;

const LogoContainer = styled(Link)`
  margin-left: 25px;
  margin-right: 25px;
  max-width: 160px;
  max-height: 40px;

  ${mq.mobile(css`
    margin-top: 38px;
    margin-bottom: 38px;
    width: 30%;
    object-fit: contain;
  `)};
  ${mq.mobileSmall(css`
    width: 50%;
  `)};
`;

function LogoBlock({ title, subtitle, logos }) {
  return (
    <LogoBlockContainer pt={[80, 112]} pb={[0, 20, 80, 100]}>
      <Title as={StyledTitle} maxWidth={500} html={title} />
      <Subtitle mb={42} html={subtitle} />
      <LogoRowContainer>
        {logos.length > 1 &&
          logos.map((logo, index) => (
            <LogoContainer to={logo.link} key={index}>
              <LogoItem src={logo.logo.src} />
            </LogoContainer>
          ))}
      </LogoRowContainer>
    </LogoBlockContainer>
  );
}

export default LogoBlock;
