import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Intro from './intro';
import Wizard from './wizard';

function SpecTool(props) {
  const [wizardIsVisible, setWizardIsVisible] = useState(false);

  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "spec-tool-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  function handleFormSubmit() {
    setWizardIsVisible(true);
  }

  return (
    <>
      <Intro
        {...props}
        backgroundImage={backgroundImage}
        onFormSubmit={handleFormSubmit}
      />
      <Wizard visible={wizardIsVisible} />
    </>
  );
}

export default SpecTool;
