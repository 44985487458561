import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import { lightGreyBackground } from '../utils/styles';
import Container from './container';
import LazyImage from './lazy-image';
import Title from './title';
import LinkWithArrow from './link-with-arrow';
import TextWithBar from './text-with-bar';
import { STANDARD_PADDING } from '../constants';
import mq from '../utils/mq';
import Link from './link';

const StyledSectionCaseStudyPreview = styled(Box)`
  ${lightGreyBackground};
`;

const ContentContainer = styled(Flex)`
  ${mq.tablet(css`
    flex-wrap: wrap;
  `)};
`;

const ImageLink = styled(Link)`
  display: block;
  width: 450px;
  max-width: 100%;
  flex-shrink: 0;

  ${mq.tablet(css`
    width: 100%;
  `)};
`;

const ImageContainer = styled(Box)``;

const CaseStudyButton = styled(LinkWithArrow)`
  ${mq.tablet(css`
    padding-top: 22px;
  `)};
`;

function SectionCaseStudyPreview({ image, title, text, ctaText, ctaLink }) {
  return (
    <StyledSectionCaseStudyPreview py={STANDARD_PADDING}>
      <Container>
        <ContentContainer height={['unset', 'unset', 450]} bg={'white'}>
          <ImageLink maxWidth={'100%'} height={'100%'} to={ctaLink}>
            <ImageContainer width={'100%'} height={'100%'} flexShrink={0}>
              <LazyImage fluid={image} height={'100%'} />
            </ImageContainer>
          </ImageLink>
          <Flex
            pt={60}
            pb={43}
            px={[22, 22, 57]}
            height={'100%'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
          >
            <Box>
              <TextWithBar mb={20} bold>
                {ctaText}
              </TextWithBar>
              <Title
                mb={15}
                as={'h3'}
                fontSize={[20, 28, 38, 42]}
                html={title}
              />
              <Paragraph fontSize={[14, 16, 18]} html={text} grey />
            </Box>
            <CaseStudyButton to={ctaLink}>
              {ctaText || 'View Case Study'}
            </CaseStudyButton>
          </Flex>
        </ContentContainer>
      </Container>
    </StyledSectionCaseStudyPreview>
  );
}

export default SectionCaseStudyPreview;
