const data = [
  {
    product: 'Micro',
    type: 'Horizontal',
    productCode: '71.21 RMP-R Micro',
    dutyStandbyFanDiameter: '710/90L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '85.9',
    efficiency_75: '86.4',
    efficiency_100: '85.4',
    powerFactorStar: '0.77',
    powerFactor_50: '0.71',
    powerFactor_75: '0.81',
    powerFactor: '0.84',
    '100InletFirefightingMode': '87',
    '100Outlet': '86',
    '100Breakout': '79',
    '20InletEnvironmental': '46',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1350 x 1310 x 2045',
    fanMotorDiameter: '710/90L',
    weight: '236',
    roofLoadPressure: '3.69',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '2.64',
    fullLoadCurrentAmp: '5.32',
    startingCurrentInverterAmp: '6',
    startingCurrentInverterKVa: '6',
    fuseRating: '10',
    upsRating: '8',
    maxRate: '2.1',
    staticPressure: '400'
  },
  {
    product: 'Micro',
    type: 'Horizontal',
    productCode: '71.25 RMP-R Micro',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '91',
    '100Outlet': '90',
    '100Breakout': '76',
    '20InletEnvironmental': '53',
    '20Outlet': '52',
    '20Breakout': '39',
    overallSize: '1350 x 1310 x 2045',
    fanMotorDiameter: '710/100L',
    weight: '259',
    roofLoadPressure: '4.05',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '2.55',
    staticPressure: '450'
  },
  {
    product: 'Micro',
    type: 'Horizontal',
    productCode: '71.31 RMP-R Micro',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '88',
    '100Outlet': '87',
    '100Breakout': '80',
    '20InletEnvironmental': '46',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1350 x 1310 x 2045',
    fanMotorDiameter: '710/100L',
    weight: '257',
    roofLoadPressure: '4.02',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '3.1',
    staticPressure: '400'
  },
  {
    product: 'Micro',
    type: 'Horizontal',
    productCode: '71.40 RMP-R Micro',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '87.8',
    efficiency_75: '89.2',
    efficiency_100: '89.5',
    powerFactorStar: '0.52',
    powerFactor_50: '0.72',
    powerFactor_75: '0.82',
    powerFactor: '0.87',
    '100InletFirefightingMode': '91',
    '100Outlet': '91',
    '100Breakout': '78',
    '20InletEnvironmental': '48',
    '20Outlet': '47',
    '20Breakout': '40',
    overallSize: '1350 x 1310 x 2045',
    fanMotorDiameter: '710/132S',
    weight: '329',
    roofLoadPressure: '5.14',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '6.6',
    fullLoadCurrentAmp: '12.24',
    startingCurrentInverterAmp: '14',
    startingCurrentInverterKVa: '14',
    fuseRating: '20',
    upsRating: '18',
    maxRate: '4',
    staticPressure: '450'
  },
  {
    product: 'Micro',
    type: 'Horizontal',
    productCode: '71.60 RMP-R Micro',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '89.2',
    efficiency_75: '90.2',
    efficiency_100: '90.2',
    powerFactorStar: '0.53',
    powerFactor_50: '0.74',
    powerFactor_75: '0.84',
    powerFactor: '0.86',
    '100InletFirefightingMode': '90',
    '100Outlet': '90',
    '100Breakout': '77',
    '20InletEnvironmental': '49',
    '20Outlet': '49',
    '20Breakout': '39',
    overallSize: '1350 x 1310 x 2045',
    fanMotorDiameter: '710/132S',
    weight: '345',
    roofLoadPressure: '5.39',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '9',
    fullLoadCurrentAmp: '16.68',
    startingCurrentInverterAmp: '19',
    startingCurrentInverterKVa: '19',
    fuseRating: '25',
    upsRating: '25',
    maxRate: '6',
    staticPressure: '450'
  },
  {
    product: 'Micro',
    type: 'Horizontal',
    productCode: '71.77 RMP-R Micro',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '86',
    '100Outlet': '84',
    '100Breakout': '76',
    '20InletEnvironmental': '48',
    '20Outlet': '48',
    '20Breakout': '38',
    overallSize: '1350 x 1310 x 2045',
    fanMotorDiameter: '710/132M',
    weight: '379',
    roofLoadPressure: '5.92',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '7.77',
    staticPressure: '450'
  },
  {
    product: 'Micro',
    type: 'Horizontal',
    productCode: '71.80 RMP-R Micro',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '86',
    '100Outlet': '85',
    '100Breakout': '76',
    '20InletEnvironmental': '48',
    '20Outlet': '47',
    '20Breakout': '38',
    overallSize: '1350 x 1310 x 2045',
    fanMotorDiameter: '710/132M',
    weight: '379',
    roofLoadPressure: '5.92',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '8',
    staticPressure: '400'
  },
  {
    product: 'Mini',
    type: 'Horizontal',
    productCode: '71.21 RMP-R Mini',
    dutyStandbyFanDiameter: '710/90L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '85.9',
    efficiency_75: '86.4',
    efficiency_100: '85.4',
    powerFactorStar: '0.77',
    powerFactor_50: '0.71',
    powerFactor_75: '0.81',
    powerFactor: '0.84',
    '100InletFirefightingMode': '87',
    '100Outlet': '86',
    '100Breakout': '79',
    '20InletEnvironmental': '46',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 1636',
    fanMotorDiameter: '710/90L',
    weight: '266',
    roofLoadPressure: '4.15',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '2.64',
    fullLoadCurrentAmp: '5.32',
    startingCurrentInverterAmp: '6',
    startingCurrentInverterKVa: '6',
    fuseRating: '10',
    upsRating: '8',
    maxRate: '2.1',
    staticPressure: '400'
  },
  {
    product: 'Mini',
    type: 'Horizontal',
    productCode: '71.25 RMP-R Mini',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '91',
    '100Outlet': '90',
    '100Breakout': '76',
    '20InletEnvironmental': '53',
    '20Outlet': '52',
    '20Breakout': '39',
    overallSize: '1400 x 1310 x 1636',
    fanMotorDiameter: '710/100L',
    weight: '289',
    roofLoadPressure: '4.51',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '2.55',
    staticPressure: '450'
  },
  {
    product: 'Mini',
    type: 'Horizontal',
    productCode: '71.31 RMP-R Mini',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '88',
    '100Outlet': '87',
    '100Breakout': '80',
    '20InletEnvironmental': '46',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 1636',
    fanMotorDiameter: '710/100L',
    weight: '287',
    roofLoadPressure: '4.48',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '3.1',
    staticPressure: '400'
  },
  {
    product: 'Mini',
    type: 'Horizontal',
    productCode: '71.40 RMP-R Mini',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '87.8',
    efficiency_75: '89.2',
    efficiency_100: '89.5',
    powerFactorStar: '0.52',
    powerFactor_50: '0.72',
    powerFactor_75: '0.82',
    powerFactor: '0.87',
    '100InletFirefightingMode': '91',
    '100Outlet': '91',
    '100Breakout': '78',
    '20InletEnvironmental': '48',
    '20Outlet': '47',
    '20Breakout': '40',
    overallSize: '1400 x 1310 x 1636',
    fanMotorDiameter: '710/132S',
    weight: '359',
    roofLoadPressure: '5.61',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '6.6',
    fullLoadCurrentAmp: '12.24',
    startingCurrentInverterAmp: '14',
    startingCurrentInverterKVa: '14',
    fuseRating: '20',
    upsRating: '18',
    maxRate: '4',
    staticPressure: '450'
  },
  {
    product: 'Mini',
    type: 'Horizontal',
    productCode: '71.60 RMP-R Mini',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '89.2',
    efficiency_75: '90.2',
    efficiency_100: '90.2',
    powerFactorStar: '0.53',
    powerFactor_50: '0.74',
    powerFactor_75: '0.84',
    powerFactor: '0.86',
    '100InletFirefightingMode': '90',
    '100Outlet': '90',
    '100Breakout': '77',
    '20InletEnvironmental': '49',
    '20Outlet': '49',
    '20Breakout': '39',
    overallSize: '1400 x 1310 x 1636',
    fanMotorDiameter: '710/132S',
    weight: '375',
    roofLoadPressure: '5.86',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '9',
    fullLoadCurrentAmp: '16.68',
    startingCurrentInverterAmp: '19',
    startingCurrentInverterKVa: '19',
    fuseRating: '25',
    upsRating: '25',
    maxRate: '6',
    staticPressure: '450'
  },
  {
    product: 'Mini',
    type: 'Horizontal',
    productCode: '71.77 RMP-R Mini',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '86',
    '100Outlet': '84',
    '100Breakout': '76',
    '20InletEnvironmental': '48',
    '20Outlet': '48',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 1636',
    fanMotorDiameter: '710/132M',
    weight: '409',
    roofLoadPressure: '6.4',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '7.77',
    staticPressure: '450'
  },
  {
    product: 'Mini',
    type: 'Horizontal',
    productCode: '71.80 RMP-R Mini',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '86',
    '100Outlet': '85',
    '100Breakout': '76',
    '20InletEnvironmental': '48',
    '20Outlet': '47',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 1636',
    fanMotorDiameter: '710/132M',
    weight: '409',
    roofLoadPressure: '6.4',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '8',
    staticPressure: '400'
  },
  {
    product: 'Original',
    type: 'Horizontal',
    productCode: '71.21 RMP-R Original',
    dutyStandbyFanDiameter: '710/90L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '85.9',
    efficiency_75: '86.4',
    efficiency_100: '85.4',
    powerFactorStar: '0.77',
    powerFactor_50: '0.71',
    powerFactor_75: '0.81',
    powerFactor: '0.84',
    '100InletFirefightingMode': '77',
    '100Outlet': '86',
    '100Breakout': '79',
    '20InletEnvironmental': '37',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 2106',
    fanMotorDiameter: '710/90L',
    weight: '305',
    roofLoadPressure: '4.76',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '2.64',
    fullLoadCurrentAmp: '5.32',
    startingCurrentInverterAmp: '6',
    startingCurrentInverterKVa: '6',
    fuseRating: '10',
    upsRating: '8',
    maxRate: '2.1',
    staticPressure: '400'
  },
  {
    product: 'Original',
    type: 'Horizontal',
    productCode: '71.25 RMP-R Original',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '82',
    '100Outlet': '90',
    '100Breakout': '76',
    '20InletEnvironmental': '43',
    '20Outlet': '52',
    '20Breakout': '39',
    overallSize: '1400 x 1310 x 2106',
    fanMotorDiameter: '710/100L',
    weight: '328',
    roofLoadPressure: '5.12',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '2.55',
    staticPressure: '450'
  },
  {
    product: 'Original',
    type: 'Horizontal',
    productCode: '71.31 RMP-R Original',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '78',
    '100Outlet': '87',
    '100Breakout': '80',
    '20InletEnvironmental': '37',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 2106',
    fanMotorDiameter: '710/100L',
    weight: '326',
    roofLoadPressure: '5.09',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '3.1',
    staticPressure: '400'
  },
  {
    product: 'Original',
    type: 'Horizontal',
    productCode: '71.40 RMP-R Original',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '87.8',
    efficiency_75: '89.2',
    efficiency_100: '89.5',
    powerFactorStar: '0.52',
    powerFactor_50: '0.72',
    powerFactor_75: '0.82',
    powerFactor: '0.87',
    '100InletFirefightingMode': '80',
    '100Outlet': '91',
    '100Breakout': '78',
    '20InletEnvironmental': '38',
    '20Outlet': '47',
    '20Breakout': '40',
    overallSize: '1400 x 1310 x 2106',
    fanMotorDiameter: '710/132S',
    weight: '398',
    roofLoadPressure: '6.22',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '6.6',
    fullLoadCurrentAmp: '12.24',
    startingCurrentInverterAmp: '14',
    startingCurrentInverterKVa: '14',
    fuseRating: '20',
    upsRating: '18',
    maxRate: '4',
    staticPressure: '450'
  },
  {
    product: 'Original',
    type: 'Horizontal',
    productCode: '71.60 RMP-R Original',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '89.2',
    efficiency_75: '90.2',
    efficiency_100: '90.2',
    powerFactorStar: '0.53',
    powerFactor_50: '0.74',
    powerFactor_75: '0.84',
    powerFactor: '0.86',
    '100InletFirefightingMode': '80',
    '100Outlet': '90',
    '100Breakout': '77',
    '20InletEnvironmental': '39',
    '20Outlet': '49',
    '20Breakout': '39',
    overallSize: '1400 x 1310 x 2106',
    fanMotorDiameter: '710/132S',
    weight: '414',
    roofLoadPressure: '6.47',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '9',
    fullLoadCurrentAmp: '16.68',
    startingCurrentInverterAmp: '19',
    startingCurrentInverterKVa: '19',
    fuseRating: '25',
    upsRating: '25',
    maxRate: '6',
    staticPressure: '450'
  },
  {
    product: 'Original',
    type: 'Horizontal',
    productCode: '71.77 RMP-R Original',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '76',
    '100Outlet': '84',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '48',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 2106',
    fanMotorDiameter: '710/132M',
    weight: '448',
    roofLoadPressure: '7.01',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '7.77',
    staticPressure: '450'
  },
  {
    product: 'Original',
    type: 'Horizontal',
    productCode: '71.80 RMP-R Original',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '77',
    '100Outlet': '85',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '47',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 2106',
    fanMotorDiameter: '710/132M',
    weight: '448',
    roofLoadPressure: '7.01',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '8',
    staticPressure: '400'
  },
  {
    product: 'Max',
    type: 'Horizontal',
    productCode: '71.21 RMP-R Max',
    dutyStandbyFanDiameter: '710/90L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '85.9',
    efficiency_75: '86.4',
    efficiency_100: '85.4',
    powerFactorStar: '0.77',
    powerFactor_50: '0.71',
    powerFactor_75: '0.81',
    powerFactor: '0.84',
    '100InletFirefightingMode': '77',
    '100Outlet': '76',
    '100Breakout': '79',
    '20InletEnvironmental': '37',
    '20Outlet': '36',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 3056',
    fanMotorDiameter: '710/90L',
    weight: '335',
    roofLoadPressure: '5.23',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '2.64',
    fullLoadCurrentAmp: '5.32',
    startingCurrentInverterAmp: '6',
    startingCurrentInverterKVa: '6',
    fuseRating: '10',
    upsRating: '8',
    maxRate: '2.1',
    staticPressure: '400'
  },
  {
    product: 'Max',
    type: 'Horizontal',
    productCode: '71.25 RMP-R Max',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '82',
    '100Outlet': '81',
    '100Breakout': '76',
    '20InletEnvironmental': '43',
    '20Outlet': '42',
    '20Breakout': '39',
    overallSize: '1400 x 1310 x 3056',
    fanMotorDiameter: '710/100L',
    weight: '358',
    roofLoadPressure: '5.59',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '2.55',
    staticPressure: '450'
  },
  {
    product: 'Max',
    type: 'Horizontal',
    productCode: '71.31 RMP-R Max',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '78',
    '100Outlet': '77',
    '100Breakout': '80',
    '20InletEnvironmental': '37',
    '20Outlet': '36',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 3056',
    fanMotorDiameter: '710/100L',
    weight: '356',
    roofLoadPressure: '5.56',
    mountingFeetDiameter: '430',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '3.1',
    staticPressure: '400'
  },
  {
    product: 'Max',
    type: 'Horizontal',
    productCode: '71.40 RMP-R Max',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '87.8',
    efficiency_75: '89.2',
    efficiency_100: '89.5',
    powerFactorStar: '0.52',
    powerFactor_50: '0.72',
    powerFactor_75: '0.82',
    powerFactor: '0.87',
    '100InletFirefightingMode': '80',
    '100Outlet': '80',
    '100Breakout': '78',
    '20InletEnvironmental': '38',
    '20Outlet': '37',
    '20Breakout': '40',
    overallSize: '1400 x 1310 x 3056',
    fanMotorDiameter: '710/132S',
    weight: '428',
    roofLoadPressure: '6.68',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '6.6',
    fullLoadCurrentAmp: '12.24',
    startingCurrentInverterAmp: '14',
    startingCurrentInverterKVa: '14',
    fuseRating: '20',
    upsRating: '18',
    maxRate: '4',
    staticPressure: '450'
  },
  {
    product: 'Max',
    type: 'Horizontal',
    productCode: '71.60 RMP-R Max',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '89.2',
    efficiency_75: '90.2',
    efficiency_100: '90.2',
    powerFactorStar: '0.53',
    powerFactor_50: '0.74',
    powerFactor_75: '0.84',
    powerFactor: '0.86',
    '100InletFirefightingMode': '80',
    '100Outlet': '81',
    '100Breakout': '77',
    '20InletEnvironmental': '39',
    '20Outlet': '40',
    '20Breakout': '39',
    overallSize: '1400 x 1310 x 3056',
    fanMotorDiameter: '710/132S',
    weight: '444',
    roofLoadPressure: '6.93',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '9',
    fullLoadCurrentAmp: '16.68',
    startingCurrentInverterAmp: '19',
    startingCurrentInverterKVa: '19',
    fuseRating: '25',
    upsRating: '25',
    maxRate: '6',
    staticPressure: '450'
  },
  {
    product: 'Max',
    type: 'Horizontal',
    productCode: '71.77 RMP-R Max',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '76',
    '100Outlet': '75',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '36',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 3056',
    fanMotorDiameter: '710/132M',
    weight: '478',
    roofLoadPressure: '7.48',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '7.77',
    staticPressure: '450'
  },
  {
    product: 'Max',
    type: 'Horizontal',
    productCode: '71.80 RMP-R Max',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '77',
    '100Outlet': '75',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '35',
    '20Breakout': '38',
    overallSize: '1400 x 1310 x 3056',
    fanMotorDiameter: '710/132M',
    weight: '478',
    roofLoadPressure: '7.48',
    mountingFeetDiameter: '430',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '8',
    staticPressure: '400'
  },
  {
    product: 'Mini',
    type: 'Vertical',
    productCode: '71.21 RMP-RV Mini',
    dutyStandbyFanDiameter: '710/90L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '85.9',
    efficiency_75: '86.4',
    efficiency_100: '85.4',
    powerFactorStar: '0.77',
    powerFactor_50: '0.71',
    powerFactor_75: '0.81',
    powerFactor: '0.84',
    '100InletFirefightingMode': '77',
    '100Outlet': '86',
    '100Breakout': '79',
    '20InletEnvironmental': '37',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1340 x 1900 x 1100',
    fanMotorDiameter: '710/90L',
    weight: '305',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '2.64',
    fullLoadCurrentAmp: '5.32',
    startingCurrentInverterAmp: '6',
    startingCurrentInverterKVa: '6',
    fuseRating: '10',
    upsRating: '8',
    maxRate: '2.1',
    staticPressure: '400'
  },
  {
    product: 'Mini',
    type: 'Vertical',
    productCode: '71.25 RMP-RV Mini',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '82',
    '100Outlet': '90',
    '100Breakout': '76',
    '20InletEnvironmental': '43',
    '20Outlet': '52',
    '20Breakout': '39',
    overallSize: '1340 x 1900 x 1100',
    fanMotorDiameter: '710/100L',
    weight: '325',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '2.55',
    staticPressure: '450'
  },
  {
    product: 'Mini',
    type: 'Vertical',
    productCode: '71.31 RMP-RV Mini',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '78',
    '100Outlet': '87',
    '100Breakout': '80',
    '20InletEnvironmental': '37',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1340 x 1900 x 1100',
    fanMotorDiameter: '710/100L',
    weight: '328',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '3.1',
    staticPressure: '400'
  },
  {
    product: 'Mini',
    type: 'Vertical',
    productCode: '71.40 RMP-RV Mini',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '87.8',
    efficiency_75: '89.2',
    efficiency_100: '89.5',
    powerFactorStar: '0.52',
    powerFactor_50: '0.72',
    powerFactor_75: '0.82',
    powerFactor: '0.87',
    '100InletFirefightingMode': '80',
    '100Outlet': '91',
    '100Breakout': '78',
    '20InletEnvironmental': '38',
    '20Outlet': '47',
    '20Breakout': '40',
    overallSize: '1340 x 1900 x 1100',
    fanMotorDiameter: '710/132S',
    weight: '326',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '6.6',
    fullLoadCurrentAmp: '12.24',
    startingCurrentInverterAmp: '14',
    startingCurrentInverterKVa: '14',
    fuseRating: '20',
    upsRating: '18',
    maxRate: '4',
    staticPressure: '450'
  },
  {
    product: 'Mini',
    type: 'Vertical',
    productCode: '71.60 RMP-RV Mini',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '89.2',
    efficiency_75: '90.2',
    efficiency_100: '90.2',
    powerFactorStar: '0.53',
    powerFactor_50: '0.74',
    powerFactor_75: '0.84',
    powerFactor: '0.86',
    '100InletFirefightingMode': '80',
    '100Outlet': '90',
    '100Breakout': '77',
    '20InletEnvironmental': '39',
    '20Outlet': '49',
    '20Breakout': '39',
    overallSize: '1340 x 1900 x 1100',
    fanMotorDiameter: '710/132S',
    weight: '414',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '9',
    fullLoadCurrentAmp: '16.68',
    startingCurrentInverterAmp: '19',
    startingCurrentInverterKVa: '19',
    fuseRating: '25',
    upsRating: '25',
    maxRate: '6',
    staticPressure: '450'
  },
  {
    product: 'Mini',
    type: 'Vertical',
    productCode: '71.77 RMP-RV Mini',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '76',
    '100Outlet': '84',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '48',
    '20Breakout': '38',
    overallSize: '1340 x 1900 x 1100',
    fanMotorDiameter: '710/132M',
    weight: '448',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '7.77',
    staticPressure: '450'
  },
  {
    product: 'Mini',
    type: 'Vertical',
    productCode: '71.80 RMP-RV Mini',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '77',
    '100Outlet': '85',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '47',
    '20Breakout': '38',
    overallSize: '1340 x 1900 x 1100',
    fanMotorDiameter: '710/132M',
    weight: '448',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '8',
    staticPressure: '400'
  },
  {
    product: 'Original',
    type: 'Vertical',
    productCode: '71.21 RMP-RV Original',
    dutyStandbyFanDiameter: '710/90L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '85.9',
    efficiency_75: '86.4',
    efficiency_100: '85.4',
    powerFactorStar: '0.77',
    powerFactor_50: '0.71',
    powerFactor_75: '0.81',
    powerFactor: '0.84',
    '100InletFirefightingMode': '77',
    '100Outlet': '86',
    '100Breakout': '79',
    '20InletEnvironmental': '37',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1340 x 2590 x 1100',
    fanMotorDiameter: '710/90L',
    weight: '305',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '2.64',
    fullLoadCurrentAmp: '5.32',
    startingCurrentInverterAmp: '6',
    startingCurrentInverterKVa: '6',
    fuseRating: '10',
    upsRating: '8',
    maxRate: '2.1',
    staticPressure: '400'
  },
  {
    product: 'Original',
    type: 'Vertical',
    productCode: '71.25 RMP-RV Original',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '82',
    '100Outlet': '90',
    '100Breakout': '76',
    '20InletEnvironmental': '43',
    '20Outlet': '52',
    '20Breakout': '39',
    overallSize: '1340 x 2590 x 1100',
    fanMotorDiameter: '710/100L',
    weight: '325',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '2.55',
    staticPressure: '450'
  },
  {
    product: 'Original',
    type: 'Vertical',
    productCode: '71.31 RMP-RV Original',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '78',
    '100Outlet': '87',
    '100Breakout': '80',
    '20InletEnvironmental': '37',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1340 x 2590 x 1100',
    fanMotorDiameter: '710/100L',
    weight: '328',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '3.1',
    staticPressure: '400'
  },
  {
    product: 'Original',
    type: 'Vertical',
    productCode: '71.40 RMP-RV Original',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '87.8',
    efficiency_75: '89.2',
    efficiency_100: '89.5',
    powerFactorStar: '0.52',
    powerFactor_50: '0.72',
    powerFactor_75: '0.82',
    powerFactor: '0.87',
    '100InletFirefightingMode': '80',
    '100Outlet': '91',
    '100Breakout': '78',
    '20InletEnvironmental': '38',
    '20Outlet': '47',
    '20Breakout': '40',
    overallSize: '1340 x 2590 x 1100',
    fanMotorDiameter: '710/132S',
    weight: '326',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '6.6',
    fullLoadCurrentAmp: '12.24',
    startingCurrentInverterAmp: '14',
    startingCurrentInverterKVa: '14',
    fuseRating: '20',
    upsRating: '18',
    maxRate: '4',
    staticPressure: '450'
  },
  {
    product: 'Original',
    type: 'Vertical',
    productCode: '71.60 RMP-RV Original',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '89.2',
    efficiency_75: '90.2',
    efficiency_100: '90.2',
    powerFactorStar: '0.53',
    powerFactor_50: '0.74',
    powerFactor_75: '0.84',
    powerFactor: '0.86',
    '100InletFirefightingMode': '80',
    '100Outlet': '90',
    '100Breakout': '77',
    '20InletEnvironmental': '39',
    '20Outlet': '49',
    '20Breakout': '39',
    overallSize: '1340 x 2590 x 1100',
    fanMotorDiameter: '710/132S',
    weight: '414',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '9',
    fullLoadCurrentAmp: '16.68',
    startingCurrentInverterAmp: '19',
    startingCurrentInverterKVa: '19',
    fuseRating: '25',
    upsRating: '25',
    maxRate: '6',
    staticPressure: '450'
  },
  {
    product: 'Original',
    type: 'Vertical',
    productCode: '71.77 RMP-RV Original',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '76',
    '100Outlet': '84',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '48',
    '20Breakout': '38',
    overallSize: '1340 x 2590 x 1100',
    fanMotorDiameter: '710/132M',
    weight: '448',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '7.77',
    staticPressure: '450'
  },
  {
    product: 'Original',
    type: 'Vertical',
    productCode: '71.80 RMP-RV Original',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '77',
    '100Outlet': '85',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '47',
    '20Breakout': '38',
    overallSize: '1340 x 2590 x 1100',
    fanMotorDiameter: '710/132M',
    weight: '448',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '8',
    staticPressure: '400'
  },
  {
    product: 'Max',
    type: 'Vertical',
    productCode: '71.21 RMP-RV Max',
    dutyStandbyFanDiameter: '710/90L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '85.9',
    efficiency_75: '86.4',
    efficiency_100: '85.4',
    powerFactorStar: '0.77',
    powerFactor_50: '0.71',
    powerFactor_75: '0.81',
    powerFactor: '0.84',
    '100InletFirefightingMode': '77',
    '100Outlet': '86',
    '100Breakout': '79',
    '20InletEnvironmental': '37',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1340 x 3300 x 1100',
    fanMotorDiameter: '710/90L',
    weight: '305',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '2.64',
    fullLoadCurrentAmp: '5.32',
    startingCurrentInverterAmp: '6',
    startingCurrentInverterKVa: '6',
    fuseRating: '10',
    upsRating: '8',
    maxRate: '2.1',
    staticPressure: '400'
  },
  {
    product: 'Max',
    type: 'Vertical',
    productCode: '71.25 RMP-RV Max',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '82',
    '100Outlet': '90',
    '100Breakout': '76',
    '20InletEnvironmental': '43',
    '20Outlet': '52',
    '20Breakout': '39',
    overallSize: '1340 x 3300 x 1100',
    fanMotorDiameter: '710/100L',
    weight: '325',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '2.55',
    staticPressure: '450'
  },
  {
    product: 'Max',
    type: 'Vertical',
    productCode: '71.31 RMP-RV Max',
    dutyStandbyFanDiameter: '710/100L',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '86.5',
    efficiency_75: '87.3',
    efficiency_100: '86.6',
    powerFactorStar: '0.69',
    powerFactor_50: '0.75',
    powerFactor_75: '0.85',
    powerFactor: '0.87',
    '100InletFirefightingMode': '78',
    '100Outlet': '87',
    '100Breakout': '80',
    '20InletEnvironmental': '37',
    '20Outlet': '45',
    '20Breakout': '38',
    overallSize: '1340 x 3300 x 1100',
    fanMotorDiameter: '710/100L',
    weight: '328',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '5.5kW',
    motorPower: '3.6',
    fullLoadCurrentAmp: '6.9',
    startingCurrentInverterAmp: '8',
    startingCurrentInverterKVa: '8',
    fuseRating: '16',
    upsRating: '10',
    maxRate: '3.1',
    staticPressure: '400'
  },
  {
    product: 'Max',
    type: 'Vertical',
    productCode: '71.40 RMP-RV Max',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '87.8',
    efficiency_75: '89.2',
    efficiency_100: '89.5',
    powerFactorStar: '0.52',
    powerFactor_50: '0.72',
    powerFactor_75: '0.82',
    powerFactor: '0.87',
    '100InletFirefightingMode': '80',
    '100Outlet': '91',
    '100Breakout': '78',
    '20InletEnvironmental': '38',
    '20Outlet': '47',
    '20Breakout': '40',
    overallSize: '1340 x 3300 x 1100',
    fanMotorDiameter: '710/132S',
    weight: '326',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '6.6',
    fullLoadCurrentAmp: '12.24',
    startingCurrentInverterAmp: '14',
    startingCurrentInverterKVa: '14',
    fuseRating: '20',
    upsRating: '18',
    maxRate: '4',
    staticPressure: '450'
  },
  {
    product: 'Max',
    type: 'Vertical',
    productCode: '71.60 RMP-RV Max',
    dutyStandbyFanDiameter: '710/132S',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '89.2',
    efficiency_75: '90.2',
    efficiency_100: '90.2',
    powerFactorStar: '0.53',
    powerFactor_50: '0.74',
    powerFactor_75: '0.84',
    powerFactor: '0.86',
    '100InletFirefightingMode': '80',
    '100Outlet': '90',
    '100Breakout': '77',
    '20InletEnvironmental': '39',
    '20Outlet': '49',
    '20Breakout': '39',
    overallSize: '1340 x 3300 x 1100',
    fanMotorDiameter: '710/132S',
    weight: '414',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '9',
    fullLoadCurrentAmp: '16.68',
    startingCurrentInverterAmp: '19',
    startingCurrentInverterKVa: '19',
    fuseRating: '25',
    upsRating: '25',
    maxRate: '6',
    staticPressure: '450'
  },
  {
    product: 'Max',
    type: 'Vertical',
    productCode: '71.77 RMP-RV Max',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '76',
    '100Outlet': '84',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '48',
    '20Breakout': '38',
    overallSize: '1340 x 3300 x 1100',
    fanMotorDiameter: '710/132M',
    weight: '448',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '7.77',
    staticPressure: '450'
  },
  {
    product: 'Max',
    type: 'Vertical',
    productCode: '71.80 RMP-RV Max',
    dutyStandbyFanDiameter: '710/132M',
    motorType: 'F300 (IE3)',
    electricalSupply: '400V 3ph 50Hz',
    maxLoad: '32A',
    efficiency_50: '90.9',
    efficiency_75: '91',
    efficiency_100: '89.9',
    powerFactorStar: '0.56',
    powerFactor_50: '0.8',
    powerFactor_75: '0.87',
    powerFactor: '0.86',
    '100InletFirefightingMode': '77',
    '100Outlet': '85',
    '100Breakout': '76',
    '20InletEnvironmental': '36',
    '20Outlet': '47',
    '20Breakout': '38',
    overallSize: '1340 x 3300 x 1100',
    fanMotorDiameter: '710/132M',
    weight: '448',
    roofLoadPressure: '',
    mountingFeetDiameter: '',
    inverterSize: '11.0kW',
    motorPower: '11',
    fullLoadCurrentAmp: '20.45',
    startingCurrentInverterAmp: '24',
    startingCurrentInverterKVa: '23',
    fuseRating: '25',
    upsRating: '31',
    maxRate: '8',
    staticPressure: '400'
  }
]

export default data
