import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import TitleWithSeparator from './title-with-separator';
import Button from './button';
import Container from './container';
import mq from '../utils/mq';
import DownloadPopup from '../components/resource-centre/download-popup';

const StyledSectionButton = styled(Box)`
  position
`;

const Inner = styled(Flex)`
  ${(props) =>
    props.grey &&
    css`
      background-color: #f8f8f8;
      padding: 63px 90px 63px 40px;
      ${mq.tablet(css`
        padding: 40px 40px 40px 40px;
      `)};
    `};
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const TitleContainer = styled.div`
  ${mq.mobile(css`
    margin-bottom: 32px;
  `)};
`;

const StyledButton = styled(Button)`
  ${mq.mobile(css`
    margin-top: 22px;
    width: 100%;
  `)};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
`;

function SectionTitleAndButton({
  title,
  text,
  subtitle,
  button,
  grey,
  download,
  filePath,
  popupTitle,
  displayDivider,
}) {
  const [popupOpen, setPopupOpen] = useState(false);

  function handlePopupClose() {
    setPopupOpen(false);
  }

  return (
    <>
      {displayDivider && <Divider />}
      <StyledSectionButton as={'section'} pt={[50, 40, 70]} pb={[50, 40, 70]}>
        {popupOpen ? (
          <DownloadPopup
            filePath={filePath}
            title={popupTitle}
            handlePopupClose={handlePopupClose}
          />
        ) : null}

        <Container>
          <Inner
            grey={grey}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <TitleContainer>
              {title && (
                <TitleWithSeparator
                  title={title}
                  subtitle={subtitle}
                  large
                  split
                />
              )}
            </TitleContainer>
            {text && (
              <Paragraph
                mr={[0, 20, 20]}
                maxWidth={['unset', 543, 543]}
                semibold
                html={text}
              />
            )}
            {download ? (
              <StyledButton
                chevronRight
                onClick={() => setPopupOpen(true)}
                {...button}
              />
            ) : (
              <StyledButton chevronRight {...button} />
            )}
          </Inner>
        </Container>
      </StyledSectionButton>
    </>
  );
}

export default SectionTitleAndButton;
