import React from 'react';

import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import RichtextParser from '../../../utils/richtext-parser';
import SectionContact from '../../section-contact';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';
import { NULL_LINK } from '../../../constants';

function StoryblokSectionContactAdapter({ items }) {
  return (
    <SectionContact
      items={items.map((item) => {
        const parsedLink = parseStoryblokLink(item.link);
        let fluidIcon = getFluidGatsbyImage(item.icon, {
          maxWidth: 160,
        });
        return {
          ...item,
          text: item.text && RichtextParser(item.text),
          link: parsedLink && parsedLink !== NULL_LINK && parsedLink,
          icon: fluidIcon,
          bottomText: item.bottomText && RichtextParser(item.bottomText),
        };
      })}
    />
  );
}

export default StoryblokSectionContactAdapter;
