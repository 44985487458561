import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import parseStoryblokLink from '../../utils/parse-storyblok-link'
import Title from '../title';
import { Box, Flex, Paragraph } from '../UIKit/system';
import Button from '../button';
import mq from '../../utils/mq';
import DownloadPopup from '../../components/resource-centre/download-popup';

const StyledItem = styled.div`
  display: flex;
  margin-bottom: 104px;

  &:last-of-type {
    margin-bottom: 0;
  }
  ${mq.tablet(css`
    flex-wrap: wrap;
  `)};
`;

const StyledButton = styled(Button)`
  background-color: #753fbf;
  color: #fff;
  :hover {
    background-color: #000;
  }
`;

function Item({ title, text, button, productName, type }) {
  const [popupOpen, setPopupOpen] = useState(false);

  const isPopupActive = useMemo(() => type !== 'downloadLink' && button && popupOpen, [type, button, popupOpen])

  const selectedItem = {
    name: productName,
    type: title.toLowerCase()
  }

  // Only know of our custom download type ATM, assume the rest is a URL already
  const to = useMemo(() => type === 'downloadLink' ? parseStoryblokLink(button.link) : button.to, [type, button])
  const linkProps = useMemo(() => {
    const {rel, title, target} = (button || {}).link || {}
    return {rel, title, target}
  }, [button])

  return (
    <StyledItem>
      {isPopupActive && (
        <div>
          <DownloadPopup
            selectedItem={selectedItem}
            filePath={button.to}
            handlePopupClose={() => setPopupOpen(false)}
          />
        </div>
      )}
      <Box width={['100%', '100%', '30.5%']} pr={60} mb={[5, 5, 0]}>
        <Title fontSizeSmall html={title} />
      </Box>
      <Box
        flex={['unset', 1, 1]}
        width={['100%', 'unset', 'unset']}
        mb={[30, 0, 0]}
      >
        <Paragraph grey fontSizeSmall>
          {text}
        </Paragraph>
      </Box>
      {button && (
        <>
          <Flex
            justifyContent={['flex-start', 'flex-end', 'flex-end']}
            flex={1}
          >
            {type === 'downloadLink' ? (
              <StyledButton whitePurple to={to} {...linkProps}>
                {button.children}
              </StyledButton>

            ) : (

              <StyledButton whitePurple onClick={() => setPopupOpen(true)}>
                {button.children}
              </StyledButton>
            )}
          </Flex>
        </>
      )}
    </StyledItem>
  );
}

const StyledLinks = styled.div``;

function Links({ items, productName }) {
  return (
    <StyledLinks>
      {items.map((item, index) => {
        return <Item key={index} productName={productName} {...item} />;
      })}
    </StyledLinks>
  );
}

export default Links;
