import { css } from 'styled-components';

import {
  button,
  buttonReset,
  fontFamilySans,
  getThemeColor,
  hoverDefault,
  inputCommon,
  inputLabel,
  inputReset,
  inputSelect,
  textarea,
} from '../utils/styles';
import chevronDownIcon from '../images/svg/chevron-down-white.url-loader.svg';
import mq from '../utils/mq';

export const hubSpotFormStyles = (props) => css`
  .hs-richtext {
    margin-top: 20px;
    margin-bottom: 19px;

    p {
      font-size: 14px;
      color: #474747;
      line-height: 1.45;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 18px;
  }

  .field {
    margin-bottom: 32px;
  }

  .input {
    margin-right: 0 !important;
  }

  .hs-input {
    width: 100% !important;
  }

  .hs-form-field {
    width: calc(50% - 16px) !important;
    margin-right: 16px !important;
    display: inline-block;

    ${(props) =>
      props.fieldsFullWidth &&
      css`
        width: 100% !important;
      `};

    &.hs-fieldtype-booleancheckbox,
    &.hs-fieldtype-radio,
    &.hs-fieldtype-checkbox,
    &.hs-fieldtype-file,
    &.hs-fieldtype-textarea {
      width: 100% !important;
    }

    &.hs-fieldtype-textarea {
      width: calc(100% - 16px) !important;
      margin-right: 0 !important;
    }

    ${mq.mobile(css`
      width: 100% !important;

      &.hs-fieldtype-textarea {
        width: 100% !important;
      }
    `)};
  }

  label {
    ${inputLabel};
    margin-bottom: 16px;
    font-size: 14px;
    color: #474747;
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='tel'],
  textarea {
    ${inputReset};
    ${inputCommon(props)};
    margin-top: -10px;
  }

  textarea {
    ${textarea};
    min-height: 120px;
  }

  input[type='submit'] {
    ${fontFamilySans};
    ${buttonReset};
    ${hoverDefault};
    ${button};
    color: ${getThemeColor('white')};
    background-color: ${getThemeColor('primary')};
    min-width: 220px;
    margin-top: 6px;
  }

  .multi-container,
  .inputs-list {
    list-style: none;
  }

  .hs-form-radio,
  .hs-form-checkbox,
  .hs-form-booleancheckbox {
    label {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 28px;
      margin-bottom: 20px;
    }

    input {
      position: absolute;
      left: 0;
      display: block;
    }

    &:last-child {
      label {
        margin-bottom: 0;
      }
    }
  }

  .legal-consent-container .hs-form-booleancheckbox-display > span {
    margin-left: 0;
  }

  .hs-richtext {
    margin-bottom: 20px;
  }

  .hs-fieldtype-select {
    .input {
      position: relative;
      ${inputSelect};

      &:after {
        content: '';
        display: block;
        width: 11px;
        height: 7px;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        background-image: url(${chevronDownIcon});
        background-size: cover;
      }

      select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }

      .selected {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 700;
        padding: 0 35px;
        display: flex;
        align-items: center;
      }
    }
  }

  .hs-fieldtype-file {
    .input {
      margin-top: -15px;
    }
  }

  .submitted-message {
    text-align: center;
    font-size: 18px;
  }

  .hs-error-msgs {
    margin-top: 10px;
    display: none !important;
    list-style: none;

    label {
      font-size: 14px;
      color: ${getThemeColor('danger')};
    }
  }

  .legal-consent-container .hs-error-msgs {
    display: block !important;
  }

  .legal-consent-container .hs-error-msgs label {
    color: ${getThemeColor('danger')};
  }
`;
