import React from 'react';

import parseStoryblokLink from '../../../utils/parse-storyblok-link';
import SectionQuote from '../../section-quote';
import getFluidGatsbyImage from '../../../utils/gatsby-storyblok-image-fixed/getFluidGatsbyImage';

function StoryblokQuoteSection({ caseStudy, quote, ...rest }) {
  let parsedCaseStudy;
  let displayCaseStudy = true;

  if (caseStudy[0]) {
    const item = caseStudy[0].link;
    parsedCaseStudy = {
      ...item.content,
      link: parseStoryblokLink(item.full_slug),
      image:
        item.content &&
        getFluidGatsbyImage(item.content.gridImage, {
          width: 350,
        }),
    };
  } else {
    displayCaseStudy = false;
  }

  return (
    <SectionQuote
      gatsbyImg
      text={quote}
      caseStudy={parsedCaseStudy}
      displayCaseStudy={displayCaseStudy}
      {...rest}
    />
  );
}

export default StoryblokQuoteSection;
