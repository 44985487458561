import React from 'react';
import styled, { css } from 'styled-components';

import { Paragraph } from '../UIKit/system';
import Title from '../title';
import CloseIcon from '../../images/svg/close-cross.svg';
import { buttonReset, getThemeColor, hoverDefault } from '../../utils/styles';
import Portal from '../portal';
import { OFFSET_SIDE_SM } from '../../constants';
import mq from '../../utils/mq';

const StyledInfoPopup = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 40px ${OFFSET_SIDE_SM}px 52px;
  background-color: #fff;
  z-index: 100;

  ${mq.mobile(css`
    padding-bottom: 40px;
  `)};
`;

const Content = styled.div`
  position: relative;
  max-width: 625px;
`;

const CloseButton = styled.button`
  ${hoverDefault};
  ${buttonReset};
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0;
  cursor: pointer;

  :before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  :focus {
    outline: none;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  stroke: ${getThemeColor('black')};
`;

function InfoPopup({ onClose }) {
  return (
    <Portal>
      <StyledInfoPopup>
        <Content>
          <Title mb={18} fontSizeSmall>
            Why is this important?
          </Title>
          <Paragraph grey fontSizeSmall>
            As&nbsp;your building is&nbsp;over 30&nbsp;meters, you will likely
            require a mechanical ventilation system. This is&nbsp;due
            to&nbsp;recommendations in BS9991 which state mechanical ventilation
            must be&nbsp;used in&nbsp;firefighting shafts for buildings taller
            than 11&nbsp;meters. This guidance is&nbsp;typically construed
            as&nbsp;being applicable even when there is&nbsp;no&nbsp;requirement
            for a firefighter&lsquo;s shaft.
          </Paragraph>
          <CloseButton onClick={onClose}>
            <StyledCloseIcon />
          </CloseButton>
        </Content>
      </StyledInfoPopup>
    </Portal>
  );
}

export default InfoPopup;
