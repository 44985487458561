import React from 'react';
import styled from 'styled-components';

import { Box, Paragraph, Text } from './UIKit/system';
import Container from './container';
import Title from './title';
import LazyImage from './lazy-image';

const StyledIntro5 = styled(Box)`
  position: relative;
  color: #fff;
  text-align: center;
`;

const BackgroundImage = styled(LazyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(50%);
`;

function Intro5({ title, subtitle, text, image }) {
  return (
    <StyledIntro5 pt={108} pb={136}>
      <BackgroundImage fluid={image} />
      <Container position={'relative'}>
        <Text mb={5} color={'#eaeaea'} fontSizeSmall html={subtitle} />
        <Title mb={12} as={'h1'} html={title} />
        <Paragraph maxWidth={500} mx={'auto'} color={'#f6f6f6'} html={text} />
      </Container>
    </StyledIntro5>
  );
}

export default Intro5;
