import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph, Text } from './UIKit/system';
import Container from './container';
import Subtitle from './subtitle';
import Title from './title';
import LazyImage from './lazy-image';
import { supportsGrid } from '../utils/styles';
import ArrowInCircle from './arrow-in-circle';
import mq from '../utils/mq';
import Link from './link';

const StyledIntro4 = styled(Box)`
  position: relative;
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -24px;
  max-width: 934px;
  margin-left: auto;
  margin-right: auto;

  > * {
    margin-right: 24px;
    margin-bottom: 24px;
    flex: 1;

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }

  ${supportsGrid(css`
    display: grid;
    grid-gap: 24px;
    margin-bottom: 0;
    grid-template-columns: repeat(4, 1fr);

    > * {
      margin-right: 0;
      margin-bottom: 0;
    }
  `)}
  ${supportsGrid(
    mq.tablet(css`
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      align-items: center;
      > * :nth-of-type(2n + 1) {
        justify-self: end;
      }
    `)
  )};
  ${supportsGrid(
    mq.mobileSmall(css`
      grid-template-columns: repeat(1, 1fr);
      > * :nth-of-type(2n) {
        justify-self: center;
      }
      > * :nth-of-type(2n + 1) {
        justify-self: center;
      }
    `)
  )};
`;

const LinkItem = styled(Flex)`
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15), 0 6px 10px rgba(0, 0, 0, 0.05);
  ${mq.tablet(css`
    width: 215px;
    height: 284px;
  `)};
  ${mq.mobile(css`
    width: 161px;
    height: 218px;
  `)};
`;

function Intro4({ title, subtitle, text, links, bottomText, backgroundImage }) {
  return (
    <StyledIntro4 pt={68} pb={[58, 80]}>
      {backgroundImage && <LazyImage asBackground fluid={backgroundImage} />}
      <Container position={'relative'}>
        <Flex
          flexDirection={'column'}
          alignItems={'center'}
          mb={45}
          textAlign={'center'}
        >
          {subtitle && <Subtitle mb={5}>{subtitle}</Subtitle>}
          <Title mb={15} as={'h1'} html={title} />
          {text && (
            <Paragraph
              maxWidth={[500, 500, 900]}
              fontSize={15}
              greyAlt
              html={text}
            />
          )}
        </Flex>
        <Links>
          {links.map((item, index) => {
            return (
              <LinkItem
                key={index}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-between'}
                height={284}
                pt={[24, 42, 42]}
                pb={24}
                px={15}
                bg={'white'}
                borderRadius={3}
              >
                <Link to={item.link} hover>
                  <Flex flexDirection={'column'} alignItems={'center'}>
                    <LazyImage mb={[15, 30, 30]} fluid={item.icon} width={[77, 86]} />
                    <Text fontSize={[13, 18, 18]} bold>
                      {item.name}
                    </Text>
                  </Flex>
                </Link>
                <Link to={item.link}>
                  <ArrowInCircle large morePale withHover />
                </Link>
              </LinkItem>
            );
          })}
        </Links>
        {bottomText && (
          <Paragraph
            maxWidth={900}
            ml={'auto'}
            mr={'auto'}
            textAlign={'center'}
            mt={54}
            greyAlt
            fontSize={15}
            html={bottomText}
          />
        )}
      </Container>
    </StyledIntro4>
  );
}

export default Intro4;
