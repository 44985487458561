import React, { useEffect, useState, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Paragraph } from '../UIKit/system';
import Title from '../title';
import mq from '../../utils/mq';
import { getThemeColor } from '../../utils/styles';
import VideoAutoplay from '../video-autoplay';
import ReactPlayer from 'react-player/lazy';

const lineAnimation = keyframes`
  0% {
    transform: scaleY(0);
  }
  
  100% {
    transform: scaleY(1);
  }
`;

const StyledStageContent = styled.div`
  position: relative;
  margin-bottom: 43px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;

    ${mq.mobile(css`
      display: none;
    `)};
  }

  &:before {
    background-color: #d4d4d4;
  }

  &:after {
    background-color: #8a55fb;
    transform: scaleY(0);
    will-change: transform;
    transform-origin: top center;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  ${(props) =>
    props.doAnimate &&
    css`
      &:after {
        animation-name: ${lineAnimation};
        animation-duration: ${(props) => props.animationLength}s;
      }
    `};

  ${(props) =>
    props.filled &&
    css`
      &:after {
        transform: scaleY(1);
        transition: transform 0.55s ease-out;
      }
    `};

  ${mq.desktopSmall(css`
    padding-left: 0;
  `)};

  ${mq.mobile(css`
    padding-left: 0;

    &:before {
      display: none;
    }
  `)};
`;

const TextContainer = styled.div`
  height: fit-content;
  position: relative;
  padding-left: 37px;
  margin-bottom: 32px;

  &:before {
    ${mq.mobile(css`
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background: rgb(212, 212, 212);
    `)};
  }
`;

const MobileVideoContainer = styled.div`
  display: none;

  ${mq.mobile(css`
    display: block;
    margin-left: -20px;
  `)};
`;

const StyledTitle = styled(Title)`
  transition: color 0.55s ease-out;

  ${mq.mobile(css`
    font-weight: bold;
    font-size: 24px;
    color: #161616;
  `)};

  ${(props) =>
    props.active &&
    css`
      color: ${getThemeColor('black')(props)};
    `};
`;

function StageContent({
  title,
  text,
  active,
  filled,
  videoDuration,
  lengthSeconds,
  totalLength,
  delay,
  visible,
  playerPlaying,
  doAnimate,
  video,
  ...rest
}) {
  const [isInView, setIsInView] = useState(false);
  const [animate, setAnimate] = useState(false);
  const videoRef = useRef();
  useEffect(() => {
    if (doAnimate) {
      triggerAnim();
    } else {
      setAnimate(false);
    }
  }, [doAnimate]);

  function triggerAnim() {
    setTimeout(() => {
      if (doAnimate) {
        playAnim();
      }
    }, delay);
  }

  function playAnim() {
    setAnimate(true);
  }

  function handleEnded() {
    videoRef.current.seekTo(0);
  }

  return (
    <StyledStageContent
      active={active}
      filled={filled}
      doAnimate={animate}
      animationLength={lengthSeconds}
    >
      <TextContainer>
        <StyledTitle grey mb={9} fontSizeSmall html={title} active={animate} />
        <Paragraph grey fontSize={[18, 16, 16]} html={text} />
      </TextContainer>
      <MobileVideoContainer>
        <ReactPlayer
          url={video.filename}
          width={'100%'}
          height={'100%'}
          controls={false}
          playsinline={true}
          playing={true}
          onEnded={handleEnded}
          ref={videoRef}
        />
      </MobileVideoContainer>
    </StyledStageContent>
  );
}

export default StageContent;
