import React from 'react';
import { Field } from 'formik';

import { Box, Flex } from '../UIKit/system';
import InputSelect from '../controls/input-select';
import ButtonContinue from './button-continue';
import StepBase from './step-base';

function StepInputSelectFactory({
  onNext,
  fieldName,
  options,
  values,
  placeholder,
  ...rest
}) {
  const value = values[fieldName];
  return (
    <StepBase {...rest}>
      <Flex mx={'auto'} maxWidth={[400, null, 504]} flexDirection={'column'}>
        <Box mb={8}>
          <Field
            name={fieldName}
            options={options}
            component={InputSelect}
            placeholder={placeholder}
            white
            textAlignCenter
            invalid={!value}
          />
        </Box>
        <ButtonContinue disabled={!value} onClick={onNext} />
      </Flex>
    </StepBase>
  );
}

export default StepInputSelectFactory;
