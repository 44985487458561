import React, { FunctionComponent, useCallback } from 'react'

// Components
import Layout from '../components/Layout'
import { SelectCard } from '../components/inputs'
import FormStep from '../components/FormStep'
import formTypes from '../data/form-types'

// Types
import { Info } from '../root-page'
import { View } from '../types'

interface Props {
  info: Info
  callback: (type: View) => void
  setView: (view: View) => void
}

const FormType: FunctionComponent<Props> = ({ callback, setView }) => {
  const handleBack = useCallback(() => setView(View.ENTRY), [])
  const handleSelectedOption = useCallback((value) => callback(value), [])

  return (
    <Layout back={handleBack} progress={0}>
      <FormStep currentStep={1} title="About your project" subtitle="What stage is your project at?">
        <form>
          <SelectCard options={formTypes} callback={handleSelectedOption} input="" />
        </form>
      </FormStep>
    </Layout>
  )
}

export default FormType
