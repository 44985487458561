import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import mq from '../../utils/mq';
import ReactSwiper from '../react-swiper';
import Title from '../title';
import { Box, Flex, Text } from '../UIKit/system';
import { getThemeColor } from '../../utils/styles';

const QuoteBlockContainer = styled.div`
  width: 731px;
  background-color: #fff;
  height: 524px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 70px;
  user-select: none;
  ${mq.mobile(css`
    width: 80vw;
    height: unset;
    padding: 0px 20px;
    padding-top: 70px;
  `)};
`;

const QuoteBody = styled(Title)`
  position: relative;
  display: inline;
  font-weight: bold;
  font-size: 27px;
  line-height: 32px;

  &:before {
    content: '“';
    display: block;
    position: absolute;
    color: ${getThemeColor('primary')};
    left: -6px;
    top: 0;
    transform: translateX(-100%);
    ${mq.mobile(css`
      left: 15px;
      top: -30px;
      font-size: 42px;
    `)};
  }

  &:after {
    content: '”';
    display: inline;
    color: ${getThemeColor('primary')};
    margin-left: 8px;
    ${mq.mobile(css`
      display: none;
    `)};
  }

  ${mq.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)};
`;

const QuoteAuthor = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 5px;

  ${mq.tablet(css`
    margin-top: 33px;
  `)};
  ${mq.mobile(css`
    font-size: 14px;
  `)};
`;

const QuoteCompany = styled(Text)`
  font-size: 15px;

  color: #666;
  ${mq.tablet(css`
    margin-bottom: 50px;
  `)};
  ${mq.mobile(css`
    font-size: 12px;
  `)};
`;

function Carousel({ items, swiperOptions, ...rest }) {
  return (
    <ReactSwiper options={swiperOptions} {...rest}>
      {items.map((item, index) => {
        return (
          <ReactSwiper.Item key={index}>
            <QuoteBlockContainer>
              <QuoteBody html={item.quote} />
              <QuoteAuthor html={item.quotee} />
              <QuoteCompany html={item.quoteeSubtitle} />
            </QuoteBlockContainer>
          </ReactSwiper.Item>
        );
      })}
    </ReactSwiper>
  );
}

export default Carousel;
