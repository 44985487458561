import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { globalHistory as history } from '@reach/router';
import PageStatic from '../page-static';
import { Paragraph, Text } from '../../components/UIKit/system';
import Title from '../../components/title';
import mq from '../../utils/mq';
import RichtextParser from '../../utils/richtext-parser';

const ContentContainerOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 680px;
  flex-direction: column;
`;

const StyledTitle = styled(Title)`
  font-weight: bold;
  font-size: 42px;
  line-height: 53px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #161616;
  margin-bottom: 33px;
  margin-top: 7px;
`;

const StyledSubtext = styled(Text)`
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #753fbf;
  margin-top: 68px;
`;

const ItemContainer = styled.div`
  margin-bottom: 60px;
`;

const TitleContainer = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  margin-bottom: 22px;
  ${mq.mobile(css`
    flex-wrap: wrap;
    height: 100%;
    margin-bottom: 17px;
  `)};
`;

const TitleNumber = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: #959595;
  font-weight: 300;
  ${mq.mobile(css`
    font-size: 18px;
  `)};
`;

const TitleDivider = styled.div`
  height: 70%;
  width: 2px;
  background-color: #959595;
  margin-right: 6px;
  margin-left: 6px;
  ${mq.mobile(css`
    height: 16px;
    width: 1.5px;
  `)};
`;

const TitleText = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #161616;
  ${mq.mobile(css`
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.02em;
  `)};
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 18px;
  line-height: 32px;
  color: #666666;
  ul {
    margin-top: 22px;
    margin-left: 22px;
  }

  ${mq.mobile(css`
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
  `)};
`;

const MenuOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  justify-content: center;
  height: 40px;
  ${mq.mobile(css`
    overflow: auto;
    justify-content: flex-start;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
    scroll-snap-type: x mandatory;
    margin-bottom: 50px;
  `)};
`;

const MenuItem = styled.div`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #959595;
  margin-bottom: 18px;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      color: #161616;
    `};

  ${mq.mobile(css`
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 7px;
  `)};
`;

const MenuItemContainer = styled(motion.div)`
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  position: relative;
`;

const ActiveIndicator = styled(motion.div)`
  height: 2px;
  background-color: #753fbf;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  ${mq.mobile(css`
    width: 70%;
    left: 17.5%;
    padding-left: 20px;
    padding-right: 20px;
  `)};
`;

const ItemsContainer = styled.div`
  width: 100%;
  ${mq.mobile(css`
    width: 90%;
  `)};
`;

const NumberOuterContainer = styled.div`
  display: flex;
  align-items: center;
  ${mq.mobile(css`
    width: 100%;
    height: 100%;
  `)};
`;

function StoryblokTermsOfService({
  generalTermsItems,
  cookieItems,
  privacyItems,
  useLicenseItems,
  ...rest
}) {
  const [currentPage, setcurrentPage] = useState(0);
  const { location, navigate } = history;

  function handlePageChange(id) {
    setcurrentPage(id);
  }

  useEffect(() => {
    if (location.search !== '') {
      let search = location.search.split('?id=');
      let activeSearch = data.filter((item) => {
        return item.searchId === search[1];
      });
      if (activeSearch.length > 0) {
        setcurrentPage(activeSearch[0].id);
      }
    }
  }, [location]);

  let generalTermsContent = [];
  let useLicenseContent = [];
  let cookiePolicyContent = [];
  let privacyPolicyContent = [];

  generalTermsItems.forEach((term) => {
    let termObj = {
      title: term.title,
      text: RichtextParser(term.text),
    };
    generalTermsContent.push(termObj);
  });

  cookieItems.forEach((term) => {
    let termObj = {
      title: term.title,
      text: RichtextParser(term.text),
    };
    cookiePolicyContent.push(termObj);
  });

  privacyItems.forEach((term) => {
    let termObj = {
      title: term.title,
      text: RichtextParser(term.text),
    };
    privacyPolicyContent.push(termObj);
  });

  useLicenseItems.forEach((term) => {
    let termObj = {
      title: term.title,
      text: RichtextParser(term.text),
    };
    useLicenseContent.push(termObj);
  });

  let data = [
    {
      id: 0,
      searchId: 'general',
      name: 'General Terms',
      content: generalTermsContent,
    },
    {
      id: 1,
      name: 'Warranty',
      searchId: 'license',
      content: useLicenseContent,
    },
    {
      id: 2,
      name: 'Cookie Policy',
      searchId: 'cookies',
      content: cookiePolicyContent,
    },
    {
      id: 3,
      name: 'Privacy Policy',
      searchId: 'privacy',
      content: privacyPolicyContent,
    },
  ];

  return (
    <ContentContainerOuter>
      <ContentContainer>
        <StyledSubtext>The Fine Print</StyledSubtext>
        <StyledTitle>Terms of Service</StyledTitle>
        <AnimateSharedLayout>
          <MenuOuterContainer>
            <MenuContainer>
              {data.map((item, index) => (
                <MenuItemContainer key={index}>
                  <MenuItem
                    onClick={() => handlePageChange(item.id)}
                    active={currentPage === item.id ? true : false}
                  >
                    {item.name}
                  </MenuItem>
                  {currentPage === item.id ? (
                    <ActiveIndicator layoutId="indicator" />
                  ) : null}
                </MenuItemContainer>
              ))}
            </MenuContainer>
          </MenuOuterContainer>
        </AnimateSharedLayout>
        <ItemsContainer>
          {data[currentPage].content.map((data, index) => (
            <ItemContainer key={index}>
              <TitleContainer>
                <TitleText>{data.title}</TitleText>
              </TitleContainer>
              <StyledParagraph html={data.text} />
            </ItemContainer>
          ))}
        </ItemsContainer>
      </ContentContainer>
    </ContentContainerOuter>
  );
}

export default StoryblokTermsOfService;
