import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

// Styles
import { StyledFormButton } from '../../styles'

const StyledInputContainer = styled.form`
  max-width: 500px;
  margin: 2rem auto;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;

    .inputs {
      flex-grow: 1;

      p {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #666666;
      }
    }

    .image {
      flex-basis: 40%;

      @media (max-width: 500px) {
        display: none;
      }
    }
  }
`

const StyledContent = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #666666;
`

interface Props {
  content: string
  button: string
  callback: () => void
}

const Content: FunctionComponent<Props> = ({ content, button, callback }) => {
  return (
    <StyledInputContainer>
      <StyledContent dangerouslySetInnerHTML={{ __html: content }}></StyledContent>
      <StyledFormButton onClick={callback}>{button}</StyledFormButton>
    </StyledInputContainer>
  )
}

export default Content
