import React from 'react';
import styled, { css } from 'styled-components';

import Title from './title';
import Container from './container';
import { Box, Flex, Paragraph } from './UIKit/system';
import GridCol from './grid-col';
import { supportsGrid } from '../utils/styles';
import mq from '../utils/mq';

const ColsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 30px;
  `)};

  ${mq.mobile(css`
    grid-template-columns: none;
  `)};
`;

const StyledItem = styled.div`
  flex: 1 0 calc(50% - 26px);
  margin-bottom: 30px;
  margin-right: 40px;

  ${supportsGrid(css`
    margin-right: 0;
    margin-bottom: 0;
  `)}

  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 95%;
    background-color: #ebebeb;
    margin-top: 18px;
  }

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  &:last-of-type,
  &:nth-last-of-type(2) {
    &:after {
      display: none;
    }
  }
  @media (max-width: 475px) {
    width: 100%;
    flex: 1 0 auto;
    margin-right: 0;
  }
`;

const StyledParagraph = styled(Paragraph)``;

function Item({ title, subtitle }) {
  return (
    <StyledItem>
      <Title mb={5} fontSizeExtraSmall3>
        {title}
      </Title>
      <StyledParagraph grey fontSizeSmall html={subtitle} />
    </StyledItem>
  );
}

const StyledSectionTechSpecs = styled(Box)``;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
`;

const StyledGridCol = styled(GridCol)``;

function SectionTechSpecs({ title, data }) {
  return (
    <StyledSectionTechSpecs pt={55}>
      <Container>
        <Flex flexWrap={['wrap', null, 'nowrap']}>
          <StyledGridCol>
            <Title as={'h2'} mb={[20, null, 0]} fontSizeMedium html={title} />
          </StyledGridCol>
          <Content>
            <ColsContainer>
              {data.map((item, index) => {
                return <Item key={index} {...item} />;
              })}
            </ColsContainer>
          </Content>
        </Flex>
      </Container>
    </StyledSectionTechSpecs>
  );
}

export default SectionTechSpecs;
