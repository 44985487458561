import React from 'react';

import SectionCarousel from '../../section-carousel';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed';

function StoryblokCarouselAdapter({ items }) {
  return (
    <SectionCarousel
      images={
        items && items.map((v) => getFluidGatsbyImage(v, { maxWidth: 710 }))
      }
    />
  );
}

export default StoryblokCarouselAdapter;
