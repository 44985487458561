import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import { Box, Flex, Paragraph } from '../UIKit/system';
import { STANDARD_PADDING } from '../../constants';
import Container from '../container';
import StageContent from './stage-content';
import { vw } from '../../utils/size-functions';
import Title from '../title';
import mq from '../../utils/mq';
import VideoAutoplay from '../video-autoplay';
import { useResponsiveContext } from '../responsive';
import ReactPlayer from 'react-player/lazy';
import { useInView } from 'react-intersection-observer';

const StyledSectionStagesOuter = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSectionStages = styled(Box)`
  overflow-x: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1850px;
`;

const StyledContainer = styled(Container)`
  justify-content: flex-end;
  width: 50%;
  @media (min-width: 1600px) {
    justify-content: center;
  }
  @media (max-width: 1400px) {
    width: 50%;
    justify-content: center;
  }
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const Col = styled.div`
  width: ${vw(616)};
  flex-shrink: 0;
  padding-right: 30px;

  ${mq.mobile(css`
    width: 100%;
    padding-right: 0;
  `)};
`;

const Content = styled(Box)`
  max-width: 520px;
  ${mq.mobile(css`
    max-width: 100%;
  `)};
`;

const VideoContainer = styled.div`
  position: relative;
  width: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1400px) {
    width: 36%;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 70%;
  }

  ${mq.mobile(css`
    display: none;
  `)};
`;

const StyledVideoAutoplay = styled(VideoAutoplay)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
  @media (min-width: 2000px) {
    justify-content: center;
  }
`;

function SectionStages({ title, text, stages, totalLength, video }) {
  const [activeStage, setActiveStage] = useState(0);
  const [videoCanPlay, setVideoCanPlay] = useState(false);
  const [playerPlaying, setPlayerPlaying] = useState(true);
  const [visible, setVisible] = useState(false);
  const [doAnimate, setDoAnimate] = useState(false);
  const videoNode = useRef(null);
  const { isDesktopOrTablet } = useResponsiveContext();
  const [isInView, setIsInView] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.45,
  });

  const videoRef = useRef();

  const videoDuration = videoNode.current ? videoNode.current.duration : 0;
  useEffect(() => {
    if (!videoNode.current || !videoCanPlay) {
      return;
    }
    videoNode.current.currentTime = 0;
    videoNode.current.play();
  }, [activeStage, videoCanPlay]);

  useEffect(() => {
    if (!videoNode.current) {
      return;
    }
    visible ? videoNode.current.play() : videoNode.current.pause();
  }, [visible, isDesktopOrTablet]);

  function handleVideoEnded() {
    setActiveStage(getNextStageIndex());
  }

  function handleVisibilityChange(visible) {
    setVisible(visible);
  }

  function getNextStageIndex() {
    return activeStage >= stages.length - 1 ? 0 : activeStage + 1;
  }

  function getCurrentVideoSrc() {
    return stages[activeStage].video;
  }

  function handlePlaying() {
    setDoAnimate(true);
  }

  function handleEnded() {
    videoRef.current.seekTo(0);
    setActiveStage(0);
    setPlayerPlaying(false);
    setDoAnimate(false);
    setTimeout(() => {
      setPlayerPlaying(true);
      setDoAnimate(true);
    }, 1);
  }

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  useEffect(() => {
    if (isInView) {
      setIsInView(true);
      setPlayerPlaying(true);
    }
  }, [isInView]);

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      minTopValue={200}
    >
      <StyledSectionStagesOuter>
        <StyledSectionStages pt={88} pb={STANDARD_PADDING} ref={ref}>
          <StyledContainer as={Flex} alignItems={'center'}>
            <StyledCol>
              <Content>
                <Title mb={68} fontSizeMedium html={title} />
                {stages.map((stage, index) => {
                  let delay = 0;
                  let lengthUpd = stage.length / 1000;
                  if (index !== 0) {
                    let priorStages = stages.slice(0, index);
                    priorStages.map((s, i) => {
                      delay = delay + parseInt(s.length);
                    });
                  }

                  return (
                    <StageContent
                      key={index}
                      delay={delay}
                      playerPlaying={playerPlaying}
                      videoDuration={videoDuration}
                      totalLength={totalLength}
                      doAnimate={doAnimate}
                      visible={visible}
                      video={stage.video}
                      lengthSeconds={lengthUpd}
                      {...stage}
                    />
                  );
                })}
                <Paragraph grey fontSize={15} html={text} />
              </Content>
            </StyledCol>
          </StyledContainer>
          {isDesktopOrTablet && (
            <VideoContainer>
              <ReactPlayer
                url={video}
                ref={videoRef}
                width={'100%'}
                height={'100%'}
                playing={playerPlaying}
                controls={false}
                playsinline={true}
                muted={true}
                onPlay={() => handlePlaying()}
                onEnded={() => handleEnded()}
                vimeoConfig={{
                  iframeParams: { fullscreen: 0 },
                  playsinline: true,
                }}
              />
            </VideoContainer>
          )}
        </StyledSectionStages>
      </StyledSectionStagesOuter>
    </VisibilitySensor>
  );
}

export default SectionStages;
