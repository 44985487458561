import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Title from './title';
import { Box, Flex, Text } from './UIKit/system';
import Container from './container';
import LazyImage from './lazy-image';
import { styledLink } from '../utils/styles';
import { percents, vw } from '../utils/size-functions';
import Button from './button';
import { STANDARD_PADDING } from '../constants';
import mq from '../utils/mq';
import Responsive, { withResponsiveContext } from './responsive';

const LeftImageRightContentContainer = styled(Box)``;

const LeftImageRightContentInnerContainer = styled(Container)`
  ${mq.mobile(css`
    border-left-width: 0px;
  `)};
`;

const ImageContainer = styled.div`
  width: ${percents(466)};
  flex-shrink: 0;
  max-width: 100%;
  margin-right: ${vw(96)};
  position: relative;

  overflow: hidden;
  ${(props) =>
    props.floating &&
    css`
      // width: ${percents(650)};
      //margin-left: -173px;
      // margin-right: ${vw(76)};
    `};
  ${mq.mobile(css`
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
    max-width: 400px;
  `)};
`;

const StyledFlex = styled(Flex)`
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const TextContainer = styled(Flex)`
  ${mq.mobile(css`
    margin-left: 20px;
  `)};
`;

const StyledTitle = styled.h2`
  ${mq.mobile(css`
    font-size: 32px;
    line-height: 36px;
  `)};
`;

const Paragraph = styled(Text)`
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #666666;
`;

const Subtext = styled(Text)`
  font-size: 15px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #959595;
  margin-bottom: 10px;
  a {
    ${styledLink}
  }
`;

const ImageWipe = styled(motion.div)`
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  top: 0;
`;

const ImageZoomAnim = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const ImageOverlayAnim = {
  hidden: { x: 0 },
  visible: {
    x: '-50vw',
    transition: {
      delay: 0.2,
      duration: 0.6,
      type: 'tween',
    },
  },
};

const ImageOverlayAnimMobile = {
  hidden: { x: 0 },
  visible: {
    x: '-100vw',
    transition: {
      delay: 0.2,
      duration: 0.6,
      type: 'tween',
    },
  },
};

const ImageAnim = {
  hidden: { scale: 1.1 },
  visible: {
    scale: 1,
    transition: {
      delay: 0.3,
      duration: 0.8,
      type: 'tween',
    },
  },
};

function LeftImageRightContent({
  title,
  paragraph,
  subText,
  image,
  button,
  floatingImage,
}) {
  const [isInView, setIsInView] = useState(false);

  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);
  return (
    <LeftImageRightContentContainer py={[50, 50, 100]} ref={ref}>
      <LeftImageRightContentInnerContainer>
        <StyledFlex alignItems={'center'}>
          <ImageContainer floating={floatingImage}>
            <Responsive.Desktop>
              <ImageWipe
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={ImageOverlayAnim}
              />
            </Responsive.Desktop>
            <Responsive.MobileOrTablet>
              <ImageWipe
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={ImageOverlayAnimMobile}
              />
            </Responsive.MobileOrTablet>
            <ImageZoomAnim
              initial="hidden"
              animate={isInView ? 'visible' : 'hidden'}
              variants={ImageAnim}
            >
              <LazyImage fluid={image} alt="Floating Image" />
            </ImageZoomAnim>
          </ImageContainer>
          <TextContainer
            flexDirection={'column'}
            alignItems={'flex-start'}
            maxWidth={477}
            pb={vw(20)}
          >
            <Title
              as={StyledTitle}
              maxWidth={420}
              mt={[50, 0, 0, 0]}
              mb={[60, 60, 72]}
              fontSizeMedium
              html={title}
            />
            <Paragraph mb={[0, 39, 39]} grey fontSizeMedium html={paragraph} />
            {subText && <Subtext html={subText} />}
            {button && <Button {...button} />}
          </TextContainer>
        </StyledFlex>
      </LeftImageRightContentInnerContainer>
    </LeftImageRightContentContainer>
  );
}

export default withResponsiveContext(LeftImageRightContent);
