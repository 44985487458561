import styled, { css } from 'styled-components';

import { getThemeColor } from '../utils/styles';
import mq from '../utils/mq';
import Link from './link';

const PageNavMenuItem = styled(Link)`
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;
  color: #959595;
  padding: 0 28px;
  text-decoration: none;
  transition: 700ms;

  :hover {
    transition: 700ms;

    :after {
      transform: translateX(-50%) scaleX(1);
    }
  }

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    transform-origin: center;
    width: calc(100% - 56px);
    height: 2px;
    background-color: ${getThemeColor('primary')};
    transition: transform 0.3s ease-out;
  }

  ${mq.tablet(css`
    display: none;
  `)};

  ${(props) =>
    props.active &&
    css`
      color: ${getThemeColor('black')(props)};
      pointer-events: none;

      ${mq.tablet(css`
        display: flex;
        color: #161616;
        font-weight: bold;
        font-size: 13px;
        line-height: 140%;
      `)};
    `};

  ${(props) =>
    props.underline &&
    css`
      &:after {
        transform: translateX(-50%) scaleX(1);
        transition-duration: 0.15s;
      }
    `};
`;

export default PageNavMenuItem;
