import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import { STANDARD_PADDING } from '../constants';
import Container from './container';
import Title from './title';
import mq from '../utils/mq';
import LazyImage from './lazy-image';

const StyledColumn = styled(Box)`
  max-width: 253px;
  margin-left: 10px;
  margin-right: 10px;

  ${mq.tablet(css`
    padding-right: 5px;
    padding-left: 5px;
  `)};

  ${mq.mobile(css`
    max-width: unset;
    margin-top: 22px;
    margin-bottom: 22px;
  `)};
`;

const Image = styled.img``;

function Column({ title, text, icon }) {
  return (
    <StyledColumn>
      <Flex alignItems={'center'} height={50} mb={20} fontSize={0}>
        {typeof icon == 'string' ? (
          <Image src={icon} aria-hidden="true" />
        ) : (
          <Image width={'100%'} maxWidth={36} as={LazyImage} fluid={icon} />
        )}
      </Flex>
      <Title mb={15} fontSize={20} letterSpacing={'0px'} html={title} />
      <Paragraph grey html={text} letterSpacing={'0px'} />
    </StyledColumn>
  );
}

const StyledSectionFeatureColumns = styled(Box)``;

const Columns = styled(Flex)`
  flex-wrap: wrap;
  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};
`;

const LeadingTextContainer = styled(Box)`
  ${mq.mobile(css`
    text-align: left;
  `)};
`;

function SectionFeatureColumns({ title, text, columns }) {
  return (
    <StyledSectionFeatureColumns pt={STANDARD_PADDING} pb={STANDARD_PADDING}>
      <Container>
        <LeadingTextContainer mb={[40, 109]} textAlign={'center'}>
          <Title mb={12} letterSpacing={'0px'} fontSizeMedium html={title} />
          <Paragraph
            maxWidth={640}
            mx={'auto'}
            letterSpacing={'0px'}
            grey
            html={text}
          />
        </LeadingTextContainer>
        <Columns justifyContent={'space-between'}>
          {columns.map((item, index) => {
            return <Column key={index} {...item} />;
          })}
        </Columns>
      </Container>
    </StyledSectionFeatureColumns>
  );
}

export default SectionFeatureColumns;
