import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

// Styles
export const StyledLayout = styled.div`
  /* CSS Vars */
  --padding: 3rem;

  background: #f0f1f4;
  display: flex;
  flex-direction: column;
`

export const StyledLayoutNav = styled.div`
  display: flex;
  flex-direction: column;

  progress {
    width: 100%;

    &[value] {
      appearance: none;
      height: 5px;

      &::-webkit-progress-bar {
        background-color: transparent;
      }

      &::-webkit-progress-value {
        background-color: #753fbf;
      }

      &::-moz-progress-bar {
        background-color: #753fbf;
      }
    }
  }

  div {
    padding: var(--padding);

    @media (max-width: 425px) {
      padding: calc(var(--padding) / 2);
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      background: #fff;
      border-radius: 50%;
      border: none;
      cursor: pointer;
    }
  }
`

export const StyledLayoutContainer = styled.div`
  padding: 0 var(--padding);
  min-height: 480px;
  max-width: var(--max-width, 1200px);
  margin: auto;

  @media (max-width: 425px) {
    max-width: 100vw;
    padding: 0 calc(var(--padding) / 2);
  }
`

export const StyledLayoutFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #fff;
  text-align: center;
  margin-top: var(--padding);

  .footer {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    gap: 0.5rem;

    &:hover {
      .footer-popup {
        opacity: 1;
        visibility: visible;
      }
    }

    .footer-popup {
      position: absolute;
      bottom: 0;
      transform: translateY(-40px);
      background: white;
      padding: 1rem;
      opacity: 0;
      visibility: hidden;
      transition: all 250ms ease-in-out;
      text-align: left;
      max-width: 300px;
      width: max-content;

      .footer-popup-title {
        font-family: Muli;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.02em;
      }

      .footer-popup-text {
        font-family: Muli;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
        letter-spacing: -0.02em;
      }
    }
  }
`

interface Props {
  back: () => void
  progress: number
  tooltip?: {
    title?: string
    text?: string
  }
}

const Layout: FunctionComponent<Props> = ({ children, back, tooltip, progress = 0 }) => {
  return (
    <StyledLayout>
      <StyledLayoutNav>
        <progress value={progress} max={100}></progress>
        <div>
          <button onClick={back}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.25 2.25L1.5 6L5.25 9.75" stroke="#161616" strokeWidth="2" strokeLinecap="square" />
              <path d="M1.5 6L12 6" stroke="#161616" strokeWidth="2" />
            </svg>
          </button>
        </div>
      </StyledLayoutNav>
      <StyledLayoutContainer>{children}</StyledLayoutContainer>
      <StyledLayoutFooter>
        {tooltip && (
          <a className="footer">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.3043 13.4175C9.91787 13.8058 9.43478 14 8.85507 14C8.27536 14 7.81804 13.8759 7.48309 13.6278C7.16103 13.3797 7 13.0561 7 12.657C7 12.5167 7.10306 11.9288 7.30918 10.8932L7.94686 7.99676H7.13527L7.17391 7.7055H8.00483C8.95813 7.7055 9.66023 7.67314 10.1111 7.60841L10.3816 7.55987L9.04831 13.6764C9.43478 13.644 9.75684 13.493 10.0145 13.2233L10.3043 13.4175ZM8.60386 6.00647C8.33333 5.77993 8.19807 5.50485 8.19807 5.18123C8.19807 4.8576 8.33333 4.58252 8.60386 4.35599C8.8744 4.11866 9.2029 4 9.58937 4C9.97585 4 10.3043 4.11866 10.5749 4.35599C10.8583 4.58252 11 4.8576 11 5.18123C11 5.50485 10.8583 5.77993 10.5749 6.00647C10.3043 6.23301 9.97585 6.34628 9.58937 6.34628C9.2029 6.34628 8.8744 6.23301 8.60386 6.00647Z"
                fill="#666666"
              />
              <circle cx="9" cy="9" r="8.2" stroke="#666666" strokeWidth="1.6" />
            </svg>
            <span>Why is this important?</span>
            <div className="footer-popup">
              {tooltip.title && <p className="footer-popup-title">{tooltip.title}</p>}
              {tooltip.text && <p className="footer-popup-text">{tooltip.text}</p>}
            </div>
          </a>
        )}
      </StyledLayoutFooter>
    </StyledLayout>
  )
}

export default Layout
