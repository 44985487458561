import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import Title from './title';
import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import TextWithBar from './text-with-bar';
import LazyImage from './lazy-image';
import mq from '../utils/mq';
import Breadcrumbs from './breadcrumbs';
import linkParser from '../utils/parse-storyblok-link';

const ServicesHeaderContainer = styled.div`
  position: relative;
  color: #fff;
  height: calc(100vh - 137px);
  max-height: 600px;
  background-color: #161616;
  overflow: hidden;
  ${mq.mobile(css`
    max-height: unset;
    min-height: 600px;
    max-height: 700px;
  `)};
`;

const StyledContainer = styled(Container)`
  ${mq.mobile(css`
    flex-wrap: wrap;
    flex-direction: row;
  `)}
`;

const StyledTitle = styled(Title)`
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 56px;
  color: #ffffff;
  letter-spacing: -0.02em;
  max-width: 450px;
  margin-bottom: 11px;
  ${mq.mobile(css`
    font-size: 28px;
    line-height: 32px;
  `)};
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 18px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #f6f6f6;
  font-weight: normal;
  ${mq.mobile(css`
    font-size: 14px;
    line-height: 160%;
  `)};
`;

const ImageContainer = styled(motion.div)`
  width: 540px;
  overflow: hidden;
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const ImageAnimationLayer = styled(motion.div)`
  height: 100%;
  width: 100%;
`;

const TextContainer = styled.div`
  margin-right: 25px;
`;

const StyledImage = styled(LazyImage)``;

const BreadcrumbsContainer = styled(motion.div)``;
const TitleContainer = styled(motion.div)``;
const ParagraphContainer = styled(motion.div)``;

const imageFaderAnim = {
  hidden: { opacity: 0, scale: 1.1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delay: 0.3,
      type: 'tween',
      easing: 'easeIn',
    },
  },
};

const breadcrumbsAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      type: 'tween',
      easing: 'easeIn',
    },
  },
};

const titleAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.35,
      type: 'tween',
      easing: 'easeIn',
    },
  },
};

const paraAnim = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.4,
      type: 'tween',
      easing: 'easeIn',
    },
  },
};

function ServicesHeader({ title, subtitle, image, breadcrumbs }) {
  let breadcrumb = [
    {
      link: linkParser(breadcrumbs[0].subpageLink),
      name: breadcrumbs[0].subpage,
    },
    { name: breadcrumbs[0].pageName },
  ];

  return (
    <ServicesHeaderContainer>
      <StyledContainer
        as={Flex}
        position={'relative'}
        alignItems={['flex-end', 'center']}
        justifyContent={'space-between'}
        pb={[20, 20, 0]}
        height={'100%'}
      >
        <TextContainer>
          <BreadcrumbsContainer
            initial="hidden"
            animate={'visible'}
            variants={breadcrumbsAnim}
          >
            <Breadcrumbs mb={15} items={breadcrumb} white />
          </BreadcrumbsContainer>
          <TitleContainer
            initial="hidden"
            animate={'visible'}
            variants={titleAnim}
          >
            <StyledTitle
              as={'h1'}
              maxWidth={570}
              mb={[14, null, 26]}
              lineHeight={1.1}
            >
              {title}
            </StyledTitle>
          </TitleContainer>
          <ParagraphContainer
            initial="hidden"
            animate={'visible'}
            variants={paraAnim}
          >
            <StyledParagraph maxWidth={410} html={subtitle} />
          </ParagraphContainer>
        </TextContainer>
        <ImageContainer>
          <ImageAnimationLayer
            initial="hidden"
            animate={'visible'}
            variants={imageFaderAnim}
          >
            <StyledImage fluid={image} />
          </ImageAnimationLayer>
        </ImageContainer>
      </StyledContainer>
    </ServicesHeaderContainer>
  );
}

export default ServicesHeader;
